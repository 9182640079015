import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';
import { Grid } from '@mui/material';
import Form from '../../../../Atoms/Form/Form';
import AutocompleteForm from '../../../../Molecules/Autocomplete/AutocompleteForm';
import {
  ADMIN,
  PROJECT_MANAGER,
  DEPARTMENT_MANAGER,
  MECHANIC,
  CONTRACTOR_STOPA,
} from '../../../../../../../shared/src/constants/general';
import { getSortedDataTwo, getSortedData } from '../../../../../helpers/getSortedData';
import { userSelector, projectSelector } from '../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  timeEntries: state.rootReducer.timeEntries,
  // redux-form
  calendarWeek: state.form.calendarWeek,
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  auth: any;
  timeEntries: any[];
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  calendarWeek?: any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class CalendarWeek extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    calendarWeek: null,
  };

  componentWillMount() {
    const { auth, users, initialize } = this.props;
    // set the current week as predefined
    const currentDate = new Date();
    const currentWeek = moment(currentDate).week();
    const currentYear = moment(currentDate).year();
    const mondayDate = moment(currentDate, 'YYYY-WW').startOf('week').format('DD.MM.');
    const sundayDate = moment(currentDate, 'YYYY-WW').endOf('week').format('DD.MM.YY');
    const week = {
      label: `KW ${currentWeek} ${mondayDate}-${sundayDate}`,
      value: `${currentYear}-${currentWeek}`,
    };
    let user;
    if (auth.rightId === MECHANIC) {
      user = users({
        include: { userId: auth.id },
      });
    } else {
      user = null;
    }
    initialize({
      week,
      user,
      project: null,
    });
  }
  getProjectOptions = () => {
    const {
      auth,
      projects,
      // userId,
      calendarWeek,
    } = this.props;
    const userId = calendarWeek.values.user ? [parseInt(calendarWeek.values.user.value, 10)] : null;
    const sortedProjects = getSortedData(
      projects({ option: { plain: true, deactivated: true } }),
      'number',
      true
    ).map((p: any) => p.id);
    // check if any user has been selected
    if (!userId) {
      return [];
    }
    // if admin send all projects back where the user is in
    if (auth.rightId === ADMIN) {
      return projects({
        option: { deactivated: true },
        include: { userId, projectId: sortedProjects },
      });
    }
    // if not, send only the projects back where the selected user is in, and the logged in user
    userId.push(auth.id);
    return projects({
      option: { deactivated: true },
      include: { userId, projectId: sortedProjects },
    });
  };
  getWeekOptions = () => {
    const { timeEntries } = this.props;
    // set the current week as predefined
    const currentDate = new Date();
    const currentWeek = moment(currentDate).week();
    const currentYear = moment(currentDate).year();
    // get only the week and year
    const onlyWeekYear = timeEntries
      .map((entry: any) => ({ week: entry.week, year: entry.year }))
      .concat({ week: currentWeek, year: currentYear }) // concat the current week
      .reduce(
        (r: any, i: any) =>
          !r.some((j: any) => JSON.stringify(i) === JSON.stringify(j)) ? [...r, i] : r,
        []
      ); // remove all duplicates
    // sort with year and week, then create new array
    const sorted = getSortedDataTwo(onlyWeekYear, 'week', 'year', false).map((entry: any) => {
      const mondayDate = moment(`${entry.year}-${entry.week}`, 'YYYY-WW')
        .startOf('week')
        .format('DD.MM.');
      const sundayDate = moment(`${entry.year}-${entry.week}`, 'YYYY-WW')
        .endOf('week')
        .format('DD.MM.YY');
      return {
        label: `KW ${entry.week} ${mondayDate}-${sundayDate}`,
        value: `${entry.year}-${entry.week}`,
      };
    });
    return sorted;
  };
  getUserOptions = () => {
    const { auth, users, projects } = this.props;
    let userProjects;
    // if admin, get all projects, if not, get only the ones the user is in
    if (auth.rightId === ADMIN) {
      userProjects = projects({ option: { plain: true } });
    } else {
      userProjects = projects({
        include: { userId: auth.id },
        option: { plain: true },
      });
    }
    // const usersData = [];
    if (userProjects) {
      // if higher level than mechanic, get all users in all projects
      let userIds: number[] = [];
      userProjects.forEach((project: any) => {
        userIds = userIds.concat(project.userIds);
      });
      // check if the user is an admin
      // if so, return all users, except department managers
      if (auth.rightId === ADMIN) {
        return users({
          exclude: { rightId: DEPARTMENT_MANAGER },
          include: { userId: userIds },
        });
      }
      // check if the user is an department manager
      // if so, return all users with the right mechanic
      if (auth.rightId === DEPARTMENT_MANAGER) {
        return users({
          include: { userId: userIds, rightId: MECHANIC },
        });
      }
      // check if the user is an project manager
      // if so, return all users and include the user himself
      if (auth.rightId === PROJECT_MANAGER) {
        const allUsers = users({
          include: { userId: userIds, rightId: MECHANIC },
        });
        const usersData = [
          users({
            include: { userId: auth.id },
          }),
        ];
        return usersData.concat(allUsers);
      }
      // if none of the above
      // its a mechanic, so send the user and the externals back
      // and contractor is stopa
      if (auth.contractorId === CONTRACTOR_STOPA) {
        const externalUsers = users({
          include: { userId: userIds, rightId: MECHANIC },
          exclude: { contractorId: CONTRACTOR_STOPA },
        });
        const usersData = [
          users({
            include: { userId: auth.id },
          }),
        ];
        return usersData
          .concat(externalUsers)
          .filter((value, i, self) => self.indexOf(value) === i);
      }
      // its an external contractor
      return [
        users({
          include: { userId: auth.id },
        }),
      ];
    }
    return [];
  };
  render() {
    const { calendarWeek } = this.props;
    return (
      <Fragment>
        <Grid
          item
          container
          spacing={3}
          justifyContent="flex-start"
          md={12}
          alignItems="flex-start"
        >
          {calendarWeek && (
            <Form>
              <AutocompleteForm
                size={3}
                options={this.getWeekOptions()}
                label="Übersicht"
                name="week"
                isClearable={false}
              />
              <AutocompleteForm size={6} options={this.getUserOptions()} label="für" name="user" />
              <AutocompleteForm
                size={3}
                options={this.getProjectOptions()}
                label="Projekt"
                name="project"
              />
            </Form>
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'calendarWeek',
  })(CalendarWeek)
);
