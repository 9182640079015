import {
  SET_EDITED_FORMS,
  SET_SNACKBAR,
  SET_ADMIN_SECTION,
  SET_AUTH,
  SET_USERS,
  SET_PROJECTS,
  SET_PROJECT_VARIABLES,
  SET_CUSTOMERS,
  SET_CUSTOMER_CONTACTS,
  SET_CONTRACTORS,
  SET_GROUPS,
  SET_ROLES,
  SET_RIGHTS,
  SET_SITES,
  SET_RULES,
  SET_RULE_VARIABLES,
  SET_TIME_ENTRIES,
  SET_PROJECT_TIME_ENTRIES,
  SET_BILLINGS,
  SET_TIME_SHEETS,
  SET_VISITS,
  // sidebar
  SET_SIDEBAR,
  // dialogs
  SET_GREETING_DIALOG,
  SET_SERVICE_ENTRY_DIALOG,
  SET_TIME_ENTRY_CREATE_DIALOG,
  SET_TIME_ENTRY_EDIT_DIALOG,
  SET_SIGNATURE_CREATE_DIALOG,
  SET_PROJECT_VISIT_DIALOG,
  // For edit
  SET_SINGLE_SERVICE_ENTRY,
  SET_SINGLE_TIME_ENTRY,
  SET_SINGLE_VISIT_ENTRY,
  // Print
  SET_PRINT_ENTRIES,
  SET_PRINT_OPTIONS,
  // forms
  SET_QUESTION_DETAILS,
  SET_FORMS,
  SET_ANSWER_CHECKS,
  SET_ANSWER_SITE_START,
  SET_STATION_TYPES,
  // service projects
  SET_SERVICE_PROJECTS,
  SET_SERVICE_ENTRIES,
  SET_IS_LOADING_TIME_SHEET,
  SET_COMBINED_TIME_ENTRIES,
  SET_ALL_SERVICE_ENTRIES,
} from '../actions/ActionTypes';

export interface State {
  [key: string]: any;
}

const initialState = {
  editedForms: null,
  snackbar: null,
  adminSection: 'user',
  auth: null,
  users: null,
  projects: null,
  projectTypes: null,
  projectStates: null,
  projectKinds: null,
  projectCategories: null,
  customers: null,
  customerContacts: null,
  sites: null,
  contractors: null,
  groups: null,
  roles: null,
  rights: null,
  rules: null,
  services: null,
  serviceTypes: null,
  specificationAs: null,
  specificationBs: null,
  timeEntries: null,
  projectTimeEntries: null,
  billings: null,
  billingTypes: null,
  timeSheets: null,
  visits: null,
  // isLoading
  isLoadingTimeSheet: false,
  // sidebar
  sidebarOpen: false,
  // dialogs
  greetingDialogOpen: true,
  timeEntryDialogCreateOpen: false,
  serviceEntryDialogOpen: false,
  timeEntryDialogEditOpen: false,
  signatureDialogCreateOpen: false,
  projectVisitDialogOpen: false,
  // edit
  // project: null,
  // user: null,
  serviceEntry: null,
  timeEntry: null,
  visitEntry: null,
  // Print
  printEntries: null,
  printOptions: null,
  // Forms
  questionTypes: null,
  formTypes: null,
  questions: null,
  forms: null,
  formSections: null,
  formStationTypes: null,
  answerChecks: null,
  answerSiteStarts: null,
  stationTypes: null,
  // service projects
  serviceProjects: null,
  serviceEntries: null,
  combinedTimeEntries: null,
  serviceTimeEntries: null,
};
const rootReducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case SET_EDITED_FORMS:
      return {
        ...state,
        editedForms: action.payload,
      };
    case SET_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: action.payload.open,
          message: action.payload.message,
        },
      };
    case SET_ADMIN_SECTION:
      return { ...state, adminSection: action.payload };
    case SET_AUTH:
      return { ...state, auth: action.payload };
    case SET_IS_LOADING_TIME_SHEET:
      return { ...state, isLoadingTimeSheet: action.payload };
    case SET_SIDEBAR:
      return { ...state, sidebarOpen: action.payload };
    case SET_TIME_ENTRY_CREATE_DIALOG:
      return { ...state, timeEntryDialogCreateOpen: action.payload };
    case SET_SERVICE_ENTRY_DIALOG:
      return { ...state, serviceEntryDialogOpen: action.payload };
    case SET_GREETING_DIALOG:
      return { ...state, greetingDialogOpen: action.payload };
    case SET_TIME_ENTRY_EDIT_DIALOG:
      return { ...state, timeEntryDialogEditOpen: action.payload };
    case SET_SIGNATURE_CREATE_DIALOG:
      return { ...state, signatureDialogCreateOpen: action.payload };
    case SET_PROJECT_VISIT_DIALOG:
      return { ...state, projectVisitDialogOpen: action.payload };
    case SET_USERS:
      return { ...state, users: action.payload };
    case SET_PROJECTS:
      return { ...state, projects: action.payload };
    case SET_PROJECT_VARIABLES:
      return {
        ...state,
        projectTypes: action.payload.types,
        projectStates: action.payload.states,
        projectKinds: action.payload.kinds,
        projectCategories: action.payload.categories,
      };
    case SET_CUSTOMERS:
      return { ...state, customers: action.payload };
    case SET_CUSTOMER_CONTACTS:
      return { ...state, customerContacts: action.payload };
    case SET_SITES:
      return { ...state, sites: action.payload };
    case SET_CONTRACTORS:
      return { ...state, contractors: action.payload };
    case SET_GROUPS:
      return { ...state, groups: action.payload };
    case SET_ROLES:
      return { ...state, roles: action.payload };
    case SET_RIGHTS:
      return { ...state, rights: action.payload };
    case SET_RULES:
      return { ...state, rules: action.payload };
    case SET_RULE_VARIABLES:
      return {
        ...state,
        services: action.payload.services,
        serviceTypes: action.payload.serviceTypes,
        specificationAs: action.payload.specificationAs,
        specificationBs: action.payload.specificationBs,
      };
    case SET_PROJECT_TIME_ENTRIES:
      return { ...state, projectTimeEntries: action.payload };
    case SET_TIME_ENTRIES:
      return { ...state, timeEntries: action.payload };
    case SET_TIME_SHEETS:
      return { ...state, timeSheets: action.payload };
    case SET_VISITS:
      return { ...state, visits: action.payload };
    case SET_BILLINGS:
      return {
        ...state,
        billings: action.payload.billings,
        billingTypes: action.payload.billingTypes,
      };
    // Locally to edit
    case SET_SINGLE_TIME_ENTRY:
      return { ...state, timeEntry: action.payload };
    case SET_SINGLE_SERVICE_ENTRY:
      return { ...state, serviceEntry: action.payload };
    case SET_SINGLE_VISIT_ENTRY:
      return { ...state, visitEntry: action.payload };
    case SET_PRINT_ENTRIES:
      return {
        ...state,
        printEntries: {
          entries: action.payload.entries,
          week: action.payload.week,
          userId: action.payload.userId,
          projectId: action.payload.projectId,
        },
      };
    case SET_PRINT_OPTIONS:
      return {
        ...state,
        printOptions: {
          isInternalDoc: action.payload.isInternalDoc,
        },
      };
    // Forms
    case SET_STATION_TYPES:
      return {
        ...state,
        stationTypes: action.payload,
      };
    case SET_QUESTION_DETAILS:
      return {
        ...state,
        questionTypes: action.payload.questionTypes,
        formTypes: action.payload.formTypes,
      };
    case SET_FORMS:
      return {
        ...state,
        forms: action.payload.forms,
        formStationTypes: action.payload.formStationTypes,
        formSections: action.payload.formSections,
      };
    case SET_ANSWER_CHECKS:
      return {
        ...state,
        answerChecks: action.payload,
      };
    case SET_ANSWER_SITE_START:
      return {
        ...state,
        answerSiteStarts: action.payload,
      };
    // service projects
    case SET_SERVICE_PROJECTS: {
      return {
        ...state,
        serviceProjects: action.payload,
      };
    }
    case SET_SERVICE_ENTRIES: {
      return {
        ...state,
        serviceEntries: {
          ...state.serviceEntries,
          [action.payload.serviceProjectId]: action.payload.serviceEntries,
        },
      };
    }
    case SET_COMBINED_TIME_ENTRIES: {
      return {
        ...state,
        combinedTimeEntries: action.payload,
      };
    }
    case SET_ALL_SERVICE_ENTRIES: {
      return {
        ...state,
        serviceTimeEntries: action.payload,
      };
    }
    default:
      return state;
  }
};
export default rootReducer;
