import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const serviceTypeSelector = (
  state: any,
  {
    include = {
      serviceTypeId: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.serviceTypes,
    (serviceTypes) => {
      // for single selected serviceType
      if (checkSingleValue(include.serviceTypeId)) {
        const serviceTypeId = Array.isArray(include.serviceTypeId)
          ? include.serviceTypeId![0]
          : include.serviceTypeId;
        // @ts-ignore
        const serviceType = serviceTypes.find((sT: any) => sT.id === parseInt(serviceTypeId, 10));
        if (serviceType) {
          if (option.plain) {
            return serviceType;
          }
          return { label: serviceType.name, value: `${serviceType.id}` };
        }
        return null;
      }

      if (serviceTypes) {
        if (option.plain) {
          return serviceTypes;
        }
        // @ts-ignore
        return serviceTypes.map((serviceType: any) => ({
          label: serviceType.name,
          value: `${serviceType.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
