import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change as changeAction } from 'redux-form';
import Form from '../../../../../../Atoms/Form/Form';
import Button from '../../../../../../Atoms/Button/Button';
import Typography from '../../../../../../Atoms/Typography/Typography';
import { createInitialFormValues, renderForm } from '../../../../../../Molecules/Forms/FormHelpers';
import { saveFormAction } from '../../../../../../../actions';
import { siteStartCompletion } from '../../../../../../../helpers/formCompletion';
import { MECHANIC } from '../../../../../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  siteStartGeneral: state.form.siteStartGeneral,
  auth: state.rootReducer.auth,
  answerSiteStarts: state.rootReducer.answerSiteStarts,
  forms: state.rootReducer.forms,
});

const mapDispatchToProps = (dispatch: any) => ({
  change: (field: any, value: any, form?: any) => dispatch(changeAction(field, value, form)),
  saveForm: (data: any) => dispatch(saveFormAction(data)),
});

type SiteStartGeneralProps = {
  initialize: (...args: any[]) => any;
  saveForm: (...args: any[]) => any;
  formId: number;
  siteStartGeneral?: any;
  forms: any[];
  auth: any;
  answerSiteStarts: any[];
};
class SiteStartGeneral extends React.Component<SiteStartGeneralProps, {}> {
  static defaultProps = {
    siteStartGeneral: null,
  };

  componentDidMount() {
    const { initialize, answerSiteStarts } = this.props;
    // create the value objects
    const initValues = createInitialFormValues(answerSiteStarts);
    initialize({
      ...initValues,
    });
  }
  handleComplete = () => {
    const { saveForm, formId, auth } = this.props;
    saveForm({ formId, completed: 1, completedBy: auth.id });
  };
  render() {
    const { siteStartGeneral, answerSiteStarts, auth, formId, forms } = this.props;
    const completeEnabled = siteStartCompletion(answerSiteStarts).notAnswered === 0;
    const formCompleted = forms.find(f => f.id === formId).completed;
    return (
      <>
        {siteStartGeneral && (
          <Form>
            {siteStartGeneral.values && (
              <>
                {renderForm({
                  answers: answerSiteStarts,
                  formData: siteStartGeneral,
                  formCompleted,
                })}
              </>
            )}
            <Button
              disabled={!completeEnabled || auth.rightId === MECHANIC || formCompleted}
              handleClick={this.handleComplete}
            >
              Abschließen
            </Button>
          </Form>
        )}
        <Typography
          size={12}
          value="Sind Punkte nicht geklärt oder nicht vorhanden, ist umgehend der Projektleiter und Montageleiter zu informieren!"
          variant="h6"
          color="error"
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'siteStartGeneral',
  })(SiteStartGeneral)
);
