import React, { Fragment } from 'react';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';

const CustomerDetails = () => (
  <Fragment>
    <TextFieldForm name="name" label="Name" />
    <TextFieldForm size={4} name="adress" label="Adresse" />
    <TextFieldForm name="number" label="Kundennummer" />
    <TextFieldForm name="adressAdditional" label="Adresszusatz" />
    <TextFieldForm name="additionalField" label="Zusatzfeld" />
    <TextFieldForm name="postalCode" label="Postleitzahl" />
    <TextFieldForm name="city" label="Stadt" />
    <TextFieldForm name="country" label="Land" />
    <TextFieldForm name="telephone1" label="Tel 1" type="number" />
    <TextFieldForm name="telephone2" label="Tel 2" type="number" />
    <TextFieldForm name="telephone3" label="Tel 3" type="number" />
    <TextFieldForm name="email" label="E-Mail" />
  </Fragment>
);

export default CustomerDetails;
