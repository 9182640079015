// Api
import makeRequest from '../helpers/Requests';
// import store from '../store/index';

// Get authentication
// export const getAuthenticationData = async () => {
//   const result = await makeRequest('GET', 'authentication');
//   return result;
// };
// GET ALL DATA

export const getUsersData = async () => {
  const result = await makeRequest('GET', 'user');
  if (!Object.prototype.hasOwnProperty.call(result, 'error')) {
    const changedResult = result.map((user: any) => {
      const u = { ...user };
      u.roles = user.roles.map((role: any) => role.id);
      u.groups = user.groups.map((group: any) => group.id);
      return u;
    });
    return changedResult;
  }
  return result;
};

export const getProjectsData = async () => {
  const result = await makeRequest('GET', 'project');
  const changedResult = result.map((project: any) => {
    const p = { ...project };
    p.userIds = project.userIds.map((user: any) => user.id);
    p.customerContactIds = project.customerContactIds.map(
      (customerContact: any) => customerContact.id
    );
    p.projectCategoryIds = project.projectCategories.map(
      (projectCategory: any) => projectCategory.id
    );
    // p.pWA = project.pWA.map(pWA => pWA);
    return p;
  });
  return changedResult;
};

export const getRulesData = async () => {
  const result = await makeRequest('GET', 'rule');
  const changedResult = result.map((rule: any) => {
    const r = { ...rule };
    r.groups = rule.groups.map((group: any) => group.id);
    r.roles = rule.roles.map((role: any) => role.id);
    return r;
  });
  return changedResult;
};

export const getTimeEntriesData = async (selectedWeek: any, selectedYear: any) => {
  // merge the entries together for each date
  const allDates = await makeRequest('GET', `timeEntry?week=${selectedWeek}&year=${selectedYear}`);
  return allDates;
};
// GENERAL GET DATA FUNCTION
export const getGeneralData = async (path: any, param: string | null = null) => {
  const result = param
    ? await makeRequest('GET', `${path}/${param}`)
    : await makeRequest('GET', `${path}`);
  return result;
};

// ADD RECORD

export const addFormStationData = async (data: any) => {
  const result = await makeRequest('POST', 'form/station', data);
  return result;
};

// USER
export const addUserData = async (data: any) => {
  const result = await makeRequest('POST', 'user', data);

  return result;
};

// PROJECT
export const addProjectData = async (data: any) => {
  const result = await makeRequest('POST', 'project', data);
  return result;
};

// RULE
export const addRuleData = async (data: any) => {
  const result = await makeRequest('POST', 'rule', data);
  return result;
};

export const addServiceData = async (name: any) => {
  const result = await makeRequest('POST', 'service', {
    name,
  });
  return result;
};

export const addSpecificationAData = async (name: any) => {
  const result = await makeRequest('POST', 'specificationA', {
    name,
  });
  return result;
};

export const addSpecificationBData = async (name: any) => {
  const result = await makeRequest('POST', 'specificationB', {
    name,
  });
  return result;
};

export const addTimeEntryData = async (data: any) => {
  const result = await makeRequest('POST', 'timeEntry', data);

  return result;
};

export const addTimeSheetData = async (data: any) => {
  const result = await makeRequest('POST', 'timeSheet', data);
  return result;
};

// CUSTOMER
export const addCustomerData = async (data: any) => {
  const result = await makeRequest('POST', 'customer', data);
  return result;
};

export const addCustomerContactData = async (data: any) => {
  const result = await makeRequest('POST', 'customerContact', data);
  return result;
};

export const addSiteData = async (data: any) => {
  const result = await makeRequest('POST', 'site', data);
  return result;
};

// CONTRACTOR
export const addContractorData = async (data: any) => {
  const result = await makeRequest('POST', 'contractor', data);
  return result;
};

// SAVE RECORD
export const saveRuleData = async (rule: any) => {
  const result = await makeRequest('PUT', `rule/${rule.id}`, {
    rule,
  });
  return result;
};

export const saveServiceData = async (service: any) => {
  const result = await makeRequest('PUT', `service/${service.id}`, {
    service,
  });
  return result;
};

export const saveSpecificationAData = async (specificationA: any) => {
  const result = await makeRequest('PUT', `specificationA/${specificationA.id}`, {
    specificationA,
  });
  return result;
};

export const saveSpecificationBData = async (specificationB: any) => {
  const result = await makeRequest('PUT', `specificationB/${specificationB.id}`, {
    specificationB,
  });
  return result;
};

export const saveTimeEntryData = async (timeEntry: any) => {
  const result = await makeRequest('PUT', `timeEntry/${timeEntry.id}`, {
    timeEntry,
  });
  return result;
};

export const saveTimeSheetData = async (timeSheet: any) => {
  const result = await makeRequest('PUT', `timeSheet/${timeSheet.id}`, {
    timeSheet,
  });
  return result;
};

export const saveWorkLogAllData = async ({ userId, week, year }: any) => {
  const result = await makeRequest('PUT', 'workLog/all', {
    userId,
    week,
    year,
  });
  return result;
};

export const saveUserData = async (user: any) => {
  const result = await makeRequest('PUT', `user/${user.id}`, {
    user,
  });
  return result;
};

export const saveProjectData = async (project: any) => {
  const result = await makeRequest('PUT', `project/${project.id}`, {
    project,
  });
  return result;
};

export const saveCustomerData = async (customer: any) => {
  const result = await makeRequest('PUT', `customer/${customer.id}`, {
    customer,
  });
  return result;
};

export const saveCustomerContactData = async (customerContact: any) => {
  const result = await makeRequest('PUT', `customerContact/${customerContact.id}`, {
    customerContact,
  });
  return result;
};

export const saveSiteData = async (site: any) => {
  const result = await makeRequest('PUT', `site/${site.id}`, {
    site,
  });
  return result;
};

export const saveContractorData = async (contractor: any) => {
  const result = await makeRequest('PUT', `contractor/${contractor.id}`, {
    contractor,
  });
  return result;
};

// FORM
export const saveFormData = async (data: any) => {
  const result = await makeRequest('PUT', `form/${data.formId}`, {
    data,
  });
  return result;
};

export const saveFormSectionData = async (data: any) => {
  const result = await makeRequest('PUT', `form/${data.formId}/section`, {
    sectionId: data.sectionId,
  });
  return result;
};

export const saveFormStationData = async (data: any) => {
  const result = await makeRequest('PUT', `form/${data.formId}/station`, {
    stationId: data.stationId,
  });
  return result;
};

export const saveAnswerChecksData = async (
  answerChecks: any,
  projectId: any,
  formStationTypeId: any
) => {
  const result = await makeRequest('PUT', `answerCheck/${projectId}`, {
    answerChecks,
    formStationTypeId,
  });
  return result;
};
export const saveSiteStartData = async (answerSiteStart: any, projectId: any) => {
  const result = await makeRequest('PUT', `answerSiteStart/${projectId}`, {
    answerSiteStart,
  });
  return result;
};

// DELETE RECORD
export const deleteUserData = async (id: any) => {
  const result = await makeRequest('DELETE', `user/${id}`);
  return result;
};

export const deleteProjectData = async (id: any) => {
  const result = await makeRequest('DELETE', `project/${id}`);
  return result;
};

export const deleteRuleData = async (id: any) => {
  const result = await makeRequest('DELETE', `rule/${id}`);
  return result;
};

export const deleteCustomerData = async (customerId: any) => {
  const result = await makeRequest('DELETE', `customer/${customerId}`);
  return result;
};

export const deleteCustomerContactData = async (id: any) => {
  const result = await makeRequest('DELETE', `customerContact/${id}`);
  return result;
};

export const deleteSiteData = async (siteId: any) => {
  const result = await makeRequest('DELETE', `site/${siteId}`);
  return result;
};

export const deleteContractorData = async (id: any) => {
  const result = await makeRequest('DELETE', `contractor/${id}`);
  return result;
};

export const deleteServiceData = async (id: any) => {
  const result = await makeRequest('DELETE', `service/${id}`);
  return result;
};

export const deleteSpecificationAData = async (id: any) => {
  const result = await makeRequest('DELETE', `specificationA/${id}`);
  return result;
};

export const deleteSpecificationBData = async (id: any) => {
  const result = await makeRequest('DELETE', `specificationB/${id}`);
  return result;
};

export const deleteTimeEntryData = async (id: any) => {
  const result = await makeRequest('DELETE', `timeEntry/${id}`);
  return result;
};

export const deleteFormStationData = async (id: any) => {
  const result = await makeRequest('DELETE', `form/station/${id}`);
  return result;
};

export const duplicateFormData = async (formId: any) => {
  const result = await makeRequest('POST', `answercheck/${formId}/duplicate`);
  return result;
};

// SERVICE PROJECT
export const addServiceProjectData = async (data: any) => {
  const result = await makeRequest('POST', 'serviceProject', data);
  return result;
};

export const saveServiceProjectData = async (serviceProject: any) => {
  const result = await makeRequest('PUT', `serviceProject/${serviceProject.id}`, {
    serviceProject,
  });
  return result;
};
export const getServiceProjectsData = async () => {
  const result = await makeRequest('GET', `serviceProject`);
  return result;
};

// SERVICE ENTRY
export const addServiceEntryData = async (data: any) => {
  const result = await makeRequest('POST', 'serviceEntry', data);

  return result;
};
export const saveServiceEntryData = async (serviceEntry: any) => {
  const result = await makeRequest('PUT', `serviceEntry/${serviceEntry.id}`, {
    serviceEntry,
  });
  return result;
};
export const deleteServiceEntryData = async (id: any) => {
  const result = await makeRequest('DELETE', `serviceEntry/${id}`);
  return result;
};
export const getServiceEntriesData = async () => {
  // merge the entries together for each date
  const result = await makeRequest('GET', `serviceEntry`);
  return result;
};
export const getServiceEntriesWeeksData = async (selectedWeek: any, selectedYear: any) => {
  const result = await makeRequest(
    'GET',
    `serviceEntry/time?week=${selectedWeek}&year=${selectedYear}`
  );
  return result;
};

export default {};
