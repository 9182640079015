import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Image from '../../../../../../assets/img/STOPA-Logo.svg';
import {
  groupSelector,
  roleSelector,
  contractorSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  contractors: (filter: any) => contractorSelector(state, filter),
});

interface ComponentOwnProps {
  form: any;
  project: any;
}

interface ComponentStateProps {
  groups: (...args: any[]) => any;
  roles: (...args: any[]) => any;
  contractors: (...args: any[]) => any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class PrintSiteStartHeader extends React.Component<ComponentProps, {}> {
  render() {
    const { form, project, groups, roles, contractors } = this.props;
    return (
      <div>
        <div className="form-meta">
          <strong>Prüfprotokoll Baustellenstart</strong>
          <p>
            <strong>{form.name}</strong>
            <span>
              zuletzt gespeichert am:{' '}
              <strong>
                {form.updatedAt ? moment(form.updatedAt, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''}
              </strong>
            </span>
          </p>
          <img src={Image} width="100px" className="meta-logo" alt="logo" />
        </div>
        <div className="information-container">
          <div className="standard-information-wrapper site-start-information  mb-25">
            <div className="col">
              <p>
                <strong>Projekt-Nr.:</strong> {project.number}
              </p>
              <p>
                <strong>Kunde:</strong> {project.customer.label}
              </p>
              <p>
                <strong>Ort/Land:</strong> {project.site.label}
              </p>
            </div>
            <div className="col">
              <p>
                <strong>Lagertyp:</strong> {project.projectKind.label}
              </p>
              <p>
                <strong>Lagerart:</strong> {project.projectType.label}
              </p>
              <p>
                <strong>FAB-Nr.:</strong> {project.fab}
              </p>
              <p>
                <strong>AB-Nr.:</strong> {project.ab}
              </p>
            </div>
            <div className="col">
              <p>
                <strong>PL. Stopa: </strong>
                {project.projectManager[0]
                  ? `${project.projectManager[0].lastName}, ${project.projectManager[0].firstName}`
                  : 'k.A.'}
              </p>
              <p>
                <strong>AL. Stopa: </strong>
                {project.projectDepartmentManager[0]
                  ? `${project.projectDepartmentManager[0].lastName}, ${project.projectDepartmentManager[0].firstName}`
                  : 'k.A.'}
              </p>
              <p>
                <strong>GU-Anspr.:</strong> {form.responsibleContractor}
              </p>
              <p>
                <strong>Verantw. Me.: </strong>
                {form.responsibleMechanic
                  ? `${form.responsibleMechanic.lastName}, ${form.responsibleMechanic.firstName}`
                  : 'k.A.'}
              </p>
              <p>
                <strong>Verantw. El.: </strong>
                {form.responsibleElectrician
                  ? `${form.responsibleElectrician.lastName}, ${form.responsibleElectrician.firstName}`
                  : 'k.A.'}
              </p>
            </div>
            <div className="col">
              {groups({ option: { plain: true } }).map((gr: any) => {
                return (
                  <p key={gr.id}>
                    <strong>{gr.shortName}-WA:</strong> {project[gr.shortName]}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        <div className="standard-information-wrapper site-start-information user-table-wrapper mb-25 flex-col">
          <strong className="mb-25">Projekt-MA</strong>
          <table className="entry-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>E-Mail</th>
                <th>Telefon</th>
                <th>Rolle</th>
                <th>Firma</th>
              </tr>
            </thead>
            {project.projectUser.map((user: any) => {
              return (
                <tbody key={user.id}>
                  <tr>
                    <td>
                      {user.lastName}
                      {', '}
                      {user.firstName}
                    </td>
                    <td>{user.email}</td>
                    <td>{user.telephone}</td>
                    <td>
                      {roles({
                        include: { roleId: user.roles },
                        option: { plain: true, array: true },
                      }).map((r: any) => `${r.shortName} `)}
                    </td>
                    <td>
                      {
                        contractors({
                          include: { contractorId: user.contractorId },
                          option: { plain: true },
                        }).shortName
                      }
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(PrintSiteStartHeader);
