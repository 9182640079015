import React, { memo } from 'react';
import { connect } from 'react-redux';
import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { withRouter } from 'react-router-dom';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';
import { projectSelector, customerSelector, formSelector } from '../../../helpers/selectors';
import { compareProps } from '../../../helpers/compareProps';
import {
  FORM_TYPE_CHECK,
  FORM_TYPE_SITE_START,
  MECHANIC,
  FORM_TYPE_SITE_CHECK,
  FORM_TYPE_RETROFIT,
} from '../../../../../shared/src/constants/general';
import { getSortedData } from '../../../helpers/getSortedData';

const styles = {
  paddingLeftZero: {
    '& *': { paddingLeft: 0 },
  },
};

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  projects: (filter: any) => projectSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  adminSection: state.rootReducer.adminSection,
  // forms
  forms: (filter: any) => formSelector(state, filter),
  formTypes: state.rootReducer.formTypes,
  formFilter: state.form.formFilter,
});

type FormTableProps = {
  auth: any;
  history: any;
  projects: (...args: any[]) => any;
  forms: (...args: any[]) => any;
  adminSection: string;
  customers: (...args: any[]) => any;
  formTypes: any[];
  formFilter?: any;
};

const FormTable: React.FC<FormTableProps> = memo(
  ({ history, formFilter = null, adminSection, forms, formTypes, projects, customers, auth }) => {
    const tableData = () => {
      let projectOptions = projects({
        option: { plain: true, deactivated: true },
      });
      let formOptions;
      if (formFilter) {
        const { values } = formFilter;
        if (values.projectStateId) {
          projectOptions = projectOptions.filter(
            (project: any) => project.projectStateId === parseInt(values.projectStateId, 10)
          );
        }
        if (values.project) {
          projectOptions = projectOptions.filter(
            (project: any) => project.id === parseInt(values.project.value, 10)
          );
        }
      }
      if (auth.rightId === MECHANIC) {
        projectOptions = projectOptions.filter((project: any) => project.userIds.includes(auth.id));
      }
      const projectId = projectOptions.map((el: any) => el.id);

      if (adminSection === 'formSiteStart') {
        formOptions = forms({
          include: { formTypeId: FORM_TYPE_SITE_START, projectId },
          option: { plain: true },
        });
      } else if (adminSection === 'formCheck') {
        // include Neulager and Retrofit Check
        formOptions = [
          ...forms({
            include: { formTypeId: FORM_TYPE_CHECK, projectId },
            option: { plain: true },
          }),
          ...forms({
            include: { formTypeId: FORM_TYPE_RETROFIT, projectId },
            option: { plain: true },
          }),
        ];
      } else if (adminSection === 'formSiteCheck') {
        formOptions = forms({
          include: { formTypeId: FORM_TYPE_SITE_CHECK, projectId },
          option: { plain: true },
        });
      } else {
        formOptions = forms({
          include: { projectId },
          option: { plain: true },
        });
      }
      formOptions = formOptions.map((form: any) => {
        const formProject = projects({
          include: { projectId: form.projectId },
          option: { plain: true },
        });
        const formCustomer = customers({
          include: { customerId: formProject.customerId },
          option: { plain: true },
        });
        const formFormType = formTypes.find((fT) => fT.id === form.formTypeId);

        const formData = {
          ...form,
          projectNumber: formProject.number,
          customerName: formCustomer.name,
          formTypeName: formFormType.name,
        };
        return formData;
      });

      return formOptions;
    };
    return (
      <Table sx={styles.paddingLeftZero} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Operation</TableCell>
            <TableCell>Projekt-Nr</TableCell>
            <TableCell>Kunde</TableCell>
            <TableCell>Protokoll</TableCell>
            <TableCell>Protokoll Typ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getSortedData(tableData(), 'projectNumber', false).map((form: any) => (
            <ActiveTableRow key={form.id}>
              <TableCell>
                <IconButton onClick={() => history.push(`form/${form.id}`)}>
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell>{form.projectNumber}</TableCell>
              <TableCell>{form.customerName}</TableCell>
              <TableCell>{form.name}</TableCell>
              <TableCell>{form.formTypeName}</TableCell>
            </ActiveTableRow>
          ))}
        </TableBody>
      </Table>
    );
  },
  compareProps
);

export default connect(mapStateToProps, null)(withRouter<any, any>(FormTable));
