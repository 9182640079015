import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ProjectCategorySelectorTable from '../ProjectCategorySelectorTable/ProjectCategorySelectorTable';

const mapStateToProps = (state: any) => ({
  // redux-form
  pageProjectEdit: state.form.pageProjectEdit,
});

type ProjectUserProps = {
  pageProjectEdit: object | null;
};

class ProjectUser extends React.Component<ProjectUserProps, {}> {
  static defaultProps = {
    pageProjectEdit: null,
  };

  render() {
    const { pageProjectEdit } = this.props;
    return (
      <Fragment>
        <ProjectCategorySelectorTable
          label="Kategorien"
          name="projectCategoriesAdd"
          formData={pageProjectEdit}
          formName="pageProjectEdit"
        />
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(ProjectUser);
