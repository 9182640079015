import React from 'react';
import { Tooltip, Box } from '@mui/material';

const styles = {
  buttonRight: {
    marginLeft: 'auto',
  },
  tooltip: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
  },
  span: { fontSize: 14 },
};

type TooltipIconProps = {
  // TODO: cleanup any
  icon: any;
  title?: string;
};

const TooltipIcon: React.SFC<TooltipIconProps> = ({ title = '', icon }) => {
  return (
    <Tooltip
      sx={styles.tooltip}
      title={
        <React.Fragment>
          <Box component="span" color="inherit" sx={styles.span}>
            {title}
          </Box>
        </React.Fragment>
      }
    >
      {icon}
    </Tooltip>
  );
};

export default TooltipIcon;
