import React, { FC, useRef } from 'react';
import Button from '../../../../Atoms/Button/Button';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';
import { projectSelector, customerSelector } from '../../../../../helpers/selectors';

export const ProjectExport: FC = () => {
  const projects = useSelector((state: any) => (filter: any) => projectSelector(state, filter));
  const customers = useSelector((state: any) => (filter: any) => customerSelector(state, filter));
  const csvLink = useRef<any>();
  const projectList = projects({
    option: { plain: true, deactivated: false },
  });

  const csvList = (projectList || []).map((project: any) => {
    const customerName = project.customerId
      ? customers({
          include: { customerId: project.customerId },
          option: { plain: true },
        }).name
      : null;
    return {
      'Projekt-Nr': project.number,
      Kunde: customerName,
      'WA - ME': project.ME,
      'WA - KS': project.KS,
      'WA - AT-INB': project['AT-INB'],
      'WA - LVS-INB': project['LVS-INB'],
      'WA - PL': project.PL,
      'WA - AT_VOR': project['AT-VOR'],
      'WA - LVS_VOR': project['LVS-VOR'],
    };
  });

  return (
    <>
      <Button variant="outlined" handleClick={() => csvLink?.current?.link?.click()}>
        CSV Export
      </Button>
      <CSVLink
        // @ts-ignore
        ref={csvLink}
        data={csvList}
        separator=";"
        filename="projekte.csv"
      ></CSVLink>
    </>
  );
};
