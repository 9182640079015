import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Grid, Box } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';
import DialogWrapper from '../../Atoms/DialogWrapper/DialogWrapper';
import Button from '../../Atoms/Button/Button';
import TextField from '../../Atoms/TextField/TextField';
import { setSignatureCreateDialogAction } from '../../../actions';

const styles = {
  signatureWrapper: {
    marginTop: '40px',
    // marginBottom: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: 'solid 2px #0072B6',
  },
};

const mapStateToProps = (state: any) => ({
  signatureDialogCreateOpen: state.rootReducer.signatureDialogCreateOpen,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSignatureCreateDialog: (open: any) => dispatch(setSignatureCreateDialogAction(open)),
});

interface ComponentOwnProps {
  classes?: any;
  handleSave: (signature: string, signedBy: string) => void;
  label?: string;
}

interface ComponentStateProps {
  signatureDialogCreateOpen: boolean;
}

interface ComponentDispatchProps {
  setSignatureCreateDialog: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

type DialogSignatureState = {
  signedBy: string | any;
  sigPadEmpty: boolean | void | any;
  hasRunningSaveProcess: boolean;
};

class DialogSignature extends PureComponent<ComponentProps, DialogSignatureState> {
  static defaultProps = {
    label: 'Unterschrieben durch',
  };

  sigPad = {
    clear: () => {},
    getTrimmedCanvas: () => {
      return { toDataURL: (val: any) => val };
    },
    isEmpty: () => {},
  };
  state = {
    signedBy: '',
    sigPadEmpty: true,
    hasRunningSaveProcess: false,
  };
  handleDelete = () => {
    this.sigPad.clear();
    this.sigPadChange();
  };

  handleSave = () => {
    this.setState({ hasRunningSaveProcess: true });
    const { setSignatureCreateDialog, handleSave } = this.props;
    const { signedBy } = this.state;
    const signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    this.sigPad.clear();
    handleSave(signature, signedBy);
    setSignatureCreateDialog(false);
  };

  handleChange = (name: any, event: any) => {
    if (name === 'signedBy') {
      this.setState({ signedBy: event.target.value });
    }
  };
  sigPadChange = () => {
    if (this.sigPad.isEmpty) {
      this.setState({ sigPadEmpty: this.sigPad.isEmpty() });
    }
  };
  checkSignatures = () => {
    const { sigPadEmpty, signedBy } = this.state;
    if (!sigPadEmpty) {
      if (signedBy.length !== 0) {
        return false;
      }
    }
    return true;
  };
  checkRunningSaveProcess = () => {
    return this.state.hasRunningSaveProcess;
  };
  render() {
    const {
      signatureDialogCreateOpen,
      setSignatureCreateDialog,
      label = "'Unterschrieben durch'",
    } = this.props;
    const { signedBy } = this.state;
    return (
      <DialogWrapper
        open={signatureDialogCreateOpen}
        handleClose={() => {
          this.setState({ hasRunningSaveProcess: false });
          setSignatureCreateDialog(false);
        }}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid container item md={12}>
            <Box sx={styles.signatureWrapper}>
              <SignatureCanvas
                canvasProps={{
                  width: 1000,
                  height: 500,
                  className: 'sigCanvas',
                  throttle: 1,
                }}
                ref={(ref: any) => {
                  this.sigPad = ref;
                }}
                onEnd={this.sigPadChange}
              />
            </Box>
          </Grid>
          <Grid container item spacing={2} style={{ width: 640 }}>
            <TextField
              size={12}
              id="signedBy"
              label={label}
              value={signedBy}
              handleChange={this.handleChange}
            />
            <Button size={6} handleClick={this.handleDelete}>
              Löschen
            </Button>
            <Button size={6} handleClick={this.handleSave}
                    disabled={this.checkSignatures()||this.checkRunningSaveProcess()}>
              Speichern und Schließen
            </Button>
          </Grid>
        </Grid>
      </DialogWrapper>
    );
  }
}
export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(DialogSignature);
