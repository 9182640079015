import React, { PureComponent, Fragment } from 'react';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import RuleToolbar from '../../../../Organisms/Toolbars/RuleToolbar';
import RuleDetails from '../../../../Molecules/AdminAreaDetails/RuleDetails';
import {
  serviceSelector,
  specificationASelector,
  specificationBSelector,
  ruleSelector,
} from '../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  rules: (filter: any) => ruleSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
});

type PageRuleEditProps = {
  initialize: (...args: any[]) => any;
  rules: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
  match: any;
};
class PageRuleEdit extends PureComponent<PageRuleEditProps, {}> {
  componentWillMount() {
    const { initialize, match, rules, services, specificationAs, specificationBs } = this.props;
    const ruleId = parseInt(match.params.id, 10);
    const rule = rules({ include: { ruleId } });
    const service = services({ include: { serviceId: rule.serviceId } });
    const specificationA = rule.specificationAId
      ? specificationAs({
          include: { specificationAId: rule.specificationAId },
        })
      : null;
    const specificationB = rule.specificationBId
      ? specificationBs({
          include: { specificationBId: rule.specificationBId },
        })
      : null;
    const withStation = rule.station === '1' ? ['1'] : [];
    initialize({
      ...rule,
      service,
      specificationA,
      specificationB,
      withStation,
    });
  }
  render() {
    return (
      <Fragment>
        <RuleToolbar withSave withDelete />
        <RuleDetails />
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  null
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageRuleEdit',
  })(withRouter<any, any>(PageRuleEdit))
);
