import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import NavBar from '../../Organisms/NavBar/NavBar';
import AdminOuterWrapper from '../AdminWrappers/AdminOuterWrapper';
import Sidebar from '../../Organisms/Sidebar/Sidebar';
import { DEPARTMENT_MANAGER } from '../../../../../shared/src/constants/general';
import { SIDEBAR_DIAGNOSTIC_ITEMS } from '../../../constants/all';
import { RouteComponentProps } from 'react-router-dom';

import {
  getProjectsAction,
  getCustomersAction,
  getUsersAction,
  getContractorsAction,
  getSitesAction,
  getGroupsAction,
  getVisitsAction,
  getRulesAction,
  getRuleVariablesAction,
  getProjectVariablesAction,
  getBillingsAction,
} from '../../../actions';

export default function PageDiagnosticWrapper(WrappedComponent: React.ComponentType) {
  const mapStateToProps = (state: any) => ({
    auth: state.rootReducer.auth,
    rules: state.rootReducer.users,
    users: state.rootReducer.users,
    projects: state.rootReducer.projects,
    customers: state.rootReducer.customers,
    sites: state.rootReducer.sites,
    contractors: state.rootReducer.contractors,
    services: state.rootReducer.services,
    billings: state.rootReducer.billings,
    specificationAs: state.rootReducer.specificationAs,
    specificationBs: state.rootReducer.specificationBs,
  });
  const mapDispatchToProps = (dispatch: any) => ({
    getProjects: () => dispatch(getProjectsAction()),
    getRules: () => dispatch(getRulesAction()),
    getCustomers: () => dispatch(getCustomersAction()),
    getUsers: () => dispatch(getUsersAction()),
    getContractors: () => dispatch(getContractorsAction()),
    getSites: () => dispatch(getSitesAction()),
    getGroups: () => dispatch(getGroupsAction()),
    getVisits: () => dispatch(getVisitsAction()),
    getRuleVariables: () => dispatch(getRuleVariablesAction()),
    getProjectVariables: () => dispatch(getProjectVariablesAction()),
    getBillings: () => dispatch(getBillingsAction()),
  });

  interface ComponentOwnProps {}

  interface ComponentStateProps {
    auth: any;
    users?: any;
    rules?: any;
    billings?: any;
    specificationBs?: any;
    specificationAs?: any;
    contractors?: any;
    sites?: any;
    customers?: any;
    projects?: any;
    services?: any;
  }

  interface ComponentDispatchProps {
    getVisits: (...args: any[]) => any;
    getProjects: (...args: any[]) => any;
    getUsers: (...args: any[]) => any;
    getProjectVariables: (...args: any[]) => any;
    getCustomers: (...args: any[]) => any;
    getContractors: (...args: any[]) => any;
    getSites: (...args: any[]) => any;
    getRules: (...args: any[]) => any;
    getRuleVariables: (...args: any[]) => any;
    getGroups: (...args: any[]) => any;
    getBillings: (...args: any[]) => any;
  }

  type ComponentProps = ComponentOwnProps &
    ComponentStateProps &
    ComponentDispatchProps &
    RouteComponentProps;

  class InnerWrapper extends PureComponent<ComponentProps, {}> {
    static defaultProps = {
      users: null,
      customers: null,
      projects: null,
      services: null,
      rules: null,
      billings: null,
      sites: null,
      specificationAs: null,
      specificationBs: null,
      contractors: null,
    };

    componentDidMount() {
      const {
        auth,
        history,
        getUsers,
        getRules,
        getProjects,
        getCustomers,
        getGroups,
        getContractors,
        getSites,
        getVisits,
        getRuleVariables,
        getBillings,
        getProjectVariables,
      } = this.props;
      getRules();
      getGroups();
      getProjects();
      getCustomers();
      getUsers();
      getContractors();
      getSites();
      getRuleVariables();
      getBillings();
      getProjectVariables();
      // visits is only for admins
      if (auth.rightId < DEPARTMENT_MANAGER) {
        getVisits();
      }
      // only show the current page settings if not admin
      if (auth.rightId >= DEPARTMENT_MANAGER) {
        history.push('/overview');
      }
    }
    render() {
      const {
        services,
        customers,
        sites,
        contractors,
        specificationAs,
        specificationBs,
        billings,
        rules,
        projects,
        users,
      } = this.props;
      return (
        <Fragment>
          <NavBar />
          <Sidebar sidebarItems={SIDEBAR_DIAGNOSTIC_ITEMS} />
          {projects &&
            sites &&
            billings &&
            customers &&
            specificationBs &&
            services &&
            specificationAs &&
            rules &&
            contractors &&
            users && (
              <AdminOuterWrapper>
                <WrappedComponent />
              </AdminOuterWrapper>
            )}
        </Fragment>
      );
    }
  }

  // return InnerWrapper;
  return connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(InnerWrapper);
}
