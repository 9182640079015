import React, { PureComponent, Fragment } from 'react';
import TextFieldForm from '../../../../../Atoms/TextField/TextFieldForm';
import AutocompleteForm from '../../../../../Molecules/Autocomplete/AutocompleteForm';
import formatTime from '../../../../../../helpers/FormatTime';
import Typography from '../../../../../Atoms/Typography/Typography';
import getTimeData from '../../../../../../helpers/timeFunctions';
import CheckboxesForm from '../../../../../Molecules/Checkboxes/CheckboxesForm';
import moment from 'moment';

type ServiceEntryDateTimeProps = {
  formData: any;
  handleFlush: (...args: any[]) => any;
  isDisabled?: boolean;
};
type ServiceEntryDateTimeState = {
  timeResult: number | string | null;
};

class ServiceEntryDateTime extends PureComponent<
  ServiceEntryDateTimeProps,
  ServiceEntryDateTimeState
> {
  state = {
    timeResult: null,
  };
  componentDidMount = () => {
    const { formData } = this.props;

    if (formData.values.timeStart && formData.values.timeEnd) {
      const timeStart = formData.values.timeStart.value;
      formData.values.date = moment(formData.values.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
      // const timeEnd = formData.values.timeEnd.value;
      const timeEnd =
        formData.values.timeEnd.value === '23:59' ? '24:00' : formData.values.timeEnd.value;
      this.changeTimeResult(timeStart, timeEnd);
    }
  };
  changeTimeResult = (timeStart: any, timeEnd: any) => {
    if (timeEnd === '24:00') {
      const calculatedTime = formatTime(timeStart, '23:00') + 1;
      const result = Number(calculatedTime) ? calculatedTime : '';
      this.setState({ timeResult: result });
    } else {
      const calculatedTime = formatTime(timeStart, timeEnd);
      const result = Number(calculatedTime) ? calculatedTime : '';
      this.setState({ timeResult: result });
    }
  };
  onChangeTimeStart = (value: any) => {
    const { handleFlush } = this.props;
    const { formData } = this.props;
    if (formData.values.timeEnd) {
      const timeStart = value.value;
      // const timeEnd = formData.values.timeEnd.value;
      const timeEnd =
        formData.values.timeEnd.value === '23:59' ? '24:00' : formData.values.timeEnd.value;
      if (timeStart >= timeEnd) {
        handleFlush(['timeEnd']);
        this.changeTimeResult(null, null);
      } else {
        this.changeTimeResult(timeStart, timeEnd);
      }
    }
  };
  onChangeTimeEnd = (value: any) => {
    const { formData } = this.props;
    if (formData.values.timeStart) {
      const timeStart = formData.values.timeStart.value;
      const timeEnd = value.value === '23:59' ? '24:00' : value.value;
      this.changeTimeResult(timeStart, timeEnd);
    }
  };
  render() {
    const { formData, isDisabled } = this.props;
    const { timeResult } = this.state;
    return (
      <Fragment>
        <TextFieldForm name="date" label="Datum" type="date" disabled={isDisabled} />

        <AutocompleteForm
          options={getTimeData({ minSteps: 5 })}
          label="Zeit von"
          name="timeStart"
          onChange={this.onChangeTimeStart}
          isDisabled={isDisabled}
        />

        <AutocompleteForm
          options={getTimeData({
            minSteps: 5,
            timeStart: formData.values.timeStart,
          })}
          label="bis"
          name="timeEnd"
          onChange={this.onChangeTimeEnd}
          isDisabled={isDisabled}
        />
        {timeResult && (
          <Typography
            size={1}
            value={`${timeResult}`}
            variant="subtitle1"
            caption="Stunden"
            captionType="top"
          />
        )}
        <CheckboxesForm
          label=""
          name="timeOptions"
          options={[
            { label: 'Mehrarbeit', value: 'extraWork' },
            { label: 'Wartungsvertrag pauschal', value: 'flatRate' },
            { label: 'Übernachtung', value: 'overnightStay' },
            { label: 'Montagematerial verwendet KLEIN', value: 'materialSmall' },
            { label: 'Montagematerial verwendet GROSS', value: 'materialLarge' },
          ]}
          size={2}
          disabled={isDisabled}
        />
      </Fragment>
    );
  }
}
export default ServiceEntryDateTime;
