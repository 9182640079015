import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CustomerDetails from '../../../../Molecules/AdminAreaDetails/CustomerDetails';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';
import { customerSelector } from '../../../../../helpers/selectors';
import SidebarScrollSpy from '../../../../Organisms/Sidebar/SidebarScrollSpy';
import { CUSTOMER_SCROLLSPY_ITEMS } from '../../../../../constants/all';
import AdminOuterWrapper from '../../../../Templates/AdminWrappers/AdminOuterWrapper';
import { saveCustomerAction, deleteCustomerAction } from '../../../../../actions';
import CustomerContactCreate from '../../../../Molecules/AdminAreaCreates/CustomerContactCreate';
import SiteCreate from '../../../../Molecules/AdminAreaCreates/SiteCreate';
import CustomerContactTable from '../../../../Molecules/Tables/CustomerContactTable';
import SiteTable from '../../../../Molecules/Tables/SiteTable';

const mapStateToProps = (state: any) => ({
  customers: (filter: any) => customerSelector(state, filter),
  pageCustomerEdit: state.form.pageCustomerEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveCustomer: (customer: any) => dispatch(saveCustomerAction(customer)),
  deleteCustomer: (customerId: any) => dispatch(deleteCustomerAction(customerId)),
});

interface RouteParams {
  id: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  customers: (...args: any[]) => any;
  pageCustomerEdit: any;
}

interface ComponentDispatchProps {
  deleteCustomer: (...args: any[]) => any;
  saveCustomer: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams> &
  InjectedFormProps;

type ComponentOwnState = {
  scrollSpyLabel: string[];
};

class PageCustomerEdit extends PureComponent<ComponentProps, ComponentOwnState> {
  state = {
    scrollSpyLabel: [''],
  };

  componentWillMount() {
    const { initialize, customers, match } = this.props;
    const customerId = parseInt(match.params.id, 10);
    const customer = customers({
      include: { customerId },
      option: { plain: true },
    });
    const scrollSpyLabel = customers({
      include: { customerId },
    });
    this.setState({ scrollSpyLabel: [scrollSpyLabel.label] });
    initialize({
      ...customer,
    });
  }

  handleSave = () => {
    const { saveCustomer, pageCustomerEdit } = this.props;

    const formValues = { ...pageCustomerEdit.values };
    if (formValues) {
      saveCustomer(formValues);
    }
  };

  handleDelete = async () => {
    const { history, deleteCustomer, pageCustomerEdit } = this.props;
    const formValues = { ...pageCustomerEdit.values };
    if (formValues) {
      history.push('/admin/customer');
      deleteCustomer(formValues.id);
    }
  };

  scrollToRefs = () => CUSTOMER_SCROLLSPY_ITEMS.map(() => React.createRef());

  render() {
    const { scrollSpyLabel } = this.state;
    const { customers, match } = this.props;
    const customerId = parseInt(match.params.id, 10);
    const scrollToRefs = this.scrollToRefs();

    return (
      <AdminOuterWrapper withScrollSpy>
        <AdminSettingsWrapper
          alignItems="flex-start"
          heading="Kundendetails"
          id="customerDetails"
          setRef={scrollToRefs[0]}
          editWrapper
        >
          <CustomerDetails />
        </AdminSettingsWrapper>
        <AdminSettingsWrapper
          alignItems="flex-start"
          heading="Ansprechpartner"
          id="customerContacts"
          setRef={scrollToRefs[1]}
          editWrapper
        >
          <CustomerContactCreate />
          <CustomerContactTable />
        </AdminSettingsWrapper>
        <AdminSettingsWrapper
          alignItems="flex-start"
          heading="Baustellen"
          id="customerSites"
          setRef={scrollToRefs[2]}
          editWrapper
        >
          <SiteCreate />
          <SiteTable />
        </AdminSettingsWrapper>
        <SidebarScrollSpy
          scrollSpyItems={CUSTOMER_SCROLLSPY_ITEMS}
          scrollToRefs={scrollToRefs}
          handleSave={this.handleSave}
          handleDelete={this.handleDelete}
          deactivatedEntry={
            customers({
              include: { customerId },
              option: { plain: true },
            }).deactivated
          }
          withDelete
          scrollSpyLabel={scrollSpyLabel}
          backPath={`customer/${customerId}`}
        />
      </AdminOuterWrapper>
    );
  }
}

// router
export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageCustomerEdit',
  })(withRouter<any, any>(PageCustomerEdit))
);
