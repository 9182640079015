import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles, createStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { CSVLink } from 'react-csv';
import Typography from '../../../../../Atoms/Typography/Typography';
import Button from '../../../../../Atoms/Button/Button';
import {
  projectSelector,
  projectTypeSelector,
  projectKindSelector,
  billingSelector,
  billingTypeSelector,
  customerSelector,
  contractorSelector,
  userSelector,
  groupSelector,
  ruleSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
  siteSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  projectTimeEntries: state.rootReducer.projectTimeEntries,
  projects: (filter: any) => projectSelector(state, filter),
  projectTypes: (filter: any) => projectTypeSelector(state, filter),
  projectKinds: (filter: any) => projectKindSelector(state, filter),
  billings: (filter: any) => billingSelector(state, filter),
  billingTypes: (filter: any) => billingTypeSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  contractors: (filter: any) => contractorSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  // redux-form
  ProjectHours: state.form.ProjectHours,
});

const styles = () =>
  createStyles({
    csvButton: {
      color: '#fff',
      textDecoration: 'none',
    },
  });

const csvHeaders = [
  { label: 'Projekt Nr', key: 'projectNumber' },
  { label: 'Kunden Name', key: 'customerName' },
  { label: 'Baustelle', key: 'site' },
  { label: 'WA Nr.', key: 'wa' },
  { label: 'AB Nr.', key: 'ab' },
  { label: 'FAB Nr.', key: 'fab' },
  { label: 'Projekt Typ', key: 'projectType' },
  { label: 'Projekt Art', key: 'projectKind' },
  { label: 'Mitarbeiter', key: 'userName' },
  { label: 'Gruppe', key: 'group' },
  { label: 'Abrechnungsart', key: 'billingType' },
  { label: 'Abrechnung', key: 'billing' },
  { label: 'Tätigkeit', key: 'service' },
  { label: 'Spezifikation A', key: 'specificationA' },
  { label: 'Spezifikation B', key: 'specificationB' },
  { label: 'Summe St.', key: 'duration' },
];

interface ComponentOwnProps {
  classes?: any;
}

interface ComponentStateProps {
  projects?: (...args: any[]) => any;
  customers?: (...args: any[]) => any;
  users?: (...args: any[]) => any;
  rules?: (...args: any[]) => any;
  services?: (...args: any[]) => any;
  specificationAs?: (...args: any[]) => any;
  specificationBs?: (...args: any[]) => any;
  sites?: (...args: any[]) => any;
  projectTypes?: (...args: any[]) => any;
  groups?: (...args: any[]) => any;
  projectKinds?: (...args: any[]) => any;
  billingTypes?: (...args: any[]) => any;
  billings?: (...args: any[]) => any;
  projectTimeEntries?: any;
  ProjectHours?: any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class InformationBar extends React.Component<ComponentProps, {}> {
  static defaultProps = {
    projectTimeEntries: [],
    // redux-forms
    ProjectHours: null,
  };

  getDuration = () => {
    const { projectTimeEntries, ProjectHours } = this.props;
    let newProjectTimeEntries = projectTimeEntries;
    if (ProjectHours && newProjectTimeEntries) {
      if (ProjectHours.values) {
        if (ProjectHours.values.projectUser) {
          newProjectTimeEntries = newProjectTimeEntries.filter(
            (e: any) => e.userId === parseInt(ProjectHours.values.projectUser.value, 10)
          );
        }
        if (ProjectHours.values.projectRule) {
          newProjectTimeEntries = newProjectTimeEntries.filter(
            (e: any) => e.ruleId === parseInt(ProjectHours.values.projectRule.value, 10)
          );
        }
        if (ProjectHours.values.project) {
          const sum = newProjectTimeEntries
            .map((e: any) => e.duration)
            .reduce((pv: any, cv: any) => pv + cv, 0);
          return sum;
        }
      }
    }
    return 0;
  };
  getProjectTimeEntries = () => {
    const {
      projectTimeEntries,
      rules,
      specificationAs,
      specificationBs,
      services,
      ProjectHours,
      users,
      projects,
      customers,
      sites,
      projectTypes,
      groups,
      projectKinds,
      billingTypes,
      billings,
    } = this.props;

    if (
      projectTimeEntries &&
      rules &&
      specificationAs &&
      specificationBs &&
      services &&
      ProjectHours &&
      users &&
      projects &&
      customers &&
      sites &&
      projectTypes &&
      groups &&
      projectKinds &&
      billingTypes &&
      billings
    ) {
      const newProjectTimeEntries = projectTimeEntries.map((entry: any) => {
        const rule = rules({ include: { ruleId: entry.ruleId } });
        const service = rule.serviceId
          ? services({
              include: { serviceId: rule.serviceId },
              option: { plain: true },
            }).name
          : 'k.A.';
        const specificationA = rule.specificationAId
          ? specificationAs({
              include: { specificationAId: rule.specificationAId },
              option: { plain: true },
            }).name
          : 'k.A.';
        const specificationB = rule.specificationBId
          ? specificationBs({
              include: { specificationBId: rule.specificationBId },
              option: { plain: true },
            }).name
          : 'k.A.';
        const user = users({
          include: { userId: entry.userId },
          option: { plain: true },
        });
        const project = projects({
          include: { projectId: ProjectHours.values.project.value },
          option: { plain: true },
        });
        const customer = customers({
          include: { customerId: project.customerId },
          option: { plain: true },
        });
        const site = sites({
          include: { siteId: project.siteId },
          option: { plain: true },
        });
        const projectType = projectTypes({
          include: { projectTypeId: project.projectTypeId },
          option: { plain: true },
        });
        const projectKind = projectKinds({
          include: { projectKindId: project.projectKindId },
          option: { plain: true },
        });
        const group = groups({
          include: { groupId: entry.groupId },
          option: { plain: true },
        });
        const billingType = entry.billingTypeId
          ? billingTypes({
              include: { billingTypeId: entry.billingTypeId },
              option: { plain: true },
            }).name
          : 'k.A.';
        const billing = entry.billingId
          ? billings({
              include: { billingId: entry.billingId },
              option: { plain: true },
            }).name
          : 'k.A.';
        const newEntry = {
          projectNumber: project.number,
          customerName: customer.name,
          site: site.adress,
          wa: project[group.shortName] ? project[group.shortName] : 'k.A.',
          ab: project.ab,
          fab: project.fab,
          projectType: projectType ? projectType.name : 'k.A.',
          projectKind: projectKind ? projectKind.name : 'k.A.',
          userName: `${user.firstName} ${user.lastName}`,
          group: group.name,
          billingType,
          billing,
          service,
          specificationA,
          specificationB,
          duration: entry.duration.toFixed(2).replace('.', ','),
        };
        return newEntry;
      });
      return newProjectTimeEntries;
    }
    return [];
  };
  render() {
    const { ProjectHours, projectTimeEntries, classes } = this.props;
    return (
      <Grid container justifyContent="flex-start" spacing={3}>
        <Typography size={8} variant="h4" value="Projektstunden" />
        {projectTimeEntries && (
          <Fragment>
            <Typography
              size={2}
              value={this.getDuration().toFixed(2)}
              captionType="bot"
              caption="Std. Summe"
            />
            {ProjectHours && (
              <Fragment>
                {ProjectHours.values.project && this.getProjectTimeEntries().length > 0 && (
                  <Grid item md={2}>
                    <CSVLink
                      headers={csvHeaders}
                      data={this.getProjectTimeEntries()}
                      separator=";"
                      filename="projektstunden.csv"
                      className={classes.csvButton}
                    >
                      <Button handleClick={() => {}} size={12}>
                        CSV Export
                      </Button>
                    </CSVLink>
                  </Grid>
                )}
              </Fragment>
            )}
          </Fragment>
        )}
      </Grid>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(withStyles(styles)(InformationBar));
