import React, { PureComponent } from 'react';
import { Grid, AccordionSummary } from '@mui/material';
import { connect } from 'react-redux';
import Typography from '../../../../../Atoms/Typography/Typography';

const styles = {
  paddingZero: {
    padding: '0px!important',
  },
};

type ProjectLegendProps = {};
class ProjectLegend extends PureComponent<ProjectLegendProps, {}> {
  render() {
    return (
      <AccordionSummary sx={styles.paddingZero}>
        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
          <Typography size={2} value="Projekt-Nr." variant="subtitle1" />
          <Typography size={2} value="Kunde" variant="subtitle1" />
          <Typography size={2} value="Baustelle" variant="subtitle1" />
          <Typography size={2} value="Projekt-Status" variant="subtitle1" />
          <Typography size={2} value="Projektleiter" variant="subtitle1" />
          <Typography size={2} value="Anz. Besuche" variant="subtitle1" />
        </Grid>
      </AccordionSummary>
    );
  }
}

export default connect(null, null)(ProjectLegend);
