import React, { Fragment, PureComponent } from 'react';
import Form from '../../Atoms/Form/Form';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
class SiteDetails extends PureComponent<{}, {}> {
  render() {
    return (
      <Fragment>
        <AdminSettingsWrapper>
          <Form>
            <TextFieldForm name="adress" label="Adresse" />
            <TextFieldForm name="adressAdditional" label="Zusatzfeld" />
            <TextFieldForm name="postalCode" label="Postleitzahl" />
            <TextFieldForm size={3} name="city" label="Stadt" />
            <TextFieldForm name="country" label="Land" />
            <TextFieldForm name="fab" label="FAB" />
          </Form>
        </AdminSettingsWrapper>
      </Fragment>
    );
  }
}
export default SiteDetails;
