import PageUserWrapper from '../../components/Templates/PageWrappers/PageUserWrapper';
import PageUserOverview from '../../components/Pages/PageAdminArea/User/PageUserOverview';
import PageUserEdit from '../../components/Pages/PageAdminArea/User/PageUserEdit';

/**
 * @constant
 * @description A constant to define routes for the user module.
 */
export const userRoute = [
  // USER
  {
    path: '/admin/user',
    component: PageUserWrapper(PageUserOverview),
    exact: true,
  },
  {
    path: '/admin/user/:id',
    component: PageUserWrapper(PageUserEdit),
    exact: true,
  },
];

export default {};
