import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Drawer, Divider, Box } from '@mui/material';
import LogoImage from '../../../assets/img/Stopa-Pactam-standard.svg';
import SidebarMenuList from '../../Molecules/SidebarMenuList/SidebarMenuList';
import { setAdminAreaSectionAction } from '../../../actions';
import { DRAWER_WIDTH, SIDEBAR_ADMIN_ITEMS } from '../../../constants/all';

const mapStateToProps = (state: any) => ({
  adminSection: state.rootReducer.adminSection,
  auth: state.rootReducer.auth,
  sidebarOpen: state.rootReducer.sidebarOpen,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAdminAreaSection: (section: any) => dispatch(setAdminAreaSectionAction(section)),
});

const styles = {
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: DRAWER_WIDTH,
      zIndex: 2,
    },
  },

  cssImage: {
    height: '60px',
    margin: '20px 0',
  },
};

interface ComponentOwnProps {
  sidebarItems?: any;
}

interface ComponentStateProps {
  adminSection: string;
  auth?: any;
  sidebarOpen?: boolean;
}

interface ComponentDispatchProps {
  setAdminAreaSection: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps;

class Sidebar extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    auth: null,
    sidebarItems: null,
    sidebarOpen: false,
  };

  handleMenu = (link: any, value: any) => () => {
    const { history, setAdminAreaSection } = this.props;
    history.push(`${link}`);
    setAdminAreaSection(value);
  };
  render() {
    const { auth, adminSection, sidebarOpen, sidebarItems } = this.props;
    return (
      <Drawer sx={styles.drawer} variant="persistent" open={sidebarOpen} anchor="left">
        <Box component="img" sx={styles.cssImage} src={LogoImage} alt="Logo" />
        <Divider />
        <SidebarMenuList
          data={sidebarItems || SIDEBAR_ADMIN_ITEMS}
          auth={auth}
          selectedSection={adminSection}
          handleClick={this.handleMenu}
        />
      </Drawer>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(Sidebar));
