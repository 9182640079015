import React, { Fragment } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import { PROJECT_MANAGER } from '../../../../../shared/src/constants/general';
import { contractorSelector, rightSelector, roleSelector } from '../../../helpers/selectors';

const styles = {
  paddingLeftZero: {
    '& *': { paddingLeft: 0 },
  },
};

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  contractors: (filter: any) => contractorSelector(state, filter),
  rights: (filter: any) => rightSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
});

interface ComponentOwnProps {
  users: any[];
  handleDelete: (...args: any[]) => any;
  contractorId?: number | null;
  withTel?: boolean;
  withRight?: boolean;
  withRole?: boolean;
  withOperation?: boolean;
}

interface ComponentStateProps {
  auth?: any;
  contractors?: (...args: any[]) => any;
  rights?: (...args: any[]) => any;
  roles?: (...args: any[]) => any;
}

type ComponentProps = ComponentStateProps & ComponentOwnProps; // &

const UserTable: React.FC<ComponentProps> = ({
  auth,
  contractors = () => {},
  rights = () => {},
  roles = () => {},
  users,
  handleDelete,
  contractorId = null,
  withTel = false,
  withRole = false,
  withRight = false,
  withOperation = true,
}) => {
  return (
    <Fragment>
      <Grid item md={12}>
        {users && (
          <Table sx={styles.paddingLeftZero}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>E-Mail</TableCell>
                {withTel && <TableCell>Telefon</TableCell>}
                {withRole && <TableCell>Rolle</TableCell>}
                {withRight && <TableCell>Rechte</TableCell>}
                {contractorId === null && <TableCell>Firma</TableCell>}
                {withOperation && <TableCell>Operation</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    {user.lastName}
                    {', '}
                    {user.firstName}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  {withTel && <TableCell>{user.telephone}</TableCell>}
                  {withRole && (
                    <TableCell>
                      {roles({
                        include: { roleId: user.roles },
                        option: { plain: true, array: true },
                      }).map((e: any) => `${e.shortName} `)}
                    </TableCell>
                  )}
                  {withRight && (
                    <TableCell>
                      {
                        rights({
                          include: { rightId: user.rightId },
                          option: { plain: true },
                        }).name
                      }
                    </TableCell>
                  )}
                  {contractorId === null && (
                    <TableCell>
                      {
                        contractors({
                          include: { contractorId: user.contractorId },
                          option: { plain: true },
                        }).shortName
                      }
                    </TableCell>
                  )}
                  {withOperation && (
                    <TableCell>
                      {auth.rightId === PROJECT_MANAGER && user.id === auth.id ? (
                        ''
                      ) : (
                        <IconButton onClick={() => handleDelete(user.id)}>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>
    </Fragment>
  );
};

export default connect<ComponentStateProps, {}, ComponentOwnProps>(mapStateToProps, {})(UserTable);
