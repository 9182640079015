import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Form from '../../../../Atoms/Form/Form';
import TextFieldForm from '../../../../Atoms/TextField/TextFieldForm';
import Button from '../../../../Atoms/Button/Button';
import DataTable from '../../../../Molecules/Tables/DataTable';
import RuleTabs from '../../../../Organisms/Tabs/RuleTabs';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';

import { addSpecificationBAction } from '../../../../../actions';
import { specificationBSelector } from '../../../../../helpers/selectors';
import { RULE_EDIT_SPECIFICATIONBS } from '../../../../../constants/all';

const mapStateToProps = (state: any) => ({
  specificationBs: (filter: any) => specificationBSelector(state, filter),
});

const mapDispatchToProps = (dispatch: any) => ({
  addSpecificationB: (name: any) => dispatch(addSpecificationBAction(name)),
});

type PageSpecificationBOverviewProps = {
  history: any;
  specificationBs: (...args: any[]) => any;
  addSpecificationB: (...args: any[]) => any;
  initialize: (...args: any[]) => any;
  reset: (...args: any[]) => any;
  handleSubmit: (...args: any[]) => any;
};
class PageSpecificationBOverview extends PureComponent<PageSpecificationBOverviewProps, {}> {
  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      name: '',
    });
  }
  getSpecificationB = async (specificationBId: any) => {
    const { history } = this.props;
    history.push(`/admin/rule/specificationb/${specificationBId}`);
  };
  handleCreate = (values: any) => {
    const { addSpecificationB, reset } = this.props;
    const { name } = values;
    if (name.value !== '') {
      addSpecificationB(name);
      reset();
    }
  };
  render() {
    const { specificationBs, handleSubmit } = this.props;
    return (
      <Fragment>
        <RuleTabs selectedTab={RULE_EDIT_SPECIFICATIONBS} />
        <AdminSettingsWrapper>
          <Form handleSubmit={handleSubmit(this.handleCreate)} justify="center">
            <TextFieldForm size={4} name="name" label="Tätigkeit" />
            <Button handleClick={handleSubmit(this.handleCreate)}>Neu Anlegen</Button>
            <DataTable
              label="Tätigkeiten"
              data={specificationBs({
                option: { plain: true, deactivated: true },
              })}
              getVariable={this.getSpecificationB}
            />
          </Form>
        </AdminSettingsWrapper>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'specificationBsCreate',
  })(withRouter<any, any>(PageSpecificationBOverview))
);
