import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  FORM_TYPE_SITE_START,
  FORM_TYPE_CHECK,
  FORM_TYPE_RETROFIT,
  FORM_TYPE_SITE_CHECK,
} from '../../../../../../../shared/src/constants/general';
import FormSiteStart from './FormSiteStart/FormSiteStart';
import FormCheck from './FormCheck/FormCheck';
import FormSiteCheck from './FormSiteCheck/FormSiteCheck';

const mapStateToProps = (state: any) => ({
  // forms
  forms: state.rootReducer.forms,
});
type PageFormEditProps = {
  forms: any[];
  match: any;
};
type PageFormEditState = {
  formTypeId: any;
};
class PageFormEdit extends PureComponent<PageFormEditProps, PageFormEditState> {
  state = {
    formTypeId: null,
  };

  componentDidMount() {
    const { forms, match } = this.props;
    const formId = parseInt(match.params.id, 10);
    const form = forms.find((f) => f.id === formId);
    this.setState({ formTypeId: form.formTypeId });
  }
  renderForm = () => {
    const { formTypeId } = this.state;
    if (formTypeId === FORM_TYPE_SITE_START) {
      return <FormSiteStart />;
    }
    if (formTypeId === FORM_TYPE_CHECK || formTypeId === FORM_TYPE_RETROFIT) {
      return <FormCheck />;
    }
    if (formTypeId === FORM_TYPE_SITE_CHECK) {
      return <FormSiteCheck />;
    }
    return null;
  };
  render() {
    return <>{this.renderForm()}</>;
  }
}
export default connect(mapStateToProps, null)(withRouter<any, any>(PageFormEdit));
