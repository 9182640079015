import React from 'react';
import { Grid, Dialog, DialogContent } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import Button from '../../../../../../Atoms/Button/Button';
import Typography from '../../../../../../Atoms/Typography/Typography';

const styles = () => ({
  overflow: {
    overflow: 'visible',
  },
  paper: {
    margin: '0px',
    maxHeight: 'calc(100% - 48px)',
    width: '75%',
  },
});

type ModalFormClosedProps = {
  classes: any;
  open: boolean;
  handleClose: (...args: any[]) => any;
  overflow?: boolean;
};
const ModalFormClosed: React.SFC<ModalFormClosedProps> = ({
  open,
  handleClose,
  classes,
  overflow = false,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth={false}
    classes={{ paper: classes.paper }}
  >
    <DialogContent className={classNames({ [classes.overflow]: overflow })}>
      <Grid container item md={12} spacing={5} justifyContent="center">
        <Typography
          size={4}
          value="Dieses Formular wird gerade von einem anderen Mitarbeiter bearbeitet"
        />
        <Button handleClick={handleClose}>Zurück</Button>
      </Grid>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(ModalFormClosed);
