import React, { FC, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ApolloContext } from './context/ApolloContext';

import { Base } from './components/Templates/Base/Base';
import Print from './components/Templates/Print/Print';
import './app.scss';
import theme from './theme/theme';

const App: FC = React.memo(() => {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ApolloContext>
          <Switch>
            <Route path="/print" component={Print} />
            <Route path="/" component={Base} />
          </Switch>
        </ApolloContext>
      </ThemeProvider>
    </>
  );
});

export default App;
