import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Form from '../../../../Atoms/Form/Form';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';
import TextFieldForm from '../../../../Atoms/TextField/TextFieldForm';
import SpecificationBToolbar from '../../../../Organisms/Toolbars/SpecificationBToolbar';
import { specificationBSelector } from '../../../../../helpers/selectors';
const mapStateToProps = (state: any) => ({
  specificationBs: (filter: any) => specificationBSelector(state, filter),
});
type PageSpecificationBEditProps = {
  match: any;
  specificationBs: (...args: any[]) => any;
  initialize: (...args: any[]) => any;
};
class PageSpecificationBEdit extends PureComponent<PageSpecificationBEditProps, {}> {
  componentWillMount() {
    const { initialize, specificationBs, match } = this.props;
    const specificationBId = parseInt(match.params.specificationBId, 10);
    const specificationB = specificationBs({
      include: { specificationBId },
      option: { plain: true },
    });
    initialize({
      ...specificationB,
    });
  }
  render() {
    return (
      <Fragment>
        <SpecificationBToolbar withSave withDelete />
        <AdminSettingsWrapper alignItems="flex-start">
          <Form>
            <TextFieldForm size={4} label="Spezifikation B" name="name" />
          </Form>
        </AdminSettingsWrapper>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  null
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageSpecificationBEdit',
  })(withRouter<any, any>(PageSpecificationBEdit))
);
