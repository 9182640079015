import React, { PureComponent, Fragment } from 'react';
import { Grid } from '@mui/material';
import VisitTable from '../../../../Molecules/Tables/VisitTable';
import Typography from '../../../../Atoms/Typography/Typography';
import ProjectLegend from './components/ProjectLegend';
import VisitEntry from './components/VisitEntry';

class Visit extends PureComponent<{}, {}> {
  render() {
    return (
      <Fragment>
        <Fragment>
          <Grid item md={12}>
            <Typography variant="h4" value="Projektbesuche" size={12} />
          </Grid>
          <Grid item md={12}>
            <ProjectLegend />
          </Grid>
          <Grid item md={12}>
            <VisitTable />
          </Grid>
          <VisitEntry />
        </Fragment>
      </Fragment>
    );
  }
}
export default Visit;
