import React, { FC } from 'react';
import { Theme } from '@mui/material/styles';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import { DRAWER_WIDTH, SCROLLSPY_WIDTH } from '../../../constants/all';

const styles = {
  drawerGrid: {
    width: `calc(100% - ${DRAWER_WIDTH + 24}px)`,
    marginLeft: `calc(${DRAWER_WIDTH}px + 24px) !important`,
    paddingRight: '24px!important',
    // transition
    transition: ({ transitions }: Theme) =>
      transitions.create(['margin', 'width'], {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
  },
  drawerClosedGrid: {
    width: `calc(100% - 24px)`,
    marginLeft: `24px !important`,
    paddingRight: '24px!important',
    // transition
    transition: ({ transitions }: Theme) =>
      transitions.create(['margin', 'width'], {
        easing: transitions.easing.easeOut,
        duration: transitions.duration.enteringScreen,
      }),
  },
  drawerScrollSpyGrid: {
    width: `calc(100% - ${DRAWER_WIDTH + SCROLLSPY_WIDTH + 24}px)`,
    marginLeft: `calc(${DRAWER_WIDTH}px + 24px) !important`,
    paddingRight: '24px!important',
  },
  drawerClosedScrollSpyGrid: {
    width: `calc(100% - ${SCROLLSPY_WIDTH + 24}px)`,
    marginLeft: `24px !important`,
    paddingRight: '24px!important',
  },
};

interface AdminOuterWrapperProps {
  withDrawer?: boolean;
  withScrollSpy?: boolean;
}

const AdminOuterWrapper: FC<AdminOuterWrapperProps> = React.memo(
  ({ withDrawer = true, withScrollSpy = false, children }) => {
    const sidebarOpen = useSelector((state: any) => state.rootReducer.sidebarOpen);

    return (
      <Grid
        md={12}
        container
        item
        spacing={3}
        justifyContent="flex-start"
        sx={{
          ...(withDrawer && sidebarOpen && styles.drawerGrid),
          ...(withDrawer && !sidebarOpen && styles.drawerClosedGrid),
          ...(sidebarOpen && withScrollSpy && styles.drawerScrollSpyGrid),
          ...(!sidebarOpen && withScrollSpy && styles.drawerClosedScrollSpyGrid),
        }}
      >
        {children}
      </Grid>
    );
  }
);

export default AdminOuterWrapper;
