import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Form from '../../Atoms/Form/Form';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import Button from '../../Atoms/Button/Button';
import { addUserAction } from '../../../actions';

const mapDispatchToProps = (dispatch: any) => ({
  addUser: (
    contractorId: any,
    firstName: any,
    lastName: any,
    telephone: any,
    email: any,
    password: any,
    position: any
  ) =>
    dispatch(
      addUserAction({
        contractorId,
        firstName,
        lastName,
        telephone,
        email,
        password,
        position,
      })
    ),
});

type ContractorUserCreateProps = {
  addUser: (...args: any[]) => any;
  match: { params: { id: string } };
  initialize: (...args: any[]) => any;
  reset: (...args: any[]) => any;
  handleSubmit: (...args: any[]) => any;
};

class ContractorUserCreate extends PureComponent<ContractorUserCreateProps, {}> {
  componentWillMount() {
    const { match, initialize } = this.props;
    const contractorId = parseInt(match.params.id, 10);
    initialize({
      contractorId,
      firstName: '',
      lastName: '',
      telephone: '',
      email: '',
      password: '',
      position: '',
    });
  }
  handleCreate = async (values: any) => {
    const { addUser, reset } = this.props;
    const { contractorId, firstName, lastName, telephone, email, password, position } = values;
    addUser(contractorId, firstName, lastName, telephone, email, password, position);
    reset();
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <Fragment>
        <Form handleSubmit={handleSubmit(this.handleCreate)}>
          <TextFieldForm size={3} name="firstName" label="Vorname" />
          <TextFieldForm size={3} name="lastName" label="Nachname" />
          <TextFieldForm size={3} name="telephone" label="Telefon" />
          <TextFieldForm size={3} name="position" label="Position" />
          <TextFieldForm size={3} name="email" label="E-Mail" />
          <TextFieldForm size={3} name="password" label="Passwort" type="password" />
          <Button handleClick={handleSubmit(this.handleCreate)} alignRight>
            Neu Anlegen
          </Button>
        </Form>
      </Fragment>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'ContractorUserCreate',
  })(withRouter<any, any>(ContractorUserCreate))
);
