import React, { PureComponent, Fragment } from 'react';
import { Grid, Paper, Box } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginForm from './components/FormLogin';
import Image from '../../../assets/img/Stopa-Pactam.png';
import NavBar from '../../Organisms/NavBar/NavBar';
import { apiUrl } from '../../../constants/api';
import BackgroundImage from '../../../assets/img/Stopa_Pactam_bg.jpg';
import BackgroundImageDev from '../../../assets/img/Stopa_Pactam_dev_bg.jpg';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
});

const styles = {
  cssLogo: {
    top: '-40px',
    maxWidth: '160px',
    left: '50%',
    transform: 'translateX(-50%)',
    position: 'absolute',
  },
  cssContainer: {
    marginTop: '-130px',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  cssContainerBg: {
    backgroundImage: `url(${BackgroundImage})`,
  },
  cssContainerBgDev: {
    backgroundImage: `url(${BackgroundImageDev})`,
  },
};

type PageLoginProps = {};
class PageLogin extends PureComponent<PageLoginProps, {}> {
  render() {
    return (
      <Fragment>
        <NavBar />
        <Grid
          container
          sx={{
            ...styles.cssContainer,
            ...styles.cssContainerBgDev,
            ...(apiUrl === 'https://stopa-pac-tam.de/backend' && styles.cssContainerBg),
            ...(apiUrl === 'https://staging.stopa-pac-tam.de/backend' && styles.cssContainerBgDev),
          }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={4}>
            <Paper className="" elevation={1}>
              <Grid container justifyContent="center">
                <Grid item md={6} style={{ position: 'relative' }}>
                  <Box component={'img'} sx={styles.cssLogo} src={Image} alt="Logo" />
                </Grid>
                <Grid item md={8}>
                  <LoginForm />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(withRouter<any, any>(PageLogin));
