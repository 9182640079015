import React, { FC } from 'react';
import { TableRow } from '@mui/material';

const styles = {
  gray: {
    backgroundColor: '#c2c2c2',
  },
  lightGray: {
    backgroundColor: '#f3f3f3',
  },
  red: {
    backgroundColor: '#ff8784',
  },
};
interface ActiveTableRowProps {
  hover?: boolean;
  gray?: boolean;
  lightGray?: boolean;
  red?: boolean;
  handleClick?: (...args: any[]) => any;
  bottomRow?: React.ReactNode;
  sx?: any;
}

const ActiveTableRow: FC<ActiveTableRowProps> = React.memo(
  ({
    children,
    hover = false,
    gray = false,
    red = false,
    lightGray = false,
    handleClick = () => {},
    bottomRow,
    sx,
  }) => {
    return (
      <>
        <TableRow
          hover={hover}
          onClick={handleClick}
          sx={{
            ...(lightGray && styles.lightGray),
            ...(gray && styles.gray),
            ...(red && styles.red),
            ...sx,
          }}
        >
          {children}
        </TableRow>
        {bottomRow}
      </>
    );
  }
);

export default ActiveTableRow;
