import React, { memo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Edit from '@mui/icons-material/Edit';
import { getSortedDataTwo } from '../../../helpers/getSortedData';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';
import { userSelector } from '../../../helpers/selectors';
import { compareProps } from '../../../helpers/compareProps';

const mapStateToProps = (state: any) => ({
  users: (filter: any) => userSelector(state, filter),
  compareUsers: state.rootReducer.users,
});

type ContractorUserTableProps = {
  users: (...args: any[]) => any;
  history: any;
  match: any;
};

const ContractorUserTable: React.SFC<ContractorUserTableProps> = memo(
  ({ users, history, match }) => {
    const getUsers = () => {
      const contractorId = parseInt(match.params.id, 10);
      return getSortedDataTwo(
        users({
          include: { contractorId },
          option: { deactivated: true, plain: true },
        }),
        'deactivated',
        'lastName',
        true
      );
    };
    return (
      <AdminSettingsWrapper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Operation</TableCell>
              <TableCell>Nachname</TableCell>
              <TableCell>Vorname</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>E-Mail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getUsers().map((user: any) => (
              <ActiveTableRow key={user.id} gray={user.deactivated}>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      const contractorId = parseInt(match.params.id, 10);
                      history.push(`/admin/contractor/${contractorId}/user/${user.id}`);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell component="th" scope="row">
                  {user.firstName}
                </TableCell>
                <TableCell>{user.telephone}</TableCell>
                <TableCell>{user.email}</TableCell>
              </ActiveTableRow>
            ))}
          </TableBody>
        </Table>
      </AdminSettingsWrapper>
    );
  },
  compareProps
);
export default connect(mapStateToProps, null)(withRouter<any, any>(ContractorUserTable));
