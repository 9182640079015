import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps, change as changeAction } from 'redux-form';
import { Grid } from '@mui/material';
import moment from 'moment';
import Form from '../../../../../Atoms/Form/Form';
import DialogTimeEntryWrapper from '../components/TimeEntryWrapper';
import TextFieldForm from '../../../../../Atoms/TextField/TextFieldForm';
import Button from '../../../../../Atoms/Button/Button';
import TimeEntryDateTime from '../components/TimeEntryDateTime';
import TimeEntryRules from '../components/TimeEntryRules';
import TimeEntryProject from '../components/TimeEntryProject';
import TimeEntryVisit from '../components/TimeEntryVisit';
import TimeEntryUser from '../components/TimeEntryUser';

import {
  SERVICE_TYPE_BREAK,
  SERVICE_BREAK,
  PROJECT_MANAGER,
} from '../../../../../../../../shared/src/constants/general';
import {
  addTimeEntryAction,
  setSnackbarAction,
  setTimeEntryDialogCreateAction,
} from '../../../../../../actions';
import { ruleSelector, userSelector } from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  rules: (filter: any) => ruleSelector(state, filter),
  timeEntryDialogCreateOpen: state.rootReducer.timeEntryDialogCreateOpen,
  // redux-form
  // self
  users: (filter: any) => userSelector(state, filter),
  dialogTimeEntryCreate: state.form.dialogTimeEntryCreate,
  // other
  calendarWeek: state.form.calendarWeek,
});
const mapDispatchToProps = (dispatch: any) => ({
  addTimeEntry: (
    projectId: any,
    userId: any,
    groupId: any,
    roleId: any,
    ruleId: any,
    commentPublic: any,
    commentPrivate: any,
    commentBilling: any,
    // billingId: any,
    billingTypeId: any,
    spareParts: any,
    date: any,
    timeStart: any,
    timeEnd: any,
    station: any,
    distance: any, // ToDo: Needs cleanup...
    week: any, // weekValue from create function
    year: any, // yearValue from create function
    visitTopic: any,
    visitParticipants: any,
    visitDistributionCircle: any,
    timeEntryVisits: any,
    isExtraWork: any
  ) =>
    dispatch(
      addTimeEntryAction({
        projectId,
        userId,
        groupId,
        roleId,
        ruleId,
        commentPublic,
        commentPrivate,
        commentBilling,
        // billingId,
        billingTypeId,
        spareParts,
        date,
        timeStart,
        timeEnd,
        station,
        distance,
        week,
        year,
        visitTopic,
        visitParticipants,
        visitDistributionCircle,
        timeEntryVisits,
        isExtraWork,
      })
    ),
  setSnackbar: (snackbar: any) => dispatch(setSnackbarAction(snackbar)),
  setTimeEntryDialogCreate: (open: any) => dispatch(setTimeEntryDialogCreateAction(open)),
  // redux-form
  change: (field: any, value: any, form?: any) => dispatch(changeAction(field, value, form)),
});

// interface ComponentOwnProps {}

interface ComponentDispatchProps {
  addTimeEntry: (...args: any[]) => any;
  setSnackbar: (...args: any[]) => any;
  change: (...args: any[]) => any;
  setTimeEntryDialogCreate: (...args: any[]) => any;

  //reduxforms
  // initialize: (...args: any[]) => any;
  // reset: (...args: any[]) => any;
  // handleSubmit: (...args: any[]) => any;
}

interface ComponentStateProps {
  rules: (...args: any[]) => any;
  timeEntryDialogCreateOpen: boolean;
  users: (...args: any[]) => any;
  dialogTimeEntryCreate?: any;
  calendarWeek?: any;
}

type ComponentProps = ComponentDispatchProps & ComponentStateProps;

type DialogTimeEntryCreateState = {
  timeEntryVisitData: any;
};

class DialogTimeEntryCreate extends PureComponent<
  ComponentProps & InjectedFormProps<{}, ComponentProps>,
  DialogTimeEntryCreateState
> {
  // class DialogTimeEntryCreate extends PureComponent<ComponentProps, DialogTimeEntryCreateState> {
  static defaultProps = {
    // redux-form
    dialogTimeEntryCreate: null,
    // passed
    calendarWeek: null,
  };

  state = {
    timeEntryVisitData: [],
  };
  componentWillMount() {
    const { initialize } = this.props;
    // set the current week as predefined
    initialize({
      project: null,
      user: null,
      group: null,
      role: null,
      serviceType: null,
      service: null,
      specificationA: null,
      specificationB: null,
      billingType: null,
      // billing: null,
      commentPublic: '',
      commentPrivate: '',
      commentBilling: '',
      spareParts: '',
      date: moment().format('YYYY-MM-DD'),
      timeStart: null,
      timeEnd: null,
      station: '',
      distance: '',
      extraWork: [],
    });
  }
  handleCreate = (values: any) => {
    const {
      rules,
      addTimeEntry,
      setTimeEntryDialogCreate,
      timeEntryDialogCreateOpen,
      calendarWeek,
      setSnackbar,
    } = this.props;
    const {
      project,
      user,
      group,
      role,
      serviceType,
      service,
      specificationA,
      specificationB,
      commentPublic,
      commentPrivate,
      commentBilling,
      // billing,
      billingType,
      spareParts,
      date,
      timeStart,
      timeEnd,
      station,
      distance,
      extraWork,
      visitTopic,
      visitParticipants,
      visitDistributionCircle,
      ...visitData // we dont know how many for now, so we just take all there is
    } = values;

    const isExtraWork = extraWork[0] === '1';
    const { timeEntryVisitData } = this.state;
    const projectId = parseInt(project.value, 10);
    const userId = parseInt(user.value, 10);
    const roleId = parseInt(role.value, 10);
    const groupId = parseInt(group.value, 10);
    const serviceTypeId = parseInt(serviceType.value, 10);
    // if break, set the service id to break
    let serviceId = null;
    if (serviceTypeId === SERVICE_TYPE_BREAK) {
      serviceId = SERVICE_BREAK;
    } else {
      serviceId = service ? parseInt(service.value, 10) : null;
    }
    const specificationAId = specificationA ? parseInt(specificationA.value, 10) : null;
    const specificationBId = specificationB ? parseInt(specificationB.value, 10) : null;
    // const billingId = billing ? parseInt(billing.value, 10) : null;
    const billingTypeId = billingType ? parseInt(billingType.value, 10) : null;
    const rule = rules({
      include: {
        roleId,
        groupId,
        serviceTypeId,
        serviceId,
        specificationAId,
        specificationBId,
      },
    });
    if (rule === null) {
      setSnackbar({ open: true, message: 'Es wurde keine Regel ausgewählt.' });
      return;
    }
    // get the already selected week for post-fetch after creation
    // since we wann to keep the user in the same screen
    const weekValue = moment(calendarWeek.values.week.value, 'YYYY-WW').week();
    const yearValue = moment(calendarWeek.values.week.value, 'YYYY-WW').year();
    // get the timeValues from autocomplete form data
    const timeStartValue = timeStart.value;
    const timeEndValue = timeEnd.value;
    let timeEntryVisits: any = [];
    // get the size of the visit data
    if (timeEntryVisitData.length > 0) {
      timeEntryVisits = timeEntryVisitData.map((e) => {
        return {
          visitDiscussedTopic: visitData[`visitDiscussedTopic${e}`],
          visitComment: visitData[`visitComment${e}`],
          visitNameResponsible: visitData[`visitNameResponsible${e}`],
          visitDeadline: visitData[`visitDeadline${e}`],
        };
      });
    }
    addTimeEntry(
      projectId,
      userId,
      groupId,
      roleId,
      rule.id,
      commentPublic,
      commentPrivate,
      commentBilling,
      // billingId,
      billingTypeId,
      spareParts,
      date,
      timeStartValue,
      timeEndValue,
      station,
      distance,
      weekValue,
      yearValue,
      visitTopic,
      visitParticipants,
      visitDistributionCircle,
      timeEntryVisits,
      isExtraWork
    );
    setTimeEntryDialogCreate(!timeEntryDialogCreateOpen);
  };
  handleClose = () => {
    const { setTimeEntryDialogCreate, reset } = this.props;
    reset();
    setTimeEntryDialogCreate(false);
  };
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field, null));
  };
  handleChange = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field.name, field.value));
  };
  handleTimeEntryVisitAdd = () => {
    const { timeEntryVisitData } = this.state;
    const newArr: any[] = [...timeEntryVisitData];
    if (timeEntryVisitData.length === 0) {
      newArr.push(1);
    } else {
      newArr.push(timeEntryVisitData[timeEntryVisitData.length - 1] + 1);
    }
    this.setState({ timeEntryVisitData: newArr });
  };
  handleTimeEntryVisitDelete = (id: any) => {
    const { timeEntryVisitData } = this.state; //  ids of redux form fields
    const newArr: any[] = [...timeEntryVisitData];
    newArr.splice(newArr.indexOf(id), 1);
    this.setState({ timeEntryVisitData: newArr });
  };
  render() {
    const {
      timeEntryDialogCreateOpen,
      // setTimeEntryDialogCreate,
      users,
      dialogTimeEntryCreate,
      // redux-form
      handleSubmit,
    } = this.props;
    const { timeEntryVisitData } = this.state;
    let userRightId;
    if (dialogTimeEntryCreate) {
      const { user } = dialogTimeEntryCreate.values;
      userRightId = user
        ? users({ include: { userId: user.value }, option: { plain: true } }).rightId
        : null;
    }
    return (
      <DialogTimeEntryWrapper open={timeEntryDialogCreateOpen} handleClose={this.handleClose}>
        <Form>
          {dialogTimeEntryCreate && (
            <Fragment>
              <Grid md={12} item container spacing={5}>
                <TimeEntryProject formData={dialogTimeEntryCreate} handleFlush={this.handleFlush} />
                <Button handleClick={handleSubmit(this.handleCreate)} alignRight size={3}>
                  Speichern & schließen
                </Button>
              </Grid>

              <Grid md={12} item container spacing={5}>
                <TimeEntryUser
                  formData={dialogTimeEntryCreate}
                  handleFlush={this.handleFlush}
                  handleChange={this.handleChange}
                />
                <Button handleClick={this.handleClose} alignRight size={3}>
                  Abbrechen
                </Button>
              </Grid>
              <Grid md={12} item container spacing={5}>
                <TimeEntryDateTime
                  formData={dialogTimeEntryCreate}
                  handleFlush={this.handleFlush}
                />
              </Grid>
              <TimeEntryRules
                formData={dialogTimeEntryCreate}
                handleFlush={this.handleFlush}
                timeEntryVisitData={timeEntryVisitData}
              />
              <TextFieldForm size={4} name="commentPublic" label="Anmerkung / Öffentlich" />
              {userRightId !== PROJECT_MANAGER && (
                <TextFieldForm size={12} name="spareParts" label="Ersatzteile" multiline />
              )}
              <TextFieldForm size={12} name="commentPrivate" label="Kommentar / Intern" />
              <TimeEntryVisit
                handleTimeEntryVisitAdd={this.handleTimeEntryVisitAdd}
                handleTimeEntryVisitDelete={this.handleTimeEntryVisitDelete}
                timeEntryVisitData={timeEntryVisitData}
                formData={dialogTimeEntryCreate}
              />
            </Fragment>
          )}
        </Form>
      </DialogTimeEntryWrapper>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, ComponentProps>({
    // a unique name for the form
    form: 'dialogTimeEntryCreate',
  })(DialogTimeEntryCreate)
);
