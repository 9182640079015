import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../../Organisms/Sidebar/Sidebar';
import NavBar from '../../Organisms/NavBar/NavBar';
import { DEPARTMENT_MANAGER } from '../../../../../shared/src/constants/general';
import { SIDEBAR_FORM_ITEMS } from '../../../constants/all';
import {
  getProjectsAction,
  getProjectVariablesAction,
  getCustomersAction,
  getCustomerContactsAction,
  getUsersAction,
  getContractorsAction,
  getSitesAction,
  getGroupsAction,
  setAdminAreaSectionAction,
  getRolesAction,
  getFormsAction,
  getRightsAction,
  getQuestionDetailsAction,
  getStationTypesAction,
  getRuleVariablesAction,
  getVisitsAction,
  getRulesAction,
} from '../../../actions';
export default function PageFormWrapper(WrappedComponent: React.ComponentType) {
  const mapStateToProps = (state: any) => ({
    auth: state.rootReducer.auth,
    users: state.rootReducer.users,
    rules: state.rootReducer.rules,
    groups: state.rootReducer.groups,
    projects: state.rootReducer.projects,
    projectTypes: state.rootReducer.projectTypes,
    projectStates: state.rootReducer.projectStates,
    projectKinds: state.rootReducer.projectKinds,
    customers: state.rootReducer.customers,
    customerContacts: state.rootReducer.customerContacts,
    sites: state.rootReducer.sites,
    contractors: state.rootReducer.contractors,
    roles: state.rootReducer.roles,
    rights: state.rootReducer.rights,
    // forms
    forms: state.rootReducer.forms,
    formTypes: state.rootReducer.formTypes,
    questionTypes: state.rootReducer.questionTypes,
    stationTypes: state.rootReducer.stationTypes,
    adminSection: state.rootReducer.adminSection,
  });
  const mapDispatchToProps = (dispatch: any) => ({
    getProjects: () => dispatch(getProjectsAction()),
    getProjectVariables: () => dispatch(getProjectVariablesAction()),
    getCustomers: () => dispatch(getCustomersAction()),
    getCustomerContacts: () => dispatch(getCustomerContactsAction()),
    getUsers: () => dispatch(getUsersAction()),
    getContractors: () => dispatch(getContractorsAction()),
    getSites: () => dispatch(getSitesAction()),
    setAdminAreaSection: (section: any) => dispatch(setAdminAreaSectionAction(section)),
    getGroups: () => dispatch(getGroupsAction()),
    getRoles: () => dispatch(getRolesAction()),
    getRights: () => dispatch(getRightsAction()),
    // forms
    getForms: () => dispatch(getFormsAction()),
    getQuestionDetails: () => dispatch(getQuestionDetailsAction()),
    getStationTypes: () => dispatch(getStationTypesAction()),
    getVisits: () => dispatch(getVisitsAction()),
    getRules: () => dispatch(getRulesAction()),
    getRuleVariables: () => dispatch(getRuleVariablesAction()),
  });

  interface ComponentOwnProps {}

  interface ComponentStateProps {
    auth: any;
    users?: any;
    rules?: any;
    projects?: any;
    projectTypes?: any;
    projectStates?: any;
    rights?: any;
    projectKinds?: any;
    customers?: any;
    customerContacts?: any;
    sites?: any;
    contractors?: any;
    roles?: any;
    forms?: any;
    formTypes?: any;
    questionTypes?: any;
    stationTypes?: any;
    adminSection: string;
  }

  interface ComponentDispatchProps {
    getProjects: (...args: any[]) => any;
    getProjectVariables: (...args: any[]) => any;
    getCustomers: (...args: any[]) => any;
    getCustomerContacts: (...args: any[]) => any;
    getUsers: (...args: any[]) => any;
    getContractors: (...args: any[]) => any;
    getSites: (...args: any[]) => any;
    getGroups: (...args: any[]) => any;
    getRoles: (...args: any[]) => any;
    getForms: (...args: any[]) => any;
    getRights: (...args: any[]) => any;
    getVisits: (...args: any[]) => any;
    getRuleVariables: (...args: any[]) => any;
    getRules: (...args: any[]) => any;
    getQuestionDetails: (...args: any[]) => any;
    getStationTypes: (...args: any[]) => any;
    setAdminAreaSection: (...args: any[]) => any;
  }

  type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

  class InnerWrapper extends PureComponent<ComponentProps, {}> {
    static defaultProps = {
      users: null,
      rules: null,
      roles: null,
      projects: null,
      projectTypes: null,
      rights: null,
      projectStates: null,
      projectKinds: null,
      customers: null,
      customerContacts: null,
      sites: null,
      contractors: null,
      // forms
      forms: null,
      questionTypes: null,
      formTypes: null,
      stationTypes: null,
    };

    componentDidMount() {
      const {
        auth,
        getUsers,
        getProjects,
        getProjectVariables,
        getCustomers,
        getCustomerContacts,
        getGroups,
        getContractors,
        getSites,
        getRoles,
        setAdminAreaSection,
        getForms,
        getRights,
        getQuestionDetails,
        getStationTypes,
        getVisits,
        getRuleVariables,
        getRules,
        adminSection,
      } = this.props;
      getRules();
      getGroups();
      getProjects();
      getProjectVariables();
      getCustomers();
      getCustomerContacts();
      getUsers();
      getContractors();
      getSites();
      getRoles();
      getForms();
      getRights();
      getQuestionDetails();
      getStationTypes();
      getRuleVariables();
      if (auth.rightId < DEPARTMENT_MANAGER) {
        getVisits();
      }

      if (
        adminSection !== 'formCheck' &&
        adminSection !== 'formSiteStart' &&
        adminSection !== 'formSiteCheck' &&
        adminSection !== 'formVisit'
      ) {
        setAdminAreaSection('form');
      }
    }
    render() {
      const {
        users,
        projects,
        projectTypes,
        projectStates,
        projectKinds,
        customers,
        customerContacts,
        sites,
        roles,
        contractors,
        forms,
        formTypes,
        rights,
        questionTypes,
        stationTypes,
        rules,
      } = this.props;
      return (
        <Fragment>
          <NavBar />
          <Sidebar sidebarItems={SIDEBAR_FORM_ITEMS} />
          {users &&
            projects &&
            projectTypes &&
            projectStates &&
            projectKinds &&
            customers &&
            customerContacts &&
            sites &&
            roles &&
            contractors &&
            rights &&
            forms &&
            formTypes &&
            rules &&
            questionTypes &&
            stationTypes && (
              <Fragment>
                <WrappedComponent />
              </Fragment>
            )}
        </Fragment>
      );
    }
  }

  return connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(InnerWrapper);
}
