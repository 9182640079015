import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change as changeAction, InjectedFormProps } from 'redux-form';
import { Grid } from '@mui/material';
import Form from '../../../../../Atoms/Form/Form';
import AutocompleteForm from '../../../../../Molecules/Autocomplete/AutocompleteForm';
import RadiosForm from '../../../../../Molecules/Radios/RadiosForm';
import {
  PROJECT_STATE_ACTIVE,
  PROJECT_STATE_INACTIVE,
} from '../../../../../../../../shared/src/constants/general';
import {
  siteSelector,
  projectSelector,
  formSelector,
  customerSelector,
  projectStateSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  formFilter: state.form.formFilter,
  projectStates: (filter: any) => projectStateSelector(state, filter),
  forms: (filter: any) => formSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
});

const mapDispatchToProps = (dispatch: any) => ({
  change: (field: any, value: any, form?: any) => dispatch(changeAction(field, value, form)),
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  projectStates: (...args: any[]) => any;
  forms: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  formFilter?: any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class FormFilter extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    formFilter: null,
  };

  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      projectStateId: null,
      project: null,
    });
  }
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field, null));
  };

  getAutocompleteData = () => {
    const { formFilter, projects, forms, customers, sites } = this.props;
    if (forms) {
      const uniqueProjectIds = forms({
        option: { plain: true },
      })
        .map((el: any) => el.projectId)
        .filter((value: any, i: any, self: any) => self.indexOf(value) === i);
      let projectOptions = projects({
        include: { projectId: uniqueProjectIds },
        option: { deactivated: true, plain: true, array: true },
      });

      if (formFilter) {
        const { values } = formFilter;
        if (values.projectStateId) {
          projectOptions = projectOptions.filter(
            (project: any) => project.projectStateId === parseInt(values.projectStateId, 10)
          );
        }
      }
      if (projectOptions) {
        const projectsData = projectOptions.map((p: any) => {
          const site = sites({
            include: { siteId: p.siteId },
            option: { plain: true },
          });
          const customer = customers({
            include: { customerId: p.customerId },
            option: { plain: true },
          });
          const neededData = {
            label: `${p.number} - ${customer.name} - ${site.adress} `,
            value: `${p.id}`,
          };
          return neededData;
        });
        return projectsData;
      }
    }
    return [];
  };
  render() {
    const { projectStates } = this.props;

    return (
      <Fragment>
        <Grid
          item
          container
          spacing={3}
          justifyContent="flex-start"
          md={12}
          alignItems="flex-start"
        >
          <Form>
            <RadiosForm
              label="Status"
              name="projectStateId"
              options={projectStates({
                option: { plain: true },
                include: { projectStateId: [PROJECT_STATE_ACTIVE, PROJECT_STATE_INACTIVE] },
              }).map((pS: any) => ({
                label: pS.name,
                value: pS.id,
              }))}
              size={3}
            />
            <AutocompleteForm
              size={4}
              options={this.getAutocompleteData()}
              //   onChange={this.handleChange}
              label="Suche"
              name="project"
            />
          </Form>
        </Grid>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'formFilter',
  })(FormFilter)
);
