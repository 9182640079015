import React, { PureComponent, Fragment } from 'react';
import { reduxForm, change as changeAction, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { getSortedData } from '../../../../../../helpers/getSortedData';
import Form from '../../../../../Atoms/Form/Form';
import AutocompleteForm from '../../../../../Molecules/Autocomplete/AutocompleteForm';
import { getProjectTimeEntriesAction } from '../../../../../../actions';
import { DEPARTMENT_MANAGER } from '../../../../../../../../shared/src/constants/general';

import {
  projectSelector,
  userSelector,
  ruleSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  projects: (filter: any) => projectSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  projectTimeEntries: state.rootReducer.projectTimeEntries,
  // redux-form
  ProjectHours: state.form.ProjectHours,
});

const mapDispatchToProps = (dispatch: any) => ({
  getProjectTimeEntries: (projectId: any) => dispatch(getProjectTimeEntriesAction(projectId)),
  change: (field: any, value: any, form?: any) => dispatch(changeAction(field, value, form)),
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  rules: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
  services: (...args: any[]) => any;
  projectTimeEntries?: any;
  ProjectHours?: any;
}

interface ComponentDispatchProps {
  getProjectTimeEntries: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class SearchBar extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    projectTimeEntries: null,
    ProjectHours: null,
  };

  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      project: null,
      projectUser: null,
    });
  }
  getSortedProjects = () => {
    const { projects } = this.props;
    const projectId = getSortedData(
      projects({ option: { plain: true, deactivated: true } }),
      'number',
      true
    ).map((p: any) => p.id);
    const p = projects({ include: { projectId } });
    return p;
  };
  getProjectUsers = () => {
    const { users, ProjectHours, projectTimeEntries } = this.props;
    if (ProjectHours) {
      if (ProjectHours.values.project) {
        // GET ALL TE FOR PROJECT
        const uniqueUserIds = projectTimeEntries
          ? projectTimeEntries
              .map((tE: any) => tE.userId) // get all unique userIds
              .filter((value: any, i: any, self: any) => self.indexOf(value) === i)
          : [];
        // get all the user for the project

        const projectUsers = users({
          option: { deactivated: true, array: true },
          include: { userId: uniqueUserIds },
          exclude: { rightId: DEPARTMENT_MANAGER },
        });
        return projectUsers;
      }
    }
    return [];
  };
  currentProjectTimeEntries = (value: any) => {
    const { getProjectTimeEntries } = this.props;
    this.handleFlush(['projectUser', 'projectRule']);
    if (value) {
      const projectId = parseInt(value.value, 10);
      getProjectTimeEntries(projectId);
    }
  };
  getProjectRules = () => {
    const { projectTimeEntries, rules, specificationAs, specificationBs, services } = this.props;
    const newProjectTimeEntries = projectTimeEntries;
    if (newProjectTimeEntries) {
      const rulesOptions = newProjectTimeEntries.map((entry: any) => {
        const rule = rules({ include: { ruleId: entry.ruleId } });
        const service = rule.serviceId
          ? services({
              include: { serviceId: rule.serviceId },
              option: { plain: true },
            }).name
          : '';
        const specificationA = rule.specificationAId
          ? specificationAs({
              include: { specificationAId: rule.specificationAId },
              option: { plain: true },
            }).name
          : '';
        const specificationB = rule.specificationBId
          ? specificationBs({
              include: { specificationBId: rule.specificationBId },
              option: { plain: true },
            }).name
          : '';
        const ruleOption = {
          label: `${service} ${specificationA} ${specificationB}`,
          value: rule.id,
        };
        return ruleOption;
      });
      return rulesOptions;
    }
    return [];
  };
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field, null));
  };
  render() {
    const { ProjectHours } = this.props;
    return (
      <Fragment>
        <Grid container justifyContent="center" spacing={3}>
          {ProjectHours && (
            <Form>
              <AutocompleteForm
                size={4}
                options={this.getSortedProjects()}
                label="Projekt"
                name="project"
                onChange={(value) => this.currentProjectTimeEntries(value)}
              />
              <AutocompleteForm
                size={4}
                options={this.getProjectUsers()}
                label="Mitarbeiter"
                name="projectUser"
                onChange={() => this.handleFlush(['projectRule'])}
              />
              <AutocompleteForm
                size={4}
                options={this.getProjectRules()}
                label="Leistung(Regel)"
                name="projectRule"
                onChange={() => this.handleFlush(['projectUser'])}
              />
            </Form>
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'ProjectHours',
  })(SearchBar)
);
