import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { withRouter } from 'react-router-dom';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import { getSortedDataTwo } from '../../../helpers/getSortedData';
import { rightSelector, userSelector } from '../../../helpers/selectors';
import { CONTRACTOR_STOPA } from '../../../../../shared/src/constants/general';
import { compareProps } from '../../../helpers/compareProps';

const mapStateToProps = (state: any) => ({
  rights: (filter: any) => rightSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  // For memo prop compare and rerender
  compareUsers: state.rootReducer.users,
});

type UserTableProps = {
  history: any;
  users: (...args: any[]) => any;
  rights: (...args: any[]) => any;
};
const UserTable: React.FC<UserTableProps> = memo(({ history, rights, users }) => {
  return (
    <AdminSettingsWrapper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Operation</TableCell>
            <TableCell>Nachname</TableCell>
            <TableCell>Vorname</TableCell>
            <TableCell>Telefon</TableCell>
            <TableCell>E-Mail</TableCell>
            <TableCell>Rechte</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getSortedDataTwo(
            users({
              include: { contractorId: CONTRACTOR_STOPA },
              option: { deactivated: true, plain: true },
            }),
            'deactivated',
            'lastName',
            true
          ).map((user: any) => (
            <ActiveTableRow key={user.id} gray={user.deactivated}>
              <TableCell>
                <IconButton onClick={() => history.push(`/admin/user/${user.id}`)}>
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {user.lastName}
              </TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.telephone}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {
                  rights({
                    include: { rightId: user.rightId },
                    option: { plain: true },
                  }).name
                }
              </TableCell>
            </ActiveTableRow>
          ))}
        </TableBody>
      </Table>
    </AdminSettingsWrapper>
  );
}, compareProps);

export default connect(mapStateToProps, null)(withRouter<any, any>(UserTable));
