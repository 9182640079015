import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { reduxForm, change as changeAction, InjectedFormProps } from 'redux-form';
import UserDetails from '../../../Molecules/AdminAreaDetails/UserDetails';
import {
  ADMIN,
  PROJECT_MANAGER,
  GROUP_PROJECTMANAGER,
  ROLE_PROJECTMANAGER,
} from '../../../../../../shared/src/constants/general';
import { userSelector } from '../../../../helpers/selectors';
import AdminOuterWrapper from '../../../Templates/AdminWrappers/AdminOuterWrapper';
import { saveUserAction, deleteUserAction } from '../../../../actions';
import SidebarScrollSpy from '../../../Organisms/Sidebar/SidebarScrollSpy';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
  pageUserEdit: state.form.pageUserEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveUser: (user: any) => dispatch(saveUserAction(user)),
  deleteUser: (userId: any) => dispatch(deleteUserAction(userId)),
  // redux-form
  change: (field: any, value: any, form?: any) => dispatch(changeAction(field, value, form)),
});

interface RouteParams {
  id: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  auth: any;
  users: (...args: any[]) => any;
  pageUserEdit?: any;
}

interface ComponentDispatchProps {
  saveUser: (...args: any[]) => any;
  deleteUser: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams> &
  InjectedFormProps;

type ComponentOwnState = {
  disabled: boolean;
};

class PageUserEdit extends PureComponent<ComponentProps, ComponentOwnState> {
  state = {
    disabled: false,
  };

  componentWillMount() {
    const { initialize, users, match, auth, history } = this.props;
    const userId = parseInt(match.params.id, 10);
    let user;
    if (auth.rightId !== ADMIN) {
      // if not admin and not current user go to the user page via id
      if (auth.id !== userId) {
        history.push(`/admin/user/${auth.id}`);
      }
      user = users({
        include: { userId: auth.id },
        option: { plain: true },
      });
    } else {
      user = users({
        include: { userId },
        option: { plain: true },
      });
    }
    user.password = '';
    initialize({
      ...user,
    });
    // check for the right if to disable the selection
    if (user.rightId === PROJECT_MANAGER) {
      this.setState({ disabled: true });
    }
  }
  checkManager = (value: any) => {
    // get the value from the radio
    const rightId = parseInt(value, 10);
    const { change } = this.props;
    // check if the rightId is the project manager
    // if so, disable all the other selections and set them to projectmanager
    if (rightId === PROJECT_MANAGER) {
      this.setState({ disabled: true });
      change('groups', [GROUP_PROJECTMANAGER]);
      change('roles', [ROLE_PROJECTMANAGER]);
    } else {
      this.setState({ disabled: false });
    }
  };
  handleSave = () => {
    const { saveUser, pageUserEdit } = this.props;
    const formValues = { ...pageUserEdit.values };
    if (formValues) {
      saveUser(formValues);
    }
  };
  handleDelete = async () => {
    const { history, deleteUser, pageUserEdit } = this.props;
    const formValues = { ...pageUserEdit.values };
    if (formValues) {
      history.push('/admin/user');
      deleteUser(formValues.id);
    }
  };

  render() {
    const { disabled } = this.state;
    const { users, match } = this.props;
    const userId = parseInt(match.params.id, 10);
    return (
      <AdminOuterWrapper withScrollSpy>
        <UserDetails disabled={disabled} checkManager={this.checkManager} />
        <SidebarScrollSpy
          handleSave={this.handleSave}
          handleDelete={this.handleDelete}
          deactivatedEntry={users({ include: { userId }, option: { plain: true } }).deactivated}
          withDelete
          backPath="user"
        />
      </AdminOuterWrapper>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageUserEdit',
  })(withRouter<any, any>(PageUserEdit))
);
