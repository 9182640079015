import { createSelector } from 'reselect';
import { PROJECT_STATE_ACTIVE } from '../../../../shared/src/constants/general';
import { checkSingleValue } from './utilities';
import { RootState } from '../../store/index';

export const projectSelector = <T = any>(
  state: RootState,
  {
    include = {
      userId: null,
      projectId: null,
      projectStateId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      array: false,
    },
  }
): T =>
  createSelector(
    () => state.rootReducer.projects,
    () => state.rootReducer.customers,
    (projects, customers) => {
      // for single selected project
      if (checkSingleValue(include.projectId)) {
        const projectId = Array.isArray(include.projectId)
          ? include.projectId![0]
          : include.projectId;

        const project = projects.find((p: any) => p.id === parseInt(projectId!, 10));
        if (option.plain) {
          return option.array ? [project] : project;
        }
        const customer = customers.find((c: any) => c.id === project.customerId);
        return option.array
          ? [{ label: `${project.number} - ${customer.name}`, value: `${project.id}` }]
          : { label: `${project.number} - ${customer.name}`, value: `${project.id}` };
      }

      let projectOptions = projects;
      if (!option.deactivated) {
        // Filter all deactivated projects
        projectOptions = projectOptions.filter(
          (project: any) => project.projectStateId === PROJECT_STATE_ACTIVE
        );
      }

      if (include.projectStateId) {
        projectOptions = projectOptions.filter(
          // @ts-ignore
          (project) => project.projectStateId === include.projectStateId
        );
      }

      if (include.projectId) {
        projectOptions = projectOptions.filter((project: any) =>
          // @ts-ignore
          include.projectId.includes(project.id)
        );
      }
      // include the projects that have the specified userids
      // if (include.userId) {
      //   projectOptions = projectOptions.filter(project => project.userIds.includes(include.userId));
      // }
      if (include.userId) {
        const userId = Array.isArray(include.userId)
          ? include.userId
          : [parseInt(include.userId!, 10)];

        projectOptions = projectOptions.filter((project: any) =>
          // userId.every(r => project.userIds.indexOf(r) >= 0)
          // @ts-ignore
          userId.every((r) => project.userIds.includes(r))
        );
      }

      if (projectOptions) {
        if (option.plain) {
          return projectOptions;
        }
        return projectOptions.map((project: any) => {
          const customer = customers.find((c: any) => c.id === project.customerId);
          return { label: `${project.number} - ${customer.name}`, value: `${project.id}` };
        });
      }
      return null;
    }
  )(null as any);

export default {};
