import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_TEST } from '../../../graphql/Test';

export const PageControlling2 = () => {
  const { data, loading, error, refetch } = useQuery(GET_TEST);
  return (
    <div>
      <button onClick={() => refetch()}>refetch</button>
      <h1>PageControlling2</h1>
    </div>
  );
};
