import axios from 'axios';
import { apiUrl } from '../constants/api';

axios.defaults.withCredentials = true;
const makeRequest: (method: any, uri: any, data?: any) => any = (method, uri, data) => {
  const result = axios({
    method,
    url: `${apiUrl}/${uri}`,
    data,
    withCredentials: true,
  }).then(response => response.data);

  return result;
};

export default makeRequest;
