import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import CheckHeader from './components/CheckHeader';
import CheckGeneral from './components/CheckGeneral';
import CheckStations from './components/CheckStations';
import CheckInformation from './components/CheckInformation';
import { editingForm, leavingForm } from '../../../../../Molecules/Forms/FormHelpers';
import {
  FORM_SECTION_STATION,
  FORM_SECTION_RETROFIT_STATION,
} from '../../../../../../../../shared/src/constants/general';
import ModalFormClosed from './components/ModalFormClosed';
import {
  getAnswerChecksAction,
  saveAnswerChecksAction,
  saveFormAction,
} from '../../../../../../actions';
import AdminOuterWrapper from '../../../../../Templates/AdminWrappers/AdminOuterWrapper';
import SidebarScrollSpy from '../../../../../Organisms/Sidebar/SidebarScrollSpy';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  forms: state.rootReducer.forms,
  answerChecks: state.rootReducer.answerChecks,
  formCheckGeneral: state.form.formCheckGeneral,
  formCheckHeader: state.form.formCheckHeader,
  editedForms: state.rootReducer.editedForms,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAnswerChecks: (formId: any) => dispatch(getAnswerChecksAction(formId)),
  saveAnswerChecks: (answerChecks: any, formId: any, formStationTypeId: any) =>
    dispatch(saveAnswerChecksAction(answerChecks, formId, formStationTypeId)),
  saveForm: (data: any) => dispatch(saveFormAction(data)),
});

interface RouteParams {
  id: string;
  formStationTypeId: string;
  section: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  auth?: any;
  answerChecks?: any;
  editedForms: any;
  forms: any;
  formCheckGeneral?: any;
  formCheckHeader?: any;
}

interface ComponentDispatchProps {
  getAnswerChecks: (...args: any[]) => any;
  saveAnswerChecks: (...args: any[]) => any;
  saveForm: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams>;

type FormCheckState = {
  formSectionId: number | null;
  formId: any;
  formStationTypeId: number | null;
};

class FormCheck extends PureComponent<ComponentProps, FormCheckState> {
  state = {
    formStationTypeId: null,
    formSectionId: null,
    formId: null,
  };

  static defaultProps = {
    auth: null,
    answerChecks: null,
    editedForms: null,
    formCheckGeneral: null,
  };

  componentDidMount() {
    const { match, getAnswerChecks, auth } = this.props;
    const formStationTypeId = match.params.section
      ? parseInt(match.params.formStationTypeId, 10)
      : null;
    const formSectionId = match.params.section ? parseInt(match.params.section, 10) : null;
    const formId = parseInt(match.params.id, 10);
    this.setState({ formSectionId, formId, formStationTypeId });
    getAnswerChecks(formId);
    editingForm(auth.id, formId);
  }
  componentWillUnmount() {
    const { formId } = this.state;
    leavingForm(formId);
  }

  changeFormSection = (formSectionId: any) => {
    const { history } = this.props;
    const { formId } = this.state;
    history.push(`/admin/form/${formId}/${formSectionId}`);
    this.setState({ formSectionId });
  };

  handleDialog = () => {
    const { history } = this.props;
    history.push(`/admin/form`);
  };

  checkFormEdited = () => {
    const { editedForms, auth } = this.props;
    const { formId } = this.state;
    const currentForm = editedForms.find((e: any) => e.formId === formId);
    if (currentForm) {
      if (currentForm.userId !== auth.id) {
        return true;
      }
    }
    return false;
  };
  renderSection = () => {
    const { formSectionId, formId, formStationTypeId } = this.state;
    // if we are at station but noone is selected, render the stationselect screen
    if (formSectionId === FORM_SECTION_STATION || formSectionId === FORM_SECTION_RETROFIT_STATION) {
      if (!formStationTypeId) {
        return <CheckStations formId={formId} />;
      }
    }
    // if we are in a formsection, render it
    if (formSectionId) {
      return (
        <CheckGeneral
          formId={formId}
          formSectionId={formSectionId}
          // @ts-ignore
          key={formSectionId !== null ? formSectionId : 0}
          // only station formsection has a formstationTypeId
          // so render this specific station
          {...(formStationTypeId ? { formStationTypeId } : {})}
        />
      );
    }
    return <CheckInformation formId={formId} />;
  };

  handleSave = () => {
    const {
      saveForm,
      saveAnswerChecks,
      match,
      forms,
      formCheckHeader,
      formCheckGeneral,
    } = this.props;
    const { formId } = this.state;
    // todo: refactore to use formSelector
    if (forms) {
      const form = forms.find((f: any) => f.id === formId);

      // save header information
      if (formCheckHeader) {
        const formValues = { ...formCheckHeader.values };
        const { responsibleMechanic, responsibleElectrician, responsibleContractor } = formValues;

        const saveFormData = {
          formId: form.id,
          responsibleMechanicId: responsibleMechanic ? responsibleMechanic.value : null,
          responsibleElectricianId: responsibleElectrician ? responsibleElectrician.value : null,
          responsibleContractor,
        };

        saveForm({
          ...saveFormData,
        });
      }
      if (formCheckGeneral) {
        const { formStationTypeId } = match.params;
        const formValues = { ...formCheckGeneral.values };
        if (formValues) {
          saveAnswerChecks(formValues, form.id, parseInt(formStationTypeId, 10));
        }
      }
    }
  };

  render() {
    const { answerChecks, editedForms, match } = this.props;
    const { formSectionId, formId } = this.state;

    return (
      <AdminOuterWrapper withScrollSpy>
        {editedForms && (
          <ModalFormClosed open={this.checkFormEdited()} handleClose={this.handleDialog} />
        )}
        {answerChecks && formId && (
          <>
            <CheckHeader
              formId={formId}
              formSectionId={formSectionId}
              changeFormSection={this.changeFormSection}
            />
            {this.renderSection()}
            <SidebarScrollSpy
              handleSave={this.handleSave}
              withPrint
              printLink={`/print/form/${formId}`}
              backPath={formSectionId ? `form/${match.params.id}` : 'form'}
            />
          </>
        )}
      </AdminOuterWrapper>
    );
  }
}
export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormCheck));
