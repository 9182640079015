import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import RoutesHandler from '../../Molecules/RoutesHandler/RoutesHandler';
import Snackbar from '../../Molecules/Snackbar/Snackbar';
import makeRequest from '../../../helpers/Requests';
import {
  setTimeEntryDialogCreateAction,
  setAuthenticationAction,
  getRightsAction,
} from '../../../actions';
const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
});

const mapDispatchToProps = (dispatch: any) => ({
  setTimeEntryDialogCreate: (open: any) => dispatch(setTimeEntryDialogCreateAction(open)),
  setAuthentication: (auth: any) => dispatch(setAuthenticationAction(auth)),
  getRights: () => dispatch(getRightsAction()),
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  auth?: any;
}

interface ComponentDispatchProps {
  setAuthentication: (...args: any[]) => any;
  getRights: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class Print extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    auth: null,
  };
  componentDidMount = async () => {
    const { setAuthentication, getRights } = this.props;
    const result = await makeRequest('GET', 'authentication');
    if (result.user) {
      getRights();
      setAuthentication(result.user);
    }
  };
  render() {
    const { auth } = this.props;
    return (
      <Fragment>
        <div>{auth && <Fragment>{RoutesHandler}</Fragment>}</div>
        <Snackbar />
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(Print);
