import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { Box } from '@mui/material';
import TextFieldForm from '../../../Atoms/TextField/TextFieldForm';
import Button from '../../../Atoms/Button/Button';
// Api
import { apiUrl } from '../../../../constants/api';
import {
  setAuthenticationAction,
  getRightsAction,
  setSnackbarAction,
  getUsersAction,
  getContractorsAction,
} from '../../../../actions';
import { GROUP_SERVICE } from '../../../../../../shared/src/constants/general';
import { userSelector } from '../../../../helpers/selectors/userSelector';

const mapDispatchToProps = (dispatch: any) => ({
  setAuthentication: (auth: any) => dispatch(setAuthenticationAction(auth)),
  getRights: () => dispatch(getRightsAction()),
  getUsers: () => dispatch(getUsersAction()),

  setSnackbar: (snackbar: any) => dispatch(setSnackbarAction(snackbar)),
});
const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
});
const styles = {
  cssForm: {
    margin: '25px 0px',
  },
};

type FormLoginProps = {
  history?: any;
  auth?: any;
  users?: any;
  handleSubmit: (...args: any[]) => any;
  setAuthentication: (...args: any[]) => any;
  setSnackbar: (...args: any[]) => any;
  getRights: (...args: any[]) => any;
  getUsers: (...args: any[]) => any;
};
class FormLogin extends PureComponent<FormLoginProps, {}> {
  static defaultProps = {
    history: {},
  };

  handleSubmit = (values: any) => {
    const { history, setAuthentication, getRights, getUsers, setSnackbar, users } = this.props;
    const { email, password } = values;
    axios
      .post(`${apiUrl}/login`, {
        email,
        password,
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.user) {
          setAuthentication(response.data.user);
          getRights();

          if (response.data.user.groupId === GROUP_SERVICE) {
            history.push('/serviceproject');
          } else {
            history.push('/overview');
          }
        }
      })
      .catch((e) => {
        console.log('error', e);
        setSnackbar({
          open: true,
          message: 'Der Benutzername oder das Passwort ist falsch.',
        });
      });
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <Box
        component="form"
        sx={styles.cssForm}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(this.handleSubmit)}
      >
        <TextFieldForm name="email" label="E-Mail" size={12} />
        <TextFieldForm name="password" label="Password" type="password" size={12} />
        <Button
          //TODO: Do we need this?
          // type="submit"
          size={3}
          handleClick={handleSubmit(this.handleSubmit)}
        >
          Log in
        </Button>
      </Box>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    reduxForm<any, any>({
      // a unique name for the form
      form: 'login',
    })(FormLogin)
  )
);
