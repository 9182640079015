import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import DeleteRestoreButton from './components/DeleteRestoreButton';
import Button from '../../Atoms/Button/Button';

import { saveSpecificationBAction, deleteSpecificationBAction } from '../../../actions';

const mapStateToProps = (state: any) => ({
  pageSpecificationBEdit: state.form.pageSpecificationBEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveSpecificationB: (specificationB: any) => dispatch(saveSpecificationBAction(specificationB)),
  deleteSpecificationB: (specificationBId: any) =>
    dispatch(deleteSpecificationBAction(specificationBId)),
});

type SpecificationBToolbarProps = {
  history: any;
  saveSpecificationB: (...args: any[]) => any;
  deleteSpecificationB: (...args: any[]) => any;
  withDelete?: boolean;
  pageSpecificationBEdit: any;
};
class SpecificationBToolbar extends React.Component<SpecificationBToolbarProps, {}> {
  static defaultProps = {
    withDelete: false,
  };

  handleSave = () => {
    const { saveSpecificationB, pageSpecificationBEdit } = this.props;
    saveSpecificationB(pageSpecificationBEdit.values);
  };
  handleBack = () => {
    const { history } = this.props;
    history.push('/admin/rule/specificationb');
  };

  handleDelete = async () => {
    const { history, deleteSpecificationB, pageSpecificationBEdit } = this.props;
    history.push('/admin/rule/specificationb');
    deleteSpecificationB(pageSpecificationBEdit.values.id);
  };

  render() {
    const { pageSpecificationBEdit, withDelete } = this.props;
    return (
      <AdminSettingsWrapper justify="flex-end">
        {withDelete && (
          <DeleteRestoreButton
            handleDelete={this.handleDelete}
            isDeactivated={pageSpecificationBEdit.values.deactivated}
          />
        )}
        <Button buttonSize="small" handleClick={() => this.handleSave()} icon={<SaveIcon />}>
          Speichern
        </Button>

        <Button buttonSize="small" handleClick={() => this.handleBack()} icon={<UndoIcon />}>
          Zurück
        </Button>
      </AdminSettingsWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(SpecificationBToolbar));
