import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { getSortedData } from '../../../../../../helpers/getSortedData';

const mapStateToProps = (state: any) => ({
  answerChecks: state.rootReducer.answerChecks,
});

const GIVEN_FROM_VAL_STRING_MAP: Map<number, string> = new Map([
  [1, 'Kunde'],
  [2, 'GU'],
  [3, 'Stopa'],
]);

interface ComponentOwnProps {}

interface ComponentStateProps {
  answerChecks: any[];
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class PrintSiteCheckBody extends React.Component<ComponentProps, {}> {
  getTimeStamp = () => {
    const { answerChecks } = this.props;
    const lastEdited = answerChecks.map(a => a);
    const timeStamp = moment(getSortedData(lastEdited, 'createdAt', false)[0].createdAt).format(
      'YYYY-MM-DD HH:mm'
    );
    return timeStamp;
  };

  render() {
    const { answerChecks } = this.props;
    return (
      <Fragment>
        <table className="question-table">
          <thead>
            <tr>
              <th>Frage</th>
              <th>J/N</th>
              <th>Verantwortlich</th>
              <th>Anmerkung</th>
              <th>Kg</th>
              <th>M</th>
            </tr>
          </thead>
          <tbody>
            {answerChecks.map(ans => {
              return ans.question.questionTypeId !== 9 ? (
                <tr
                  key={ans.id}
                  className={classNames({
                    'tr-no': ans.decision === 0,
                  })}
                >
                  <td>{ans.question.text}</td>
                  <td>
                    {ans.decision === 1 && 'J'}
                    {ans.decision === 0 && 'N'}
                  </td>
                  <td>{GIVEN_FROM_VAL_STRING_MAP.get(ans.givenFrom)}</td>
                  <td>{ans.textOne}</td>
                  <td>
                    {ans.textThree || ans.textTwo
                      ? `${ans.textThree ? `${ans.textThree},` : ''} ${
                          ans.textTwo ? `${ans.textTwo}` : ''
                        }`
                      : '-'}
                  </td>
                  <td>
                    {ans.textThree || ans.textTwo
                      ? `${ans.textThree ? `${ans.textThree},` : ''} ${
                          ans.textTwo ? `${ans.textTwo}` : ''
                        }`
                      : '-'}
                  </td>
                </tr>
              ) : (
                <tr key={ans.id}>
                  <td className="td-heading">
                    <strong>{ans.question.text}</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(PrintSiteCheckBody);
