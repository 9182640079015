import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PrintSiteCheckHeader from './components/PrintSiteCheckHeader';
import PrintSiteCheckBody from './components/PrintSiteCheckBody';
import { getAnswerSiteStartAction } from '../../../../../actions';

const mapStateToProps = (state: any) => ({
  answerSiteStarts: state.rootReducer.answerSiteStarts,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAnswerSiteStart: (projectId: any) => dispatch(getAnswerSiteStartAction(projectId)),
});

interface ComponentOwnProps {
  project: any;
  form: any;
}

interface ComponentStateProps {
  answerSiteStarts?: any[] | null;
}

interface ComponentDispatchProps {
  getAnswerSiteStart: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class PrintSiteCheck extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    answerSiteStarts: null,
  };

  componentDidMount() {
    const { getAnswerSiteStart, form } = this.props;
    getAnswerSiteStart(form.projectId);
  }
  render() {
    const { answerSiteStarts, project, form } = this.props;
    return (
      <div className="print-content-wrapper portrait-print">
        {answerSiteStarts && (
          <>
            <PrintSiteCheckHeader form={form} project={project} />
            <PrintSiteCheckBody />
          </>
        )}
      </div>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PrintSiteCheck);
