import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import { Grid } from '@mui/material';
import Typography from '../../../../../../Atoms/Typography/Typography';
import Button from '../../../../../../Atoms/Button/Button';

import {
  FORM_SECTION_GENERAL,
  FORM_SECTION_STEELWORK,
  FORM_SECTION_RBG,
  FORM_SECTION_STATION,
  FORM_SECTION_RETROFIT_RBG,
  FORM_SECTION_RETROFIT_CONTROL,
  FORM_SECTION_RETROFIT_HANDED,
  FORM_SECTION_RETROFIT_INSTALLATION,
  FORM_SECTION_RETROFIT_STATION,
} from '../../../../../../../../../shared/src/constants/general';

import {
  formCheckQuestionCompletion,
  formStationCompletion,
} from '../../../../../../../helpers/formCompletion';

type HeaderSectionButtonsProps = {
  sectionId: number;
  formSections: any[];
  formStationTypes: any[];
  formId: number | null;
  answerChecks: any[];
  formSectionId?: number | null;
  handleClick: (...args: any[]) => any;
};

const HeaderSectionButtons: React.SFC<HeaderSectionButtonsProps> = ({
  sectionId,
  answerChecks,
  formSectionId = null,
  handleClick,
  formSections,
  formStationTypes,
  formId,
}) => {
  const sectionsCompleted = formStationTypes.reduce((acc, cv) => {
    if (acc === false) {
      return false;
    }
    if (cv.completed === true) {
      return true;
    }
    return false;
  }, true);

  let formSectionCompleted: any;

  if (sectionId !== FORM_SECTION_STATION && sectionId !== FORM_SECTION_RETROFIT_STATION) {
    formSectionCompleted = formSections.find(
      (f) => f.sectionId === sectionId && f.formId === formId
    ).completed;
  } else {
    formSectionCompleted = sectionsCompleted;
  }

  const stationCheck = formStationCompletion(formStationTypes);
  const sectionAnswerCheck = formCheckQuestionCompletion(answerChecks, sectionId);
  const typoValue = (
    <>
      <Button
        size={12}
        variant={formSectionId === sectionId ? 'contained' : 'outlined'}
        handleClick={handleClick}
      >
        {sectionId === FORM_SECTION_GENERAL && 'Allgemein'}
        {sectionId === FORM_SECTION_STEELWORK && 'Stahlbau'}
        {sectionId === FORM_SECTION_RBG && 'RBG'}
        {sectionId === FORM_SECTION_STATION && 'Stationen'}

        {/* Retrofit */}
        {sectionId === FORM_SECTION_RETROFIT_RBG && 'RBG + Stahlbau'}
        {sectionId === FORM_SECTION_RETROFIT_CONTROL && 'Steuerung'}
        {sectionId === FORM_SECTION_RETROFIT_INSTALLATION && 'Inbetriebnahme'}
        {sectionId === FORM_SECTION_RETROFIT_HANDED && 'Übergabe'}
        {sectionId === FORM_SECTION_RETROFIT_STATION && 'Stationen'}
      </Button>
    </>
  );

  const typoCaption = () => {
    if (sectionId === FORM_SECTION_STATION || sectionId === FORM_SECTION_RETROFIT_STATION) {
      return (
        <>
          {`${stationCheck.completed} von ${stationCheck.counter}
          ${stationCheck.counter === 1 ? 'Station' : 'Stationen'} abgeschlossen`}
        </>
      );
    }
    return (
      <>{`${sectionAnswerCheck.no}x nein, ${sectionAnswerCheck.notAnswered}x unbeantwortet`}</>
    );
  };

  return (
    <Fragment>
      <Grid item container md={3}>
        <Grid item md={2}>
          {sectionAnswerCheck.notAnswered !== 0 && (
            <LockOpen style={{ marginTop: '1rem', color: '#f44336' }} />
          )}
          {sectionAnswerCheck.notAnswered === 0 && !formSectionCompleted && (
            <LockOpen style={{ marginTop: '1rem', color: 'rgb(0, 102, 68)' }} />
          )}
          {sectionAnswerCheck.notAnswered === 0 && formSectionCompleted && (
            <Lock style={{ marginTop: '1rem', color: 'rgb(0, 102, 68)' }} />
          )}
        </Grid>
        <Typography
          size={10}
          value={typoValue}
          captionType="bot"
          formButton
          caption={typoCaption()}
          variant="h6"
        />
      </Grid>
    </Fragment>
  );
};

export default connect(null, null)(HeaderSectionButtons);
