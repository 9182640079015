import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import AutocompleteForm from '../Autocomplete/AutocompleteForm';
import RadiosForm from '../Radios/RadiosForm';
import ProjectCategory from '../../Organisms/ProjectCategory/ProjectCategory';
import {
  groupSelector,
  projectTypeSelector,
  customerContactSelector,
  siteSelector,
  projectKindSelector,
  projectStateSelector,
  userSelector,
} from '../../../helpers/selectors';

import {
  CONTRACTOR_STOPA,
  PROJECT_MANAGER,
  PROJECT_STATE_ACTIVE,
  PROJECT_STATE_INACTIVE,
  PROJECT_KIND_RETROFIT,
  RIGHT_CUSTOMER_GENERAL,
  RIGHT_CUSTOMER_ADMIN,
  RIGHT_CUSTOMER_PROJECT_MANAGER,
} from '../../../../../shared/src/constants/general';

import { ADMIN } from '../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  pageProjectEdit: state.form.pageProjectEdit,
  auth: state.rootReducer.auth,
  groups: (filter: any) => groupSelector(state, filter),
  customerContacts: (filter: any) => customerContactSelector(state, filter),
  projectTypes: (filter: any) => projectTypeSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  projectKinds: (filter: any) => projectKindSelector(state, filter),
  projectStates: (filter: any) => projectStateSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
});

type ProjectDetailsProps = {
  pageProjectEdit?: any;
  auth?: any;
  handleSave?: (...args: any[]) => any;
  groups?: (...args: any[]) => any;
  projectKinds?: (...args: any[]) => any;
  customerContacts?: (...args: any[]) => any;
  projectTypes?: (...args: any[]) => any;
  sites?: (...args: any[]) => any;
  projectStates?: (...args: any[]) => any;
  users?: (...args: any[]) => any;
};

const ProjectDetails: React.FC<ProjectDetailsProps> = ({
  pageProjectEdit,
  auth,
  handleSave = () => {},
  groups,
  projectKinds,
  customerContacts,
  projectTypes,
  sites,
  projectStates,
  users,
}) => {
  const validateFullForm = (pS: any) => {
    if (users && groups) {
      const formValues = { ...pageProjectEdit.values };

      const groupShortnames = groups({ option: { plain: true } }).map((g: any) => g.shortName);
      const fieldsToTest = [
        'customerContact0',
        'projectKind',
        'projectType',
        'fab',
        'ab',
        ...groupShortnames,
      ];
      if (pS.id === PROJECT_STATE_INACTIVE && auth.rightId > ADMIN) {
        return true;
      }
      if (pS.id !== PROJECT_STATE_ACTIVE) {
        return false;
      }
      let projectUsers = users({
        include: { userId: formValues.userIds },
        option: { plain: true },
      });
      projectUsers = Array.isArray(projectUsers) ? projectUsers : [projectUsers];
      if (projectUsers) {
        const projectManager = projectUsers.find((user: any) => user.rightId === PROJECT_MANAGER);
        if (projectManager === undefined) {
          return true;
        }
        if (
          projectUsers.find(
            (user: any) => user.contractorId === CONTRACTOR_STOPA && user.id !== projectManager.id
          ) === undefined
        ) {
          return true;
        }
      }
      return fieldsToTest.reduce((prevVal, currentVal) => {
        if (
          !formValues[currentVal] ||
          formValues[currentVal] === undefined ||
          formValues[currentVal] === '' ||
          prevVal === true
        ) {
          return true;
        }
        return false;
      }, false);
    }
  };
  return (
    <Fragment>
      {projectTypes && sites && projectStates && customerContacts && projectKinds && groups && (
        <Fragment>
          <RadiosForm
            label="Status"
            name="projectStateId"
            onBlur={() => handleSave()}
            formValues={pageProjectEdit}
            options={projectStates({ option: { plain: true } }).map((pS: any) => ({
              label: pS.name,
              value: pS.id,
              singleDisabled: validateFullForm(pS),
            }))}
            size={12}
          />
          <AutocompleteForm size={6} name="customer" label="Kunde" isDisabled />
          <AutocompleteForm
            size={6}
            name="site"
            options={sites({
              include: { customerId: pageProjectEdit.values.customerId },
            })}
            label="Baustelle"
          />
          {/* Ansprechpartner */}
          <AutocompleteForm
            size={4}
            name="customerContact0"
            options={customerContacts({
              include: {
                customerId: pageProjectEdit.values.customerId,
                rightId: RIGHT_CUSTOMER_GENERAL,
              },
            })}
            label="Kunde Ansprechpartner"
            onBlur={() => handleSave()}
            formValues={pageProjectEdit}
          />
          {/* IT-Admin */}
          <AutocompleteForm
            size={4}
            name="customerContact1"
            options={customerContacts({
              include: {
                customerId: pageProjectEdit.values.customerId,
                rightId: RIGHT_CUSTOMER_ADMIN,
              },
            })}
            label="Kunde IT-Admin"
          />
          {/* Projektleiter */}
          <AutocompleteForm
            size={4}
            name="customerContact2"
            options={customerContacts({
              include: {
                customerId: pageProjectEdit.values.customerId,
                rightId: RIGHT_CUSTOMER_PROJECT_MANAGER,
              },
            })}
            label="Kunde Projektleiter"
          />
          <TextFieldForm
            name="number"
            label="Projekt-Nr"
            onBlur={() => handleSave()}
            formValues={pageProjectEdit}
          />
          <TextFieldForm
            name="ab"
            label="AB"
            type="number"
            onBlur={() => handleSave()}
            formValues={pageProjectEdit}
          />
          <TextFieldForm
            name="fab"
            label="FAB"
            type="number"
            disabled
            // onBlur={() => handleSave()}
            formValues={pageProjectEdit}
          />
          <AutocompleteForm options={projectTypes({})} name="projectType" label="Typ" />
          <AutocompleteForm options={projectKinds({})} name="projectKind" label="Art" />
          {pageProjectEdit.values.projectStationTypeCount !== null ? (
            <TextFieldForm label="Anzahl der Stationen" name="projectStationTypeCount" disabled />
          ) : (
            <Grid item md={2} />
          )}
          {groups({ option: { plain: true } }).map((group: any) => (
            <TextFieldForm
              key={group.id}
              name={group.shortName}
              label={`WA - ${group.shortName}`}
              type="number"
              onBlur={() => handleSave()}
              formValues={pageProjectEdit}
            />
          ))}
          {pageProjectEdit.values.projectKind &&
            parseInt(pageProjectEdit.values.projectKind.value, 10) === PROJECT_KIND_RETROFIT && (
              <ProjectCategory />
            )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default connect(mapStateToProps, null)(ProjectDetails);
