import React, { memo, useEffect } from 'react';
import { Grid } from '@mui/material';
import Typography from '../../Atoms/Typography/Typography';
import { FormContext } from '../../Pages/PageAdminArea/Form/PageFormEdit/FormSiteCheck/components/SiteCheckQuestions';

type QuestionTypeNineProps = {
  question: any;
  index: any;
  setRef?: any;
};

const QuestionTypeNine: React.SFC<QuestionTypeNineProps> = memo(({ index, question, setRef }) => {
  const { setSize, windowWidth } = React.useContext<any>(FormContext);
  let root: any;
  if (!setRef) {
    root = React.useRef<any>(null);
  }
  useEffect(() => {
    if (windowWidth && setSize) {
      if (root) {
        setSize(index, root.current.getBoundingClientRect().height);
      } else if (setRef) {
        setSize(index, setRef.current.getBoundingClientRect().height);
      }
    }
  }, [windowWidth]);
  return (
    <Grid
      container
      item
      md={12}
      spacing={3}
      alignItems="flex-start"
      justifyContent="flex-start"
      ref={setRef ? setRef : root}
    >
      <Typography size={12} variant="h6" value={question.text} />
    </Grid>
  );
});
export default QuestionTypeNine;
