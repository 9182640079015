import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/de';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import TextsmsIcon from '@mui/icons-material/Textsms';

import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Typography from '../../../../Atoms/Typography/Typography';
import ActiveTableRow from '../../../../Atoms/ActiveTableRow/ActiveTableRow';
import formatTime from '../../../../../helpers/FormatTime';
import { getUserWa } from '../../../../../helpers/utils';
import {
  SERVICE_TYPE_WORK,
  SERVICE_TYPE_TRAVEL,
  SERVICE_TYPE_BREAK,
} from '../../../../../../../shared/src/constants/general';
import { setTimeEntryDialogEditAction, setTimeEntryAction } from '../../../../../actions';
import { getSortedDataThree } from '../../../../../helpers/getSortedData';
import {
  projectSelector,
  customerSelector,
  userSelector,
  roleSelector,
  serviceTypeSelector,
  serviceSelector,
  billingTypeSelector,
  ruleSelector,
  groupSelector,
} from '../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  projects: (filter: any) => projectSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  serviceTypes: (filter: any) => serviceTypeSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  billingTypes: (filter: any) => billingTypeSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  timeSheets: state.rootReducer.timeSheets,
});

const mapDispatchToProps = (dispatch: any) => ({
  setTimeEntryDialogEdit: (open: any) => dispatch(setTimeEntryDialogEditAction(open)),
  setTimeEntry: (timeEntry: any) => dispatch(setTimeEntryAction(timeEntry)),
});
const styles = {
  buttonRight: {
    marginRight: '24px',
    marginLeft: 'auto',
  },
  paddingZero: {
    padding: '0px!important',
  },
  paper: {
    width: '100%',
  },
};

type DayOverviewProps = {
  timeSheets: any[];
  projects: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  users: (...args: any[]) => any;
  serviceTypes: (...args: any[]) => any;
  services: (...args: any[]) => any;
  billingTypes: (...args: any[]) => any;
  rules: (...args: any[]) => any;
  timeEntry: any;
  setTimeEntryDialogEdit: (...args: any[]) => any;
  setTimeEntry: (...args: any[]) => any;
  groups: (...args: any[]) => any;
};
type DayOverviewState = {
  isExpanded: boolean;
};
class DayOverview extends PureComponent<DayOverviewProps, DayOverviewState> {
  state = {
    isExpanded: false,
  };

  checkTimeSheetState = (timeEntry: any) => {
    const { timeSheets } = this.props;
    if (timeSheets && timeEntry) {
      const foundTimeSheet = timeSheets.find(
        (tS: any) =>
          tS.week === timeEntry.week &&
          tS.year === timeEntry.year &&
          tS.projectId === timeEntry.projectId &&
          tS.userId === timeEntry.userId
      );
      if (foundTimeSheet) {
        if (foundTimeSheet.completed !== 1) {
          return 'completed';
        }
        return 'exported';
      }
    }
    return null;
  };

  handleEditTimeEntry = (timeEntryId: any) => {
    const { rules, setTimeEntryDialogEdit, setTimeEntry, timeEntry } = this.props;
    const foundTimeEntry = timeEntry.entries.find((entry: any) => entry.id === timeEntryId);
    setTimeEntryDialogEdit(true);
    // add the serviceTypeId, serviceId, specificationA, speicifactionBId from the rule
    const rule = rules({ include: { ruleId: foundTimeEntry.ruleId } });
    const addedRuleData = {
      ...foundTimeEntry,
      serviceTypeId: rule.serviceTypeId,
      serviceId: rule.serviceId,
      specificationAId: rule.specificationAId,
      specificationBId: rule.specificationBId,
    };
    setTimeEntry(addedRuleData);
  };

  handleChange = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };

  calculateTime = (serviceTypeId: any) => {
    const { rules, timeEntry } = this.props;
    const result = timeEntry.entries.filter((entry: any) => {
      // get the selected rule for the entry
      const rule = rules({ include: { ruleId: entry.ruleId } });
      // check if its the serviceType we are looking for
      if (rule.serviceTypeId === serviceTypeId) {
        return entry;
      }
      return null;
    });
    if (result) {
      const calculated = result.map((entry: any) => {
        if (entry.timeEnd === '23:59') {
          const calculatedTime = formatTime(entry.timeStart, '23:00') + 1;
          // const result = Number(calculatedTime) ? calculatedTime : '';
          // console.log(formatTime(entry.timeStart, '23:00') + 1);
          return calculatedTime;
        }
        return formatTime(entry.timeStart, entry.timeEnd);
      });
      const sum = calculated.reduce((pv: any, cv: any) => pv + cv, 0);
      return sum;
    }
    return '';
  };

  getSortedTimeEntries = () => {
    const { users, timeEntry } = this.props;
    const newTimeEntryArray = timeEntry.entries.map((e: any) => {
      const userLastName = users({
        include: { userId: e.userId },
        option: { plain: true },
      }).lastName;
      return {
        ...e,
        userLastName,
      };
    });
    return getSortedDataThree(
      newTimeEntryArray,
      'timeStart',
      'projectNumber',
      'userLastName',
      true
    );
  };

  getWa = (entry: any) => {
    const { groups, rules, projects } = this.props;
    const { userId, groupId } = entry;
    const userGroups = groups({
      include: { userId, groupId: [groupId] },
      option: { plain: true },
    });
    const project = projects({
      include: { projectId: entry.projectId },
      option: { plain: true },
    });
    const wa1 = getUserWa(project, userGroups)?.number;
    const wa2 = rules({
      include: { ruleId: entry.ruleId },
    })?.wa;
    return `${wa1 ? wa1 : 'k.A'} ${wa2 ? wa2 : 'k.A.'}`;
  };

  render() {
    const { projects, customers, serviceTypes, services, rules, timeEntry } = this.props;
    const { isExpanded } = this.state;
    const workTime = this.calculateTime(SERVICE_TYPE_WORK);
    const travelTime = this.calculateTime(SERVICE_TYPE_TRAVEL);
    const breakTime = this.calculateTime(SERVICE_TYPE_BREAK);
    const totalWork = workTime + travelTime;
    return (
      <Grid item container justifyContent="flex-start" md={12} alignItems="flex-start">
        <Accordion onChange={this.handleChange} elevation={0} sx={styles.paper}>
          <AccordionSummary sx={styles.paddingZero}>
            <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
              <Typography
                value={moment(timeEntry.date).format('DD.MM')}
                style={timeEntry.isFalseDay ? { background: '#FF8784' } : undefined}
              />
              <Typography value={moment(timeEntry.date).format('dddd')} size={2} />
              <Typography value={travelTime.toFixed(2)} caption="Reise" />
              <Typography value={workTime.toFixed(2)} caption="Arbeit" />
              <Typography value={breakTime.toFixed(2)} caption="Pause" />
              <Typography value={totalWork.toFixed(2)} caption="gesamt Arbeit" size={2} />
              <IconButton sx={styles.buttonRight} color="inherit" aria-label="Menu">
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={styles.paddingZero}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item md={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Projekt</TableCell>
                      <TableCell>Kunde</TableCell>
                      <TableCell>Mitarbeiter</TableCell>
                      <TableCell>Tätigkeit</TableCell>
                      <TableCell>von</TableCell>
                      <TableCell>bis</TableCell>
                      <TableCell>Std</TableCell>
                      <TableCell>WA-Nr. Vorgang</TableCell>
                      <TableCell>Anmerkung</TableCell>
                      <TableCell>Kommentar</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.getSortedTimeEntries().map((entry: any) => (
                      <ActiveTableRow
                        hover
                        key={entry.id}
                        handleClick={() => this.handleEditTimeEntry(entry.id)}
                        gray={this.checkTimeSheetState(entry) === 'completed'}
                        lightGray={this.checkTimeSheetState(entry) === 'exported'}
                      >
                        <TableCell>{entry.projectNumber}</TableCell>
                        <TableCell>
                          {
                            customers({
                              option: { plain: true },
                              include: {
                                customerId: projects({
                                  include: { projectId: entry.projectId },
                                  option: { plain: true },
                                }).customerId,
                              },
                            }).name
                          }
                        </TableCell>
                        <TableCell>{entry.userLastName}</TableCell>
                        <TableCell>
                          {entry.serviceTypeId === SERVICE_TYPE_WORK ? (
                            <Typography
                              variant="body2"
                              value={
                                services({
                                  include: {
                                    serviceId: rules({
                                      include: { ruleId: entry.ruleId },
                                    }).serviceId,
                                  },
                                  option: { plain: true },
                                }).name
                              }
                            />
                          ) : (
                            <Typography
                              variant="body2"
                              value={
                                serviceTypes({
                                  include: {
                                    serviceTypeId: rules({
                                      include: { ruleId: entry.ruleId },
                                    }).serviceTypeId,
                                  },
                                  option: { plain: true },
                                }).name
                              }
                              caption={
                                rules({
                                  include: { ruleId: entry.ruleId },
                                }).serviceTypeId === SERVICE_TYPE_TRAVEL
                                  ? `${entry.distance}km`
                                  : ''
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            value={moment(entry.timeStart, 'HH:mm:ss').format('HH:mm')}
                            style={entry.isFalseEntry ? { backgroundColor: '#ff8784' } : undefined}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            value={
                              entry.timeEnd === '23:59'
                                ? '24:00'
                                : moment(entry.timeEnd, 'HH:mm:ss').format('HH:mm')
                            }
                            style={entry.isFalseEntry ? { backgroundColor: '#ff8784' } : undefined}
                          />
                        </TableCell>
                        <TableCell>
                          {entry.timeEnd === '23:59'
                            ? formatTime(entry.timeStart, '23:00') + 1
                            : formatTime(entry.timeStart, entry.timeEnd)}
                        </TableCell>
                        <TableCell>{this.getWa(entry)}</TableCell>
                        <TableCell>{entry.commentPublic}</TableCell>
                        <TableCell>
                          {entry.commentPrivate !== '' ? (
                            <TextsmsIcon style={{ color: '#0072B6' }} />
                          ) : (
                            <TextsmsIcon style={{ color: '#3333' }} />
                          )}
                        </TableCell>
                      </ActiveTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DayOverview);
