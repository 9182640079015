import React from 'react';
import { Dialog as MuiDialog, DialogContent as MuiDialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DialogContentProps as MuiDialogContentProps } from '@mui/material/DialogContent';

const styles = {
  overflow: {
    overflow: 'visible',
  },
  paper: {
    '& .MuiDialog-paper': {
      margin: '0px',
      height: 'calc(100% - 96px)',
      maxHeight: 'calc(100% - 96px)',
      width: 'calc(100% - 96px)',
    },
  },
};

const Dialog = styled(MuiDialog)`
  /* margin: 0px;
  height: calc(100% - 48px);
  maxheight: calc(100% - 48px);
  width: calc(100% - 48px); */
`;

interface DialogContentProps extends MuiDialogContentProps {
  overflow?: boolean;
}

const DialogContent = styled(MuiDialogContent)<DialogContentProps>(({ overflow }) => ({
  ...(overflow && {
    overflow: 'visible',
  }),
}));

type DialogServiceEntryWrapperProps = {
  open: boolean;
  handleClose: (...args: any[]) => any;
  overflow?: boolean;
};

const DialogServiceEntryWrapper: React.SFC<DialogServiceEntryWrapperProps> = ({
  open,
  handleClose,
  overflow = false,
  children,
}) => (
  <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false} sx={styles.paper}>
    <DialogContent overflow={overflow}>{children}</DialogContent>
  </Dialog>
);

export default DialogServiceEntryWrapper;
