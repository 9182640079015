import React from 'react';
import { Grid } from '@mui/material';
import { Dialog, DialogContent } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import Button from '../../../../../../Atoms/Button/Button';
import Typography from '../../../../../../Atoms/Typography/Typography';

const styles = () => ({
  overflow: {
    overflow: 'visible',
  },
  paper: {
    margin: '0px',
    maxHeight: 'calc(100% - 48px)',
    width: '75%',
  },
});

type ModalStationDeleteProps = {
  classes: any;
  open: boolean;
  handleClose: (...args: any[]) => any;
  handleDelete: (...args: any[]) => any;
  overflow?: boolean;
};

const ModalStationDelete: React.SFC<ModalStationDeleteProps> = ({
  open,
  handleClose,
  handleDelete,
  classes,
  overflow = false,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    maxWidth={false}
    classes={{ paper: classes.paper }}
  >
    <DialogContent className={classNames({ [classes.overflow]: overflow })}>
      <Grid container item md={12} spacing={5} justifyContent="center">
        <Typography size={4} value="Sind Sie sich sicher dass Sie die Station löschen wollen?" />
        <Button handleClick={handleClose}>Abbrechen</Button>
        <Button handleClick={handleDelete}>Ja</Button>
      </Grid>
    </DialogContent>
  </Dialog>
);

export default withStyles(styles)(ModalStationDelete);
