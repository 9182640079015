import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import DeleteRestoreButton from './components/DeleteRestoreButton';
import Button from '../../Atoms/Button/Button';
import { saveRuleAction, deleteRuleAction } from '../../../actions';

const mapStateToProps = (state: any) => ({
  pageRuleEdit: state.form.pageRuleEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveRule: (rule: any) => dispatch(saveRuleAction(rule)),
  deleteRule: (ruleId: any) => dispatch(deleteRuleAction(ruleId)),
});

type RuleToolbarProps = {
  history: any;
  saveRule: (...args: any[]) => any;
  deleteRule: (...args: any[]) => any;
  withDelete?: boolean;
  pageRuleEdit: any;
};
class RuleToolbar extends React.Component<RuleToolbarProps, {}> {
  static defaultProps = {
    withDelete: false,
  };
  handleSave = () => {
    const { saveRule, pageRuleEdit } = this.props;
    const formValues = pageRuleEdit.values;
    // take the values back from the autocomplete
    const wa = formValues.wa.padStart(3, '0'); // leading zeros;
    const serviceId = formValues.service.value;
    const specificationAId = formValues.specificationA ? formValues.specificationA.value : null;
    const specificationBId = formValues.specificationB ? formValues.specificationB.value : null;
    const station = formValues.withStation[0] === '1';
    // get the data from the form
    const rule = {
      ...formValues,
      wa,
      serviceId,
      specificationAId,
      specificationBId,
      station,
    };
    saveRule(rule);
  };
  handleBack = () => {
    const { history } = this.props;
    history.push('/admin/rule');
  };
  handleDelete = async () => {
    const { history, deleteRule, pageRuleEdit } = this.props;
    history.push('/admin/rule');
    deleteRule(pageRuleEdit.values.id);
  };
  render() {
    const { withDelete, pageRuleEdit } = this.props;
    return (
      <AdminSettingsWrapper justify="flex-end">
        {withDelete && (
          <DeleteRestoreButton
            handleDelete={this.handleDelete}
            isDeactivated={pageRuleEdit.values.deactivated}
          />
        )}

        <Button buttonSize="small" handleClick={() => this.handleSave()} icon={<SaveIcon />}>
          Speichern
        </Button>

        <Button buttonSize="small" handleClick={() => this.handleBack()} icon={<UndoIcon />}>
          Zurück
        </Button>
      </AdminSettingsWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter<any, any>(RuleToolbar));
