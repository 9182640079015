import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change as changeAction, InjectedFormProps } from 'redux-form';
import Form from '../../../../Atoms/Form/Form';
import moment from 'moment';
import 'moment/locale/de';
import AutocompleteForm from '../../../../Molecules/Autocomplete/AutocompleteForm';
import TextFieldForm from '../../../../Atoms/TextField/TextFieldForm';
import TextField from '../../../../Atoms/TextField/TextField';
import Button from '../../../../Atoms/Button/Button';
// import validate from '../../../../../../helpers/validation';
import { addServiceProjectAction } from '../../../../../actions';
import { siteSelector } from '../../../../../helpers/selectors';
import { Grid, Dialog, DialogContent } from '@mui/material';
import {
  serviceProjectSelector,
  customerSelector,
  customerContactSelector,
} from '../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  sites: (filter: any) => siteSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  customerContacts: (filter: any) => customerContactSelector(state, filter),
  serviceProjectCreate: state.form.serviceProjectCreate,
  serviceProjects: (filter: any) => serviceProjectSelector(state, filter),
  auth: state.rootReducer.auth,
});

const mapDispatchToProps = (dispatch: any) => ({
  addServiceProject: (data: any) => dispatch(addServiceProjectAction(data)),
  change: (field: string, value: any, form?: any) => dispatch(changeAction(form, field, value)),
});

interface ComponentOwnProps {
  toggleCreateDialog: (...args: any[]) => any;
  visibility: boolean;
}

interface ComponentStateProps {
  sites: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  customerContacts: (...args: any[]) => any;
  serviceProjects: (...args: any[]) => any;
  serviceProjectCreate?: any;
  auth?: any;
}

interface ComponentDispatchProps {
  addServiceProject: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class ServiceProjectCreate extends React.Component<ComponentProps, {}> {
  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      site: null,
    });
  }
  handleCreate = (values: any) => {
    const { addServiceProject, sites, reset, auth, serviceProjects, toggleCreateDialog } =
      this.props;
    const { comment, site, wa, ab } = values;
    const siteData = sites({ include: { siteId: site.value }, option: { plain: true } });
    if (siteData) {
      if (siteData.id && comment) {
        const siteId = siteData.id;
        const customerId = siteData.customerId;

        const newServiceProjectNumber = `${siteData.fab}-${moment().format('YYYYMMDD')}`;

        const existingSP = serviceProjects({
          include: { serviceProjectNumber: newServiceProjectNumber },
        });
        // const
        addServiceProject({
          siteId,
          customerId,
          wa,
          ab,
          serviceProjectNumber: `${newServiceProjectNumber}-${existingSP.length + 1}`,
          createdByUserId: auth.id,
          comment,
        });
        reset();
        toggleCreateDialog(false);
      }
    }
  };
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: string) => change(field, null));
  };
  getFabs = () => {
    const { sites } = this.props;
    const availableSites = sites({ include: { fab: true }, option: { plain: true } });
    const options = availableSites.map((site: any) => {
      return {
        value: site.id,
        label: `${site.fab} - ${site.country} - ${site.city} - ${site.adress}`,
      };
    });
    return options;
  };
  getCustomer = () => {
    const { serviceProjectCreate, sites, customers } = this.props;
    if (serviceProjectCreate) {
      const { site } = serviceProjectCreate.values;
      if (site) {
        const siteData = sites({ include: { siteId: site.value }, option: { plain: true } });
        return customers({ include: { customerId: siteData.customerId } }).label;
      }
    }
    return null;
  };
  getCustomerContacts = () => {
    const { serviceProjectCreate, sites, customerContacts } = this.props;
    if (serviceProjectCreate) {
      const { site } = serviceProjectCreate.values;
      if (site) {
        const siteData = sites({ include: { siteId: site.value }, option: { plain: true } });
        return customerContacts({
          include: { customerId: siteData.customerId },
        });
      }
    }
    return [];
  };
  render() {
    const {
      handleSubmit,
      visibility,
      toggleCreateDialog,
      serviceProjectCreate = null,
    } = this.props;
    // customerContactsSP = customerContacts({
    //   include: { customerId: serviceProject.customerId },
    // });
    return (
      <Dialog
        open={visibility}
        onClose={() => toggleCreateDialog(false)}
        fullWidth
        maxWidth={false}
      >
        <DialogContent>
          <Form handleSubmit={handleSubmit(this.handleCreate)}>
            <Grid item container md={6} spacing={3}>
              <AutocompleteForm
                size={12}
                options={this.getFabs()}
                label="FAB"
                name="site"
                // onChange={() => this.handleFlush(['site'])}
              />
            </Grid>
            {this.getCustomer() ? (
              <TextField
                id={'customer'}
                size={4}
                value={this.getCustomer()}
                disabled
                label="Kunde"
              />
            ) : (
              <Grid item md={4} />
            )}
            <Button handleClick={handleSubmit(this.handleCreate)} alignRight>
              Neu Anlegen
            </Button>
            <Grid item container md={6} spacing={3}>
              <TextFieldForm size={12} type="number" name="wa" label="WA" />
              <TextFieldForm size={12} type="number" name="ab" label="AB" />
              <TextFieldForm size={12} name="comment" label="Kommentar (Pflichtfeld)" />
            </Grid>
            <Grid item container md={6} spacing={3}>
              {this.getCustomerContacts().length > 0 &&
                this.getCustomerContacts().map((el: any, i: any) => (
                  <TextField
                    key={el.value}
                    id={el.value}
                    size={12}
                    value={el.label}
                    label="Ansprechpartner beim Kunden"
                    disabled
                  />
                ))}
            </Grid>
          </Form>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'serviceProjectCreate',
  })(ServiceProjectCreate)
);
