import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import PrintSiteStart from './FormSiteStart/PrintSiteStart';
import PrintFormCheck from './FormCheck/PrintCheck';
import './printForm.scss';

import {
  FORM_TYPE_SITE_START,
  FORM_TYPE_CHECK,
  FORM_TYPE_SITE_CHECK,
  DEPARTMENT_MANAGER,
  PROJECT_MANAGER,
  MECHANIC,
  FORM_TYPE_RETROFIT,
} from '../../../../../../shared/src/constants/general';
import {
  projectSelector,
  customerSelector,
  siteSelector,
  projectTypeSelector,
  userSelector,
  projectKindSelector,
  formStationTypeSelector,
  formSelector,
} from '../../../../helpers/selectors';
import PrintSiteCheck from './FormSiteCheck/PrintSiteCheck';

const mapStateToProps = (state: any) => ({
  // forms
  stationTypes: state.rootReducer.stationTypes,
  forms: (filter: any) => formSelector(state, filter),
  formStationTypes: (filter: any) => formStationTypeSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  projectTypes: (filter: any) => projectTypeSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  projectKinds: (filter: any) => projectKindSelector(state, filter),
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  stationTypes: any[];
  forms: (...args: any[]) => any;
  formStationTypes: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  projectTypes: (...args: any[]) => any;
  projectKinds: (...args: any[]) => any;
  users: (...args: any[]) => any;
}

interface RouteParams {
  id: string;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams>;

type PrintFormState = {
  formTypeId: any | null;
  form: any | null;
  project: any | null;
};

class PrintForm extends PureComponent<ComponentProps, PrintFormState> {
  state = {
    formTypeId: null,
    form: null,
    project: null,
  };

  componentDidMount() {
    const {
      match,
      forms,
      projects,
      customers,
      sites,
      projectTypes,
      projectKinds,
      users,
      formStationTypes,
      stationTypes,
    } = this.props;
    const formId = parseInt(match.params.id, 10);
    const form = forms({ include: { formId }, option: { plain: true } });
    this.setState({ formTypeId: form.formTypeId });

    // get form  information and save into form
    if (form.formTypeId === FORM_TYPE_CHECK || form.formTypeId === FORM_TYPE_RETROFIT) {
      form.stations = formStationTypes({
        include: { formId },
        option: { plain: true },
      });
      form.stations.forEach((e: any) => {
        e.stationType = stationTypes.find(s => s.id === e.stationTypeId).name;
      });
    }
    form.responsibleElectrician = users({
      include: {
        userId: [form.responsibleElectricianId],
      },
      option: { plain: true },
    });
    form.responsibleMechanic = users({
      include: {
        userId: [form.responsibleMechanicId],
      },
      option: { plain: true },
    });
    const project = projects({
      include: { projectId: form.projectId },
      option: { plain: true },
    });
    // get project specific information and save them into project
    project.customer = customers({
      include: { customerId: project.customerId },
    });
    project.site = sites({
      include: { siteId: project.siteId },
    });
    project.projectType = projectTypes({
      include: {
        projectTypeId: project.projectTypeId,
      },
    });
    project.projectKind = projectKinds({
      include: {
        projectKindId: project.projectKindId,
      },
    });
    // get projectUser and save them into project
    project.projectDepartmentManager = users({
      include: {
        userId: project.userIds,
        rightId: DEPARTMENT_MANAGER,
      },
      option: { plain: true },
    });
    project.projectManager = users({
      include: {
        userId: project.userIds,
        rightId: PROJECT_MANAGER,
      },
      option: { plain: true },
    });
    project.projectUser = users({
      include: {
        userId: project.userIds,
        rightId: MECHANIC,
      },
      option: { plain: true },
    });
    this.setState({ form });
    this.setState({ project });
  }
  renderPrint = () => {
    const { formTypeId, form, project } = this.state;
    if (formTypeId === FORM_TYPE_SITE_START) {
      return <PrintSiteStart form={form} project={project} />;
    }
    if (formTypeId === FORM_TYPE_CHECK || formTypeId === FORM_TYPE_RETROFIT) {
      return <PrintFormCheck form={form} project={project} />;
    }
    if (formTypeId === FORM_TYPE_SITE_CHECK) {
      return <PrintSiteCheck form={form} project={project} />;
    }
    return null;
  };
  render() {
    return <>{this.renderPrint()}</>;
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(withRouter(PrintForm));
