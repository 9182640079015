import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { change as changeAction } from 'redux-form';
import { Grid } from '@mui/material';
import UserTable from './UserTable';
import Button from '../../Atoms/Button/Button';
import AutocompleteForm from '../../Molecules/Autocomplete/AutocompleteForm';
import { MECHANIC, CONTRACTOR_STOPA } from '../../../../../shared/src/constants/general';
import { userSelector } from '../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  users: (filter: any) => userSelector(state, filter),
});

const mapDispatchToProps = (dispatch: any) => ({
  change: (form: any, field: any, value: any) => dispatch(changeAction(form, field, value)),
});

interface ComponentOwnProps {
  rightId?: number;
  contractorId?: number | null;
  label: string;
  disabled?: boolean;
  name: string;
  formData: any;
  formName: string;
  handleSave?: any;
}

interface ComponentStateProps {
  users: (...args: any[]) => any;
}

interface ComponentDispatchProps {
  change: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class UserSelectorTable extends React.Component<ComponentProps, {}> {
  static defaultProps = {
    disabled: false,
    rightId: MECHANIC,
    contractorId: null,
  };

  getUsers = () => {
    const { users, rightId, contractorId, formData } = this.props;
    if (contractorId === CONTRACTOR_STOPA) {
      return users({
        exclude: { userId: formData.values.userIds },
        include: { rightId, contractorId: CONTRACTOR_STOPA },
      });
    }
    return users({
      include: { rightId },
      exclude: {
        userId: formData.values.userIds,
        contractorId: CONTRACTOR_STOPA,
      },
    });
  };
  handleAdd = () => {
    const { change, name, formData, formName, handleSave = () => {} } = this.props;
    if (formData.values[name]) {
      const userId = parseInt(formData.values[name].value, 10); // [departmentManager] then get userId
      const users = [...formData.values.userIds, userId];
      // add the user to the form
      change(formName, 'userIds', users);
      // clear the add autocomplete field
      change(formName, name, null);
      handleSave();
    }
  };
  handleDelete = (id: number) => {
    const { change, formData, formName, handleSave = () => {} } = this.props;
    const formValues = formData.values; // no need for [name], since they are simply added
    const users = [...formValues.userIds];
    users.splice(formValues.userIds.indexOf(id), 1);
    change(formName, 'userIds', users);
    handleSave();
  };
  getUsersTableData = () => {
    const {
      users,
      rightId,
      contractorId,
      formData: {
        values: { userIds },
      },
    } = this.props;
    if (contractorId === CONTRACTOR_STOPA) {
      return users({
        include: { rightId, userId: userIds, contractorId: CONTRACTOR_STOPA },
        option: { plain: true, array: true, all: true },
      });
    }
    return users({
      include: { userId: userIds, rightId },
      exclude: { contractorId: CONTRACTOR_STOPA },
      option: { plain: true, array: true, all: true },
    });
  };
  render() {
    const { label, disabled, contractorId, name, handleSave } = this.props;

    return (
      <Fragment>
        <Grid container item md={6} justifyContent="center">
          <Grid container item md={12} justifyContent="space-between" alignItems="flex-end">
            <AutocompleteForm size={8} options={this.getUsers()} name={name} label={label} />
            <Button
              handleClick={() => {
                handleSave();
                this.handleAdd();
              }}
              size={3}
              disabled={disabled}
            >
              Hinzufügen
            </Button>
          </Grid>
          <UserTable
            users={this.getUsersTableData()}
            handleDelete={this.handleDelete}
            contractorId={contractorId}
          />
        </Grid>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(UserSelectorTable);
