import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Grid, Divider, Tabs, Tab, Box } from '@mui/material';
import ServiceProjectFilter from './components/ServiceProjectFilter/ServiceProjectFilter';
import ServiceProjectCreate from './components/ServiceProjectCreate/ServiceProjectCreate';
import ServiceProjectEdit from './components/ServiceProjectEdit/ServiceProjectEdit';
import SingleServiceProject from './components/SingleServiceProject/SingleServiceProject';
import DialogServiceEntry from './components/DialogTimeEntrySP/DialogServiceEntry/DialogServiceEntry';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import { getServiceProjectsAction, getAllServiceEntriesAction } from '../../../actions';
import { serviceProjectSelector } from '../../../helpers/selectors';
import { ServiceProjectWeeks } from './components/ServiceProjectsWeeks/ServiceProjectsWeeks';

const mapStateToProps = (state: any) => ({
  serviceProjectFilter: state.form.serviceProjectFilter,
  // redux-form
  serviceProjects: (filter: any) => serviceProjectSelector(state, filter),
});

const mapDispatchToProps = (dispatch: any) => ({
  getServiceProjects: () => dispatch(getServiceProjectsAction()),
});

interface ComponentOwnProps {
  // history: any;
}

interface ComponentStateProps {
  serviceProjects: (...args: any[]) => any;
  match?: any;
  history?: any;
  serviceProjectFilter?: any;
}

interface ComponentDispatchProps {
  getServiceProjects: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps;

const PageServiceProjectOverview: React.FC<ComponentProps> = ({
  serviceProjectFilter,
  serviceProjects,
}) => {
  const [selectedServiceProjects, setServiceProjects] = useState<any[]>([]);
  const [serviceProjectCreateDialogOpen, setServiceProjectCreateDialog] = useState<any>(false);
  //todo: refactor
  const [serviceProjectEditDialogOpen, setServiceProjectEditDialog] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    //show all serviceProjects on render
    setServiceProjects(serviceProjects({ option: { plain: true, array: true } }));
  }, []);
  // get filtered serviceProjects
  useEffect(() => {
    if (serviceProjectFilter) {
      if (serviceProjectFilter.values) {
        const { state, serviceProject } = serviceProjectFilter.values;
        if (state || serviceProject) {
          let serviceProjectStateId = !state
            ? null
            : state === 'null'
            ? null
            : [parseInt(state, 10)];
          let serviceProjectId = serviceProject ? serviceProject.value : null;
          const foundServiceProjects = serviceProjects({
            include: { serviceProjectId: serviceProjectId, state: serviceProjectStateId },
            option: { plain: true, array: true },
          });
          setServiceProjects(foundServiceProjects);
        } else {
          // setServiceProjects([]);
        }
      }
    }
  }, [serviceProjectFilter, serviceProjects]);

  return (
    <Fragment>
      <Box sx={{ width: '100%', mb: 4 }}>
        <Tabs
          value={tabIndex}
          onChange={(_, newIndex) => setTabIndex(newIndex)}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          <Tab label="Hauptansicht" />
          <Tab label="Wochenübersicht" />
        </Tabs>
      </Box>
      {tabIndex === 0 && (
        <Grid container>
          <Grid item md={12}>
            <ServiceProjectCreate
              toggleCreateDialog={setServiceProjectCreateDialog}
              visibility={serviceProjectCreateDialogOpen}
            />
            {serviceProjectEditDialogOpen && (
              <ServiceProjectEdit
                serviceProject={serviceProjectEditDialogOpen}
                toggleEditDialog={setServiceProjectEditDialog}
                visibility={serviceProjectEditDialogOpen !== null}
              />
            )}
          </Grid>
          <Grid item md={12}>
            <AdminSettingsWrapper>
              <>
                <ServiceProjectFilter
                  toggleCreateDialog={setServiceProjectCreateDialog}
                ></ServiceProjectFilter>
                <Divider style={{ width: '100%', marginBottom: 20, marginTop: 20 }} />
              </>
            </AdminSettingsWrapper>
          </Grid>
          {selectedServiceProjects.length >= 1 &&
            selectedServiceProjects.map((serviceProject) => (
              <AdminSettingsWrapper key={serviceProject.id}>
                <SingleServiceProject
                  serviceProject={serviceProject}
                  toggleServiceProjectEdit={setServiceProjectEditDialog}
                />
              </AdminSettingsWrapper>
            ))}
          <DialogServiceEntry />
        </Grid>
      )}
      {tabIndex === 1 && (
        <>
          <ServiceProjectWeeks />
        </>
      )}
    </Fragment>
  );
};

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageServiceProjectOverview));
