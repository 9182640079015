import React, { Fragment, memo } from 'react';
import { Grid } from '@mui/material';
import RadiosForm from '../Radios/RadiosForm';
import AutocompleteForm from '../Autocomplete/AutocompleteForm';
import Typography from '../../Atoms/Typography/Typography';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import getTimeData from '../../../helpers/timeFunctions';

type QuestionTypeTwoProps = {
  question: any;
  formCompleted: boolean;
  formData?: any;
};

const QuestionTypeTwo: React.SFC<QuestionTypeTwoProps> = memo(
  ({ question, formData = {}, formCompleted }) => {
    const { id } = question;
    return (
      <Grid container item md={12} spacing={3} alignItems="flex-start" justifyContent="flex-start">
        <Typography size={4} variant="body1" value={question.text} formQuestion />
        <RadiosForm
          name={`${id}`}
          options={[
            { label: 'Ja', value: 1 },
            { label: 'Nein', value: 0 },
          ]}
          size={2}
          disabled={formCompleted}
        />
        <Grid container item md={6} spacing={3} alignItems="flex-start" justifyContent="flex-start">
          {parseInt(formData.values[id], 10) === 1 && (
            <Fragment>
              <AutocompleteForm
                options={getTimeData({ minSteps: 15 })}
                label="Zeit von"
                size={6}
                name={`${id}timeStart`}
                formQuestion
                isDisabled={formCompleted}
              />
              <AutocompleteForm
                options={getTimeData({
                  timeStart: formData.values[`${id}timeStart`],
                  minSteps: 15,
                })}
                formQuestion
                label="bis"
                size={6}
                name={`${id}timeEnd`}
                isDisabled={formCompleted}
              />
            </Fragment>
          )}
          {formData.values[id] !== null && (
            <TextFieldForm
              size={12}
              name={`${id}textOne`}
              label="Anmerkung"
              formQuestion
              multiline
              rowsMax={3}
              disabled={formCompleted}
            />
          )}
        </Grid>
      </Grid>
    );
  }
);

export default QuestionTypeTwo;
