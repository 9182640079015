import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import moment, { locale } from 'moment';
import Image from '../../../../assets/img/STOPA-Logo.svg';
import formatTime from '../../../../helpers/FormatTime';
import Button from '../../../Atoms/Button/Button';
import DialogSignature from '../../../Organisms/DialogSignature/DialogSignature';
import { withRouter } from 'react-router-dom';
import {
  BILLING_TYPE_FLAT_WITHOUT,
  SERVICE_TYPE_WORK,
  SERVICE_TYPE_TRAVEL,
  SERVICE_TYPE_BREAK,
  SERVICE_PROJECT_STATE_FINISHED,
} from '../../../../../../shared/src/constants/general';
import './print.scss';
import { setSignatureCreateDialogAction, saveServiceProjectAction } from '../../../../actions';
import {
  serviceProjectSelector,
  customerSelector,
  siteSelector,
  serviceSelector,
  specificationASelector,
  userSelector,
  ruleSelector,
} from '../../../../helpers/selectors';
import { getGeneralData } from '../../../../constants/calls';
import {
  IServiceEntry,
  IServiceEntryEnriched,
} from '../../../../../../shared/src/interfaces/ServiceEntry';
import { getSortedServiceEntries } from '../../../../helpers/utils';

locale('de');

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  printOptions: state.rootReducer.printOptions,
  serviceProjects: (filter: any) => serviceProjectSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
});
const mapDispatchToProps = (dispatch: any) => ({
  setSignatureCreateDialog: (open: any) => dispatch(setSignatureCreateDialogAction(open)),
  saveServiceProject: (data: any) => dispatch(saveServiceProjectAction(data)),
});

interface ComponentOwnProps {
  match?: any;
}

interface ComponentStateProps {
  auth: any;
  printOptions: any;
  serviceProjects: (...args: any[]) => any;
  rules: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  users: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  // printEntries: any;
  // timeSheets: any[];
}

interface ComponentDispatchProps {
  saveServiceProject: (...args: any[]) => any;
  setSignatureCreateDialog: (...args: any[]) => any;
  // addTimeSheet: (...args: any[]) => any;
}

interface IServiceEntryPrint {
  date: any;
  entries: IServiceEntryEnriched[];
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

const PrintServiceEntry: React.FC<ComponentProps> = ({
  serviceProjects,
  rules,
  match,
  customers,
  sites,
  users,
  services,
  auth,
  specificationAs,
  saveServiceProject,
  setSignatureCreateDialog,
  printOptions,
}) => {
  const [serviceProject, setServiceProject] = useState<any>(null);
  const [selectedServiceEntries, setSelectedServiceEntries] = useState<IServiceEntryPrint[]>([]);
  const { isInternalDoc } = printOptions || {};

  useEffect(() => {
    let isCancelled = false;
    const serviceProjectId = parseInt(match.params.id, 10);
    const foundServiceProject = serviceProjects({
      include: { serviceProjectId: serviceProjectId },
      option: { plain: true },
    });
    const fetchServiceEntries = async () => {
      try {
        const foundServiceEntries: IServiceEntry[] = await getGeneralData(
          'serviceEntry/serviceProjectEntries',
          match.params.id
        );
        const sortedServiceEntries = getSortedServiceEntries(foundServiceEntries, users);
        if (!isCancelled) {
          const printServiceEntries: IServiceEntryPrint[] = sortedServiceEntries.reduce(
            (acc: any, cur: any) => {
              const entryIndex: any = acc.indexOf(acc.find((el: any) => el.date === cur.date));
              if (entryIndex === -1) {
                acc.push({ date: cur.date, entries: [cur] });
              } else {
                acc[entryIndex].entries.push(cur);
              }
              return acc;
            },
            []
          );
          setSelectedServiceEntries(printServiceEntries);
        }
      } catch (e) {
        if (!isCancelled) {
          setSelectedServiceEntries([]);
        }
      }
      return () => {
        isCancelled = true;
      };
    };
    fetchServiceEntries();
    setServiceProject(foundServiceProject);
  }, []);

  const calculateTime = (serviceTypeId: any) => {
    const result = selectedServiceEntries
      .map((day) => {
        const foundEntries = day.entries.filter((entry) => {
          // get the selected rule for the entry
          const rule = rules({ include: { ruleId: entry.ruleId } });
          // check if its the serviceType we are looking fo
          if (rule.serviceTypeId === serviceTypeId && evalFlatRate(entry, isInternalDoc)) {
            return true;
          }
          return false;
        });
        return foundEntries;
      })
      .reduce((acc, val) => acc.concat(val), []); //flatten array;
    if (result) {
      const calculated = result.map((entry) => {
        if (moment(entry.timeEnd).format('HH:mm') === '23:59') {
          const calculatedTime = formatTime(entry.timeStart, '23:00') + 1;
          return calculatedTime;
        }
        return formatTime(entry.timeStart, entry.timeEnd);
      });
      const sum = calculated.reduce((pv: any, cv: any) => pv + cv, 0);
      return sum;
    }
    return 0;
  };

  const calculateDayTime = (dayEntry: any, serviceTypeId: any) => {
    const allDayEntries: IServiceEntry[] = [];
    dayEntry.entries.forEach((entry: any) => {
      allDayEntries.push(entry);
    });
    const result = allDayEntries.filter((entry) => {
      // get the selected rule for the entry
      const rule = rules({ include: { ruleId: entry.ruleId } });
      // check if its the serviceType we are looking for
      if (
        rule.serviceTypeId === serviceTypeId &&
        entry.billingTypeId !== BILLING_TYPE_FLAT_WITHOUT &&
        evalFlatRate(entry, isInternalDoc)
      ) {
        return entry;
      }
      return null;
    });

    if (result) {
      const calculated = result.map((entry) => {
        if (moment(entry.timeEnd).format('HH:mm') === '23:59') {
          const calculatedTime = formatTime(entry.timeStart, '23:00') + 1;
          return calculatedTime;
        }
        return formatTime(entry.timeStart, entry.timeEnd);
      });
      const sum = calculated.reduce((pv, cv) => pv + cv, 0);
      return sum;
    }
    return 0;
  };

  const handleSaveDialog = (signature: string, signedBy: string) => {
    // const {
    //   users,
    //   serviceProjects,
    //   printEntries,
    //   addTimeSheet,
    //   setSignatureCreateDialog,
    //   // handleSave,
    // } = this.props;
    // const { signedBy } = this.state;
    // get all the data
    if (serviceProject) {
      const newServiceProject = {
        ...serviceProject,
        signedByUserId: auth.id,
        signedBy,
        state: SERVICE_PROJECT_STATE_FINISHED,
        signedAt: moment().valueOf(),
        signature,
      };
      saveServiceProject(newServiceProject);

      setServiceProject(newServiceProject);
      // const signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
      // this.sigPad.clear();
      // handleSave();
    }

    //todo: updateServiceProject
    // addTimeSheet(year, week, signature, userId, projectId, signedBy);
    setSignatureCreateDialog(false);
  };

  if (serviceProject && selectedServiceEntries) {
    const workTime = calculateTime(SERVICE_TYPE_WORK);
    const travelTime = calculateTime(SERVICE_TYPE_TRAVEL);
    const totalWork = workTime + travelTime;

    const customer = customers({
      include: { customerId: serviceProject.customerId },
      option: { plain: true },
    });
    const site = sites({
      include: { siteId: serviceProject.siteId },
      option: { plain: true },
    });

    return (
      <div className="sp-container-wrapper">
        <img src={Image} width="100px" className="sp-logo" alt="logo" />
        <div className="sp-header-wrapper">
          <div className="sp-block">
            <div className="sp-headerInformation">
              <p className="sp-headerInformationPara">{`${serviceProject.serviceProjectNumber}`}</p>
              <p className="sp-headerInformationPara">{workTime.toFixed(2)}</p>
              <p className="sp-headerInformationPara">{travelTime.toFixed(2)}</p>
              <p className="sp-headerInformationPara">{totalWork.toFixed(2)}</p>
            </div>
            <div className="sp-information-wrapper">
              <p className="sp-customer">{customer.name}</p>
            </div>
          </div>
          <div className="sp-stampArea">
            {/* if serviceProject is signed show signBy else show signature dialog */}
            {serviceProject.signedBy ? (
              <Fragment>
                {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
                <img className="sp-signatureImage" src={serviceProject.signature} alt="Signatur" />
                {/* </div> */}
                <p className="sp-stampInformation">
                  {moment(serviceProject.signedAt).format('DD.MM.YYYY')}
                  {', '}
                  {serviceProject.signedBy}
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <div className="sp-signatureButton">
                  <Button handleClick={() => setSignatureCreateDialog(true)} size={12}>
                    Unterschreiben
                  </Button>
                </div>
                <p className="sp-stampInformation">Datum, Name, Unterschrift</p>
              </Fragment>
            )}
          </div>
        </div>
        <div className="sp-info-row">
          <div className="sp-container-left">
            <p className="sp-location">{`${site.city} / ${site.adress}`}</p>
            <p className="sp-company">{`${site.fab}`}</p>
            <p className="sp-ab">{`${serviceProject.ab || 'k.A.'}`}</p>
            <p className="sp-wa">{`${serviceProject.wa || 'k.A.'}`}</p>
          </div>
        </div>
        {selectedServiceEntries.map((entry) => (
          <Fragment key={entry.date}>
            {entry.entries.length > 0 && (
              <div className="sp-entry-wrapper">
                <div className="sp-entry-general-information">
                  <p>
                    {moment(entry.date).format('DD.MM')}
                    <span>{moment(entry.date).format('dd')}</span>
                  </p>
                  <p>{calculateDayTime(entry, SERVICE_TYPE_WORK).toFixed(2)}</p>
                  <p>{calculateDayTime(entry, SERVICE_TYPE_TRAVEL).toFixed(2)}</p>
                  <p>
                    {(
                      calculateDayTime(entry, SERVICE_TYPE_WORK) +
                      calculateDayTime(entry, SERVICE_TYPE_TRAVEL)
                    ).toFixed(2)}
                  </p>
                  <p>{calculateDayTime(entry, SERVICE_TYPE_BREAK).toFixed(2)}</p>
                </div>
                <table className="sp-entry-table">
                  <thead>
                    <tr>
                      <th>Std</th>
                      <th>von</th>
                      <th>bis</th>
                      <th>Mitarbeiter</th>
                      <th>Tätigkeit</th>
                      <th>Spez. 1</th>
                      <th>Ersatzteile</th>
                      {isInternalDoc ? (
                        <th className="commentsSmall">Kommentar (extern)</th>
                      ) : (
                        <th className="commentsWide">Kommentar</th>
                      )}
                      {isInternalDoc ? <th className="commentsSmall">Kommentar (intern)</th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {entry.entries.map((singleEntry) => (
                      <div key={singleEntry.id} style={{ display: 'contents' }}>
                        <tr>
                          <td style={hideExtFlatRate(singleEntry, isInternalDoc)}>
                            {moment(singleEntry.timeEnd).format('HH:mm') === '23:59'
                              ? formatTime(singleEntry.timeStart, '23:00') + 1
                              : formatTime(singleEntry.timeStart, singleEntry.timeEnd)}
                          </td>
                          <td style={hideExtFlatRate(singleEntry, isInternalDoc)}>
                            {moment(singleEntry.timeStart, 'HH:mm:ss').format('HH:mm')}
                          </td>
                          <td style={hideExtFlatRate(singleEntry, isInternalDoc)}>
                            {moment(singleEntry.timeEnd).format('HH:mm') === '23:59'
                              ? '24:00'
                              : moment(singleEntry.timeEnd, 'HH:mm:ss').format('HH:mm')}
                          </td>
                          <td>{singleEntry.userLastName}</td>
                          <td>
                            {
                              services({
                                include: {
                                  serviceId: rules({
                                    include: { ruleId: singleEntry.ruleId },
                                  }).serviceId,
                                },
                                option: { plain: true },
                              }).name
                            }
                          </td>
                          <td>
                            {specificationAs({
                              include: {
                                specificationAId: rules({
                                  include: { ruleId: singleEntry.ruleId },
                                }).specificationAId,
                              },
                              option: { plain: true },
                            }) &&
                              specificationAs({
                                include: {
                                  specificationAId: rules({
                                    include: { ruleId: singleEntry.ruleId },
                                  }).specificationAId,
                                },
                                option: { plain: true },
                              }).name}
                          </td>
                          <td style={{ whiteSpace: 'pre-line' }}>{`${
                            singleEntry.licensePlate ? singleEntry.licensePlate + '\n' : ''
                          }${singleEntry.spareParts}`}</td>
                          <td style={{ whiteSpace: 'pre-line' }}>{singleEntry.commentPublic}</td>
                          {isInternalDoc ? (
                            <td style={{ whiteSpace: 'pre-line' }}>{singleEntry.commentPrivate}</td>
                          ) : null}
                        </tr>
                        {singleEntry.isMaterialSmallUsed && (
                          <tr>
                            <td colSpan={8} style={{ textAlign: 'center' }}>
                              --- Montagematerial klein verwendet ---
                            </td>
                          </tr>
                        )}
                        {singleEntry.isMaterialLargeUsed && (
                          <tr>
                            <td colSpan={8} style={{ textAlign: 'center' }}>
                              --- Montagematerial groß verwendet ---
                            </td>
                          </tr>
                        )}
                        {singleEntry.isOvernightStay && (
                          <tr>
                            <td colSpan={8} style={{ textAlign: 'center' }}>
                              --- Übernachtung ---
                            </td>
                          </tr>
                        )}
                      </div>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Fragment>
        ))}
        <DialogSignature handleSave={handleSaveDialog} />
      </div>
    );
  } else {
    return <div>loading...</div>;
  }
};

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(PrintServiceEntry));

/**
 * hide service entries is the document is not internal and type is flatRate
 * @param entry
 * @param isInternal
 * @returns
 */
const hideExtFlatRate = (entry: IServiceEntry, isInternal: boolean): React.CSSProperties =>
  entry.isFlatRateService && !isInternal ? { visibility: 'collapse' } : {};

const evalFlatRate = (entry: IServiceEntry, isInternal: boolean): boolean => {
  if (isInternal) {
    return true;
  }
  return !entry.isFlatRateService;
};
