import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';
import { RootState } from '../../store/index';

export const serviceProjectSelector = <T = any>(
  state: RootState,
  {
    include = {
      serviceProjectId: null,
      serviceProjectNumber: null,
      state: null,
    },
    option = {
      plain: false,
      array: false,
    },
  }
): T =>
  createSelector(
    () => state.rootReducer.serviceProjects,
    () => state.rootReducer.sites,
    () => state.rootReducer.customers,
    (serviceProjects, sites, customers) => {
      // for single selected project

      if (checkSingleValue(include.serviceProjectId)) {
        const serviceProjectId = Array.isArray(include.serviceProjectId)
          ? include.serviceProjectId![0]
          : include.serviceProjectId;

        const project = serviceProjects.find((p: any) => p.id === parseInt(serviceProjectId!, 10));
        if (option.plain) {
          return option.array ? [project] : project;
        }
        // const customer = customers.find((c: any) => c.id === project.customerId);
        const site = sites.find((s: any) => s.id === project.siteId);
        const customer = customers.find((s: any) => s.id === project.customerId);

        // return option.array
        //   ? [
        //       {
        //         label: `${site.serviceProjectNumber} - ${site.country} - ${site.city} - ${site.adress}`,
        //         value: `${project.id}`,
        //       },
        //     ]
        //   : {
        //       label: `${site.serviceProjectNumber} - ${site.country} - ${site.city} - ${site.adress}`,
        //       value: `${project.id}`,
        //     };

        return option.array
          ? [
              {
                label: `${project.serviceProjectNumber} - ${customer.name}`,
                value: `${project.id}`,
              },
            ]
          : {
              label: `${project.serviceProjectNumber} - ${customer.name}`,
              value: `${project.id}`,
            };
      }

      let serviceProjectOptions = [...serviceProjects];
      // if (option.deactivated) {
      //   // Filter all deactivated projects
      //   serviceProjectOptions = serviceProjectOptions.filter(
      //     (project: any) => project.projectStateId === PROJECT_STATE_ACTIVE
      //   );
      // }

      if (include.state) {
        serviceProjectOptions = serviceProjectOptions.filter(
          // @ts-ignore
          (project) => include.state.includes(project.state)
        );
      }

      if (include.serviceProjectId) {
        serviceProjectOptions = serviceProjectOptions.filter((project: any) =>
          // @ts-ignore
          include.serviceProjectId.includes(project.id)
        );
      }
      if (include.serviceProjectNumber) {
        serviceProjectOptions = serviceProjectOptions.filter((project: any) =>
          // @ts-ignore
          include.serviceProjectNumber.includes(project.serviceProjectNumber)
        );
      }
      // include the projects that have the specified userids
      // if (include.userId) {
      //   serviceProjectOptions = serviceProjectOptions.filter(project => project.userIds.includes(include.userId));
      // }
      // if (include.userId) {
      //   const userId = Array.isArray(include.userId)
      //     ? include.userId
      //     : [parseInt(include.userId!, 10)];

      //   serviceProjectOptions = serviceProjectOptions.filter((project: any) =>
      //     // userId.every(r => project.userIds.indexOf(r) >= 0)
      //     // @ts-ignore
      //     userId.every(r => project.userIds.includes(r))
      //   );
      // }

      if (serviceProjectOptions) {
        if (option.plain) {
          return serviceProjectOptions;
        }
        return serviceProjectOptions.map((project: any) => {
          const site = sites.find((s: any) => s.id == project.siteId);
          const customer = customers.find((s: any) => s.id === project.customerId);
          // const customer = customers.find((c: any) => c.id === project.customerId);

          return {
            label: `${project.serviceProjectNumber} - ${customer.name}`,
            value: `${project.id}`,
          };
        });
      }
      return null;
    }
  )(null as any);

export default {};
