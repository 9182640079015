import React, { PureComponent, Fragment } from 'react';
import { Grid } from '@mui/material';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import SearchBar from './components/SearchBar';
import InformationBar from './components/InformationBar';
import ProjectHourTable from '../../../../Molecules/Tables/ProjectHourTable';
const mapStateToProps = (state: any) => ({
  projectTimeEntries: state.rootReducer.projectTimeEntries,
});

type ProjectHoursProps = {
  projectTimeEntries?: any[];
};

class ProjectHours extends PureComponent<ProjectHoursProps, {}> {
  static defaultProps = {
    projectTimeEntries: null,
  };

  render() {
    const { projectTimeEntries } = this.props;
    return (
      <Fragment>
        <Grid item md={12}>
          <InformationBar />
        </Grid>
        <Grid item md={12}>
          <SearchBar />
        </Grid>
        {projectTimeEntries && (
          <Fragment>
            <Grid item md={12}>
              <ProjectHourTable />
            </Grid>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'ProjectHours',
  })(ProjectHours)
);
