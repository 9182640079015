import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const billingSelector = (
  state: any,
  {
    include = {
      billingId: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.billings,
    (billings) => {
      if (checkSingleValue(include.billingId)) {
        const billingId = Array.isArray(include.billingId)
          ? include.billingId![0]
          : include.billingId;
        const billing = billings.find((bT: any) => bT.id === parseInt(billingId!, 10));
        if (billing) {
          if (option.plain) {
            return billing;
          }
          return { label: billing.name, value: `${billing.id}` };
        }
        return null;
      }

      if (billings) {
        if (option.plain) {
          return billings;
        }
        return billings.map((billing: any) => ({
          label: billing.name,
          value: `${billing.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
