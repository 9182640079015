import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm, change as changeAction } from 'redux-form';
import { Grid } from '@mui/material';
import Form from '../../../../../../Atoms/Form/Form';
import AutocompleteForm from '../../../../../../Molecules/Autocomplete/AutocompleteForm';
import TextFieldForm from '../../../../../../Atoms/TextField/TextFieldForm';
import Typography from '../../../../../../Atoms/Typography/Typography';
import UserTable from '../../../../../../Organisms/UserSelectorTable/UserTable';

import {
  MECHANIC,
  ROLE_MECHANIC,
  ROLE_ELECTRICIAN,
  DEPARTMENT_MANAGER,
  PROJECT_MANAGER,
} from '../../../../../../../../../shared/src/constants/general';
import { saveFormAction } from '../../../../../../../actions';
import {
  ruleSelector,
  userSelector,
  projectSelector,
  customerContactSelector,
  contractorSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
  projectTypeSelector,
  projectKindSelector,
  groupSelector,
} from '../../../../../../../helpers/selectors';
const mapStateToProps = (state: any) => ({
  rules: (filter: any) => ruleSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  customerContacts: (filter: any) => customerContactSelector(state, filter),
  contractors: (filter: any) => contractorSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  projectTypes: (filter: any) => projectTypeSelector(state, filter),
  projectKinds: (filter: any) => projectKindSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  visits: state.rootReducer.visits,
  formSiteCheckHeader: state.form.formSiteCheckHeader,
  forms: state.rootReducer.forms,
});
const mapDispatchToProps = (dispatch: any) => ({
  saveForm: (data: any) => dispatch(saveFormAction(data)),
  change: (field: any, value: any, form?: any) => dispatch(changeAction(field, value, form)),
});

type SiteCheckInformationProps = {
  projects: (...args: any[]) => any;
  customerContacts: (...args: any[]) => any;
  users: (...args: any[]) => any;
  projectTypes: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  forms: any[];
  formSiteCheckHeader?: any;
  saveForm: (...args: any[]) => any;
  handleSubmit: (...args: any[]) => any;
  change: (...args: any[]) => any;
  initialize: (...args: any[]) => any;
  formId: number;
};

type SiteCheckInformationState = {
  form: any;
};

class SiteCheckInformation extends React.Component<
  SiteCheckInformationProps,
  SiteCheckInformationState
> {
  static defaultProps = {
    formSiteCheckHeader: null,
  };

  state = {
    form: { completed: true },
  };

  componentDidMount() {
    // Kunde LagerTyp Ort/Land FAB AUftragsNR WA-NR PL-STOPA PL-TRUMP PL-Kunde Monteur mech. Monteru elek
    const { initialize, users, projectTypes, projects, customerContacts, forms, formId } =
      this.props;
    const form = forms.find((f) => f.id === formId);

    this.setState({ form });
    const project = projects({
      include: { projectId: form.projectId },
      option: { plain: true },
    });
    const customerContact = customerContacts({
      include: { customerContactId: project.customerContactId },
    });
    const projectType = projectTypes({
      include: { projectTypeId: project.projectTypeId },
    });

    const responsibleMechanic = form.responsibleMechanicId
      ? users({
          include: { userId: form.responsibleMechanicId },
        })
      : null;

    const responsibleElectrician = form.responsibleElectricianId
      ? users({
          include: { userId: form.responsibleElectricianId },
        })
      : null;

    initialize({
      ...project,
      responsibleMechanic,
      responsibleElectrician,
      responsibleContractor: form.responsibleContractor,
      customerContact: customerContact ? customerContact.label : null,
      projectType: projectType ? projectType.label : null,
    });
  }
  getSortedProjectUser = () => {
    const { users, formSiteCheckHeader } = this.props;
    if (formSiteCheckHeader) {
      const projectDepartmentManager = users({
        include: {
          userId: formSiteCheckHeader.values.userIds,
          rightId: DEPARTMENT_MANAGER,
        },
        option: { plain: true },
      });
      const projectManager = users({
        include: {
          userId: formSiteCheckHeader.values.userIds,
          rightId: PROJECT_MANAGER,
        },
        option: { plain: true },
      });
      const projectUser = users({
        include: {
          userId: formSiteCheckHeader.values.userIds,
          rightId: MECHANIC,
        },
        option: { plain: true },
      });
      return projectDepartmentManager.concat(projectManager, projectUser);
    }
    return [];
  };
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field, null));
  };

  handleSave = (values: any) => {
    const { saveForm, forms, formId } = this.props;
    const { responsibleMechanic, responsibleElectrician, responsibleContractor } = values;
    const form = forms.find((f) => f.id === formId);
    if (responsibleMechanic && responsibleElectrician) {
      if (responsibleMechanic.value && responsibleElectrician.value) {
        const responsibleMechanicId = responsibleMechanic.value;
        const responsibleElectricianId = responsibleElectrician.value;
        saveForm({
          formId: form.id,
          responsibleMechanicId,
          responsibleElectricianId,
          responsibleContractor,
        });
      }
    }
  };
  render() {
    const { users, groups, formSiteCheckHeader, handleSubmit } = this.props;
    const { form } = this.state;

    return (
      <Fragment>
        {formSiteCheckHeader && form && (
          <Form handleSubmit={handleSubmit(this.handleSave)}>
            <AutocompleteForm
              size={4}
              name="responsibleMechanic"
              options={users({
                include: {
                  userId: formSiteCheckHeader.values.userIds,
                  role: ROLE_MECHANIC,
                },
              })}
              label="Verantwortlicher Mechaniker"
              isDisabled={form.completed}
            />
            <AutocompleteForm
              size={4}
              name="responsibleElectrician"
              options={users({
                include: {
                  userId: formSiteCheckHeader.values.userIds,
                  role: ROLE_ELECTRICIAN,
                },
              })}
              label="Verantwortlicher Elektriker"
              isDisabled={form.completed}
            />
            <TextFieldForm
              size={4}
              name="responsibleContractor"
              label="GU-Ansprech-Partner"
              disabled={form.completed}
            />
            <Fragment>
              <TextFieldForm label="Ansprechpartner" name="customerContact" disabled />
              <TextFieldForm name="fab" label="FAB" type="number" disabled />
              <TextFieldForm name="ab" label="AB" type="number" disabled />
              <TextFieldForm name="projectType" label="Typ" disabled />
              <Grid item md={3} />
              {groups({ option: { plain: true } }).map((group: any) => (
                <TextFieldForm
                  key={group.id}
                  name={group.shortName}
                  label={`WA - ${group.shortName}`}
                  type="number"
                  disabled
                />
              ))}
              <Typography size={12} value="Projekt Mitarbeiter" variant="subtitle1" />
              <UserTable
                users={this.getSortedProjectUser()}
                handleDelete={() => {}}
                withOperation={false}
                withTel
                withRole
                withRight
              />
            </Fragment>
          </Form>
        )}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'formSiteCheckHeader',
  })(withRouter<any, any>(SiteCheckInformation))
);
