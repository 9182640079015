import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IconButton } from '@mui/material';
import ExitIcon from '@mui/icons-material/ExitToApp';
import makeRequest from '../../../../helpers/Requests';
import { setAuthenticationAction, setSidebarAction } from '../../../../actions';

const mapDispatchToProps = (dispatch: any) => ({
  setAuthentication: (auth: any) => dispatch(setAuthenticationAction(auth)),
  setSidebar: (open: any) => dispatch(setSidebarAction(open)),
});

type LogoutProps = {
  setAuthentication: (...args: any[]) => any;
  setSidebar: (...args: any[]) => any;
  history?: any;
};

class Logout extends PureComponent<LogoutProps, {}> {
  handleLogout = async () => {
    const { history = {}, setAuthentication, setSidebar } = this.props;
    const result = await makeRequest('POST', 'logout');
    setSidebar(false);
    if (result) {
      history.push('/logout');
      setAuthentication(null);
    }
  };
  render() {
    return (
      <IconButton
        className=""
        color="inherit"
        aria-label="Menu"
        onClick={() => this.handleLogout()}
      >
        <ExitIcon />
      </IconButton>
    );
  }
}

export default connect(null, mapDispatchToProps)(withRouter<any, any>(Logout));
