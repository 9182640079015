import React from 'react';
import { MenuList, List, MenuItem, ListItemText } from '@mui/material';
import { CONTRACTOR_STOPA } from '../../../../../shared/src/constants/general';
import { Theme } from '@mui/material/styles';

const styles = {
  selected: {
    '& .Mui-selected': {
      color: '#fff!important',
      backgroundColor: '#0072B6!important',
      '&:hover': {
        backgroundColor: '#0072B6',
      },
    },
  },
  nested: ({ spacing }: Theme) => ({
    paddingLeft: spacing(4),
  }),
};
interface ComponentOwnProps {
  auth: any;
  data: any[];
  selectedSection: string;
  handleClick: (...args: any[]) => any;
}

interface ComponentStateProps {}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

const getData = (data: any, auth: any) => {
  let dataOption = data;
  dataOption = dataOption
    .filter((item: any) => auth.rightId <= item.rightId)
    .map((item: any) => {
      if (item.subMenuItems) {
        const subMenuItems = item.subMenuItems.filter((el: any) => auth.rightId <= el.rightId);
        return { ...item, subMenuItems };
      }
      return item;
    });
  return dataOption;
};

const SidebarMenuList: React.FC<ComponentProps> = ({
  auth,
  selectedSection,
  data,
  handleClick,
}) => (
  <MenuList sx={styles.selected}>
    {getData(data, auth)
      .filter((el: any) => !el.noContractor || auth.contractorId === CONTRACTOR_STOPA)
      .map((el: any) => {
        return (
          <MenuItem
            selected={selectedSection === el.value}
            value={el.value}
            onClick={handleClick(el.link, el.value)}
            key={el.value}
          >
            <ListItemText primary={el.label} />
            {el.subMenuItems && (
              <List sx={styles.nested}>
                {el.subMenuItems.map((subEl: any) => (
                  <MenuItem
                    key={subEl.value}
                    sx={styles.selected}
                    selected={selectedSection === subEl.value}
                    value={subEl.value}
                    onClick={handleClick(subEl.link, subEl.value)}
                  >
                    <ListItemText primary={subEl.label} />
                  </MenuItem>
                ))}
              </List>
            )}
          </MenuItem>
        );
      })}
  </MenuList>
);

export default SidebarMenuList;
