import { withRouter } from 'react-router-dom';
import React from 'react';
import ContractorCreate from '../../../../Molecules/AdminAreaCreates/ContractorCreate';
import ContractorTable from '../../../../Molecules/Tables/ContractorTable';
import AdminOuterWrapper from '../../../../Templates/AdminWrappers/AdminOuterWrapper';

const PageContractorOverview: React.SFC = () => (
  <AdminOuterWrapper>
    <ContractorCreate />
    <ContractorTable />
  </AdminOuterWrapper>
);

export default withRouter(PageContractorOverview);
