import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FormTable from '../../../../Molecules/Tables/FormTable';
import FormFilter from './components/FormFilter';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';
import Visit from '../../../PageDiagnostic/components/Visit/Visit';
import AdminOuterWrapper from '../../../../Templates/AdminWrappers/AdminOuterWrapper';

const mapStateToProps = (state: any) => ({
  visits: state.rootReducer.visits,
  adminSection: state.rootReducer.adminSection,
});
type PageFormOverviewProps = {
  visits?: any[];
  adminSection: string;
};

class PageFormOverview extends PureComponent<PageFormOverviewProps, {}> {
  static defaultProps = {
    visits: null,
  };
  render() {
    const { visits, adminSection } = this.props;
    return (
      <AdminOuterWrapper>
        {adminSection !== 'formVisit' && (
          <Fragment>
            <AdminSettingsWrapper alignItems="flex-start" editWrapper>
              <FormFilter />
            </AdminSettingsWrapper>
            <AdminSettingsWrapper alignItems="flex-start" editWrapper>
              <FormTable />
            </AdminSettingsWrapper>
          </Fragment>
        )}
        {visits && (adminSection === 'formVisit' || adminSection === 'form') && (
          <AdminSettingsWrapper alignItems="flex-start">
            <Visit />
          </AdminSettingsWrapper>
        )}
      </AdminOuterWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withRouter<any, any>(PageFormOverview));
