import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const roleSelector = (
  state: any,
  {
    include = {
      roleId: null,
      userId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      array: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.roles,
    () => state.rootReducer.users,
    (roles, users) => {
      // for single selected role
      if (checkSingleValue(include.roleId)) {
        const roleId = Array.isArray(include.roleId) ? include.roleId![0] : include.roleId;
        const role = roles.find((g: any) => g.id === parseInt(roleId!, 10));
        if (role) {
          if (option.plain) {
            return option.array ? [role] : role;
          }
          return option.array
            ? [{ label: `${role.name}`, value: `${role.id}` }]
            : { label: `${role.name}`, value: `${role.id}` };
        }
        return null;
      }

      let roleOptions = roles;

      if (include.userId) {
        const includeUserId = parseInt(include.userId!, 10);
        const user = users.find((u: any) => u.id === includeUserId);
        roleOptions = roleOptions.filter((role: any) => user.roles.includes(role.id));
      }
      if (include.roleId) {
        // @ts-ignore
        roleOptions = roleOptions.filter((role: any) => include.roleId.includes(role.id));
      }
      if (roleOptions) {
        if (option.plain) {
          return roleOptions;
        }
        return roleOptions.map((role: any) => ({ label: `${role.name}`, value: `${role.id}` }));
      }
      return null;
    }
  )(null as any);

export default {};
