import React, { memo } from 'react';
import Select, { createFilter } from 'react-select';
import { Grid, GridSize, TextField, MenuItem, Box } from '@mui/material';
import { Field } from 'redux-form';
import { compareProps } from '../../../helpers/compareProps';
import { FixedSizeList as List } from 'react-window';

const styles = {
  formQuestionGrid: {
    paddingTop: '0!important',
  },
};

const selectStyles = {
  singleValue: (provided: any) => {
    const margin = '0';
    return { ...provided, margin };
  },
  valueContainer: (provided: any) => {
    const overflow = 'visible';
    const padding = '0!important';
    return { ...provided, padding, overflow };
  },
  indicatorSeparator: (provided: any) => {
    const margin = 0;
    return { ...provided, margin };
  },
  menu: (provided: any) => {
    const zIndex = 2;
    return { ...provided, zIndex };
  },
};

const inputComponent: (params: any) => any = memo(({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
));

const Control: (params: any) => any = memo(
  ({ selectProps, innerRef, children, innerProps, hasValue }) => {
    return (
      <TextField
        fullWidth
        variant="standard"
        InputLabelProps={{ shrink: hasValue }}
        InputProps={{
          inputComponent,
          inputProps: {
            sx: {
              display: 'flex',
              alignItems: 'stretch',
              backgroundColor: selectProps.colorWhite ?? '#FFF',
            },
            ref: innerRef,
            children,
            ...innerProps,
          },
        }}
        {...selectProps.textFieldProps}
      />
    );
  }
);

const Option: (params: any) => any = ({
  innerRef,
  isFocused,
  isSelected,
  innerProps,
  selectProps,
  children,
  ...props
}) => {
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      sx={{
        zIndex: 2,
        color: '#000',
      }}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
};

const MenuList: (params: any) => any = ({ options, children, maxHeight, getValue }) => {
  const height = 35;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;
  return (
    <List
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
      width=""
    >
      {({ index, style }: any) => (
        <div className="option-wrapper" style={style}>
          {children[index]}
        </div>
      )}
    </List>
  );
};

const componentsList: any = {
  Option,
  Control,
  MenuList,
};

type renderSelectFieldProps = {
  input: any;
  label: any;
  children: any;
  options: any;
  filterOptions?: (option: any, inputValue: any) => any | undefined;
  isDisabled: any;
  isClearable: any;
  colorWhite: any;
  meta: {
    touched: any;
    error: any;
  };
};

const renderSelectField: React.SFC<renderSelectFieldProps> = memo(
  ({
    input,
    label,
    children,
    options,
    isDisabled,
    isClearable,
    colorWhite,
    meta: { touched, error },
    ...custom
  }) => {
    return (
      <Select
        options={options}
        filterOption={createFilter({ ignoreAccents: false })}
        styles={selectStyles}
        colorWhite={colorWhite}
        components={componentsList}
        placeholder=""
        isDisabled={isDisabled}
        isClearable={isClearable}
        noOptionsMessage={() => null}
        value={input.value}
        {...input}
        {...custom}
        textFieldProps={{
          label: `${label}`,
          value: `${input.value}`,
          error: !!(touched && error),
          helperText: (touched && error) || '',
        }}
        onBlur={() => input.onBlur(input.value)}
      />
    );
  }
);
type AutocompleteProps = {
  options?: any;
  gridStyles?: object;
  onChange?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  formValues?: any;
  // TODO: only gridsize leads to error, why? In button it works...
  size?: GridSize | any;
  isClearable?: boolean;
  colorWhite?: boolean;
  formQuestion?: boolean;
  placeholder?: string;
  label?: string;
  isDisabled?: any;
  name: any;
};

const Autocomplete: React.SFC<AutocompleteProps> = memo(
  ({
    options,
    isDisabled,
    size = 2,
    name,
    label = '',
    onChange = () => {},
    onBlur = () => {},
    formValues = null,
    isClearable = true,
    gridStyles = {},
    colorWhite = false,
    placeholder = '',
    formQuestion = false,
  }) => (
    <Grid
      item
      md={size}
      style={gridStyles}
      sx={{
        ...(formQuestion && styles.formQuestionGrid),
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          maxWidth: '100%',
        }}
      >
        <Field
          name={name}
          component={renderSelectField}
          isClearable={isClearable}
          colorWhite={colorWhite}
          placeholder={placeholder}
          label={label}
          options={options}
          isDisabled={isDisabled}
          onChange={(value: any) => onChange(value)}
          onBlur={() => {
            if (formValues) {
              if (formValues.initial[name] !== formValues.values[name]) {
                onBlur();
              }
            }
          }}
        />
      </Box>
    </Grid>
  ),
  compareProps
);

export default Autocomplete;
