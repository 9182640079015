import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const specificationASelector = (
  state: any,
  {
    include = {
      specificationAId: null,
    },
    exclude = {
      specificationAId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      array: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.specificationAs,
    (specificationAs) => {
      // for single selected contractor
      if (checkSingleValue(include.specificationAId)) {
        const specificationAId = Array.isArray(include.specificationAId)
          ? include.specificationAId![0]
          : include.specificationAId;

        const specificationA = specificationAs.find(
          (s: any) => s.id === parseInt(specificationAId!, 10)
        );
        if (specificationA) {
          if (option.plain) {
            return option.array ? [specificationA] : specificationA;
          }
          return option.array
            ? [{ label: specificationA.name, value: `${specificationA.id}` }]
            : { label: specificationA.name, value: `${specificationA.id}` };
        }
        return null;
      }

      let specificationAOptions = specificationAs;
      if (!option.deactivated) {
        // Filter all deactivated specificationAs
        specificationAOptions = specificationAOptions.filter(
          (specificationA: any) => specificationA.deactivated !== true
        );
      }
      if (include.specificationAId) {
        specificationAOptions = specificationAOptions.filter((specificationA: any) =>
          // @ts-ignore
          include.specificationAId.includes(specificationA.id)
        );
      }
      if (exclude.specificationAId) {
        specificationAOptions = specificationAOptions.filter(
          // @ts-ignore
          (specificationA: any) => !exclude.specificationAId.includes(specificationA.id)
        );
      }
      if (specificationAOptions) {
        if (option.plain) {
          return specificationAOptions;
        }
        return specificationAOptions.map((specificationA: any) => ({
          label: specificationA.name,
          value: `${specificationA.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
