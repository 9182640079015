import React, { PureComponent } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import StopaLogo from '../../../../assets/img/STOPA-Logo.svg';
import './printVisit.scss';

const mapStateToProps = (state: any) => ({
  visitEntry: state.rootReducer.visitEntry,
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  visitEntry: any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class PrintVisit extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    visitEntry: null,
  };

  render() {
    const { visitEntry } = this.props;
    return (
      visitEntry && (
        <div className="print-content-wrapper upright-print">
          <div className="visit-header-wrapper">
            <div className="heading-wrapper">
              <p>PROTOKOLL</p>
            </div>
            <img src={StopaLogo} width="27%" className="meta-logo" alt="logo" />
          </div>
          <table className="visit-info">
            <tbody>
              <tr>
                <td>Ersteller:</td>
                <td>{`${visitEntry.user.firstName} - ${visitEntry.user.lastName}`}</td>
              </tr>
              <tr>
                <td>Projekt:</td>
                <td>{`${visitEntry.projectNumber} - ${visitEntry.customerName}`}</td>
              </tr>
              <tr>
                <td>Baustelle:</td>
                <td>{`${visitEntry.site}`}</td>
              </tr>
              <tr>
                <td>Datum der Besprechung:</td>
                <td>{moment(visitEntry.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}</td>
              </tr>
              <tr>
                <td>Thema der Besprechung:</td>
                <td>{visitEntry.visitTopic}</td>
              </tr>
              <tr>
                <td>Teilnehmer:</td>
                <td>{visitEntry.visitParticipants}</td>
              </tr>
              <tr>
                <td>Zusätzlicher Verteilerkreis:</td>
                <td>{visitEntry.visitDistributionCircle}</td>
              </tr>
            </tbody>
          </table>
          <table className="visit-entries">
            <thead>
              <tr>
                <th>Besprochenes Thema</th>
                <th>Kommentar</th>
                <th>Verantwortlich</th>
                <th>Zu erledigen bis</th>
              </tr>
            </thead>
            <tbody>
              {visitEntry.timeEntryVisit.length > 0 &&
                visitEntry.timeEntryVisit.map((visit: any) => (
                  <tr key={visit.id}>
                    <td>{`${visit.visitDiscussedTopic}`}</td>
                    <td>{`${visit.visitComment}`}</td>
                    <td>{`${visit.visitNameResponsible}`}</td>
                    <td>
                      {visit.visitDeadline
                        ? moment(visit.visitDeadline).format('YYYY-MM-DD')
                        : 'k. A.'}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(PrintVisit);
