import { createTheme } from '@mui/material/styles';
import { hostname, API_PROD_BASE } from '../constants/api';

let primaryMainColor = '#336600';

// If we are on the production server
// make it green
if (hostname === API_PROD_BASE) {
  primaryMainColor = '#0072B6';
}

const theme = createTheme({
  palette: {
    primary: {
      light: '#0072B6#0072B6#0072B6',
      main: primaryMainColor,
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      main: '#8bc34a',
    },
  },
});

export default theme;
