import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { IconButton, Snackbar as MUISnackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { setSnackbarAction } from '../../../actions';

const mapStateToProps = (state: any) => ({
  snackbar: state.rootReducer.snackbar,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSnackbar: (snackbar: any) => dispatch(setSnackbarAction(snackbar)),
});

type SnackbarProps = {
  snackbar?: any;
  setSnackbar: (...args: any[]) => any;
};

class Snackbar extends PureComponent<SnackbarProps, {}> {
  handleClose = () => {
    const { setSnackbar, snackbar } = this.props;
    setSnackbar({ open: false, message: snackbar.message });
  };
  render() {
    const { snackbar = null } = this.props;
    return (
      <Fragment>
        {snackbar && (
          <MUISnackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={snackbar.open}
            autoHideDuration={3000}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{snackbar.message}</span>}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit" onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
