import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '../../../constants/routes/root';
/**
 * @function
 * @description A method to get all content component routes.
 * @return {Array}
 */
const getRoutes = (): Array<any> => {
  let key = 0;
  return routes.map(route => {
    key += 1;
    return <Route path={route.path} key={key} exact={route.exact} component={route.component} />;
  });
};
/**
 * @constant
 * @description A constant to get all routes. Header should be on top and footer at bottom.
 */
const RoutesHandler = <Switch>{getRoutes()}</Switch>;

export default RoutesHandler;
