import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';
import { SERVICE_TYPE_WORK } from '../../../../shared/src/constants/general';

export const ruleSelector = (
  state: any,
  {
    include = {
      ruleId: null,
      serviceTypeId: null,
      roleId: null,
      groupId: null,
      serviceId: null,
      specificationAId: null,
      specificationBId: null,
    },
    exclude = {
      serviceTypeId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      array: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.rules,
    (rules) => {
      // for single selected rule
      if (checkSingleValue(include.ruleId)) {
        // @ts-ignore
        const ruleId = Array.isArray(include.ruleId) ? include.ruleId[0] : include.ruleId;
        // @ts-ignore
        const rule = rules.find((r: any) => r.id === parseInt(ruleId, 10));
        if (rule) {
          return option.array ? [rule] : rule;
        }
        return null;
      }

      const includeServiceTypeId = include.serviceTypeId
        ? parseInt(include.serviceTypeId!, 10)
        : null;
      const includeRoleId = include.roleId ? parseInt(include.roleId!, 10) : null;
      const includeGroupId = include.groupId ? parseInt(include.groupId!, 10) : null;
      const includeServiceId = include.serviceId ? parseInt(include.serviceId!, 10) : null;
      // some rules dont have these
      const includeSpecificationAId = include.specificationAId
        ? parseInt(include.specificationAId!, 10)
        : null;
      const includeSpecificationBId = include.specificationBId
        ? parseInt(include.specificationBId!, 10)
        : null;

      if (includeServiceTypeId !== SERVICE_TYPE_WORK) {
        if (includeRoleId && includeGroupId && includeServiceId) {
          const rule = rules.find(
            (r: any) =>
              r.serviceTypeId === includeServiceTypeId &&
              r.roles.includes(includeRoleId) &&
              r.groups.includes(includeGroupId) &&
              r.serviceId === includeServiceId &&
              r.specificationAId === includeSpecificationAId &&
              r.specificationBId === includeSpecificationBId
          );

          if (rule) {
            return option.array ? [rule] : rule;
          }
        }
      } else {
        if (
          includeRoleId &&
          includeGroupId &&
          includeServiceId &&
          includeSpecificationAId &&
          includeSpecificationBId
        ) {
          const rule = rules.find(
            (r: any) =>
              r.serviceTypeId === includeServiceTypeId &&
              r.roles.includes(includeRoleId) &&
              r.groups.includes(includeGroupId) &&
              r.serviceId === includeServiceId &&
              r.specificationAId === includeSpecificationAId &&
              r.specificationBId === includeSpecificationBId
          );

          if (rule) {
            return option.array ? [rule] : rule;
          }
        }
      }
      // need to implement if single is selected by the other ids!

      // for multiple rules
      let ruleOptions = rules;
      if (!option.deactivated) {
        ruleOptions = ruleOptions.filter((r: any) => r.deactivated !== true);
      }
      if (include.serviceTypeId) {
        ruleOptions = ruleOptions.filter((r: any) => r.serviceTypeId === includeServiceTypeId);
      }
      if (include.groupId) {
        ruleOptions = ruleOptions.filter((r: any) => r.groups.includes(includeGroupId));
      }
      if (include.roleId) {
        ruleOptions = ruleOptions.filter((r: any) => r.roles.includes(includeRoleId));
      }
      if (include.serviceId) {
        ruleOptions = ruleOptions.filter((r: any) => r.serviceId === includeServiceId);
      }
      if (include.specificationAId) {
        ruleOptions = ruleOptions.filter(
          (r: any) => r.specificationAId === includeSpecificationAId
        );
      }
      if (include.specificationBId) {
        ruleOptions = ruleOptions.filter(
          (r: any) => r.specificationBId === includeSpecificationBId
        );
      }
      if (exclude.serviceTypeId) {
        ruleOptions = ruleOptions.filter(
          // @ts-ignore
          (r: any) => !exclude.serviceTypeId.includes(r.serviceTypeId)
        );
      }
      if (ruleOptions) {
        return ruleOptions;
      }
      return null;
    }
  )(null as any);

export default {};
