import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change as changeAction, InjectedFormProps } from 'redux-form';
import Form from '../../Atoms/Form/Form';
import AutocompleteForm from '../Autocomplete/AutocompleteForm';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import Button from '../../Atoms/Button/Button';
// import validate from '../../../../../../helpers/validation';
import { addProjectAction } from '../../../actions';
import { customerSelector, siteSelector } from '../../../helpers/selectors';
import { ProjectExport } from '../../Pages/PageAdminArea/Project/ProjectExport/ProjectExport';

const mapStateToProps = (state: any) => ({
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  projectCreate: state.form.projectCreate,
});

const mapDispatchToProps = (dispatch: any) => ({
  addProject: (customerId: any, siteId: any, number: any) =>
    dispatch(
      addProjectAction({
        customerId,
        siteId,
        number,
      })
    ),
  change: (field: string, value: any, form?: any) => dispatch(changeAction(form, field, value)),
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  customers: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  projectCreate?: any;
}

interface ComponentDispatchProps {
  addProject: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class ProjectCreate extends React.Component<ComponentProps, {}> {
  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      customer: null,
    });
  }
  handleCreate = (values: any) => {
    const { addProject, reset } = this.props;
    const { customer, site, number } = values;
    if (customer && site && number) {
      if (customer.value && site.value && number) {
        const customerId = customer.value;
        const siteId = site.value;
        addProject(customerId, siteId, number);
        reset();
      }
    }
  };
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: string) => change(field, null));
  };
  render() {
    const { customers, sites, handleSubmit, projectCreate = null } = this.props;
    return (
      <AdminSettingsWrapper editWrapper>
        <Form handleSubmit={handleSubmit(this.handleCreate)} justify={'flex-end'}>
          <AutocompleteForm
            size={3}
            options={customers({})}
            label="Kunde"
            name="customer"
            onChange={() => this.handleFlush(['site'])}
          />
          {projectCreate && (
            <Fragment>
              <AutocompleteForm
                size={3}
                options={
                  projectCreate!.values.customer
                    ? sites({
                        include: {
                          customerId: parseInt(projectCreate.values.customer.value, 10),
                        },
                        option: { plain: false },
                      })
                    : []
                }
                label="Baustelle"
                name="site"
              />
              <TextFieldForm size={3} name="number" label="Projekt-Nr." />
            </Fragment>
          )}
          <Button handleClick={handleSubmit(this.handleCreate)} alignRight>
            Neu Anlegen
          </Button>
          <ProjectExport />
        </Form>
      </AdminSettingsWrapper>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'projectCreate',
  })(ProjectCreate)
);
