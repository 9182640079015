import React, { Fragment, memo } from 'react';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';
import NotInterested from '@mui/icons-material/NotInterested';
import Clear from '@mui/icons-material/Clear';
import Forward from '@mui/icons-material/Forward';
import Typography from '../../../../Atoms/Typography/Typography';
import { roleSelector } from '../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  // roles: filter => roleSelector(state, filter),
  rolesSelect: (filter: any) => roleSelector(state, filter),
});

type RequiredRoleCheckerProps = {
  id: number;
  roles: number[];
  roleCheck: boolean;
  rolesSelect?: (...args: any[]) => any;
  required: boolean;
  formCompleted?: boolean;
  change: (...args: any[]) => any;
};

const RequiredRoleChecker: React.SFC<RequiredRoleCheckerProps> = memo(
  ({ id, roles, required, roleCheck, rolesSelect, change, formCompleted = false }) => {
    const handleRequired = () => {
      change(`${id}required`, !required);
    };
    const rolesInfo = (
      <>
        {roleCheck &&
          (!required ? (
            <IconButton onClick={handleRequired} disabled={formCompleted}>
              <Clear style={{ color: 'rgba(0, 0, 0, 0.38)' }} />
            </IconButton>
          ) : (
            <IconButton onClick={handleRequired} disabled={formCompleted}>
              <Forward />
            </IconButton>
          ))}
        {!roleCheck && (
          <IconButton disabled>
            <NotInterested style={{ color: 'rgba(0, 0, 0, 0.38)' }} />
          </IconButton>
        )}
      </>
    );
    const questionRolesShortNames =
      roles && rolesSelect
        ? rolesSelect({
            include: { roleId: roles },
            option: { plain: true, array: true },
          }).map((r: any) => `${r.shortName} `)
        : ['k. A.'];
    return (
      <Fragment key={id}>
        <Typography
          size={1}
          formIcon
          variant="body2"
          value={rolesInfo}
          captionType="bot"
          caption={questionRolesShortNames.join(', ')}
          disabled={!required || !roleCheck}
        />
      </Fragment>
    );
  }
);

export default connect(mapStateToProps, null)(RequiredRoleChecker);
