import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { withRouter } from 'react-router-dom';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';
import {
  ADMIN,
  DEPARTMENT_MANAGER,
  PROJECT_MANAGER,
  PROJECT_STATE_ACTIVE,
  PROJECT_STATE_INACTIVE,
  PROJECT_STATE_PLANNED,
} from '../../../../../shared/src/constants/general';

import { getSortedData } from '../../../helpers/getSortedData';
import {
  projectSelector,
  customerSelector,
  projectTypeSelector,
  siteSelector,
} from '../../../helpers/selectors';
import { compareProps } from '../../../helpers/compareProps';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  projects: (filter: any) => projectSelector(state, filter),
  projectTypes: (filter: any) => projectTypeSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  // For memo prop compare and rerender
  compareProjects: state.rootReducer.projects,
});
const projectStates = [PROJECT_STATE_ACTIVE, PROJECT_STATE_PLANNED, PROJECT_STATE_INACTIVE];
type ProjectTableProps = {
  projects: (...args: any[]) => any;
  projectTypes: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  history: any;
  auth: any;
};
const ProjectTable: React.FC<ProjectTableProps> = memo((props) => {
  const getProjects = (stateId: any) => {
    const { auth, projects, customers, sites, projectTypes } = props;
    let projectOptions;
    if (auth.rightId === ADMIN) {
      projectOptions = projects({
        include: { projectStateId: stateId },
        option: { plain: true, deactivated: true },
      });
    }
    if (auth.rightId === PROJECT_MANAGER || auth.rightId === DEPARTMENT_MANAGER) {
      projectOptions = projects({
        include: { userId: auth.id, projectStateId: stateId },
        option: { plain: true, deactivated: true },
      });
    }
    const projectsComplete = projectOptions.map((project: any) => {
      const projectType = project.projectTypeId
        ? projectTypes({
            include: { projectTypeId: project.projectTypeId },
            option: { plain: true },
          }).name
        : null;
      const customerName = project.customerId
        ? customers({
            include: { customerId: project.customerId },
            option: { plain: true },
          }).name
        : null;
      const site = project.siteId
        ? sites({
            include: { siteId: project.siteId },
            option: { plain: true },
          })
        : null;
      const siteCity = site ? site.city : null;
      const siteCountry = site ? site.country : null;
      const newProject = {
        ...project,
        projectType,
        customerName,
        siteCity,
        siteCountry,
      };
      return newProject;
    });
    return getSortedData(projectsComplete, 'customerName', true);
  };
  return (
    <AdminSettingsWrapper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Operation</TableCell>
            <TableCell>Kunde</TableCell>
            <TableCell>Projekt-Nr</TableCell>
            <TableCell>AB</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Baustelle Land</TableCell>
            <TableCell>Baustelle Ort</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectStates.map((state) =>
            getProjects(state).map((project: any) => (
              <ActiveTableRow
                key={project.id}
                gray={project.projectStateId === PROJECT_STATE_INACTIVE}
                lightGray={project.projectStateId === PROJECT_STATE_PLANNED}
              >
                <TableCell>
                  {props.auth.rightId <= PROJECT_MANAGER && (
                    <IconButton onClick={() => props.history.push(`project/${project.id}`)}>
                      <Edit />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {project.customerName}
                </TableCell>
                <TableCell>{project.number}</TableCell>
                <TableCell>{project.ab}</TableCell>
                <TableCell>{project.projectType}</TableCell>
                <TableCell>{project.siteCountry}</TableCell>
                <TableCell>{project.siteCity}</TableCell>
              </ActiveTableRow>
            ))
          )}
        </TableBody>
      </Table>
    </AdminSettingsWrapper>
  );
}, compareProps);

export default connect(mapStateToProps, null)(withRouter<any, any>(ProjectTable));
