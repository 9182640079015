const getTimeData: (props: any) => any = ({ minSteps, timeStart = null }) => {
  const startTime = 0;
  const endTime = 23;
  const hour = 60;
  const steps = hour / minSteps;

  const times = [];
  for (let i = startTime; i <= endTime; i += 1) {
    if (i < 10) {
      times.push({ label: `0${i}:00`, value: `0${i}:00` }); // push the hour with leading zero
    } else {
      times.push({ label: `${i}:00`, value: `${i}:00` }); // push the hour without leading zero
    }

    for (let j = 1; j < steps; j += 1) {
      if (i < 10) {
        if ((hour / steps) * j < 10) {
          times.push({
            label: `0${i}:0${(hour / steps) * j}`,
            value: `0${i}:0${(hour / steps) * j}`,
          }); // push the minute steps
        } else {
          times.push({
            label: `0${i}:${(hour / steps) * j}`,
            value: `0${i}:${(hour / steps) * j}`,
          }); // push the minute steps
        }
      } else if ((hour / steps) * j < 10) {
        times.push({ label: `${i}:0${(hour / steps) * j}`, value: `${i}:0${(hour / steps) * j}` }); // push the minute steps
      } else {
        times.push({ label: `${i}:${(hour / steps) * j}`, value: `${i}:${(hour / steps) * j}` }); // push the minute steps
      }
    }
  }
  times.push({ label: '24:00', value: '23:59' });
  if (timeStart) {
    const index = times.findIndex(time => time.value === timeStart.value);
    return times.slice(index + 1);
  }
  return times;
};
export default getTimeData;
