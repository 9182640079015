import React, { Fragment } from 'react';
import Form from '../../Atoms/Form/Form';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import RadiosForm from '../Radios/RadiosForm';
import { rightSelector } from '../../../helpers/selectors';
import { connect } from 'react-redux';
import { ENTITY_CUSTOMER_CONTACT } from '../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  rights: (filter: any) => rightSelector(state, filter),
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  rights: (...args: any[]) => any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

const CustomerContactDetails: React.SFC<ComponentProps> = ({ rights }) => (
  <Fragment>
    <AdminSettingsWrapper>
      <Form>
        <TextFieldForm name="firstName" label="Vorname" />
        <TextFieldForm name="lastName" label="Nachname" />
        <TextFieldForm name="title" label="Titel" />
        <TextFieldForm name="telephone" label="Telefon" />
        <TextFieldForm name="position" label="Position" />
        <TextFieldForm name="email" label="E-Mail" />
        <RadiosForm
          label="Rechte"
          name="rightId"
          options={rights({ include: { entityId: ENTITY_CUSTOMER_CONTACT } })}
          size={12}
        />
      </Form>
    </AdminSettingsWrapper>
  </Fragment>
);

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(CustomerContactDetails);
