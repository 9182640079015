import moment from 'moment';
import { useLayoutEffect, useState } from 'react';
import openSocket from 'socket.io-client';
import {
  QUESTION_TYPE_EIGHT,
  QUESTION_TYPE_FIVE,
  QUESTION_TYPE_FOUR,
  QUESTION_TYPE_TWO,
  QUESTION_TYPE_SIX,
  QUESTION_TYPE_ONE,
  QUESTION_TYPE_SEVEN,
  QUESTION_TYPE_THREE,
  ADMIN,
  QUESTION_TYPE_NINE,
  QUESTION_TYPE_TEN,
  QUESTION_TYPE_ELEVEN,
  QUESTION_TYPE_TWELVE,
} from '../../../../../shared/src/constants/general';

import QuestionTypes from './QuestionTypes';
import {
  apiUrl,
  apiBase,
  API_LOCAL_BASE,
  API_STAGING_BASE,
  API_PROD_BASE,
  API_PROD_TEST_BASE,
} from '../../../constants/api';
import store from '../../../store';
import { setEdititedFormsAction } from '../../../actions';
/**
 *
 * @param {*} answers Takes the current form answers from the database
 */
export const createInitialFormValues = (answers: any) => {
  console.log('createdFormVal');
  return answers
    .map((el: any) => ({
      id: el.id,
      questionTypeId: el.question.questionTypeId,
      questionId: el.question.id,
    }))
    .reduce((acc: any, cv: any) => {
      // populate with the answers
      const answer = answers.find((a: any) => a.id === cv.id);
      if (answer.decision === null) {
        acc[cv.questionId] = null;
      } else if (answer.decision === 0 || answer.decision === false) {
        acc[cv.questionId] = 0;
      } else {
        acc[cv.questionId] = 1;
      }
      let timeStartValue = '';
      let timeEndValue = '';
      if (answer.timeStart || answer.timeEnd) {
        timeStartValue = moment(answer.timeStart, 'HH:mm:ss').format('HH:mm');
        timeEndValue = moment(answer.timeEnd, 'HH:mm:ss').format('HH:mm');
      }
      // FORM_TYPE_SITE_START
      if (cv.questionTypeId === QUESTION_TYPE_ONE) {
        acc[`${cv.questionId}textOne`] = answer.textOne;
      }
      if (cv.questionTypeId === QUESTION_TYPE_TWO) {
        acc[`${cv.questionId}timeStart`] = answer.timeStart
          ? {
              label: `${timeStartValue}`,
              value: `${timeStartValue}`,
            }
          : null;
        acc[`${cv.questionId}timeEnd`] = answer.timeEnd
          ? { label: `${timeEndValue}`, value: `${timeEndValue}` }
          : null;
        acc[`${cv.questionId}textOne`] = answer.textOne;
      }
      if (cv.questionTypeId === QUESTION_TYPE_THREE) {
        acc[`${cv.questionId}dateOne`] = answer.dateOne
          ? moment(answer.dateOne, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;
        acc[`${cv.questionId}dateTwo`] = answer.dateTwo
          ? moment(answer.dateTwo, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;
        acc[`${cv.questionId}dateThree`] = answer.dateThree
          ? moment(answer.dateThree, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;
        acc[`${cv.questionId}dateFour`] = answer.dateFour
          ? moment(answer.dateFour, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;
        acc[`${cv.questionId}textOne`] = answer.textOne;
        acc[`${cv.questionId}textTwo`] = answer.textTwo;
        acc[`${cv.questionId}textThree`] = answer.textThree;
        acc[`${cv.questionId}textFour`] = answer.textFour;
      }
      if (cv.questionTypeId === QUESTION_TYPE_FOUR) {
        acc[`${cv.questionId}dateOne`] = answer.dateOne
          ? moment(answer.dateOne, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;
        acc[`${cv.questionId}textOne`] = answer.textOne;
        acc[`${cv.questionId}textTwo`] = answer.textTwo;
      }
      if (cv.questionTypeId === QUESTION_TYPE_FIVE) {
        acc[`${cv.questionId}textOne`] = answer.textOne;
        acc[`${cv.questionId}textTwo`] = answer.textTwo;
        acc[`${cv.questionId}textThree`] = answer.textThree;
      }
      if (cv.questionTypeId === QUESTION_TYPE_SIX) {
        acc[`${cv.questionId}textOne`] = answer.textOne;
        acc[`${cv.questionId}textTwo`] = answer.textTwo;
        acc[`${cv.questionId}textThree`] = answer.textThree;
      }
      // FORM_TYPE_CHECK and FORM_TYPE_RETROFIT
      if (cv.questionTypeId === QUESTION_TYPE_SEVEN) {
        acc[`${cv.questionId}required`] = answer.required;
        acc[`${cv.questionId}givenFrom`] = answer.givenFrom;
        acc[`${cv.questionId}textOne`] = answer.textOne;
        acc[`${cv.questionId}contactName`] = answer.contactName;
        acc[`${cv.questionId}date`] = answer.date
          ? moment(answer.date, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;
        acc[`${cv.questionId}contactType`] = answer.contactType;
      }
      if (cv.questionTypeId === QUESTION_TYPE_EIGHT) {
        acc[`${cv.questionId}required`] = answer.required;
        acc[`${cv.questionId}givenFrom`] = answer.givenFrom;
        acc[`${cv.questionId}textOne`] = answer.textOne;
        acc[`${cv.questionId}textTwo`] = answer.textTwo;
        acc[`${cv.questionId}textThree`] = answer.textThree;
        acc[`${cv.questionId}contactName`] = answer.contactName;
        acc[`${cv.questionId}date`] = answer.date
          ? moment(answer.date, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;
        acc[`${cv.questionId}contactType`] = answer.contactType;
      }

      if (cv.questionTypeId === QUESTION_TYPE_TEN) {
        acc[`${cv.questionId}required`] = answer.required;
        acc[`${cv.questionId}givenFrom`] = answer.givenFrom;
        acc[`${cv.questionId}textOne`] = answer.textOne;
      }

      if (cv.questionTypeId === QUESTION_TYPE_ELEVEN) {
        acc[`${cv.questionId}required`] = answer.required;
        acc[`${cv.questionId}givenFrom`] = answer.givenFrom;
        acc[`${cv.questionId}textOne`] = answer.textOne;
        acc[`${cv.questionId}textTwo`] = answer.textTwo;
        acc[`${cv.questionId}textThree`] = answer.textThree;
      }

      if (cv.questionTypeId === QUESTION_TYPE_TWELVE) {
        acc[`${cv.questionId}required`] = answer.required;
        acc[`${cv.questionId}givenFrom`] = answer.givenFrom;
        acc[`${cv.questionId}textOne`] = answer.textOne;
        acc[`${cv.questionId}textTwo`] = answer.textTwo;
      }

      return acc;
    }, {});
};
/**
 *
 * @param {*} answers Takes the current form answers from the database
 * @param {*} formData Takes the formdata that needs to be mapped to the components
 */
export const renderForm: (props: any) => any = ({
  answers,
  formData,
  user = null,
  change = null,
  formCompleted,
  refs,
}) => {
  const components = answers.map((element: any, index: any) => {
    let roleCheck;
    if (user) {
      roleCheck = user.roles.reduce((acc: any, cv: any) => {
        if (element.question.roles.includes(cv) || acc === true) {
          return true;
        }
        return false;
      }, false);
      if (user.rightId === ADMIN) {
        roleCheck = false;
      }
    }
    let componentData;
    // we need this only for headline
    if (refs && element.question.questionTypeId === QUESTION_TYPE_NINE) {
      componentData = {
        question: element.question,
        index,
        setRef: refs.find((el: any) => el.id === element.question.id).setRef,
      };
      /**
       * Normalize the data for FORM_CHECK and FORM_TYPE_RETROFIT
       */
    } else if (
      element.question.questionTypeId === QUESTION_TYPE_SEVEN ||
      element.question.questionTypeId === QUESTION_TYPE_EIGHT
    ) {
      const { question } = element;
      const required = formData.values[`${question.id}required`];
      const value = parseInt(formData.values[`${question.id}`], 10);
      const answer = answers.find((a: any) => a.question.id === question.id);
      componentData = {
        question,
        index,
        roleCheck,
        required,
        value,
        user,
        answer,
        change,
        formCompleted,
      };
      /**
       * Normalize the data for FORM_TYPE_SITE_CHECK
       */
    } else if (
      element.question.questionTypeId === QUESTION_TYPE_TEN ||
      element.question.questionTypeId === QUESTION_TYPE_ELEVEN ||
      element.question.questionTypeId === QUESTION_TYPE_TWELVE
    ) {
      const { question } = element;
      const required = formData.values[`${question.id}required`];
      const value = parseInt(formData.values[`${question.id}`], 10);
      const answer = answers.find((a: any) => a.question.id === question.id);
      componentData = {
        question,
        required,
        index,
        value,
        user,
        answer,
        change,
        formCompleted,
      };
    }
    // else if (element.question.questionTypeId === QUESTION_TYPE_SEVEN) {
    //   const { question } = element;
    //   const required = formData.values[`${question.id}required`];
    //   const value = parseInt(formData.values[`${question.id}`], 10);
    //   const answer = answers.find((a: any) => a.question.id === question.id);
    //   componentData = {
    //     question,
    //     roleCheck,
    //     required,
    //     value,
    //     user,
    //     answer,
    //     change,
    //     formCompleted,
    //   };
    // }
    else {
      componentData = {
        ...element,
        index,
        roleCheck,
        formData,
        user,
        answers,
        change,
        formCompleted,
      };
    }

    const questionType = QuestionTypes.find(
      (q) => q.questionTypeId === element.question.questionTypeId
    );
    if (questionType) {
      return questionType.component(componentData);
    }
    // eslint-disable-next-line
    console.log(`could not find ${element.question.questionTypeId}`);
    return null;
  });
  return components;
};
/**
 * Opens the socket connection
 */
let socket: any;
console.log('HERE', apiUrl);
if (apiBase === API_LOCAL_BASE || apiBase === API_PROD_TEST_BASE) {
  socket = openSocket(apiUrl);
} else if (apiBase === API_STAGING_BASE || apiBase === API_PROD_BASE) {
  socket = openSocket(`https://${apiBase}`, {
    secure: true,
    rejectUnauthorized: false,
    path: '/socket.io',
  });
}
/**
 *
 * @param {integer} userId the id of the user that connects
 * @param {integer} formId the id of the form that is being edited
 */
export const editingForm = (userId: any, formId: any) => {
  socket.emit('formEdit', { userId, formId });
};
/**
 *
 * @param {integer} formId the id of the form that is left
 */
export const leavingForm = (formId: any) => {
  socket.emit('formLeave', { formId });
};
/**
 * the listener for emited socket message
 */
socket.on('editedForms', (data: any) => {
  store.dispatch(setEdititedFormsAction(data));
});
export default {};

export const useWindowSize = () => {
  let [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
};
