import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AutocompleteForm from '../../../../../Molecules/Autocomplete/AutocompleteForm';
import {
  ADMIN,
  PROJECT_MANAGER,
  DEPARTMENT_MANAGER,
  MECHANIC,
  CONTRACTOR_STOPA,
  GROUP_PROJECTMANAGER,
  ROLE_PROJECTMANAGER,
} from '../../../../../../../../shared/src/constants/general';
import {
  userSelector,
  groupSelector,
  roleSelector,
  projectSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
});

interface ComponentStateProps {
  auth: any;
  users: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  roles: (...args: any[]) => any;
  projects: (...args: any[]) => any;
}

interface ComponentOwnProps {
  handleFlush: (...args: any[]) => any;
  handleChange: (...args: any[]) => any;
  formData: any;
}

type ComponentProps = ComponentStateProps & ComponentOwnProps;

class TimeEntryUser extends PureComponent<ComponentProps, {}> {
  handleChange = (value: any) => {
    const { users, groups, roles, handleFlush, handleChange } = this.props;
    // flush fields if project changed
    const flushFields = [
      'user',
      'group',
      'role',
      'serviceType',
      'service',
      'specificationA',
      'specificationB',
      'billingType',
      'billing',
      'commentBilling',
    ];
    handleFlush(flushFields);
    // check if project manager, change role and group into projectmanager
    if (value) {
      const user = users({
        include: { userId: value.value },
        option: { plain: true },
      });
      if (user.rightId === PROJECT_MANAGER) {
        const group = {
          name: 'group',
          value: groups({ include: { groupId: GROUP_PROJECTMANAGER } }),
        };
        const role = {
          name: 'role',
          value: roles({ include: { roleId: ROLE_PROJECTMANAGER } }),
        };
        handleChange([group, role]);
      }
    }
  };
  getAutocompleteData = () => {
    const { auth, users, projects, formData } = this.props;
    if (formData.values.project) {
      const projectId = parseInt(formData.values.project.value, 10);
      const project = projects({
        include: { projectId },
        option: { plain: true },
      });
      if (project) {
        // check if the user is an admin
        // if so, return all users, except department managers
        if (auth.rightId === ADMIN) {
          return users({
            exclude: { rightId: DEPARTMENT_MANAGER },
            include: { userId: project.userIds },
          });
        }
        // check if the user is an department manager
        // if so, return all users with the right mechanic
        if (auth.rightId === DEPARTMENT_MANAGER) {
          return users({
            include: { rightId: MECHANIC, userId: project.userIds },
          });
        }
        // check if the user is an project manager
        // if so, return all users and include the user himself
        if (auth.rightId === PROJECT_MANAGER) {
          const allUsers = users({
            include: { rightId: MECHANIC, userId: project.userIds },
          });
          const usersData = [
            users({
              include: { userId: auth.id },
            }),
          ];
          return usersData.concat(allUsers);
        }
        // if none of the above and contractor is stopa
        // its a mechanic, so send the user and the externals back
        if (auth.contractorId === CONTRACTOR_STOPA) {
          const externalUsers = users({
            include: { rightId: MECHANIC, userId: project.userIds },
            exclude: { contractorId: CONTRACTOR_STOPA },
          });
          const usersData = [
            users({
              include: { userId: auth.id },
            }),
          ];
          return usersData
            .concat(externalUsers)
            .filter((value, i, self) => self.indexOf(value) === i);
        }
        // its an external contractor
        return [
          users({
            include: { userId: auth.id },
          }),
        ];
      }
    }
    return [];
  };
  render() {
    return (
      <AutocompleteForm
        size={4}
        options={this.getAutocompleteData()}
        label="Monteur"
        name="user"
        onChange={value => this.handleChange(value)}
      />
    );
  }
}
export default connect<ComponentStateProps, {}, ComponentOwnProps>(
  mapStateToProps,
  {}
)(TimeEntryUser);
