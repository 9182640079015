import React from 'react';
import { Grid, Box } from '@mui/material';

const styles = {
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    flex: ' 1 1 auto',
  },
  wrapper: {
    margin: '0',
  },
};

interface FormProps {
  handleSubmit?: (...args: any[]) => any;
  justify?: string;
}

const Form: React.FC<FormProps> = ({
  handleSubmit = () => {},
  children,
  justify = 'flex-start',
}) => (
  <Box component="form" onSubmit={handleSubmit} sx={styles.form}>
    <Grid
      container={true}
      item={true}
      md={12}
      spacing={3}
      alignItems="flex-start"
      justifyContent={justify}
      sx={styles.wrapper}
    >
      {children}
    </Grid>
  </Box>
);

export default Form;
