import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import UserSelectorTable from '../UserSelectorTable/UserSelectorTable';
import { CONTRACTOR_STOPA } from '../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  // redux-form
  pageProjectEdit: state.form.pageProjectEdit,
});

type ProjectUserProps = {
  pageProjectEdit?: any;
  handleSave?: any;
};

class ProjectUser extends React.Component<ProjectUserProps, {}> {
  static defaultProps = {
    pageProjectEdit: null,
  };

  render() {
    const { pageProjectEdit, handleSave } = this.props;
    return (
      <Fragment>
        <UserSelectorTable
          label="Stopa-Monteure"
          name="usersAdd"
          contractorId={CONTRACTOR_STOPA}
          formData={pageProjectEdit}
          formName="pageProjectEdit"
          handleSave={handleSave}
        />
        <UserSelectorTable
          label="Fremdmonteure"
          name="contractorContactsAdd"
          formData={pageProjectEdit}
          formName="pageProjectEdit"
          handleSave={handleSave}
        />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(ProjectUser);
