import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import Button from '../../../../../Atoms/Button/Button';
import DialogTimeEntryWrapper from '../../../../PageWeekOverview/components/DialogTimeEntry/components/TimeEntryWrapper';
import { setProjectVisitDialogAction } from '../../../../../../actions';
import Typography from '../../../../../Atoms/Typography/Typography';

const mapDispatchToProps = (dispatch: any) => ({
  setProjectVisitDialog: (open: any) => dispatch(setProjectVisitDialogAction(open)),
});
const mapStateToProps = (state: any) => ({
  projectVisitDialogOpen: state.rootReducer.projectVisitDialogOpen,
  visitEntry: state.rootReducer.visitEntry,
});

type VisitEntryProps = {
  history: any;
  projectVisitDialogOpen?: boolean;
  visitEntry?: any;
  initialize: (...args: any[]) => any;
  setProjectVisitDialog: (...args: any[]) => any;
};

class VisitEntry extends PureComponent<VisitEntryProps, {}> {
  static defaultProps = {
    visitEntry: null,
    projectVisitDialogOpen: false,
  };

  componentWillMount() {
    const { initialize } = this.props;
    // set the current week as predefined
    initialize({});
  }
  handleClose = () => {
    const { projectVisitDialogOpen, setProjectVisitDialog } = this.props;
    setProjectVisitDialog(!projectVisitDialogOpen);
  };
  render() {
    const { projectVisitDialogOpen, visitEntry } = this.props;
    return (
      <Fragment>
        {visitEntry && projectVisitDialogOpen && (
          <DialogTimeEntryWrapper
            open={projectVisitDialogOpen}
            handleClose={this.handleClose}
            overflow
          >
            <Fragment>
              <Grid md={12} item container spacing={5}>
                <Typography
                  size={3}
                  value={`${visitEntry.projectNumber} - ${visitEntry.customerName}`}
                  caption="Projekt"
                  variant="subtitle1"
                  captionType="top"
                />
                <Typography
                  size={4}
                  value={`${visitEntry.site}`}
                  caption="Baustelle"
                  variant="subtitle1"
                  captionType="top"
                />
                <Button handleClick={this.handleClose} alignRight size={3}>
                  Schließen
                </Button>
              </Grid>
              <Grid md={12} item container spacing={5}>
                <Typography
                  size={3}
                  value={`${visitEntry.user.firstName} - ${visitEntry.user.lastName}`}
                  caption="Mitarbeiter"
                  variant="subtitle1"
                  captionType="top"
                />
                <Button
                  handleClick={() => {
                    const { history } = this.props;
                    history.push('/print/visit');
                  }}
                  alignRight
                  size={3}
                >
                  Drucken
                </Button>
              </Grid>
              <Grid md={12} item container spacing={5}>
                <Typography
                  value={moment(visitEntry.date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                  caption="Datum"
                  captionType="top"
                  size={2}
                />
                <Typography value={visitEntry.timeStart} caption="Von" captionType="top" size={1} />
                <Typography value={visitEntry.timeEnd} caption="Bis" captionType="top" size={1} />
                <Typography
                  value={visitEntry.duration.toFixed(2)}
                  caption="Stunden"
                  captionType="top"
                  size={1}
                />
              </Grid>
              <Grid md={12} item container spacing={5}>
                <Typography
                  size={2}
                  value={visitEntry.service}
                  caption="Tätigkeit"
                  variant="subtitle1"
                  captionType="top"
                />
                <Typography
                  size={2}
                  value={visitEntry.specificationA}
                  caption="Spezifikation A"
                  variant="subtitle1"
                  captionType="top"
                />
                <Typography
                  size={2}
                  value={visitEntry.specificationB}
                  caption="Spezifikation B"
                  variant="subtitle1"
                  captionType="top"
                />
              </Grid>
              <Grid md={12} item container spacing={5}>
                <Fragment>
                  <Typography
                    size={4}
                    value={visitEntry.visitTopic}
                    caption="Thema der Besprechung"
                    variant="subtitle1"
                    captionType="top"
                  />
                  <Typography
                    size={4}
                    value={visitEntry.visitParticipants}
                    caption="Teilnehmer"
                    variant="subtitle1"
                    captionType="top"
                  />
                  <Typography
                    size={4}
                    value={visitEntry.visitDistributionCircle}
                    caption="Verteiler-Kreis / optional"
                    variant="subtitle1"
                    captionType="top"
                  />
                  {visitEntry.timeEntryVisit.length > 0 &&
                    visitEntry.timeEntryVisit.map((visit: any) => (
                      <Fragment key={visit.id}>
                        <Typography
                          size={3}
                          value={visit.visitDiscussedTopic}
                          caption="Besprochenes Thema"
                          variant="subtitle1"
                          captionType="top"
                        />
                        <Typography
                          size={3}
                          value={visit.visitComment}
                          caption="Kommentar"
                          variant="subtitle1"
                          captionType="top"
                        />
                        <Typography
                          size={3}
                          value={visit.visitNameResponsible}
                          caption="Name des Verantwortlichen"
                          variant="subtitle1"
                          captionType="top"
                        />
                        <Typography
                          size={3}
                          value={
                            visit.visitDeadline
                              ? moment(visit.visitDeadline).format('YYYY-MM-DD')
                              : 'k. A.'
                          }
                          caption="zu erledigen bis"
                          variant="subtitle1"
                          captionType="top"
                        />
                      </Fragment>
                    ))}
                </Fragment>
              </Grid>
            </Fragment>
          </DialogTimeEntryWrapper>
        )}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'projectVisitEntry',
  })(withRouter<any, any>(VisitEntry))
);
