import React, { memo } from 'react';
import { Grid, TextField as MUITextField } from '@mui/material';
import { Field } from 'redux-form';
import { compareProps } from '../../../helpers/compareProps';

const styles = {
  formQuestionGrid: {
    paddingTop: '0!important',
  },
};

type CustomTextFieldProps = {
  input: object;
  label: string;
  type?: string;
  rowsMax?: number;
  disabled?: boolean;
  defaultValue?: string;
  multiline?: boolean;
  meta: any;
};

const customTextField: React.SFC<CustomTextFieldProps> = memo(
  ({
    input,
    label, // handleChange,
    type = 'text',
    disabled = false,
    defaultValue = null,
    multiline = false,
    rowsMax = 2,
    meta: { touched, error },
    ...custom
  }) => (
    <MUITextField
      // id={id}
      label={label}
      // TODO: Do we need this here?
      // onChange={event => handleChange(id, event, type)}
      fullWidth
      variant="standard"
      type={type}
      disabled={disabled}
      error={error ? touched && error.length > 0 : false}
      helperText={(touched && error) || ''}
      multiline={multiline}
      maxRows={rowsMax}
      {...(defaultValue ? { defaultValue } : '')}
      {...(type === 'date'
        ? {
            InputLabelProps: {
              shrink: true,
            },
          }
        : {})}
      {...input}
      {...custom}
    />
  )
);

type TextFieldProps = {
  name: string;
  label: string;
  size?: any;
  rowsMax?: number;
  type?: string;
  disabled?: boolean;
  defaultValue?: string | null;
  multiline?: boolean;
  // alignRight?: boolean;
  formQuestion?: boolean;
  gridStyles?: object;
  onBlur?: (...args: any[]) => any;
  onChange?: (...args: any[]) => any;
  formValues?: any;
};

const TextField: React.SFC<TextFieldProps> = memo(
  ({
    name,
    label,
    size = 2,
    type = 'text',
    disabled = false,
    defaultValue = null,
    multiline = false,
    rowsMax = 3,
    onBlur = () => {},
    onChange = () => {},
    formValues = null,
    // alignRight = false,
    gridStyles = {},
    formQuestion = false,
  }) => (
    <Grid
      item
      md={size}
      style={gridStyles}
      sx={{
        ...(formQuestion && styles.formQuestionGrid),
      }}
    >
      <Field
        name={name}
        component={customTextField as any}
        label={label}
        disabled={disabled}
        type={type}
        defaultValue={defaultValue}
        multiline={multiline}
        rowsMax={rowsMax}
        onBlur={() => {
          if (formValues) {
            if (formValues.initial[name] !== formValues.values[name]) {
              onBlur();
            }
          }
        }}
        onChange={onChange}
      />
    </Grid>
  ),
  compareProps
);

export default TextField;
