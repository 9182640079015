import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const projectTypeSelector = (
  state: any,
  {
    include = {
      projectTypeId: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.projectTypes,
    (projectTypes) => {
      // for single selected projectType
      if (checkSingleValue(include.projectTypeId)) {
        const projectTypeId = Array.isArray(include.projectTypeId)
          ? include.projectTypeId![0]
          : include.projectTypeId;

        const projectType = projectTypes.find((pT: any) => pT.id === parseInt(projectTypeId!, 10));
        if (projectType) {
          if (option.plain) {
            return projectType;
          }
          return { label: `${projectType.name}`, value: `${projectType.id}` };
        }
        return null;
      }

      if (projectTypes) {
        if (option.plain) {
          return projectTypes;
        }
        return projectTypes.map((projectType: any) => ({
          label: projectType.name,
          value: `${projectType.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
