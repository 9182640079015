import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Sidebar from '../../Organisms/Sidebar/Sidebar';
import AdminOuterWrapper from '../AdminWrappers/AdminOuterWrapper';
import NavBar from '../../Organisms/NavBar/NavBar';
import { ADMIN } from '../../../../../shared/src/constants/general';
import {
  getRulesAction,
  getRuleVariablesAction,
  getGroupsAction,
  getRolesAction,
  getUsersAction,
  setAdminAreaSectionAction,
} from '../../../actions';
export default function PageRuleWrapper(WrappedComponent: React.ComponentType) {
  const mapStateToProps = (state: any) => ({
    auth: state.rootReducer.auth,
    rules: state.rootReducer.rules,
    users: state.rootReducer.users,
    groups: state.rootReducer.groups,
    roles: state.rootReducer.roles,
    serviceTypes: state.rootReducer.serviceTypes,
    services: state.rootReducer.services,
    specificationAs: state.rootReducer.specificationAs,
    specificationBs: state.rootReducer.specificationBs,
  });

  const mapDispatchToProps = (dispatch: any) => ({
    getRules: () => dispatch(getRulesAction()),
    getRuleVariables: () => dispatch(getRuleVariablesAction()),
    getGroups: () => dispatch(getGroupsAction()),
    getRoles: () => dispatch(getRolesAction()),
    getUsers: () => dispatch(getUsersAction()),
    setAdminAreaSection: (section: any) => dispatch(setAdminAreaSectionAction(section)),
  });

  interface ComponentOwnProps {}

  interface ComponentStateProps {
    auth: any;
    rules?: any;
    groups?: any;
    roles?: any;
    users?: any;
    serviceTypes?: any;
    services?: any;
    specificationAs?: any;
    specificationBs?: any;
  }

  interface ComponentDispatchProps {
    getRules: (...args: any[]) => any;
    getRuleVariables: (...args: any[]) => any;
    getGroups: (...args: any[]) => any;
    getUsers: (...args: any[]) => any;
    getRoles: (...args: any[]) => any;
    setAdminAreaSection: (...args: any[]) => any;
  }

  type ComponentProps = ComponentOwnProps &
    ComponentStateProps &
    ComponentDispatchProps &
    RouteComponentProps;

  class InnerWrapper extends PureComponent<ComponentProps, {}> {
    static defaultProps = {
      rules: null,
      users: null,
      serviceTypes: null,
      services: null,
      specificationAs: null,
      specificationBs: null,
      roles: null,
      groups: null,
    };

    componentDidMount() {
      const {
        auth,
        history,
        getRules,
        getRuleVariables,
        getGroups,
        getRoles,
        getUsers,
        setAdminAreaSection,
      } = this.props;
      if (auth.rightId > ADMIN) {
        history.push('/overview');
      }
      getRules();
      getRuleVariables();
      getGroups();
      getRoles();
      getUsers();
      setAdminAreaSection('rule');
    }
    render() {
      const {
        rules,
        serviceTypes,
        services,
        specificationAs,
        specificationBs,
        roles,
        groups,
        users,
      } = this.props;
      return (
        <Fragment>
          <NavBar />
          <Sidebar />
          {users &&
            rules &&
            serviceTypes &&
            services &&
            specificationAs &&
            specificationBs &&
            roles &&
            groups && (
              <Fragment>
                <AdminOuterWrapper>
                  <Fragment>
                    <WrappedComponent />
                  </Fragment>
                </AdminOuterWrapper>
              </Fragment>
            )}
        </Fragment>
      );
    }
  }

  // return InnerWrapper;
  return connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(InnerWrapper));
}
