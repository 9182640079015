import moment from 'moment';
import 'moment/locale/de';

const formatTime = (timeStart: any, timeEnd: any) => {
  const timeStartHour = parseFloat(moment(timeStart, 'HH:mm:ss').format('HH'));
  const timeStartMinute = parseFloat(moment(timeStart, 'HH:mm:ss').format('mm')) / 60;
  const timeEndHour = parseFloat(moment(timeEnd, 'HH:mm:ss').format('HH'));
  const timeEndMinute = parseFloat(moment(timeEnd, 'HH:mm:ss').format('mm')) / 60;
  const formatedTime = timeEndHour + timeEndMinute - (timeStartHour + timeStartMinute);
  return parseFloat(Number(formatedTime).toFixed(2));
};

export default formatTime;
