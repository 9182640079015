import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps } from 'redux-form';
import ContractorDetails from '../../../../Molecules/AdminAreaDetails/ContractorDetails';
import { contractorSelector } from '../../../../../helpers/selectors';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';
import AdminOuterWrapper from '../../../../Templates/AdminWrappers/AdminOuterWrapper';
import SidebarScrollSpy from '../../../../Organisms/Sidebar/SidebarScrollSpy';
import { CONTRACTOR_SCROLLSPY_ITEMS } from '../../../../../constants/all';
import { saveContractorAction, deleteContractorAction } from '../../../../../actions';
import ContractorUserCreate from '../../../../Molecules/AdminAreaCreates/ContractorUserCreate';
import ContractorUserTable from '../../../../Molecules/Tables/ContractorUserTable';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  contractors: (filter: any) => contractorSelector(state, filter),
  pageContractorEdit: state.form.pageContractorEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveContractor: (contractor: any) => dispatch(saveContractorAction(contractor)),
  deleteContractor: (contractorId: any) => dispatch(deleteContractorAction(contractorId)),
});

interface RouteParams {
  id: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  auth: any;
  contractors: (...args: any[]) => any;
  pageContractorEdit: any;
}

interface ComponentDispatchProps {
  deleteContractor: (...args: any[]) => any;
  saveContractor: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams> &
  InjectedFormProps;

type ComponentOwnState = {
  scrollSpyLabel: string[];
};

class PageContractorEdit extends React.Component<ComponentProps, ComponentOwnState> {
  state = {
    scrollSpyLabel: [''],
  };

  componentWillMount() {
    const { match, contractors, initialize } = this.props;

    const contractorId = parseInt(match.params.id, 10);
    const contractor = contractors({
      include: { contractorId },
      option: { plain: true },
    });

    const scrollSpyLabel = contractors({
      include: { contractorId },
    });
    this.setState({ scrollSpyLabel: [scrollSpyLabel.label] });

    initialize({
      ...contractor,
    });
  }

  handleSave = () => {
    const { saveContractor, pageContractorEdit } = this.props;
    saveContractor(pageContractorEdit.values);
  };

  handleDelete = async () => {
    const { history, deleteContractor, pageContractorEdit } = this.props;

    deleteContractor(pageContractorEdit.values.id);
    history.push('/admin/contractor');
  };

  scrollToRefs = () => CONTRACTOR_SCROLLSPY_ITEMS.map(() => React.createRef());

  render() {
    const { scrollSpyLabel } = this.state;
    const { contractors, match } = this.props;
    const contractorId = parseInt(match.params.id, 10);
    const scrollToRefs = this.scrollToRefs();

    return (
      <AdminOuterWrapper withScrollSpy>
        <AdminSettingsWrapper
          alignItems="flex-start"
          heading="Fremdfirmadetails"
          id="projectDetails"
          editWrapper
          setRef={scrollToRefs[0]}
        >
          <ContractorDetails />
        </AdminSettingsWrapper>
        <AdminSettingsWrapper
          alignItems="flex-start"
          id="contractorUser"
          heading="Monteure"
          editWrapper
          setRef={scrollToRefs[1]}
        >
          <ContractorUserCreate />
          <ContractorUserTable />
        </AdminSettingsWrapper>
        <SidebarScrollSpy
          scrollSpyItems={CONTRACTOR_SCROLLSPY_ITEMS}
          scrollToRefs={scrollToRefs}
          handleSave={this.handleSave}
          handleDelete={this.handleDelete}
          withDelete
          deactivatedEntry={
            contractors({
              include: { contractorId },
              option: { plain: true },
            }).deactivated
          }
          scrollSpyLabel={scrollSpyLabel}
          backPath={`contractor/${contractorId}`}
        />
      </AdminOuterWrapper>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageContractorEdit',
  })(withRouter<any, any>(PageContractorEdit))
);
