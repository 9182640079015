import PageProjectWrapper from '../../components/Templates/PageWrappers/PageProjectWrapper';
import PageProjectOverview from '../../components/Pages/PageAdminArea/Project/PageProjectOverview/PageProjectOverview';
import PageProjectEdit from '../../components/Pages/PageAdminArea/Project/PageProjectEdit/PageProjectEdit';

/**
 * @constant
 * @description A constant to define routes for the admin module.
 */
export const projectRoute = [
  // PROJECT
  {
    path: '/admin/project',
    // @ts-ignore
    component: PageProjectWrapper(PageProjectOverview),
    exact: true,
  },
  {
    path: '/admin/project/:id',
    component: PageProjectWrapper(PageProjectEdit),
    exact: true,
  },
];

export default {};
