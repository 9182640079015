import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { siteSelector, customerSelector } from '../../../../../helpers/selectors';
import SiteDetails from '../../../../Molecules/AdminAreaDetails/SiteDetails';
import AdminOuterWrapper from '../../../../Templates/AdminWrappers/AdminOuterWrapper';
import SidebarScrollSpy from '../../../../Organisms/Sidebar/SidebarScrollSpy';
import { CUSTOMER_SCROLLSPY_ITEMS } from '../../../../../constants/all';
import { saveSiteAction, deleteSiteAction } from '../../../../../actions';

const mapStateToProps = (state: any) => ({
  sites: (filter: any) => siteSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  pageCustomerSiteEdit: state.form.pageCustomerSiteEdit,
});
const mapDispatchToProps = (dispatch: any) => ({
  saveSite: (site: any) => dispatch(saveSiteAction(site)),
  deleteSite: (siteId: any) => dispatch(deleteSiteAction(siteId)),
});

interface RouteParams {
  id: string;
  siteId: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  sites: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  pageCustomerSiteEdit: any;
}

interface ComponentDispatchProps {
  saveSite: (...args: any[]) => any;
  deleteSite: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams> &
  InjectedFormProps;

type ComponentOwnState = {
  scrollSpyLabel: string[];
};

class PageCustomerSiteEdit extends PureComponent<ComponentProps, ComponentOwnState> {
  state = {
    scrollSpyLabel: [''],
  };

  componentWillMount() {
    const { sites, initialize, match } = this.props;
    const siteId = parseInt(match.params.siteId, 10);
    const site = sites({ include: { siteId }, option: { plain: true } });
    initialize({
      ...site,
    });
  }
  handleSave = () => {
    const { saveSite, pageCustomerSiteEdit } = this.props;

    const formValues = { ...pageCustomerSiteEdit.values };
    if (formValues) {
      saveSite(pageCustomerSiteEdit.values);
    }
  };
  handleDelete = async () => {
    const { history, match, deleteSite, pageCustomerSiteEdit } = this.props;

    const formValues = { ...pageCustomerSiteEdit.values };
    if (formValues) {
      history.push(`/admin/customer/${match.params.id}`);
      deleteSite(formValues);
    }
  };

  scrollToRefs = () => CUSTOMER_SCROLLSPY_ITEMS.map(() => React.createRef());

  render() {
    const { scrollSpyLabel } = this.state;
    const { sites, match } = this.props;
    const customerId = parseInt(match.params.id, 10);
    const scrollToRefs = this.scrollToRefs();

    return (
      <AdminOuterWrapper withScrollSpy>
        <SiteDetails />
        <SidebarScrollSpy
          scrollSpyItems={CUSTOMER_SCROLLSPY_ITEMS}
          scrollToRefs={scrollToRefs}
          handleSave={this.handleSave}
          handleDelete={this.handleDelete}
          deactivatedEntry={
            sites({
              include: { siteId: parseInt(match.params.siteId, 10) },
              option: { plain: true },
            }).deactivated
          }
          withDelete
          scrollSpyLabel={scrollSpyLabel}
          backPath={`customer/${customerId}`}
        />
      </AdminOuterWrapper>
    );
  }
}
export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageCustomerSiteEdit',
  })(withRouter<any, any>(PageCustomerSiteEdit))
);
