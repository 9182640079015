import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PrintCheckHeader from './components/PrintCheckHeader';
import PrintCheckBody from './components/PrintCheckBody';
import { getAnswerChecksAction } from '../../../../../actions';

const mapStateToProps = (state: any) => ({
  answerChecks: state.rootReducer.answerChecks,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAnswerChecks: (formId: any) => dispatch(getAnswerChecksAction(formId)),
});

interface ComponentOwnProps {
  form: any;
  project: any;
}

interface ComponentStateProps {
  answerChecks: any[] | null;
}

interface ComponentDispatchProps {
  getAnswerChecks: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class PrintCheck extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    answerChecks: null,
  };

  componentDidMount() {
    const { getAnswerChecks, form } = this.props;
    getAnswerChecks(form.id);
  }
  render() {
    const { answerChecks, project, form } = this.props;
    return (
      <div className="print-content-wrapper portrait-print">
        {answerChecks && (
          <>
            <PrintCheckHeader form={form} project={project} />
            <PrintCheckBody form={form} />
          </>
        )}
      </div>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PrintCheck);
