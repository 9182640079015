import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import DeleteRestoreButton from './components/DeleteRestoreButton';
import Button from '../../Atoms/Button/Button';
import { saveServiceAction, deleteServiceAction } from '../../../actions';

const mapStateToProps = (state: any) => ({
  pageServiceEdit: state.form.pageServiceEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveService: (service: any) => dispatch(saveServiceAction(service)),
  deleteService: (serviceId: any) => dispatch(deleteServiceAction(serviceId)),
});

type ServiceToolbarProps = {
  history: any;
  saveService: (...args: any[]) => any;
  deleteService: (...args: any[]) => any;
  withDelete?: boolean;
  pageServiceEdit: any;
};

class ServiceToolbar extends React.Component<ServiceToolbarProps, {}> {
  static defaultProps = {
    withDelete: false,
  };

  handleSave = () => {
    const { saveService, pageServiceEdit } = this.props;
    saveService(pageServiceEdit.values);
  };
  handleBack = () => {
    const { history } = this.props;
    history.push('/admin/rule/service');
  };
  handleDelete = async () => {
    const { deleteService, pageServiceEdit, history } = this.props;
    history.push('/admin/rule/service');
    deleteService(pageServiceEdit.values.id);
  };
  render() {
    const { pageServiceEdit, withDelete } = this.props;
    return (
      <AdminSettingsWrapper justify="flex-end">
        {withDelete && (
          <DeleteRestoreButton
            handleDelete={this.handleDelete}
            isDeactivated={pageServiceEdit.values.deactivated}
          />
        )}

        <Button buttonSize="small" handleClick={() => this.handleSave()} icon={<SaveIcon />}>
          Speichern
        </Button>

        <Button buttonSize="small" handleClick={() => this.handleBack()} icon={<UndoIcon />}>
          Zurück
        </Button>
      </AdminSettingsWrapper>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter<any, any>(ServiceToolbar));
