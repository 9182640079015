export const checkSingleValue = (value: any) => {
  // check if it is set at all
  if (
    value &&
    // either a one length array, or a string or a integer means we are single
    ((Array.isArray(value) && value.length === 1) || !Array.isArray(value))
  ) {
    return true;
  }
  return false;
};
export default {};
