import React from 'react';
import { connect } from 'react-redux';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { withRouter } from 'react-router-dom';
import {
  userSelector,
  ruleSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
} from '../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  users: (filter: any) => userSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  projectTimeEntries: state.rootReducer.projectTimeEntries,
  ProjectHours: state.form.ProjectHours,
});

type ProjectHourTableProps = {
  users: (...args: any[]) => any;
  rules: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
  services: (...args: any[]) => any;
  ProjectHours?: any;
  projectTimeEntries?: any[];
};

class ProjectHourTable extends React.Component<ProjectHourTableProps, {}> {
  getProjectTimeEntries = () => {
    const {
      projectTimeEntries = null,
      rules,
      specificationAs,
      specificationBs,
      services,
      ProjectHours = null,
    } = this.props;
    if (projectTimeEntries) {
      const newProjectTimeEntries = projectTimeEntries.map((entry) => {
        const rule = rules({ include: { ruleId: entry.ruleId } });
        const service = rule.serviceId
          ? services({
              include: { serviceId: rule.serviceId },
              option: { plain: true },
            }).name
          : '';
        const specificationA = rule.specificationAId
          ? specificationAs({
              include: { specificationAId: rule.specificationAId },
              option: { plain: true },
            }).name
          : '';
        const specificationB = rule.specificationBId
          ? specificationBs({
              include: { specificationBId: rule.specificationBId },
              option: { plain: true },
            }).name
          : '';
        const newEntry = {
          ...entry,
          service,
          specificationA,
          specificationB,
        };
        return newEntry;
      });
      if (ProjectHours) {
        if (ProjectHours.values.projectUser) {
          return newProjectTimeEntries.filter(
            (e) => e.userId === parseInt(ProjectHours.values.projectUser.value, 10)
          );
        }
        if (ProjectHours.values.projectRule) {
          return newProjectTimeEntries.filter(
            (e) => e.ruleId === parseInt(ProjectHours.values.projectRule.value, 10)
          );
        }
        if (ProjectHours.values.project) {
          return newProjectTimeEntries;
        }
      }
    }
    return [];
  };
  render() {
    const { users } = this.props;
    return (
      <Grid container justifyContent="center" spacing={3}>
        <Table style={{ tableLayout: 'fixed' }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Monteur</TableCell>
              <TableCell>Leistung (Regel = Tätigkeit + Spez-A + Spez-B)</TableCell>
              <TableCell>Std.-Summe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.getProjectTimeEntries().map((entry) => (
              <TableRow key={entry.id}>
                <TableCell component="th" scope="row">
                  {
                    users({
                      include: { userId: entry.userId },
                      option: { plain: true },
                    }).lastName
                  }
                </TableCell>
                <TableCell>
                  {`${entry.service} ${entry.specificationA} ${entry.specificationB}`}
                </TableCell>
                <TableCell>{entry.duration.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

export default connect(mapStateToProps, null)(withRouter<any, any>(ProjectHourTable));
