import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import Sidebar from '../../Organisms/Sidebar/Sidebar';
import { DEPARTMENT_MANAGER } from '../../../../../shared/src/constants/general';
import NavBar from '../../Organisms/NavBar/NavBar';
import {
  getProjectsAction,
  getProjectVariablesAction,
  getCustomersAction,
  getCustomerContactsAction,
  getUsersAction,
  getContractorsAction,
  getSitesAction,
  getGroupsAction,
  setAdminAreaSectionAction,
  getFormsAction,
} from '../../../actions';
import { projectSelector, customerSelector } from '../../../helpers/selectors';

export default function PageProjectWrapper(WrappedComponent: React.ComponentType) {
  const mapStateToProps = (state: any) => ({
    auth: state.rootReducer.auth,
    users: state.rootReducer.users,
    projects: (filter: any) => projectSelector(state, filter),
    customers: (filter: any) => customerSelector(state, filter),
    projectTypes: state.rootReducer.projectTypes,
    projectStates: state.rootReducer.projectStates,
    projectKinds: state.rootReducer.projectKinds,
    projectCategories: state.rootReducer.projectCategories,
    customerContacts: state.rootReducer.customerContacts,
    sites: state.rootReducer.sites,
    groups: state.rootReducer.groups,
    contractors: state.rootReducer.contractors,
    pageProjectEdit: state.form.pageProjectEdit,
    forms: state.rootReducer.forms,
  });
  const mapDispatchToProps = (dispatch: any) => ({
    getProjects: () => dispatch(getProjectsAction()),
    getProjectVariables: () => dispatch(getProjectVariablesAction()),
    getCustomers: () => dispatch(getCustomersAction()),
    getCustomerContacts: () => dispatch(getCustomerContactsAction()),
    getUsers: () => dispatch(getUsersAction()),
    getContractors: () => dispatch(getContractorsAction()),
    getSites: () => dispatch(getSitesAction()),
    setAdminAreaSection: (section: any) => dispatch(setAdminAreaSectionAction(section)),
    getGroups: () => dispatch(getGroupsAction()),
    getForms: () => dispatch(getFormsAction()),
  });

  interface ComponentOwnProps {}

  interface ComponentStateProps {
    auth: any;
    pageProjectEdit: any;
    users: any;
    projectTypes: any;
    projectStates?: any;
    projectKinds?: any;
    projectCategories?: any;
    customerContacts?: any;
    sites?: any;
    groups?: any;
    contractors?: any;
    forms?: any;
    customers?: (...args: any[]) => any | any;
    projects?: (...args: any[]) => any | any;
  }

  interface ComponentDispatchProps {
    getProjects: (...args: any[]) => any;
    getProjectVariables: (...args: any[]) => any;
    getCustomers: (...args: any[]) => any;
    getCustomerContacts: (...args: any[]) => any;
    getUsers: (...args: any[]) => any;
    getContractors: (...args: any[]) => any;
    getSites: (...args: any[]) => any;
    getGroups: (...args: any[]) => any;
    getForms: (...args: any[]) => any;
    setAdminAreaSection: (...args: any[]) => any;
  }

  interface RouteParams {
    id: string;
  }

  type ComponentProps = ComponentOwnProps &
    ComponentStateProps &
    ComponentDispatchProps &
    RouteComponentProps<RouteParams>;

  class InnerWrapper extends PureComponent<ComponentProps, {}> {
    static defaultProps = {
      pageProjectEdit: null,
      users: null,
      projects: () => null,
      customers: () => null,
      projectTypes: null,
      groups: null,
      projectStates: null,
      projectKinds: null,
      projectCategories: null,
      customerContacts: null,
      sites: null,
      contractors: null,
      forms: null,
    };

    componentDidMount() {
      const {
        auth,
        history,
        match,
        getUsers,
        getProjects,
        getProjectVariables,
        getCustomers,
        getCustomerContacts,
        getGroups,
        getContractors,
        getSites,
        getForms,
        setAdminAreaSection,
      } = this.props;

      if (auth.rightId > DEPARTMENT_MANAGER) {
        history.push('/admin/overview');
      } else if (auth.rightId === DEPARTMENT_MANAGER && match.path !== '/admin/project') {
        history.push('/admin/project');
      }
      getGroups();
      getProjects();
      getProjectVariables();
      getCustomers();
      getCustomerContacts();
      getUsers();
      getContractors();
      getSites();
      getForms();
      setAdminAreaSection('project');
    }

    render() {
      const {
        users,
        projects,
        projectTypes,
        projectStates,
        projectKinds,
        projectCategories,
        customers,
        customerContacts,
        sites,
        contractors,
        forms,
      } = this.props;
      return (
        <Fragment>
          <NavBar />
          <Sidebar />
          {users &&
            projects &&
            customers &&
            projectTypes &&
            projectStates &&
            projectKinds &&
            projectCategories &&
            customerContacts &&
            sites &&
            contractors &&
            forms && <WrappedComponent />}
        </Fragment>
      );
    }
  }

  // return InnerWrapper;
  return connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(InnerWrapper);
}
