import React, { Fragment, memo } from 'react';
import { Grid } from '@mui/material';
import RadiosForm from '../Radios/RadiosForm';
import RequiredRoleChecker from './components/check/RequiredRoleChecker';
import QuestionTypo from './components/check/QuestionTypo';
import QuestionAdditions from './components/check/QuestionAdditions';
import Typography from '../../Atoms/Typography/Typography';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import { compareProps } from '../../../helpers/compareProps';

const styles = {
  gridContainer: {
    margin: '15px 0px',
  },
  highlighted: {
    backgroundColor: '#f3f3f3',
  },
  smallPaddingTop: {
    paddingTop: '8px',
  },
};

type TypeSevenProps = {
  question: any;
  value?: number;
  answer: any;
  formCompleted?: boolean;
  roleCheck: boolean;
  change: (...args: any[]) => any;
  required: boolean;
};
const TypeSeven: React.FC<TypeSevenProps> = memo(
  ({ question, roleCheck, required, value = 0, answer, change, formCompleted = false }) => {
    const { id } = question;
    return (
      <Fragment>
        <Grid
          container
          item
          md={12}
          spacing={3}
          alignItems="flex-start"
          justifyContent="flex-start"
          sx={{
            ...styles.gridContainer,
            ...(question.highlight && styles.highlighted),
          }}
        >
          <RequiredRoleChecker
            id={id}
            roles={question.roles}
            required={required}
            roleCheck={roleCheck}
            change={change}
            formCompleted={formCompleted}
          />
          <QuestionTypo
            info={question.info}
            order={question.order}
            text={question.text}
            required={required}
            formCompleted={formCompleted}
            roleCheck={roleCheck}
          />
          <RadiosForm
            name={`${id}`}
            disabled={!required || !roleCheck || formCompleted}
            options={[
              { label: 'Ja', value: 1 },
              { label: 'Nein', value: 0 },
            ]}
            handleChange={(v) => {
              change(`${id}`, v);
              change(`${id}givenFrom`, null);
            }}
            size={2}
          />
          <QuestionAdditions
            id={id}
            answer={answer}
            required={required}
            value={value}
            roleCheck={roleCheck}
            formCompleted={formCompleted}
          />
          {((question.displayYes && value === 1) || (question.displayNo && value === 0)) && (
            <Grid
              container
              item
              md={12}
              alignItems="flex-start"
              justifyContent="flex-end"
              spacing={3}
            >
              <Grid item container md={6} spacing={3}>
                <Typography
                  disabled={!required || !roleCheck || formCompleted}
                  size={4}
                  variant="body1"
                  value="weitergeleitet per"
                  sx={styles.smallPaddingTop}
                />
                <Grid item md={7}>
                  <RadiosForm
                    name={`${id}contactType`}
                    disabled={!required || !roleCheck || formCompleted}
                    options={[
                      { label: 'Mail', value: 1 },
                      { label: 'Post', value: 2 },
                      { label: 'Telefon', value: 3 },
                    ]}
                    size={12}
                  />
                  <TextFieldForm
                    disabled={!required || !roleCheck || formCompleted}
                    name={`${id}date`}
                    label="am"
                    type="date"
                    size={12}
                  />
                  <TextFieldForm
                    disabled={!required || !roleCheck || formCompleted}
                    size={12}
                    name={`${id}contactName`}
                    label="an"
                    formQuestion
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  },
  compareProps
);

export default TypeSeven;
