import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import AutocompleteForm from '../../../../../Molecules/Autocomplete/AutocompleteForm';
import { ADMIN } from '../../../../../../../../shared/src/constants/general';
import {
  projectSelector,
  siteSelector,
  customerSelector,
  serviceProjectSelector,
} from '../../../../../../helpers/selectors';
import Typography from '../../../../../Atoms/Typography/Typography';
import {
  SERVICE_PROJECT_STATE_OPEN,
  SERVICE_PROJECT_STATE_FINISHED,
} from '../../../../../../../../shared/src/constants/general';
const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  serviceProjects: (filter: any) => serviceProjectSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
});

interface ComponentStateProps {
  auth: any;
  serviceProjects: (...args: any[]) => any;
  sites: (...args: any[]) => any;
}

// interface ComponentDispatchProps {
//   doSomeAction: typeof someAction;
// }

interface ComponentOwnProps {
  handleFlush: (...args: any[]) => any;
  formData: any;
  isDisabled?: boolean;
}

type ServiceEntryProjectState = {
  site: null;
};

type ComponentProps = ComponentStateProps & ComponentOwnProps;

class ServiceEntryProject extends PureComponent<ComponentProps, ServiceEntryProjectState> {
  state = {
    site: null,
  };
  changeSite = (serviceProjectId: any) => {
    const { serviceProjects, sites } = this.props;
    if (serviceProjectId) {
      const serviceProject = serviceProjects({
        include: {
          serviceProjectId,
        },
        option: { plain: true },
      });
      this.setState({
        site: sites({ include: { siteId: serviceProject.siteId } }).label,
      });
    } else {
      this.setState({ site: null });
    }
  };

  handleServiceChangeProject = (value: any) => {
    const { handleFlush } = this.props;
    this.changeSite(value.value);
    // // flush fields if project changed
    const flushFields = [
      'user',
      'role',
      'serviceType',
      'service',
      'specificationA',
      'specificationB',
      'billingType',
      'billing',
      'commentBilling',
    ];
    handleFlush(flushFields);
  };
  render() {
    const { serviceProjects, isDisabled } = this.props;
    const { site } = this.state;
    return (
      <Fragment>
        <AutocompleteForm
          options={serviceProjects({
            include: { state: [SERVICE_PROJECT_STATE_OPEN, SERVICE_PROJECT_STATE_FINISHED] },
          })}
          label="Service-Einsatz"
          name="serviceProject"
          size={4}
          onChange={this.handleServiceChangeProject}
          isDisabled={isDisabled}
        />
        {site && (
          <Typography
            size={4}
            value={`${site}`}
            variant="subtitle1"
            caption="Baustelle"
            captionType="top"
          />
        )}
      </Fragment>
    );
  }
}
export default connect<ComponentStateProps, {}, ComponentOwnProps>(
  mapStateToProps,
  {}
)(ServiceEntryProject);
