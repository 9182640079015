import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { change as changeAction } from 'redux-form';
import { Grid } from '@mui/material';
import ProjectCategoryTable from './ProjectCategoryTable';
import Button from '../../Atoms/Button/Button';
import AutocompleteForm from '../../Molecules/Autocomplete/AutocompleteForm';
import { projectCategorySelector } from '../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  projectCategories: (filter: any) => projectCategorySelector(state, filter),
});

const mapDispatchToProps = (dispatch: any) => ({
  change: (form: any, field: any, value: any) => dispatch(changeAction(form, field, value)),
});

type ProjectCategorySelectorTableProps = {
  projectCategories: (...args: any[]) => any;
  label: string;
  disabled?: boolean;
  name: string;
  change: (...args: any[]) => any;
  formData: any;
  formName: string;
};

class ProjectCategorySelectorTable extends React.Component<ProjectCategorySelectorTableProps, {}> {
  static defaultProps = {
    disabled: false,
  };

  getProjectCategories = () => {
    const { projectCategories, formData } = this.props;
    return projectCategories({
      exclude: { projectCategoryId: formData.values.projectCategoryIds },
    });
  };
  handleAdd = () => {
    const { change, name, formData, formName } = this.props;
    if (formData.values[name]) {
      const projectCategoryId = parseInt(formData.values[name].value, 10); // [departmentManager] then get userId
      const projectCategories = [...formData.values.projectCategoryIds, projectCategoryId];
      // add the user to the form
      change(formName, 'projectCategoryIds', projectCategories);
      // clear the add autocomplete field
      change(formName, name, null);
    }
  };
  handleDelete = (id: any) => {
    const { change, formData, formName } = this.props;
    const formValues = formData.values; // no need for [name], since they are simply added
    const projectCategories = [...formValues.projectCategoryIds];
    projectCategories.splice(formValues.projectCategoryIds.indexOf(id), 1);
    change(formName, 'projectCategoryIds', projectCategories);
  };
  getProjectCategoriesTableData = () => {
    const {
      projectCategories,
      formData: {
        values: { projectCategoryIds },
      },
    } = this.props;
    return projectCategories({
      include: { projectCategoryId: projectCategoryIds },
      option: { plain: true, array: true },
    });
  };
  render() {
    const { label, disabled, name } = this.props;
    return (
      <Fragment>
        <Grid container item md={6} justifyContent="center">
          <Grid container item md={12} justifyContent="space-between" alignItems="flex-end">
            <AutocompleteForm
              size={8}
              options={this.getProjectCategories()}
              name={name}
              label={label}
            />
            <Button handleClick={() => this.handleAdd()} size={3} disabled={disabled}>
              Hinzufügen
            </Button>
          </Grid>
          <ProjectCategoryTable
            projectCategories={this.getProjectCategoriesTableData()}
            handleDelete={this.handleDelete}
          />
        </Grid>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCategorySelectorTable);
