import React from 'react';
import { connect } from 'react-redux';
import { Grid, AppBar as MuiAppBar, Tabs as MUITabs } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import { styled } from '@mui/material/styles';
import { DRAWER_WIDTH } from '../../../constants/all';

const styles = {
  grid: {
    height: '48px',
    marginBotton: '30px!important',
  },
};

interface AppBarProps extends MuiAppBarProps {
  withMargin?: boolean;
  sidebarOpen?: boolean;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ withMargin, sidebarOpen }) => ({
  ...(withMargin && {
    zIndex: 1,
  }),
  ...(!sidebarOpen && {
    top: '100px',
    width: `100%`,
    right: '0',
    height: '48px',
  }),
  ...(sidebarOpen && {
    top: '100px',
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    right: '0',
    height: '48px',
  }),
}));

const mapStateToProps = (state: any) => ({
  sidebarOpen: state.rootReducer.sidebarOpen,
});

interface ComponentOwnProps {
  selectedTab: number;
  handleTab: (...args: any[]) => any;
  withMargin?: boolean;
}

interface ComponentStateProps {
  sidebarOpen: boolean;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

const Tabs: React.FC<ComponentProps> = ({
  sidebarOpen,
  children,
  selectedTab,
  handleTab,
  withMargin = true,
}) => (
  <Grid sx={styles.grid} item md={12}>
    <AppBar position="fixed" withMargin={withMargin} sidebarOpen={sidebarOpen}>
      <MUITabs
        textColor="inherit"
        indicatorColor="secondary"
        value={selectedTab}
        onChange={handleTab}
      >
        {children}
      </MUITabs>
    </AppBar>
  </Grid>
);

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(Tabs);
