import { keyBy, uniq, split } from 'lodash';

import { IServiceEntriesByWeek } from '../../../shared/src/interfaces/ServiceEntry';
import { ITimeEntriesByWeek } from '../../../shared/src/interfaces/TimeEntry';

const toKey = (serviceEntry: { week: number; year: number }) =>
  `${serviceEntry.year}-${serviceEntry.week}`;
const toObject = (key: string) => {
  const [year, week] = split(key, '-', 2);
  return { year, week };
};

export const mergeEntries = (
  serviceEntries: IServiceEntriesByWeek[],
  timeEntries: ITimeEntriesByWeek[]
) => {
  const serviceEntriesByWeek = keyBy(serviceEntries, (serv) => toKey(serv));
  const timeEntriesByWeek = keyBy(timeEntries, (timeEntry) => toKey(timeEntry));
  const times = uniq([...Object.keys(serviceEntriesByWeek), ...Object.keys(timeEntriesByWeek)]);
  const keys = times.map((time) => toObject(time));

  return keys.map(({ week, year }) => {
    return {
      week: Number(week),
      year: Number(year),
      ...(serviceEntriesByWeek[toKey({ week: Number(week), year: Number(year) })]?.entries && {
        service: serviceEntriesByWeek[toKey({ week: Number(week), year: Number(year) })]?.entries,
      }),
      ...(timeEntriesByWeek[toKey({ week: Number(week), year: Number(year) })]?.entries && {
        time: timeEntriesByWeek[toKey({ week: Number(week), year: Number(year) })]?.entries,
      }),
    };
  });
};
