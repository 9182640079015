export const compareProps = (prevProps: any, nextProps: any) =>
  JSON.stringify(prevProps) === JSON.stringify(nextProps);

export const comparePropsWithFunctions = (prevProps: any, nextProps: any, stateFunctions: any) => {
  const prevPropsN = { ...prevProps };
  const nextPropsN = { ...nextProps };
  stateFunctions.forEach((element: any) => {
    const { filter } = element;
    prevPropsN[element.name] = prevPropsN[element.name]({ ...filter });
  });
  stateFunctions.forEach((element: any) => {
    const { filter } = element;
    nextPropsN[element.name] = nextPropsN[element.name]({ ...filter });
  });
  return JSON.stringify(prevPropsN) === JSON.stringify(nextPropsN);
};

export default {};
