import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const customerContactSelector = (
  state: any,
  {
    include = {
      customerId: null,
      customerContactId: null,
      rightId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      //TODO: do we need all? search for all: true
      all: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.customerContacts,
    (customerContacts) => {
      // for single selected customer
      if (checkSingleValue(include.customerContactId) && !option.all) {
        const customerContactId = Array.isArray(include.customerContactId)
          ? include.customerContactId![0]
          : include.customerContactId;

        const customerContact = customerContacts.find(
          (cc: any) => cc.id === parseInt(customerContactId!, 10)
        );
        if (customerContact) {
          if (option.plain) {
            return customerContact;
          }
          return {
            label: `${customerContact.firstName} ${customerContact.lastName}`,
            value: `${customerContact.id}`,
          };
        }
        return null;
      }

      let customerContactOptions = customerContacts;
      if (!option.deactivated) {
        customerContactOptions = customerContactOptions.filter(
          (customerContact: any) => customerContact.deactivated !== true
        );
      }

      // include the specified users
      if (include.customerContactId) {
        customerContactOptions = customerContactOptions.filter((customerContact: any) =>
          // @ts-ignore
          include.customerContactId.includes(customerContact.id)
        );
      }
      if (include.rightId) {
        customerContactOptions = customerContactOptions.filter(
          (customerContact: any) => customerContact.rightId === include.rightId
        );
      }

      if (include.customerId) {
        customerContactOptions = customerContactOptions.filter(
          (customerContact: any) => customerContact.customerId === include.customerId
        );
      }
      if (customerContactOptions) {
        if (option.plain) {
          return customerContactOptions;
        }
        return customerContactOptions.map((customerContact: any) => ({
          label: `${customerContact.firstName} ${customerContact.lastName}`,
          value: `${customerContact.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
