import React from 'react';
import { Grid, GridSize } from '@mui/material';
import Typography from '../../Atoms/Typography/Typography';

const styles = {
  editWrapper: {
    marginBottom: '5rem',
  },
};

type AdminSettingsWrapperProps = {
  setRef?: any;
  justify?: any;
  alignItems?: any;
  id?: string;
  editWrapper?: boolean;
  size?: GridSize;
  heading?: string;
};

const AdminSettingsWrapper: React.FC<AdminSettingsWrapperProps> = ({
  justify = 'flex-start',
  editWrapper = false,
  children,
  heading = null,
  alignItems = 'center',
  id = '',
  size = 12,
  setRef = null,
}) => (
  <Grid
    ref={setRef || undefined}
    container
    item
    id={id}
    md={size}
    justifyContent={justify}
    spacing={3}
    alignItems={alignItems}
    sx={{
      ...(editWrapper && styles.editWrapper),
    }}
  >
    {heading && <Typography variant="h4" value={heading} size={12} />}
    {children}
  </Grid>
);

export default AdminSettingsWrapper;
