import React, { Fragment, memo, useEffect } from 'react';
import { Grid } from '@mui/material';
import RadiosForm from '../Radios/RadiosForm';
import QuestionTypo from './components/check/QuestionTypo';
import QuestionAdditionsSiteCheck from './components/check/QuestionAdditionsSiteCheck';
import { compareProps } from '../../../helpers/compareProps';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import { FormContext } from '../../Pages/PageAdminArea/Form/PageFormEdit/FormSiteCheck/components/SiteCheckQuestions';

const styles = {
  gridContainer: {
    margin: '15px 0px',
  },
  gridContainerTwo: {
    padding: '0 12px',
    marginTop: '-12px',
  },
  highlighted: {
    backgroundColor: '#f3f3f3',
  },
};

type TypeTwelveProps = {
  question: any;
  answer: object;
  roleCheck: boolean;
  formCompleted?: boolean;
  change: (...args: any[]) => any;
  required: boolean;
  value?: number;
  index: number;
};
const TypeTwelve: React.SFC<TypeTwelveProps> = memo(
  ({ index, question, required = false, value, answer, change, formCompleted = false }) => {
    const { id } = question;
    const { setSize, windowWidth } = React.useContext<any>(FormContext);
    const root = React.useRef<any>(null);
    useEffect(() => {
      if (windowWidth && setSize) {
        setSize(index, root.current.getBoundingClientRect().height);
      }
    }, [windowWidth]);
    return (
      <Fragment>
        <Grid
          ref={root}
          container
          item
          md={12}
          spacing={3}
          alignItems="flex-start"
          justifyContent="flex-start"
          sx={{
            ...styles.gridContainer,
            ...(question.highlight && styles.highlighted),
          }}
        >
          <QuestionTypo
            info={question.info}
            order={question.order}
            text={question.text}
            required={required}
            formCompleted={formCompleted}
          />
          <RadiosForm
            name={`${id}`}
            disabled={!required || formCompleted}
            options={[
              { label: 'Ja', value: 1 },
              { label: 'Nein', value: 0 },
            ]}
            size={2}
            handleChange={(v) => {
              change(`${id}`, v);
              change(`${id}givenFrom`, null);
            }}
          />
          <QuestionAdditionsSiteCheck
            id={id}
            answer={answer}
            required={required}
            value={value}
            formCompleted={formCompleted}
          />
          <Grid
            container
            item
            md={12}
            alignItems="flex-start"
            justifyContent="flex-start"
            sx={styles.gridContainerTwo}
          >
            <Grid item container md={7}></Grid>

            <TextFieldForm
              disabled={!required || formCompleted}
              size={3}
              name={`${id}textTwo`}
              gridStyles={{ paddingLeft: 15 }}
              // formQuestion
              label="kg"
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  },
  compareProps
);

export default TypeTwelve;
