import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const projectCategorySelector = (
  state: any,
  {
    include = {
      projectCategoryId: null,
    },
    exclude = {
      projectCategoryId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      array: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.projectCategories,
    (projectCategories) => {
      // for single selected contractor
      if (checkSingleValue(include.projectCategoryId)) {
        const projectCategoryId = Array.isArray(include.projectCategoryId)
          ? include.projectCategoryId![0]
          : include.projectCategoryId;

        const projectCategory = projectCategories.find(
          (pT: any) => pT.id === parseInt(projectCategoryId!, 10)
        );
        if (projectCategory) {
          if (option.plain) {
            return option.array ? [projectCategory] : projectCategory;
          }
          return option.array
            ? [{ label: `${projectCategory.name}`, value: `${projectCategory.id}` }]
            : { label: `${projectCategory.name}`, value: `${projectCategory.id}` };
        }
        return null;
      }
      let projectCategoryOptions = projectCategories;
      if (include.projectCategoryId) {
        projectCategoryOptions = projectCategoryOptions.filter((projectCategory: any) =>
          // @ts-ignore
          include.projectCategoryId.includes(projectCategory.id)
        );
      }
      if (exclude.projectCategoryId) {
        projectCategoryOptions = projectCategoryOptions.filter(
          // @ts-ignore
          (projectCategory) => !exclude.projectCategoryId.includes(projectCategory.id)
        );
      }

      if (projectCategoryOptions) {
        if (option.plain) {
          return projectCategoryOptions;
        }
        return projectCategoryOptions.map((projectCategory: any) => ({
          label: projectCategory.name,
          value: `${projectCategory.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
