import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Sidebar from '../../Organisms/Sidebar/Sidebar';
import NavBar from '../../Organisms/NavBar/NavBar';
import { ADMIN, CONTRACTOR_STOPA } from '../../../../../shared/src/constants/general';
import {
  getUsersAction,
  getRolesAction,
  getGroupsAction,
  getRightsAction,
  getContractorsAction,
  setAdminAreaSectionAction,
} from '../../../actions';
import { contractorSelector } from '../../../helpers/selectors';

export default function PageContractorWrapper(WrappedComponent: React.ComponentType) {
  const mapStateToProps = (state: any) => ({
    auth: state.rootReducer.auth,
    users: state.rootReducer.users,
    roles: state.rootReducer.roles,
    groups: state.rootReducer.groups,
    rights: state.rootReducer.rights,
    contractors: (filter: any) => contractorSelector(state, filter),
  });
  const mapDispatchToProps = (dispatch: any) => ({
    getUsers: () => dispatch(getUsersAction()),
    getRoles: () => dispatch(getRolesAction()),
    getGroups: () => dispatch(getGroupsAction()),
    getRights: () => dispatch(getRightsAction()),
    getContractors: () => dispatch(getContractorsAction()),
    setAdminAreaSection: (section: any) => dispatch(setAdminAreaSectionAction(section)),
  });

  interface RouteParams {
    id: string;
  }

  interface ComponentOwnProps {}

  interface ComponentStateProps {
    auth: any;
    users: any;
    roles: any;
    groups: any;
    rights: any;
    contractors: (...args: any[]) => any;
  }

  interface ComponentDispatchProps {
    getUsers: (...args: any[]) => any;
    getRoles: (...args: any[]) => any;
    getGroups: (...args: any[]) => any;
    getRights: (...args: any[]) => any;
    getContractors: (...args: any[]) => any;
    setAdminAreaSection: (...args: any[]) => any;
  }

  type ComponentProps = ComponentOwnProps &
    ComponentStateProps &
    ComponentDispatchProps &
    RouteComponentProps<RouteParams>;

  class InnerWrapper extends PureComponent<ComponentProps, {}> {
    static defaultProps = {
      users: null,
      roles: null,
      groups: null,
      rights: null,
      contractors: () => null,
    };

    componentDidMount() {
      const {
        auth,
        history,
        match,
        getUsers,
        getRoles,
        getGroups,
        getRights,
        getContractors,
        setAdminAreaSection,
      } = this.props;

      if (auth.rightId > ADMIN || parseInt(match.params.id, 10) === CONTRACTOR_STOPA) {
        history.push('/overview');
      }

      getContractors();
      getUsers();
      getRoles();
      getGroups();
      getRights();
      setAdminAreaSection('contractor');
    }
    render() {
      const { users, roles, groups, rights, contractors } = this.props;
      return (
        <Fragment>
          <NavBar />
          <Sidebar />
          {users && roles && groups && rights && contractors && (
            <Fragment>
              <WrappedComponent />
            </Fragment>
          )}
        </Fragment>
      );
    }
  }

  return connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(InnerWrapper));
}
