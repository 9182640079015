import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import Visit from './components/Visit/Visit';
import ProjectHours from './components/ProjectHours/ProjectHours';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';

const mapStateToProps = (state: any) => ({
  adminSection: state.rootReducer.adminSection,
  // This is here, because this page is used for multiple rights
  visits: state.rootReducer.visits,
});
type PageDiagnosticProps = {
  adminSection?: string;
  visits?: any;
};
class PageDiagnostic extends PureComponent<PageDiagnosticProps, {}> {
  static defaultProps = {
    visits: null,
  };

  render() {
    const { adminSection, visits } = this.props;
    return (
      <Fragment>
        {adminSection !== 'projectVisit' && (
          <AdminSettingsWrapper alignItems="flex-start" editWrapper>
            <ProjectHours />
          </AdminSettingsWrapper>
        )}
        {adminSection !== 'projectHours' && visits && (
          <AdminSettingsWrapper alignItems="flex-start" editWrapper>
            <Visit />
          </AdminSettingsWrapper>
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(PageDiagnostic);
