import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps } from 'redux-form';
import ContractorUserDetails from '../../../../Molecules/AdminAreaDetails/ContractorUserDetails';
import { userSelector, contractorSelector } from '../../../../../helpers/selectors';
import { CONTRACTOR_SCROLLSPY_ITEMS } from '../../../../../constants/all';
import SidebarScrollSpy from '../../../../Organisms/Sidebar/SidebarScrollSpy';
import { saveUserAction, deleteUserAction } from '../../../../../actions';
import AdminOuterWrapper from '../../../../Templates/AdminWrappers/AdminOuterWrapper';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  contractors: (filter: any) => contractorSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  pageUserEdit: state.form.pageUserEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveUser: (user: any) => dispatch(saveUserAction(user)),
  deleteUser: (userId: any) => dispatch(deleteUserAction(userId)),
});

interface RouteParams {
  id: string;
  userId: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  users: (...args: any[]) => any;
  contractors: (...args: any[]) => any;
  pageUserEdit: any;
}

interface ComponentDispatchProps {
  saveUser: (...args: any[]) => any;
  deleteUser: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams> &
  InjectedFormProps;

type ComponentOwnState = {
  scrollSpyLabel: string[];
};

class PageContractorUserEdit extends PureComponent<ComponentProps, ComponentOwnState> {
  state = {
    scrollSpyLabel: [''],
  };

  componentWillMount() {
    const { initialize, users, match } = this.props;
    const userId = parseInt(match.params.userId, 10);
    const user = users({
      include: { userId },
      option: { plain: true },
    });
    initialize({
      ...user,
    });
  }

  handleSave = () => {
    const { pageUserEdit, saveUser } = this.props;
    saveUser(pageUserEdit.values);
  };

  handleDelete = async () => {
    const { match, history, pageUserEdit, deleteUser } = this.props;
    deleteUser(pageUserEdit.values.id);
    history.push(`/admin/contractor/${match.params.id}`);
  };

  scrollToRefs = () => CONTRACTOR_SCROLLSPY_ITEMS.map(() => React.createRef());

  render() {
    const { scrollSpyLabel } = this.state;
    const { users, match } = this.props;
    const contractorId = parseInt(match.params.id, 10);
    const userId = parseInt(match.params.userId, 10);
    const scrollToRefs = this.scrollToRefs();

    return (
      <AdminOuterWrapper withScrollSpy>
        <ContractorUserDetails />
        <SidebarScrollSpy
          scrollSpyItems={CONTRACTOR_SCROLLSPY_ITEMS}
          scrollToRefs={scrollToRefs}
          handleSave={this.handleSave}
          handleDelete={this.handleDelete}
          withDelete
          deactivatedEntry={
            users({
              include: { userId },
              option: { plain: true },
            }).deactivated
          }
          scrollSpyLabel={scrollSpyLabel}
          backPath={`contractor/${contractorId}`}
        />
      </AdminOuterWrapper>
    );
  }
}
export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageUserEdit',
  })(withRouter<any, any>(PageContractorUserEdit))
);
