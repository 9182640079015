import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import { Grid, IconButton } from '@mui/material';
import Button from '../../../../../Atoms/Button/Button';
import Typography from '../../../../../Atoms/Typography/Typography';
import TextFieldForm from '../../../../../Atoms/TextField/TextFieldForm';
import {
  PROJECT_MANAGER,
  SERVICE_TYPE_WORK,
} from '../../../../../../../../shared/src/constants/general';
import { userSelector } from '../../../../../../helpers/selectors';

const styles = {
  headerWrapper: {
    marginTop: '3rem',
  },
};

const mapStateToProps = (state: any) => ({
  users: (filter: any) => userSelector(state, filter),
});

interface ComponentStateProps {
  users: (...args: any[]) => any;
}

interface ComponentOwnProps {
  classes?: any;
  timeEntryVisitData: any[];
  formData: any;
  handleTimeEntryVisitAdd: (...args: any[]) => any;
  handleTimeEntryVisitDelete: (...args: any[]) => any;
}

type ComponentProps = ComponentStateProps & ComponentOwnProps;

class TimeEntryVisit extends PureComponent<ComponentProps, {}> {
  render() {
    const {
      classes,
      users,
      formData,
      timeEntryVisitData,
      handleTimeEntryVisitAdd,
      handleTimeEntryVisitDelete,
    } = this.props;
    const { user, serviceType } = formData.values;
    const serviceTypeId = serviceType ? parseInt(serviceType.value, 10) : null;
    const userRightId = user
      ? users({ include: { userId: user.value }, option: { plain: true } }).rightId
      : null;
    return (
      <Fragment>
        {userRightId === PROJECT_MANAGER && serviceTypeId === SERVICE_TYPE_WORK && (
          <Fragment>
            <Typography
              variant="h5"
              value="Besuchs-Protokoll"
              size={12}
              sx={styles.headerWrapper}
            />
            <TextFieldForm size={4} name="visitTopic" label="Thema der Besprechung" multiline />
            <TextFieldForm size={4} name="visitParticipants" label="Teilnehmer" multiline />
            <TextFieldForm
              size={4}
              name="visitDistributionCircle"
              label="Verteiler-Kreis / optional"
              multiline
            />
            <Typography variant="h5" value="Tagesordnungs-Punkte" size={12} />
            {timeEntryVisitData.length > 0 &&
              timeEntryVisitData.map((id, index) => (
                <Fragment key={id}>
                  <TextFieldForm
                    size={3}
                    name={`visitDiscussedTopic${id}`}
                    label={`TOP ${index + 1}`}
                    multiline
                  />
                  <TextFieldForm size={3} name={`visitComment${id}`} label="Kommentar" multiline />
                  <TextFieldForm
                    size={3}
                    name={`visitNameResponsible${id}`}
                    label="Name des Verantwortlichen"
                    multiline
                  />
                  <TextFieldForm
                    size={2}
                    name={`visitDeadline${id}`}
                    label="zu erledigen bis"
                    type="date"
                  />
                  <IconButton onClick={() => handleTimeEntryVisitDelete(id)}>
                    <Delete />
                  </IconButton>
                </Fragment>
              ))}
            <Grid md={12} item container>
              <Button handleClick={() => handleTimeEntryVisitAdd()} icon={<Add />}>
                TOP hinzufügen
              </Button>
            </Grid>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export default connect<ComponentStateProps, {}, ComponentOwnProps>(
  mapStateToProps,
  {}
)(TimeEntryVisit);
