import React from 'react';
import QuestionTypeOne from './QuestionTypeOne';
import QuestionTypeTwo from './QuestionTypeTwo';
import QuestionTypeThree from './QuestionTypeThree';
import QuestionTypeFour from './QuestionTypeFour';
import QuestionTypeFive from './QuestionTypeFive';
import QuestionTypeSix from './QuestionTypeSix';
import QuestionTypeSeven from './QuestionTypeSeven';
import QuestionTypeEight from './QuestionTypeEight';
import QuestionTypeNine from './QuestionTypeNine';
import QuestionTypeTen from './QuestionTypeTen';
import QuestionTypeEleven from './QuestionTypeEleven';
import QuestionTypeTwelve from './QuestionTypeTwelve';
import {
  QUESTION_TYPE_EIGHT,
  QUESTION_TYPE_FIVE,
  QUESTION_TYPE_FOUR,
  QUESTION_TYPE_TWO,
  QUESTION_TYPE_SIX,
  QUESTION_TYPE_ONE,
  QUESTION_TYPE_SEVEN,
  QUESTION_TYPE_THREE,
  QUESTION_TYPE_NINE,
  QUESTION_TYPE_TWELVE,
  QUESTION_TYPE_TEN,
  QUESTION_TYPE_ELEVEN,
} from '../../../../../shared/src/constants/general';

const questionTypes = [
  {
    questionTypeId: QUESTION_TYPE_ONE,
    component: (props: any) => {
      const { id } = props;
      return <QuestionTypeOne key={id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_TWO,
    component: (props: any) => {
      const { id } = props;
      return <QuestionTypeTwo key={id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_THREE,
    component: (props: any) => {
      const { id } = props;
      return <QuestionTypeThree key={id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_FOUR,
    component: (props: any) => {
      const { id } = props;
      return <QuestionTypeFour key={id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_FIVE,
    component: (props: any) => {
      const { id } = props;
      return <QuestionTypeFive key={id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_SIX,
    component: (props: any) => {
      const { id } = props;
      return <QuestionTypeSix key={id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_SEVEN,
    component: (props: any) => {
      const { question } = props;
      return <QuestionTypeSeven key={question.id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_EIGHT,
    component: (props: any) => {
      const { question } = props;
      return <QuestionTypeEight key={question.id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_NINE,
    component: (props: any) => {
      const { question } = props;
      return <QuestionTypeNine key={question.id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_TEN,
    component: (props: any) => {
      const { question } = props;
      return <QuestionTypeTen key={question.id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_ELEVEN,
    component: (props: any) => {
      const { question } = props;
      return <QuestionTypeEleven key={question.id} {...props} />;
    },
  },
  {
    questionTypeId: QUESTION_TYPE_TWELVE,
    component: (props: any) => {
      const { question } = props;
      return <QuestionTypeTwelve key={question.id} {...props} />;
    },
  },
];
export default questionTypes;
