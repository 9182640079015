import { TIME_ENTRY_TYPE } from '../../../../../shared/src/enums/enums';
import {
  projectSelector,
  serviceProjectSelector,
  customerSelector,
  siteSelector,
} from '../../../helpers/selectors';
import { useAppSelector } from '../../../hooks/reduxHooks';
import { ICustomer } from '../../../../../shared/src/interfaces/Customer';
import { ISite } from '../../../../../shared/src/interfaces/Site';
import { IProject } from '../../../../../shared/src/interfaces/Project';
import { IServiceProject } from '../../../../../shared/src/interfaces/ServiceProject';

interface IProjectProps {
  type: TIME_ENTRY_TYPE;
  id: number;
}

export const useProjectEntriesData = ({ type, id }: IProjectProps) => {
  const customers = useAppSelector(
    (state) =>
      <T = any>(filter: any) =>
        customerSelector<T>(state, filter)
  );
  const projects = useAppSelector(
    (state) =>
      <T = any>(filter: any) =>
        projectSelector<T>(state, filter)
  );
  const serviceProjects = useAppSelector(
    (state) =>
      <T = any>(filter: any) =>
        serviceProjectSelector<T>(state, filter)
  );
  const sites = useAppSelector(
    (state) =>
      <T = any>(filter: any) =>
        siteSelector<T>(state, filter)
  );

  const getProjectData = (type: TIME_ENTRY_TYPE, projectId: number) => {
    if (type === TIME_ENTRY_TYPE.PROJECT) {
      return projects<IProject>({
        include: { projectId },
        option: { plain: true },
      });
    }
    return serviceProjects<IServiceProject>({
      include: { serviceProjectId: projectId },
      option: { plain: true },
    });
  };

  const projectData = getProjectData(type, id);
  const projectNumber = getProjectDataNumber(type, projectData);

  const site = sites<ISite>({
    include: { siteId: projectData.siteId },
    option: { plain: true },
  });
  const customer = customers<ICustomer>({
    option: { plain: true },
    include: {
      customerId: projectData.customerId,
    },
  });

  return { projectNumber, customer, site };
};

const getProjectDataNumber = (type: TIME_ENTRY_TYPE, project: IProject | IServiceProject) =>
  type === TIME_ENTRY_TYPE.PROJECT
    ? (project as IProject).number
    : (project as IServiceProject).serviceProjectNumber;
