import React, { PureComponent, memo } from 'react';
import { Field } from 'redux-form';
import {
  Grid,
  GridSize,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Radio,
  RadioGroup,
} from '@mui/material';

type renderRadioGroupProps = {
  input: any;
  meta: any;
  options: any[];
  label: string;
  disabled?: boolean;
};
const renderRadioGroup: React.SFC<renderRadioGroupProps> = memo(
  ({ input, meta, options, disabled = false, label }) => {
    const { onChange } = input;
    const { touched, error } = meta;
    const inputValue = input.value;
    return (
      <FormControl error={error && touched} component="fieldset">
        {label && <FormLabel component="legend">{label}</FormLabel>}
        {touched && error && <FormHelperText className="error">{error}</FormHelperText>}
        <RadioGroup value={`${inputValue}`} onChange={(event, value) => onChange(value)} row>
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={`${option.value}`}
              control={<Radio color="primary" />}
              label={option.label}
              disabled={disabled || option.singleDisabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
);

type RadiosProps = {
  options: any[];
  name: string;
  size?: GridSize;
  label?: string;
  handleChange?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  formValues?: any;
  disabled?: boolean;
};

class Radios extends PureComponent<RadiosProps, {}> {
  render() {
    const { formValues = null, size, onBlur = () => {}, handleChange = () => {} } = this.props;
    return (
      <Grid item md={size}>
        <Field
          {...this.props}
          component={renderRadioGroup}
          onChange={(value: any) => handleChange(value)}
          onBlur={() => {
            if (formValues) {
              // @ts-ignore
              if (formValues.initial[name] !== formValues.values[name]) {
                onBlur();
              }
            }
          }}
        />
      </Grid>
    );
  }
}

export default Radios;
