import React, { FC, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../../Organisms/NavBar/NavBar';
import AdminOuterWrapper from '../AdminWrappers/AdminOuterWrapper';
import Sidebar from '../../Organisms/Sidebar/Sidebar';
import {
  getProjectsAction,
  getServiceProjectsAction,
  getUsersAction,
  getProjectVariablesAction,
  getRulesAction,
  getRuleVariablesAction,
  getGroupsAction,
  getRolesAction,
  getCustomersAction,
  getCustomerContactsAction,
  getSitesAction,
  getBillingsAction,
  getContractorsAction,
  getTimeSheetsAction,
  setSidebarAction,
  getAllTimeEntriesAction,
} from '../../../actions';
// import { State } from '../../../reducers/index';

interface PageControllingWrapperProps {}

export const PageControllingWrapper: FC<PageControllingWrapperProps> = ({ children }) => {
  const dispatch = useDispatch();

  const getProjects = () => dispatch(getProjectsAction());
  const getServiceProjects = () => dispatch(getServiceProjectsAction());
  const getProjectVariables = () => dispatch(getProjectVariablesAction());
  const getCustomers = () => dispatch(getCustomersAction());
  const getCustomerContacts = () => dispatch(getCustomerContactsAction());
  const getSites = () => dispatch(getSitesAction());
  const getRules = () => dispatch(getRulesAction());
  const getRuleVariables = () => dispatch(getRuleVariablesAction());
  const getGroups = () => dispatch(getGroupsAction());
  const getRoles = () => dispatch(getRolesAction());
  const getBillings = () => dispatch(getBillingsAction());
  const getContractors = () => dispatch(getContractorsAction());
  const getUsers = () => dispatch(getUsersAction());
  const getTimeSheets = () => dispatch(getTimeSheetsAction());
  const setSidebar = (open: any) => dispatch(setSidebarAction(open));

  useEffect(() => {
    getProjects();
    getServiceProjects();
    getUsers();
    getProjectVariables();
    getCustomers();
    getCustomerContacts();
    getContractors();
    getSites();
    getRules();
    getRuleVariables();
    getGroups();
    getRoles();
    getBillings();
    getTimeSheets();
    // set the current week as predefined
    const currentDate = new Date();
    const currentWeek = moment(currentDate).week();
    const currentYear = moment(currentDate).year();
    dispatch(getAllTimeEntriesAction(currentWeek, currentYear));
    setSidebar(false);
  }, []);

  const projects = useSelector((state: any) => state.rootReducer.projects);
  const serviceProjects = useSelector((state: any) => state.rootReducer.serviceProjects);
  const users = useSelector((state: any) => state.rootReducer.users);
  const roles = useSelector((state: any) => state.rootReducer.roles);
  const services = useSelector((state: any) => state.rootReducer.services);
  const billingTypes = useSelector((state: any) => state.rootReducer.billingTypes);
  const customers = useSelector((state: any) => state.rootReducer.customers);
  const timeSheets = useSelector((state: any) => state.rootReducer.timeSheets);
  const combinedTimeEntries = useSelector((state: any) => state.rootReducer.combinedTimeEntries);
  const rules = useSelector((state: any) => state.rootReducer.rules);

  return (
    <>
      <NavBar />
      <Sidebar />
      {users &&
        customers &&
        combinedTimeEntries &&
        services &&
        roles &&
        rules &&
        billingTypes &&
        timeSheets &&
        projects &&
        serviceProjects && <AdminOuterWrapper>{children}</AdminOuterWrapper>}
    </>
  );
};
