import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import AutocompleteForm from '../../../../../Molecules/Autocomplete/AutocompleteForm';
import {
  ADMIN,
  PROJECT_MANAGER,
  DEPARTMENT_MANAGER,
  MECHANIC,
  CONTRACTOR_STOPA,
  GROUP_PROJECTMANAGER,
  ROLE_PROJECTMANAGER,
  GROUP_SERVICE,
} from '../../../../../../../../shared/src/constants/general';
import {
  userSelector,
  groupSelector,
  roleSelector,
  projectSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
});

interface ComponentStateProps {
  auth: any;
  users: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  roles: (...args: any[]) => any;
  projects: (...args: any[]) => any;
}

interface ComponentOwnProps {
  handleFlush: (...args: any[]) => any;
  handleChange: (...args: any[]) => any;
  formData: any;
  isDisabled?: boolean;
}

type ComponentProps = ComponentStateProps & ComponentOwnProps;

class ServiceEntryUser extends PureComponent<ComponentProps, {}> {
  handleChange = (value: any) => {
    const { users, groups, roles, handleFlush, handleChange } = this.props;
    // flush fields if project changed
    const flushFields = [
      'user',
      'role',
      'serviceType',
      'service',
      'specificationA',
      'specificationB',
      'commentBilling',
    ];
    handleFlush(flushFields);
    // check if project manager, change role and group into projectmanager
    if (value) {
      const user = users({
        include: { userId: value.value },
        option: { plain: true },
      });
      if (user.rightId === PROJECT_MANAGER) {
        const group = {
          name: 'group',
          value: groups({ include: { groupId: GROUP_PROJECTMANAGER } }),
        };
        const role = {
          name: 'role',
          value: roles({ include: { roleId: ROLE_PROJECTMANAGER } }),
        };
        handleChange([group, role]);
      } else {
        const role = {
          name: 'role',
          value: roles({ include: { userId: user.id } })?.[0],
        };
        handleChange([role]);
      }
    }
  };
  getAutocompleteData = () => {
    const { auth, users, projects, formData } = this.props;
    if (formData.values.serviceProject) {
      return users({
        include: { groupId: GROUP_SERVICE },
      });
    }
    return [];
  };
  render() {
    return (
      <AutocompleteForm
        size={4}
        options={this.getAutocompleteData()}
        label="Monteur"
        name="user"
        onChange={(value) => this.handleChange(value)}
        isDisabled={this.props.isDisabled}
      />
    );
  }
}
export default connect<ComponentStateProps, {}, ComponentOwnProps>(
  mapStateToProps,
  {}
)(ServiceEntryUser);
