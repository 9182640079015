import * as React from 'react';
import { Grid, GridSize } from '@mui/material';
import { Tooltip, Button as MUIButton, Box } from '@mui/material';

import { Theme, SxProps } from '@mui/material/styles';

const styles = {
  gridSmall: {
    padding: '20px 10px !important',
    flexBasis: 0,
  },
  buttonRight: {
    marginLeft: 'auto',
  },
  buttonSmall: {
    width: 'auto',
    fontSize: '.65rem',
  },
  buttonText: {
    textTransform: 'none',
  },
  icon: ({ spacing }: Theme) => ({
    height: '20px',
    '& svg': {
      marginRight: spacing(1),
      fontSize: 20,
    },
  }),
};

type ButtonProps = {
  size?: GridSize;
  alignRight?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
  handleClick: (...args: any[]) => any;
  disabled?: boolean;
  icon?: React.ReactNode;
  // type?: string;
  variant?: 'text' | 'outlined' | 'contained';
  tooltip?: string;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const Button: React.FC<ButtonProps> = ({
  children,
  handleClick,
  size = 2,
  alignRight = false,
  disabled = false,
  buttonSize = 'medium',
  icon = null,
  // type = null,
  variant = 'contained',
  tooltip = null,
  sx = {},
}) => (
  <Grid
    item
    md={size}
    sx={{
      ...(alignRight && styles.buttonRight),
      ...(buttonSize === 'small' && styles.gridSmall),
      ...sx,
    }}
  >
    {tooltip ? (
      <Tooltip disableFocusListener title={tooltip} color="default">
        <MUIButton
          variant={variant}
          color={variant === 'text' ? 'inherit' : 'primary'}
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
          fullWidth
          disabled={disabled}
          size={buttonSize}
          sx={{
            ...(buttonSize === 'small' && styles.buttonSmall),
            ...(variant === 'text' && styles.buttonText),
          }}
        >
          {icon && <Box sx={styles.icon}>{icon}</Box>}
          {children}
        </MUIButton>
      </Tooltip>
    ) : (
      <MUIButton
        variant={variant}
        color={variant === 'text' ? 'inherit' : 'primary'}
        onClick={(e) => {
          e.preventDefault();
          handleClick();
        }}
        fullWidth
        disabled={disabled}
        size={buttonSize}
        sx={{
          ...(buttonSize === 'small' && styles.buttonSmall),
          ...(variant === 'text' && styles.buttonText),
        }}
      >
        {icon && <Box sx={styles.icon}>{icon}</Box>}
        {children}
      </MUIButton>
    )}
  </Grid>
);

export default Button;
