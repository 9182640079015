import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const serviceSelector = (
  state: any,
  {
    include = {
      serviceId: null,
    },
    exclude = {
      serviceId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      array: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.services,
    (services) => {
      // for single service
      if (checkSingleValue(include.serviceId)) {
        const serviceId = Array.isArray(include.serviceId)
          ? include.serviceId![0]
          : include.serviceId;
        // @ts-ignore
        const service = services.find((s: any) => s.id === parseInt(serviceId, 10));
        if (service) {
          if (option.plain) {
            return option.array ? [service] : service;
          }
          return option.array
            ? [{ label: service.name, value: `${service.id}` }]
            : { label: service.name, value: `${service.id}` };
        }
        return null;
      }

      let serviceOptions = services;
      if (!option.deactivated) {
        // Filter all deactivated services
        serviceOptions = serviceOptions.filter((service: any) => service.deactivated !== true);
      }
      if (include.serviceId) {
        serviceOptions = serviceOptions.filter((service: any) =>
          // @ts-ignore
          include.serviceId.includes(service.id)
        );
      }
      if (exclude.serviceId) {
        serviceOptions = serviceOptions.filter(
          // @ts-ignore
          (service: any) => !exclude.serviceId.includes(service.id)
        );
      }
      if (serviceOptions) {
        if (option.plain) {
          return serviceOptions;
        }
        return serviceOptions.map((service: any) => ({
          label: service.name,
          value: `${service.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
