import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import AutocompleteForm from '../../../../../Molecules/Autocomplete/AutocompleteForm';
import { ADMIN } from '../../../../../../../../shared/src/constants/general';
import {
  projectSelector,
  siteSelector,
  customerSelector,
} from '../../../../../../helpers/selectors';
import Typography from '../../../../../Atoms/Typography/Typography';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  projects: (filter: any) => projectSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
});

interface ComponentStateProps {
  auth: any;
  projects: (...args: any[]) => any;
  sites: (...args: any[]) => any;
}

// interface ComponentDispatchProps {
//   doSomeAction: typeof someAction;
// }

interface ComponentOwnProps {
  handleFlush: (...args: any[]) => any;
  formData: any;
}

type TimeEntryProjectState = {
  site: null;
};

type ComponentProps = ComponentStateProps & ComponentOwnProps;

class TimeEntryProject extends PureComponent<ComponentProps, TimeEntryProjectState> {
  state = {
    site: null,
  };
  componentDidMount = () => {
    const { formData } = this.props;
    if (formData.values.project) {
      const projectId = formData.values.project.value;
      this.changeSite(projectId);
    }
  };
  changeSite = (projectId: any) => {
    const { projects, sites } = this.props;
    if (projectId) {
      const project = projects({
        include: { projectId },
        option: { plain: true },
      });
      this.setState({
        site: sites({ include: { siteId: project.siteId } }).label,
      });
    } else {
      this.setState({ site: null });
    }
  };
  getProjectOptions = () => {
    const { auth, projects } = this.props;
    // if (activeProjects) {
    if (auth.rightId === ADMIN) {
      return projects({});
    }
    return projects({ include: { userId: auth.id }, option: { deactivated: true } });
  };
  handleChangeProject = (value: any) => {
    const { projects, handleFlush } = this.props;
    const projectId = value ? value.value : null;
    const project = projectId
      ? projects({
          include: { projectId },
          option: { plain: true },
        })
      : null;
    // flush fields if project changed
    const flushFields = [
      'user',
      'group',
      'role',
      'serviceType',
      'service',
      'specificationA',
      'specificationB',
      'billingType',
      'billing',
      'commentBilling',
    ];
    handleFlush(flushFields);
    if (project) {
      this.changeSite(project.id);
    } else {
      this.changeSite(null);
    }
  };
  render() {
    const { site } = this.state;
    return (
      <Fragment>
        <AutocompleteForm
          options={this.getProjectOptions()}
          label="Projekt Nr."
          name="project"
          size={4}
          onChange={this.handleChangeProject}
        />
        {site && (
          <Typography
            size={4}
            value={`${site}`}
            variant="subtitle1"
            caption="Baustelle"
            captionType="top"
          />
        )}
      </Fragment>
    );
  }
}
export default connect<ComponentStateProps, {}, ComponentOwnProps>(
  mapStateToProps,
  {}
)(TimeEntryProject);
