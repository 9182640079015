import React from 'react';
import PageWeekOverview from '../../components/Pages/PageWeekOverview/PageWeekOverview';
import PageControlling from '../../components/Pages/PageControlling/PageControlling';
import { PageControlling2 } from '../../components/Pages/PageControlling/PageControlling2';

import PageWeekWrapper from '../../components/Templates/PageWrappers/PageWeekWrapper';
import { PageControllingWrapper } from '../../components/Templates/PageWrappers/PageControllingWrapper';
import PageServiceProjectOverview from '../../components/Pages/PageServiceProjectOverview/PageServiceProjectOverview';
import PageServiceProjectWrapper from '../../components/Templates/PageWrappers/PageServiceProjectWrapper';
/**
 * @constant
 * @description A constant to define routes for the user module.
 */
export const generalRoute = [
  // Overview
  {
    path: '/overview',
    component: PageWeekWrapper(PageWeekOverview),
    exact: true,
  },
  {
    path: '/serviceproject',
    component: PageServiceProjectWrapper(PageServiceProjectOverview),
    exact: true,
  },
  {
    path: '/serviceproject/:id',
    component: PageServiceProjectWrapper(PageServiceProjectOverview),
    exact: true,
  },
  {
    path: '/controlling',
    component: () => (
      <PageControllingWrapper>
        <PageControlling />
      </PageControllingWrapper>
    ),
    exact: true,
  },
  {
    path: '/controlling-v2',
    component: () => <PageControlling2 />,
    exact: true,
  },
];

export default {};
