import React, { Fragment } from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import { PROJECT_MANAGER } from '../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
});

interface ComponentOwnProps {
  projectCategories?: any[];
  handleDelete: (...args: any[]) => any;
  withOperation?: boolean;
}

interface ComponentStateProps {
  auth: any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

const ProjectCategoryTable: React.SFC<ComponentProps> = ({
  auth,
  projectCategories = null,
  handleDelete,
  withOperation = true,
}) => {
  return (
    <Fragment>
      <Grid item md={12}>
        {projectCategories && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Operation</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projectCategories.map((projectCategory: any) => (
                <TableRow key={projectCategory.id}>
                  <TableCell>{projectCategory.name}</TableCell>
                  {withOperation && (
                    <TableCell>
                      {auth.rightId === PROJECT_MANAGER && projectCategory.id === auth.id ? (
                        ''
                      ) : (
                        <IconButton onClick={() => handleDelete(projectCategory.id)}>
                          <Delete />
                        </IconButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Grid>
    </Fragment>
  );
};

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(ProjectCategoryTable);
