import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { getSortedData } from '../../../../../../helpers/getSortedData';

const mapStateToProps = (state: any) => ({
  answerSiteStarts: state.rootReducer.answerSiteStarts,
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  answerSiteStarts: any[];
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class PrintSiteStartBody extends React.Component<ComponentProps, {}> {
  getTimeStamp = () => {
    const { answerSiteStarts } = this.props;
    const lastEdited = answerSiteStarts.map(a => a);
    const timeStamp = moment(getSortedData(lastEdited, 'createdAt', false)[0].createdAt).format(
      'YYYY-MM-DD HH:mm'
    );
    return timeStamp;
  };
  render() {
    const { answerSiteStarts } = this.props;
    return (
      <Fragment>
        <table className="question-table">
          <thead>
            <tr>
              <th>Frage</th>
              <th>J/N</th>
              <th>Anmerkung</th>
              <th>Dok, Ind/SU</th>
              <th>Date</th>
              <th>von</th>
              <th>bis</th>
            </tr>
          </thead>
          <tbody>
            {answerSiteStarts.map(ans => {
              if (ans.question.questionTypeId === 3) {
                return (
                  <tr
                    key={ans.id}
                    className={classNames({
                      'tr-no': ans.decision === 0,
                    })}
                  >
                    <td>{ans.question.text}</td>
                    <td>
                      {ans.decision === 1 && 'J'}
                      {ans.decision === 0 && 'N'}
                    </td>
                    <td>
                      {ans.textOne}, {ans.textTwo}, {ans.textThree} , {ans.textFour}
                    </td>
                    <td />
                    <td>
                      {ans.dateOne
                        ? `${moment(ans.dateOne, 'YYYY-MM-DD').format('DD.MM.YYYY')}, `
                        : ''}
                      {ans.dateTwo
                        ? `${moment(ans.dateTwo, 'YYYY-MM-DD').format('DD.MM.YYYY')}, `
                        : ''}
                      {ans.dateThree
                        ? `${moment(ans.dateThree, 'YYYY-MM-DD').format('DD.MM.YYYY')}, `
                        : ''}
                      {ans.dateFour ? moment(ans.dateFour, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''}
                    </td>
                    <td>{ans.timeStart}</td>
                    <td>{ans.timeEnd}</td>
                  </tr>
                );
              }
              return ans.question.questionTypeId !== 9 ? (
                <tr
                  key={ans.id}
                  className={classNames({
                    'tr-no': ans.decision === 0,
                  })}
                >
                  <td>{ans.question.text}</td>
                  <td>
                    {ans.decision === 1 && 'J'}
                    {ans.decision === 0 && 'N'}
                  </td>
                  <td>{ans.textOne}</td>
                  <td>
                    {ans.textThree || ans.textTwo
                      ? `${ans.textThree ? `${ans.textThree},` : ''} ${
                          ans.textTwo ? `${ans.textTwo}` : ''
                        }`
                      : '-'}
                  </td>
                  <td>
                    {ans.dateOne ? moment(ans.dateOne, 'YYYY-MM-DD').format('DD.MM.YYYY') : ''}
                  </td>
                  <td>{ans.timeStart}</td>
                  <td>{ans.timeEnd}</td>
                </tr>
              ) : (
                <tr key={ans.id}>
                  <td className="td-heading">
                    <strong>{ans.question.text}</strong>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(PrintSiteStartBody);
