import React, { Fragment } from 'react';
import { Grid, Paper } from '@mui/material';
import NavBar from '../../Organisms/NavBar/NavBar';
import { apiUrl } from '../../../constants/api';
import BackgroundImage from '../../../assets/img/Stopa_Pactam_bg.jpg';
import BackgroundImageDev from '../../../assets/img/Stopa_Pactam_dev_bg.jpg';
import Typography from '../../Atoms/Typography/Typography';

const styles = {
  cssContainer: {
    marginTop: '-130px',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  cssContainerBg: {
    backgroundImage: `url(${BackgroundImage})`,
  },
  cssContainerBgDev: {
    backgroundImage: `url(${BackgroundImageDev})`,
  },
};

type PageLogoutProps = {};
const PageLogout: React.FC<PageLogoutProps> = React.memo(() => {
  return (
    <Fragment>
      <NavBar />
      <Grid
        container
        sx={{
          ...styles.cssContainer,
          ...styles.cssContainerBgDev,
          ...(apiUrl === 'https://stopa-pac-tam.de/backend' && styles.cssContainerBg),
          ...(apiUrl === 'https://staging.stopa-pac-tam.de/backend' && styles.cssContainerBgDev),
        }}
        alignItems="center"
        justifyContent="center"
      >
        <Paper elevation={1}>
          <Grid container justifyContent="center">
            <Typography size={12} value="Sie haben sich erfolgreich abgemeldet!" variant="h5" />
          </Grid>
        </Paper>
      </Grid>
    </Fragment>
  );
});

export default PageLogout;
