import React, { useState, useEffect, PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import AutocompleteForm from '../../../Molecules/Autocomplete/AutocompleteForm';
import Form from '../../../Atoms/Form/Form';
import { setAdminAreaSectionAction } from '../../../../actions';
import { customerSelector, projectSelector, siteSelector } from '../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  adminSection: state.rootReducer.adminSection,
  projects: (filter: any) => projectSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
});

const mapDispatchToProps = (dispatch: any) => ({
  setAdminAreaSection: (section: any) => dispatch(setAdminAreaSectionAction(section)),
});

type SearchProps = {
  history: any;
  projects: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  searchType: 'customer' | 'project';
};

const Search: React.SFC<SearchProps> = ({
  searchType,
  history,
  projects = null,
  customers = null,
  sites = null,
}) => {
  const [autocompleteOptions, setAutocompleteOptions] = useState<any>([]);
  useEffect(() => {
    let data;
    if (searchType === 'project') {
      data = getAutocompleteDataProject();
    }
    if (searchType === 'customer') {
      data = getAutocompleteDataCustomer();
    }
    setAutocompleteOptions(data);
  }, []);

  const getAutocompleteDataProject = () => {
    if (projects && customers && sites) {
      const data: any[] = [];
      const projectsData = projects({
        option: { plain: true, deactivated: true },
      })
        .reduce((acc: any, val: any) => acc.concat(val), []) // flatten  array
        .map((p: any) => {
          const c = customers({
            include: { customerId: p.customerId },
            option: { plain: true, deactivated: true },
          });
          const s = sites({
            include: { customerId: c.id },
            option: { plain: true, deactivated: true },
          });
          return {
            label: `Projekt: ${p.number} ${p.ab} ${p.fab} ${c.number ? c.number : ''} ${c.name} ${
              c.city
            } ${s[0]?.city} `,
            value: `${p.id}`,
            type: 'project',
          };
        });
      return projectsData;
    }
  };
  const getAutocompleteDataCustomer = () => {
    if (projects && customers && sites) {
      const customerData = customers({ option: { plain: true } }).map((c: any) => ({
        label: `Kunde: ${c.name} - ${c.adress}, ${c.postalCode} ${c.city}`,
        value: `${c.id}`,
        type: 'customer',
      }));
      return customerData;
    }
  };
  const handleChange = (value: any) => {
    if (value) {
      const path = value.type;
      const id = value.value;
      history.push(`/admin/${path}/${id}`);
    }
  };
  //could not make this work
  // const customFilterOption = (option: any, rawInput: any) => {
  //   const words = rawInput.split(' ');
  //   // console.log(option.keywords);
  //   return words.reduce(
  //     (acc: any, cur: any) => acc && option.keywords.toLowerCase().includes(cur.toLowerCase()),
  //     true
  //   );
  // };

  return (
    <Fragment>
      <Form>
        <AutocompleteForm
          size={4}
          colorWhite
          options={autocompleteOptions}
          name="search"
          placeholder="Suche"
          onChange={handleChange}
        />
      </Form>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'search',
  })(withRouter<any, any>(Search))
);
