import { mergeEntries } from '../helpers/mergeTimeEntries';

import {
  // GET
  getGeneralData,
  getUsersData,
  getProjectsData,
  getRulesData,
  getTimeEntriesData,
  // ADD
  addUserData,
  addProjectData,
  addCustomerData,
  addCustomerContactData,
  addSiteData,
  addContractorData,
  addRuleData,
  addServiceData,
  addSpecificationAData,
  addSpecificationBData,
  addTimeEntryData,
  addTimeSheetData,
  // forms
  addFormStationData,
  // SAVE
  saveRuleData,
  saveServiceData,
  saveSpecificationAData,
  saveSpecificationBData,
  saveUserData,
  saveProjectData,
  saveCustomerData,
  saveCustomerContactData,
  saveSiteData,
  saveContractorData,
  saveTimeEntryData,
  saveTimeSheetData,
  // Forms
  saveFormData,
  saveFormSectionData,
  saveFormStationData,
  saveAnswerChecksData,
  saveSiteStartData,
  // DELETE
  deleteUserData,
  deleteProjectData,
  deleteCustomerData,
  deleteCustomerContactData,
  deleteSiteData,
  deleteContractorData,
  deleteRuleData,
  deleteServiceData,
  deleteSpecificationAData,
  deleteSpecificationBData,
  deleteTimeEntryData,
  deleteFormStationData,
  // OTHERS
  duplicateFormData,
  // service project
  addServiceProjectData,
  saveServiceProjectData,
  getServiceProjectsData,
  addServiceEntryData,
  saveServiceEntryData,
  deleteServiceEntryData,
  saveWorkLogAllData,
  getServiceEntriesWeeksData,
} from '../constants/calls';

import {
  SET_EDITED_FORMS,
  SET_SNACKBAR,
  SET_ADMIN_SECTION,
  SET_AUTH,
  SET_USERS,
  SET_PROJECTS,
  SET_PROJECT_VARIABLES,
  SET_CUSTOMERS,
  SET_CUSTOMER_CONTACTS,
  SET_SITES,
  SET_CONTRACTORS,
  SET_ROLES,
  SET_GROUPS,
  SET_RIGHTS,
  SET_RULES,
  SET_RULE_VARIABLES,
  SET_TIME_ENTRIES,
  SET_PROJECT_TIME_ENTRIES,
  SET_TIME_SHEETS,
  SET_BILLINGS,
  SET_PRINT_ENTRIES,
  SET_PRINT_OPTIONS,
  SET_VISITS,
  SET_COMBINED_TIME_ENTRIES,
  //isLoading
  SET_IS_LOADING_TIME_SHEET,
  // sidebar
  SET_SIDEBAR,
  // dialogs
  SET_SERVICE_ENTRY_DIALOG,
  SET_TIME_ENTRY_CREATE_DIALOG,
  SET_TIME_ENTRY_EDIT_DIALOG,
  SET_SIGNATURE_CREATE_DIALOG,
  SET_GREETING_DIALOG,
  SET_PROJECT_VISIT_DIALOG,
  // locally
  SET_SINGLE_TIME_ENTRY,
  SET_SINGLE_SERVICE_ENTRY,
  SET_SINGLE_VISIT_ENTRY,
  // forms
  SET_QUESTION_DETAILS,
  SET_FORMS,
  SET_ANSWER_CHECKS,
  SET_ANSWER_SITE_START,
  SET_STATION_TYPES,
  // service projects
  SET_SERVICE_PROJECTS,
  SET_SERVICE_ENTRIES,
  SET_ALL_SERVICE_ENTRIES,
} from './ActionTypes';

// forms socket edit
export const setEdititedFormsAction = (data: any) => (dispatch: any) =>
  dispatch({
    type: SET_EDITED_FORMS,
    payload: data,
  });

// snackbar
export const setSnackbarAction = (snackbar: any) => (dispatch: any) =>
  dispatch({
    type: SET_SNACKBAR,
    payload: snackbar,
  });

// SET
// AUTH
export const setAuthenticationAction = (auth: any) => (dispatch: any) =>
  dispatch({
    type: SET_AUTH,
    payload: auth,
  });

// Admin section
export const setAdminAreaSectionAction = (section: any) => (dispatch: any) =>
  dispatch({
    type: SET_ADMIN_SECTION,
    payload: section,
  });

// isLoading
export const setIsLoadingTimeSheetAction = (isLoading: any) => (dispatch: any) =>
  dispatch({
    type: SET_IS_LOADING_TIME_SHEET,
    payload: isLoading,
  });
// Sidebar
export const setSidebarAction = (isOpen: any) => (dispatch: any) =>
  dispatch({
    type: SET_SIDEBAR,
    payload: isOpen,
  });
// Dialogs
export const setTimeEntryDialogCreateAction = (isOpen: any) => (dispatch: any) =>
  dispatch({
    type: SET_TIME_ENTRY_CREATE_DIALOG,
    payload: isOpen,
  });
export const setServiceEntryDialogAction = (isOpen: any) => (dispatch: any) =>
  dispatch({
    type: SET_SERVICE_ENTRY_DIALOG,
    payload: isOpen,
  });

export const setGreetingDialogAction = (isOpen: any) => (dispatch: any) =>
  dispatch({
    type: SET_GREETING_DIALOG,
    payload: isOpen,
  });

export const setTimeEntryDialogEditAction = (isOpen: any) => (dispatch: any) =>
  dispatch({
    type: SET_TIME_ENTRY_EDIT_DIALOG,
    payload: isOpen,
  });

export const setSignatureCreateDialogAction = (isOpen: any) => (dispatch: any) =>
  dispatch({
    type: SET_SIGNATURE_CREATE_DIALOG,
    payload: isOpen,
  });

export const setProjectVisitDialogAction = (isOpen: any) => (dispatch: any) =>
  dispatch({
    type: SET_PROJECT_VISIT_DIALOG,
    payload: isOpen,
  });

// Project, User etc.
// Print
export const setPrintEntriesAction = (data: any) => (dispatch: any) =>
  dispatch({
    type: SET_PRINT_ENTRIES,
    payload: data,
  });
export const setPrintOptionsAction = (data: any) => (dispatch: any) =>
  dispatch({
    type: SET_PRINT_OPTIONS,
    payload: data,
  });
// Others

export const setTimeEntryAction = (timeEntry: any) => (dispatch: any) =>
  dispatch({
    type: SET_SINGLE_TIME_ENTRY,
    payload: timeEntry,
  });
export const setServiceEntryAction = (serviceEntry: any) => (dispatch: any) =>
  dispatch({
    type: SET_SINGLE_SERVICE_ENTRY,
    payload: serviceEntry,
  });

export const setVisitEntryAction = (visitEntry: any) => (dispatch: any) =>
  dispatch({
    type: SET_SINGLE_VISIT_ENTRY,
    payload: visitEntry,
  });

// GET
export const getVisitsAction = () => (dispatch: any) =>
  getGeneralData('visit').then((data) =>
    dispatch({
      type: SET_VISITS,
      payload: data,
    })
  );

export const getTimeSheetsAction = () => (dispatch: any) =>
  getGeneralData('timeSheet').then((data) =>
    dispatch({
      type: SET_TIME_SHEETS,
      payload: data,
    })
  );

export const getProjectTimeEntriesAction = (projectId: any) => (dispatch: any) =>
  getGeneralData('timeEntry/projectTimeEntries', projectId).then((data) =>
    dispatch({
      type: SET_PROJECT_TIME_ENTRIES,
      payload: data,
    })
  );

export const getTimeEntriesAction = (week: any, year: any) => (dispatch: any) =>
  getTimeEntriesData(week, year).then((data) =>
    dispatch({
      type: SET_TIME_ENTRIES,
      payload: data,
    })
  );

export const getAllServiceEntriesAction = (week: any, year: any) => (dispatch: any) => {
  getServiceEntriesWeeksData(week, year).then((serviceEntries) => {
    const data = mergeEntries(serviceEntries, []);
    return dispatch({
      type: SET_ALL_SERVICE_ENTRIES,
      payload: data,
    });
  });
};

export const getAllTimeEntriesAction = (week: any, year: any) => (dispatch: any) => {
  Promise.all([getServiceEntriesWeeksData(week, year), getTimeEntriesData(week, year)]).then(
    ([serviceEntries, timeEntries]) => {
      const data = mergeEntries(serviceEntries, timeEntries);
      return dispatch({
        type: SET_COMBINED_TIME_ENTRIES,
        payload: data,
      });
    }
  );
};

export const getBillingsAction = () => (dispatch: any) =>
  getGeneralData('billing').then((data) =>
    dispatch({
      type: SET_BILLINGS,
      payload: data,
    })
  );

export const getUsersAction = () => (dispatch: any) =>
  getUsersData().then((data) =>
    dispatch({
      type: SET_USERS,
      payload: data,
    })
  );

export const getProjectsAction = () => (dispatch: any) =>
  getProjectsData().then((data) =>
    dispatch({
      type: SET_PROJECTS,
      payload: data,
    })
  );

export const getProjectVariablesAction = () => (dispatch: any) =>
  getGeneralData('project/variables').then((data) =>
    dispatch({
      type: SET_PROJECT_VARIABLES,
      payload: data,
    })
  );

export const getRulesAction = () => (dispatch: any) =>
  getRulesData().then((data) =>
    dispatch({
      type: SET_RULES,
      payload: data,
    })
  );

export const getRuleVariablesAction = () => (dispatch: any) =>
  getGeneralData('rule/variables').then((data) =>
    dispatch({
      type: SET_RULE_VARIABLES,
      payload: data,
    })
  );

export const getCustomersAction = () => (dispatch: any) =>
  getGeneralData('customer').then((data) =>
    dispatch({
      type: SET_CUSTOMERS,
      payload: data,
    })
  );

export const getCustomerContactsAction = () => (dispatch: any) =>
  getGeneralData('customerContact').then((data) =>
    dispatch({
      type: SET_CUSTOMER_CONTACTS,
      payload: data,
    })
  );

export const getSitesAction = () => (dispatch: any) =>
  getGeneralData('site').then((data) =>
    dispatch({
      type: SET_SITES,
      payload: data,
    })
  );

export const getContractorsAction = () => (dispatch: any) =>
  getGeneralData('contractor').then((data) =>
    dispatch({
      type: SET_CONTRACTORS,
      payload: data,
    })
  );

export const getGroupsAction = () => (dispatch: any) =>
  getGeneralData('group').then((data) =>
    dispatch({
      type: SET_GROUPS,
      payload: data,
    })
  );

export const getRightsAction = () => (dispatch: any) =>
  getGeneralData('right').then((data) =>
    dispatch({
      type: SET_RIGHTS,
      payload: data,
    })
  );

export const getRolesAction = () => (dispatch: any) =>
  getGeneralData('role').then((data) =>
    dispatch({
      type: SET_ROLES,
      payload: data,
    })
  );

/**
 * Forms
 */

export const getStationTypesAction = () => (dispatch: any) =>
  getGeneralData('stationType').then((data) =>
    dispatch({
      type: SET_STATION_TYPES,
      payload: data,
    })
  );

export const getQuestionDetailsAction = () => (dispatch: any) =>
  getGeneralData('questionDetail').then((data) =>
    dispatch({
      type: SET_QUESTION_DETAILS,
      payload: data,
    })
  );

export const getFormsAction = () => (dispatch: any) =>
  getGeneralData('form').then((data) =>
    dispatch({
      type: SET_FORMS,
      payload: data,
    })
  );

// TODO: clean me
export const getAnswerChecksAction = (formId: any) => (dispatch: any) =>
  getGeneralData('answerCheck', formId).then((data: any) =>
    dispatch({
      type: SET_ANSWER_CHECKS,
      payload: data,
    })
  );

// TODO: clean me - look at getanswerchecks, formid
export const getAnswerSiteStartAction = (projectId: any) => (dispatch: any) => {
  dispatch({
    type: SET_ANSWER_SITE_START,
    payload: null,
  });

  getGeneralData('answerSiteStart', projectId).then((data) =>
    dispatch({
      type: SET_ANSWER_SITE_START,
      payload: data,
    })
  );
};

// ADD
export const addFormStationAction = (data: any) => (dispatch: any) =>
  addFormStationData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('form').then((formsData) =>
      dispatch({
        type: SET_FORMS,
        payload: formsData,
      })
    );
  });

export const addUserAction = (data: any) => (dispatch: any) =>
  addUserData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getUsersData().then((userData) =>
      dispatch({
        type: SET_USERS,
        payload: userData,
      })
    );
  });

export const addProjectAction = (data: any) => (dispatch: any) =>
  addProjectData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getProjectsData().then((projectData) =>
      dispatch({
        type: SET_PROJECTS,
        payload: projectData,
      })
    );
  });

export const addRuleAction = (data: any) => (dispatch: any) =>
  addRuleData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getRulesData().then((rulesData) =>
      dispatch({
        type: SET_RULES,
        payload: rulesData,
      })
    );
  });

export const addCustomerAction = (data: any) => (dispatch: any) =>
  addCustomerData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('customer').then((cutonerData) =>
      dispatch({
        type: SET_CUSTOMERS,
        payload: cutonerData,
      })
    );
  });

export const addCustomerContactAction = (data: any) => (dispatch: any) =>
  addCustomerContactData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('customerContact').then((customerContactsData) =>
      dispatch({
        type: SET_CUSTOMER_CONTACTS,
        payload: customerContactsData,
      })
    );
  });

export const addSiteAction = (data: any) => (dispatch: any) =>
  addSiteData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('site').then((sitesData) =>
      dispatch({
        type: SET_SITES,
        payload: sitesData,
      })
    );
  });

export const addContractorAction = (data: any) => (dispatch: any) =>
  addContractorData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('contractor').then((contactorData) =>
      dispatch({
        type: SET_CONTRACTORS,
        payload: contactorData,
      })
    );
  });

// RULES
export const addServiceAction = (name: any) => (dispatch: any) =>
  addServiceData(name).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('rule/variables').then((data) =>
      dispatch({
        type: SET_RULE_VARIABLES,
        payload: data,
      })
    );
  });

export const addSpecificationAAction = (name: any) => (dispatch: any) =>
  addSpecificationAData(name).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('rule/variables').then((data) =>
      dispatch({
        type: SET_RULE_VARIABLES,
        payload: data,
      })
    );
  });

export const addSpecificationBAction = (name: any) => (dispatch: any) =>
  addSpecificationBData(name).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('rule/variables').then((data) =>
      dispatch({
        type: SET_RULE_VARIABLES,
        payload: data,
      })
    );
  });

export const addTimeEntryAction = (data: any) => (dispatch: any) =>
  addTimeEntryData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getTimeEntriesData(data.week, data.year).then((entriesData) =>
      dispatch({
        type: SET_TIME_ENTRIES,
        payload: entriesData,
      })
    );
  });

export const addTimeSheetAction = (data: any) => (dispatch: any) =>
  addTimeSheetData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    dispatch({
      type: SET_IS_LOADING_TIME_SHEET,
      payload: true,
    });
    getGeneralData('timeSheet').then((timeSheetsData) => {
      dispatch({
        type: SET_TIME_SHEETS,
        payload: timeSheetsData,
      });
      dispatch({
        type: SET_IS_LOADING_TIME_SHEET,
        payload: false,
      });
    });
  });

// SAVE
export const saveRuleAction = (rule: any) => (dispatch: any) =>
  saveRuleData(rule).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getRulesData().then((data) =>
      dispatch({
        type: SET_RULES,
        payload: data,
      })
    );
  });

export const saveServiceAction = (service: any) => (dispatch: any) =>
  saveServiceData(service).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('rule/variables').then((data) =>
      dispatch({
        type: SET_RULE_VARIABLES,
        payload: data,
      })
    );
  });

export const saveSpecificationAAction = (specificationA: any) => (dispatch: any) =>
  saveSpecificationAData(specificationA).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('rule/variables').then((data) =>
      dispatch({
        type: SET_RULE_VARIABLES,
        payload: data,
      })
    );
  });

export const saveSpecificationBAction = (specificationB: any) => (dispatch: any) =>
  saveSpecificationBData(specificationB).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('rule/variables').then((data) =>
      dispatch({
        type: SET_RULE_VARIABLES,
        payload: data,
      })
    );
  });

export const saveTimeEntryAction = (timeEntry: any, week: any, year: any) => (dispatch: any) =>
  saveTimeEntryData(timeEntry).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getTimeEntriesData(week, year).then((data) =>
      dispatch({
        type: SET_TIME_ENTRIES,
        payload: data,
      })
    );
  });

export const saveTimeSheetAction = (timeEntry: any) => (dispatch: any) =>
  saveTimeSheetData(timeEntry).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('timeSheet').then((data) =>
      dispatch({
        type: SET_TIME_SHEETS,
        payload: data,
      })
    );
  });

export const saveTimeSheetAllAction =
  ({ userId, week, year }: any) =>
  (dispatch: any) =>
    saveWorkLogAllData({ userId, week, year }).then((result) => {
      dispatch({
        type: SET_SNACKBAR,
        payload: { open: true, message: result },
      });

      dispatch(getAllTimeEntriesAction(week, year));
    });

export const saveUserAction = (user: any) => (dispatch: any) =>
  saveUserData(user).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getUsersData().then((data) =>
      dispatch({
        type: SET_USERS,
        payload: data,
      })
    );
  });

export const saveProjectAction = (project: any) => (dispatch: any) =>
  saveProjectData(project).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getProjectsData().then((data) =>
      dispatch({
        type: SET_PROJECTS,
        payload: data,
      })
    );
  });

export const saveCustomerAction = (customer: any) => (dispatch: any) =>
  saveCustomerData(customer).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('customer').then((data) =>
      dispatch({
        type: SET_CUSTOMERS,
        payload: data,
      })
    );
  });

export const saveCustomerContactAction = (customerContact: any) => (dispatch: any) =>
  saveCustomerContactData(customerContact).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('customerContact').then((data) =>
      dispatch({
        type: SET_CUSTOMER_CONTACTS,
        payload: data,
      })
    );
  });

export const saveSiteAction = (site: any) => (dispatch: any) =>
  saveSiteData(site).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('site').then((data) =>
      dispatch({
        type: SET_SITES,
        payload: data,
      })
    );
  });

export const saveContractorAction = (contractor: any) => (dispatch: any) =>
  saveContractorData(contractor).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('contractor').then((data) =>
      dispatch({
        type: SET_CONTRACTORS,
        payload: data,
      })
    );
  });

// Forms
export const saveFormAction = (data: any) => (dispatch: any) =>
  saveFormData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('form').then((formData) =>
      dispatch({
        type: SET_FORMS,
        payload: formData,
      })
    );
  });

export const saveFormSectionAction = (data: any) => (dispatch: any) =>
  saveFormSectionData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('form').then((formData) =>
      dispatch({
        type: SET_FORMS,
        payload: formData,
      })
    );
  });

export const saveFormStationAction = (data: any) => (dispatch: any) =>
  saveFormStationData(data).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('form').then((formData) =>
      dispatch({
        type: SET_FORMS,
        payload: formData,
      })
    );
  });

export const saveAnswerChecksAction =
  (answerChecks: any, formId: any, formStationTypeId: any) => (dispatch: any) =>
    saveAnswerChecksData(answerChecks, formId, formStationTypeId).then((result) => {
      dispatch({
        type: SET_SNACKBAR,
        payload: { open: true, message: result },
      });

      getGeneralData('answerCheck', formId).then((data) => {
        dispatch({
          type: SET_ANSWER_CHECKS,
          payload: data,
        });
      });
    });
export const saveAnswerSiteStartAction =
  (answerSiteStart: any, projectId: any) => (dispatch: any) =>
    saveSiteStartData(answerSiteStart, projectId).then((result) => {
      dispatch({
        type: SET_SNACKBAR,
        payload: { open: true, message: result },
      });

      getGeneralData('answerSiteStart', projectId).then((data) =>
        dispatch({
          type: SET_ANSWER_SITE_START,
          payload: data,
        })
      );
    });

// DELETE
export const deleteUserAction = (id: any) => (dispatch: any) =>
  deleteUserData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getUsersData().then((data) =>
      dispatch({
        type: SET_USERS,
        payload: data,
      })
    );
  });

export const deleteProjectAction = (id: any) => (dispatch: any) =>
  deleteProjectData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getProjectsData().then((data) =>
      dispatch({
        type: SET_PROJECTS,
        payload: data,
      })
    );
  });

export const deleteRuleAction = (id: any) => (dispatch: any) =>
  deleteRuleData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getRulesData().then((data) =>
      dispatch({
        type: SET_RULES,
        payload: data,
      })
    );
  });

export const deleteCustomerAction = (id: any) => (dispatch: any) =>
  deleteCustomerData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('customer').then((data) =>
      dispatch({
        type: SET_CUSTOMERS,
        payload: data,
      })
    );
  });

export const deleteCustomerContactAction = (customerContact: any) => (dispatch: any) =>
  deleteCustomerContactData(customerContact.id) // todo: change this to id ??
    .then((result) => {
      dispatch({
        type: SET_SNACKBAR,
        payload: { open: true, message: result },
      });
      getGeneralData('customerContact').then((data) =>
        dispatch({
          type: SET_CUSTOMER_CONTACTS,
          payload: data,
        })
      );
    });

export const deleteSiteAction = (site: any) => (dispatch: any) =>
  deleteSiteData(site.id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('site').then((data) =>
      dispatch({
        type: SET_SITES,
        payload: data,
      })
    );
  });

export const deleteContractorAction = (id: any) => (dispatch: any) =>
  deleteContractorData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('contractor').then((data) =>
      dispatch({
        type: SET_CONTRACTORS,
        payload: data,
      })
    );
  });

export const deleteServiceAction = (id: any) => (dispatch: any) =>
  deleteServiceData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('rule/variables').then((data) =>
      dispatch({
        type: SET_RULE_VARIABLES,
        payload: data,
      })
    );
  });

export const deleteSpecificationAAction = (id: any) => (dispatch: any) =>
  deleteSpecificationAData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('rule/variables').then((data) =>
      dispatch({
        type: SET_RULE_VARIABLES,
        payload: data,
      })
    );
  });

export const deleteSpecificationBAction = (id: any) => (dispatch: any) =>
  deleteSpecificationBData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getGeneralData('rule/variables').then((data) =>
      dispatch({
        type: SET_RULE_VARIABLES,
        payload: data,
      })
    );
  });

export const deleteTimeEntryAction = (id: any, week: any, year: any) => (dispatch: any) =>
  deleteTimeEntryData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getTimeEntriesData(week, year).then((data) =>
      dispatch({
        type: SET_TIME_ENTRIES,
        payload: data,
      })
    );
  });

export const deleteFormStationAction = (id: any) => (dispatch: any) =>
  deleteFormStationData(id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    // TODO: tried to update AnswerCheck -> so in CheckHeader there is the correct Numbers of Question under the STATION button displayed
    // getAnswerChecksAction(projectId);

    getGeneralData('form').then((formsData) =>
      dispatch({
        type: SET_FORMS,
        payload: formsData,
      })
    );
  });

export const duplicateFormAction = (formId: any) => (dispatch: any) =>
  duplicateFormData(formId).then((result: string) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });

    getGeneralData('form').then((formsData) =>
      dispatch({
        type: SET_FORMS,
        payload: formsData,
      })
    );
  });

// service projects
export const getServiceProjectsAction = () => (dispatch: any) =>
  getServiceProjectsData().then((data) =>
    dispatch({
      type: SET_SERVICE_PROJECTS,
      payload: data,
    })
  );

export const addServiceProjectAction = (data: any) => (dispatch: any) =>
  addServiceProjectData(data).then((result: any) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getServiceProjectsData().then((serviceProjectData: any) =>
      dispatch({
        type: SET_SERVICE_PROJECTS,
        payload: serviceProjectData,
      })
    );
  });
export const saveServiceProjectAction = (data: any) => (dispatch: any) =>
  saveServiceProjectData(data).then((result: any) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    getServiceProjectsData().then((fetchedData: any) => {
      console.log('fetchedData', fetchedData);
      dispatch({
        type: SET_SERVICE_PROJECTS,
        payload: fetchedData,
      });
    });
  });

// service entries

export const getServiceProjectEntriesAction = (serviceProjectId: number) => (dispatch: any) =>
  getGeneralData('serviceEntry/serviceProjectEntries', String(serviceProjectId)).then(
    (entriesData) =>
      dispatch({
        type: SET_SERVICE_ENTRIES,
        payload: { serviceEntries: entriesData, serviceProjectId },
      })
  );

export const addServiceEntryAction = (serviceEntry: any) => (dispatch: any) =>
  addServiceEntryData(serviceEntry).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
    dispatch(getServiceProjectEntriesAction(serviceEntry.serviceProjectId));
  });

export const saveServiceEntryAction = (serviceEntry: any) => (dispatch: any) =>
  saveServiceEntryData(serviceEntry).then((result) => {
    dispatch(getServiceProjectEntriesAction(serviceEntry.serviceProjectId));
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
  });

export const deleteServiceEntryAction = (serviceEntry: any) => (dispatch: any) =>
  deleteServiceEntryData(serviceEntry.id).then((result) => {
    dispatch({
      type: SET_SNACKBAR,
      payload: { open: true, message: result },
    });
  });
