import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { Box } from '@mui/material';
import PageLogin from '../../Pages/PageLogin/PageLogin';
import PageLogout from '../../Pages/PageLogout/PageLogout';
import RoutesHandler from '../../Molecules/RoutesHandler/RoutesHandler';
import Snackbar from '../../Molecules/Snackbar/Snackbar';
import makeRequest from '../../../helpers/Requests';
import { setAuthenticationAction, getRightsAction, getUsersAction } from '../../../actions';
import { inDevelopmentMode } from '../../../constants/api';

const styles = {
  cssDiv: {
    paddingTop: '130px',
    backgroundColor: '#fff',
    minHeight: '100vh',
  },
};

export const Base = React.memo(
  ({ match: { path, url }, history, location }: RouteComponentProps) => {
    const auth = useSelector((state: any) => state.rootReducer.auth);
    // const users = useSelector((state: any) => (filter: any) => userSelector(state, filter));
    const dispatch = useDispatch();
    const setAuthentication = (auth: any) => dispatch(setAuthenticationAction(auth));
    const getRights = (...args: any) => dispatch(getRightsAction());
    const getUsers = (...args: any) => dispatch(getUsersAction());
    // const { users, auth, classes, location, history } = this.props;
    // redirect to overview if only slash

    useEffect(() => {
      const checkAuth = async () => {
        const result = await makeRequest('GET', 'authentication');
        if (result.user) {
          getRights();
          getUsers();
          setAuthentication(result.user);

          if (location.pathname === '/login') {
            // if not in local development mode, so that we can login
            if (!inDevelopmentMode) {
              history.push('/overview');
            }
          }
        } else {
          history.push('/login');
        }
      };
      checkAuth();
    }, []);
    if (location.pathname === '/') {
      history.push('/overview');
    }
    return (
      <>
        <Box sx={styles.cssDiv}>
          <Route path="/login" exact component={PageLogin} />
          <Route path="/logout" exact component={PageLogout} />
          {auth && <Fragment>{RoutesHandler}</Fragment>}
        </Box>
        <Snackbar />
      </>
    );
  }
);
