import { Grid } from '@mui/material';
import Done from '@mui/icons-material/Done';
import moment from 'moment';
import React, { Fragment, memo } from 'react';
import { connect } from 'react-redux';
import { contractorSelector, userSelector } from '../../../../../helpers/selectors';
import TextFieldForm from '../../../../Atoms/TextField/TextFieldForm';
import TooltipIcon from '../../../../Atoms/TooltipIcon/TooltipIcon';
import RadiosForm from '../../../Radios/RadiosForm';

const mapStateToProps = (state: any) => ({
  users: (filter: any) => userSelector(state, filter),
  contractors: (filter: any) => contractorSelector(state, filter),
});

interface QuestionAdditionsProps {
  id: number;
  answer: any;
  roleCheck: boolean;
  required: boolean;
  users?: (...args: any[]) => any;
  contractors?: (...args: any[]) => any;
  formCompleted?: boolean;
  value?: number;
}
const QuestionAdditions: React.SFC<QuestionAdditionsProps> = memo(
  ({ id, required, value = 0, answer, users, contractors, roleCheck, formCompleted = false }) => {
    // edited by
    const user =
      answer.userId && users
        ? users({ include: { userId: answer.userId }, option: { plain: true } })
        : null;
    // last edited by
    const lastUser =
      answer.lastUserId && users
        ? users({
            include: { userId: answer.lastUserId },
            option: { plain: true },
          })
        : null;
    const timeStamp = moment(answer.createdAt).format('YYYY-MM-DD HH:mm');
    const lastTimeStamp = moment(answer.lastCreatedAt).format('YYYY-MM-DD HH:mm');
    let showUser = 'Noch nicht bearbeitet';
    if (user && contractors) {
      showUser = `geprüft von: ${user.lastName}, ${user.firstName.charAt(0)}., ${
        contractors({
          include: { contractorId: user.contractorId },
          option: { plain: true },
        }).shortName
      } - ${timeStamp}`;
      if (lastUser) {
        showUser = `${showUser}; 
      zuvor geprüft von: ${lastUser.lastName}, ${lastUser.firstName.charAt(0)}., ${
          contractors({
            include: { contractorId: lastUser.contractorId },
            option: { plain: true },
          }).shortName
        } - ${lastTimeStamp}`;
      }
    }
    return (
      <Fragment>
        {value === 1 && (
          <Fragment>
            <TextFieldForm
              size={3}
              formQuestion={true}
              name={`${id}textOne`}
              label="Anmerkung"
              multiline={true}
              rowsMax={3}
              disabled={!required || !roleCheck || formCompleted}
            />
          </Fragment>
        )}
        {value === 0 && (
          <Fragment>
            <Grid item={true} md={3}>
              <RadiosForm
                name={`${id}givenFrom`}
                disabled={!required || !roleCheck || formCompleted}
                options={[
                  { label: 'STOPA', value: 1 },
                  { label: 'GU', value: 2 },
                  { label: 'Kunde', value: 3 },
                ]}
                size={12}
              />
              <TextFieldForm
                size={12}
                formQuestion={true}
                name={`${id}textOne`}
                label="Anmerkung"
                multiline={true}
                rowsMax={3}
                disabled={!required || !roleCheck || formCompleted}
                gridStyles={{ marginBottom: '16px' }}
              />
            </Grid>
          </Fragment>
        )}
        <Grid item={true} md={1} style={{ paddingTop: 24, marginLeft: 'auto' }}>
          <TooltipIcon title={showUser} icon={<Done />} />
        </Grid>
      </Fragment>
    );
  }
);

export default connect(mapStateToProps, null)(QuestionAdditions);
