import React, { PureComponent } from 'react';
import { Stack } from '@mui/material';
import { connect } from 'react-redux';
import ProjectCreate from '../../../../Molecules/AdminAreaCreates/ProjectCreate';
import ProjectTable from '../../../../Molecules/Tables/ProjectTable';
import { ADMIN, PROJECT_MANAGER } from '../../../../../../../shared/src/constants/general';
import AdminOuterWrapper from '../../../../Templates/AdminWrappers/AdminOuterWrapper';
import Search from '../../../../Organisms/NavBar/components/Search';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  projects: state.rootReducer.projects,
  customers: state.rootReducer.customers,
  sites: state.rootReducer.sites,
});

type PageProjectOverviewProps = {
  auth?: any;
  projects?: any[];
  customers?: any[];
  sites?: any[];
};
class PageProjectOverview extends PureComponent<PageProjectOverviewProps, {}> {
  render() {
    const { auth, projects, customers, sites } = this.props;
    return (
      <AdminOuterWrapper>
        {auth.rightId <= PROJECT_MANAGER && <ProjectCreate />}
        {auth.rightId === ADMIN && projects && customers && sites && (
          <AdminSettingsWrapper editWrapper>
            <Search searchType="project" />
          </AdminSettingsWrapper>
        )}
        <ProjectTable />
      </AdminOuterWrapper>
    );
  }
}
export default connect(mapStateToProps, null)(PageProjectOverview);
