import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid } from '@mui/material';
import { reduxForm } from 'redux-form';
import moment from 'moment';
import Form from '../../../../../../Atoms/Form/Form';
import AutocompleteForm from '../../../../../../Molecules/Autocomplete/AutocompleteForm';
import TextFieldForm from '../../../../../../Atoms/TextField/TextFieldForm';
import Typography from '../../../../../../Atoms/Typography/Typography';
import UserTable from '../../../../../../Organisms/UserSelectorTable/UserTable';
// import validate from '../../../../../../helpers/validation';
import {
  ROLE_MECHANIC,
  ROLE_ELECTRICIAN,
  DEPARTMENT_MANAGER,
  PROJECT_MANAGER,
  MECHANIC,
  FORM_TYPE_SITE_START,
} from '../../../../../../../../../shared/src/constants/general';
import { getAnswerSiteStartAction } from '../../../../../../../actions';
import {
  userSelector,
  customerSelector,
  siteSelector,
  projectSelector,
} from '../../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  formTypes: state.rootReducer.formTypes,
  siteStartHeader: state.form.siteStartHeader,
  forms: state.rootReducer.forms,
  answerSiteStarts: state.rootReducer.answerSiteStarts,
  siteStartGeneral: state.form.siteStartGeneral,
  users: (filter: any) => userSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
});
const mapDispatchToProps = (dispatch: any) => ({
  getAnswerSiteStart: (projectId: any) => dispatch(getAnswerSiteStartAction(projectId)),
});

type SiteStartHeaderProps = {
  initialize: (...args: any[]) => any;
  formTypes: any[];
  forms: any[];
  siteStartHeader?: any;
  formId: number;
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  customers: (...args: any[]) => any;
};

type SiteStartHeaderState = {
  form: any;
  project: any;
};

class SiteStartHeader extends React.Component<SiteStartHeaderProps, SiteStartHeaderState> {
  static defaultProps = {
    siteStartHeader: null,
  };

  state = {
    form: { completed: false },
    project: { number: null, customerId: null, siteId: null },
  };

  componentDidMount() {
    // Kunde LagerTyp Ort/Land FAB AUftragsNR WA-NR PL-STOPA PL-TRUMP PL-Kunde Monteur mech. Monteru elek
    const { initialize, projects, users, forms, formId } = this.props;
    const form = forms.find((f) => f.id === formId);
    this.setState({ form });
    const completedAt = form.completed
      ? moment(form.updatedAt, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
      : null;
    const completedBy = form.completedBy
      ? users({
          include: { userId: form.completedBy },
        }).label
      : null;
    const formDate = moment(form.formDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
    //  moment(form.formDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
    const project = projects({
      include: { projectId: form.projectId },
      option: { plain: true },
    });
    this.setState({ project });
    const responsibleMechanic = form.responsibleMechanicId
      ? users({
          include: { userId: form.responsibleMechanicId },
        })
      : null;

    const responsibleElectrician = form.responsibleElectricianId
      ? users({
          include: { userId: form.responsibleElectricianId },
        })
      : null;
    initialize({
      ...project,
      formDate,
      completedBy,
      completedAt,
      formName: form.name,
      responsibleMechanic,
      responsibleElectrician,
      responsibleContractor: form.responsibleContractor,
    });
  }
  getSortedProjectUser = () => {
    const { users, siteStartHeader } = this.props;
    if (siteStartHeader) {
      const projectDepartmentManager = users({
        include: {
          userId: siteStartHeader.values.userIds,
          rightId: DEPARTMENT_MANAGER,
        },
        option: { plain: true },
      });
      const projectManager = users({
        include: {
          userId: siteStartHeader.values.userIds,
          rightId: PROJECT_MANAGER,
        },
        option: { plain: true },
      });
      const projectUser = users({
        include: {
          userId: siteStartHeader.values.userIds,
          rightId: MECHANIC,
        },
        option: { plain: true },
      });
      return projectDepartmentManager.concat(projectManager, projectUser);
    }
    return [];
  };
  render() {
    const { users, customers, sites, siteStartHeader, formTypes } = this.props;
    const { form, project } = this.state;
    const formName = formTypes.find((fT) => fT.id === FORM_TYPE_SITE_START).name;
    return (
      <Fragment>
        {siteStartHeader && (
          <Form>
            <Typography
              size={12}
              value={`Protokoll ${formName}`}
              variant="subtitle1"
              style={{ fontSize: '2rem' }}
            />
            <Grid container spacing={3} item md={12}>
              <Typography
                size={1}
                caption="Projekt-Nr"
                // type="text"
                value={project ? `${project.number}` : ''}
                variant="h5"
                captionType="top"
              />
              <Typography
                size={3}
                caption="Kunde"
                // type="text"
                value={
                  project ? customers({ include: { customerId: project.customerId } }).label : ''
                }
                variant="h5"
                captionType="top"
              />
              <Typography
                size={5}
                caption="Baustelle"
                // type="text"
                value={project ? sites({ include: { siteId: project.siteId } }).label : ''}
                variant="h5"
                captionType="top"
              />
            </Grid>
            <Grid container spacing={3} item md={12}>
              <TextFieldForm name="formName" label="Protokoll Name" type="text" disabled />
              <TextFieldForm name="completedBy" label="Abgeschlossen von" type="text" disabled />
              <TextFieldForm name="completedAt" label="Abgeschlossen am" type="date" disabled />
              <TextFieldForm name="formDate" label="Protokolltag" type="date" />
            </Grid>
            <AutocompleteForm
              size={3}
              name="responsibleMechanic"
              options={users({
                include: {
                  userId: siteStartHeader.values.userIds,
                  role: ROLE_MECHANIC,
                },
              })}
              label="Verantwortlicher Mechaniker"
              isDisabled={form.completed}
            />
            <AutocompleteForm
              size={3}
              name="responsibleElectrician"
              options={users({
                include: {
                  userId: siteStartHeader.values.userIds,
                  role: ROLE_ELECTRICIAN,
                },
              })}
              label="Verantwortlicher Elektriker"
              isDisabled={form.completed}
            />
            <TextFieldForm
              size={3}
              name="responsibleContractor"
              label="Ansprechpartner"
              disabled={form.completed}
            />

            <Grid
              container
              spacing={3}
              item
              md={12}
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: '2rem' }}
            >
              <Typography size={12} value="Projekt Mitarbeiter" variant="subtitle1" />
              <UserTable
                users={this.getSortedProjectUser()}
                handleDelete={() => {}}
                withOperation={false}
                withTel
                withRole
                withRight
              />
            </Grid>
          </Form>
        )}
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'siteStartHeader',
  })(withRouter<any, any>(SiteStartHeader))
);
