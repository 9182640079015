import React, { PureComponent, Fragment } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps, change as changeAction } from 'redux-form';
import { Grid } from '@mui/material';
import moment from 'moment';
import Form from '../../../../../Atoms/Form/Form';
import DialogTimeEntryWrapper from '../components/ServiceEntryWrapper';
import TextFieldForm from '../../../../../Atoms/TextField/TextFieldForm';
import Button from '../../../../../Atoms/Button/Button';
import TimeEntryDateTime from '../components/ServiceEntryDateTime';
import ServiceEntryRules from '../components/ServiceEntryRules';
import ServiceEntryProject from '../components/ServiceEntryProject';
import ServiceEntryUser from '../components/ServiceEntryUser';
import { isNil } from 'lodash';

import {
  SERVICE_TYPE_BREAK,
  SERVICE_BREAK,
  PROJECT_MANAGER,
  GROUP_SERVICE,
  SERVICE_PROJECT_STATE_OPEN,
  SPECIFICATIONA_DEPARTURE,
} from '../../../../../../../../shared/src/constants/general';
import {
  deleteServiceEntryAction,
  saveServiceEntryAction,
  addServiceEntryAction,
  setServiceEntryAction,
  setSnackbarAction,
  setServiceEntryDialogAction,
} from '../../../../../../actions';
import {
  ruleSelector,
  userSelector,
  groupSelector,
  serviceProjectSelector,
  roleSelector,
  serviceTypeSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
  billingTypeSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  rules: (filter: any) => ruleSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  serviceEntryDialogOpen: state.rootReducer.serviceEntryDialogOpen,
  serviceEntry: state.rootReducer.serviceEntry,
  serviceProjects: (filter: any) => serviceProjectSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  serviceTypes: (filter: any) => serviceTypeSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  billingTypes: (filter: any) => billingTypeSelector(state, filter),
  // redux-form
  // self
  users: (filter: any) => userSelector(state, filter),
  dialogServiceEntry: state.form.dialogServiceEntry,
  // other
});
const mapDispatchToProps = (dispatch: any) => ({
  saveServiceEntry: (serviceEntry: any, week: any, year: any) =>
    dispatch(saveServiceEntryAction(serviceEntry)),
  addServiceEntry: (data: any) => dispatch(addServiceEntryAction(data)),
  setServiceEntry: (serviceEntry: any) => dispatch(setServiceEntryAction(serviceEntry)),
  setSnackbar: (snackbar: any) => dispatch(setSnackbarAction(snackbar)),
  setServiceEntryDialog: (open: any) => dispatch(setServiceEntryDialogAction(open)),
  deleteServiceEntry: (serviceEntryId: any) => dispatch(deleteServiceEntryAction(serviceEntryId)),
  // redux-form
  change: (field: any, value: any, form?: any) => dispatch(changeAction(field, value, form)),
});

interface ComponentDispatchProps {
  saveServiceEntry: (...args: any[]) => any;
  addServiceEntry: (...args: any[]) => any;
  setServiceEntry: (...args: any[]) => any;
  setSnackbar: (...args: any[]) => any;
  deleteServiceEntry: (...args: any[]) => any;
  change: (...args: any[]) => any;
  setServiceEntryDialog: (...args: any[]) => any;
}

interface ComponentStateProps {
  rules: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  serviceProjects: (...args: any[]) => any;
  serviceEntryDialogOpen: boolean;
  users: (...args: any[]) => any;
  dialogServiceEntry?: any;
  serviceEntry?: any;
  roles: (...args: any[]) => any;
  serviceTypes: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
  billingTypes: (...args: any[]) => any;
}
interface ComponentOwnProps {}
type ComponentProps = ComponentOwnProps & ComponentDispatchProps & ComponentStateProps;

const getTimeStartFormValue = (timeStart: string | null) => {
  if (isNil(timeStart)) return null;
  const timeStartValue = moment(timeStart, 'HH:mm:ss').format('HH:mm');
  return {
    label: `${timeStartValue}`,
    value: `${timeStartValue}`,
  };
};

const getTimeEndFormValue = (timeEnd: string | null) => {
  if (isNil(timeEnd)) return null;
  const timeEndValue = moment(timeEnd, 'HH:mm:ss').format('HH:mm');
  return timeEndValue === '23:59'
    ? { label: '24:00', value: `${timeEndValue}` }
    : { label: `${timeEndValue}`, value: `${timeEndValue}` };
};

const getTimeOptions = (serviceEntry: any) => [
  ...(serviceEntry?.isExtraWork ? ['extraWork'] : []),
  ...(serviceEntry?.isFlatRateService ? ['flatRate'] : []),
  ...(serviceEntry?.isOvernightStay ? ['overnightStay'] : []),
  ...(serviceEntry?.isMaterialSmallUsed ? ['materialSmall'] : []),
  ...(serviceEntry?.isMaterialLargeUsed ? ['materialLarge'] : []),
];

class DialogServiceEntry extends PureComponent<
  ComponentProps & InjectedFormProps<{}, ComponentProps>
> {
  componentWillMount() {
    const {
      initialize,
      groups,
      serviceEntry,
      rules,
      serviceProjects,
      users,
      roles,
      serviceTypes,
      services,
      specificationAs,
      specificationBs,
      billingTypes,
    } = this.props;

    // set the current week as predefined
    if (serviceEntry?.id) {
      const rule = rules({
        include: {
          ruleId: serviceEntry.ruleId,
        },
      });
      initialize({
        id: serviceEntry.id,
        serviceProject: serviceProjects({
          include: { serviceProjectId: serviceEntry.serviceProjectId },
        }),
        user: users({ include: { userId: serviceEntry.userId } }),
        group: groups({ include: { groupId: serviceEntry.groupId } }).label,
        role: roles({ include: { roleId: serviceEntry.roleId } }),
        // get from rule
        serviceType: serviceTypes({
          include: { serviceTypeId: rule.serviceTypeId },
        }),

        service: services({ include: { serviceId: rule.serviceId } }),
        specificationA: rule.specificationAId
          ? specificationAs({
              include: { specificationAId: rule.specificationAId },
            })
          : null,
        specificationB: rule.specificationBId
          ? specificationBs({
              include: { specificationBId: rule.specificationBId },
            })
          : null,
        billingType: billingTypes({
          include: { billingTypeId: serviceEntry.billingTypeId },
        }),
        // billing: billings({ include: { billingId: serviceEntry.billingId } }),
        commentBilling: serviceEntry.commentBilling,
        commentPublic: serviceEntry.commentPublic,
        commentPrivate: serviceEntry.commentPrivate,
        spareParts: serviceEntry.spareParts,
        licensePlate: serviceEntry.licensePlate,
        date: serviceEntry.date,
        timeStart: getTimeStartFormValue(serviceEntry?.timeStart),
        timeEnd: getTimeEndFormValue(serviceEntry?.timeEnd),
        station: serviceEntry.station,
        distance: serviceEntry.distance,
        timeOptions: getTimeOptions(serviceEntry),
        // ...serviceEntryVisits,
      });
    } else {
      initialize({
        id: null,
        serviceProject: serviceEntry?.serviceProjectId
          ? serviceProjects({
              include: { serviceProjectId: serviceEntry.serviceProjectId },
            })
          : null,
        user: null,
        group: groups({ include: { groupId: GROUP_SERVICE } }).label,
        role: null,
        serviceType: null,
        service: null,
        specificationA: null,
        specificationB: null,
        commentPublic: serviceEntry?.commentPublic ?? null,
        commentPrivate: serviceEntry?.commentPrivate ?? null,
        spareParts: serviceEntry?.spareParts ?? null,
        licensePlate: serviceEntry?.licensePlate ?? null,
        date: moment(serviceEntry?.date ?? undefined).format('YYYY-MM-DD'),
        timeStart: getTimeStartFormValue(serviceEntry?.timeStart) ?? null,
        timeEnd: getTimeEndFormValue(serviceEntry?.timeEnd) ?? null,
        station: serviceEntry?.station ?? null,
        distance: serviceEntry?.distance ?? null,
        timeOptions: getTimeOptions(serviceEntry),
      });
    }
  }
  submitForm = (values: any) => {
    const { rules, addServiceEntry, saveServiceEntry, setSnackbar } = this.props;
    const {
      serviceProject,
      user,
      role,
      serviceType,
      service,
      specificationA,
      specificationB,
      billingType,
      commentPublic,
      commentPrivate,
      commentBilling,
      spareParts,
      licensePlate,
      date,
      timeStart,
      timeEnd,
      station,
      distance,
      timeOptions,
      id,
    } = values;

    const serviceProjectId = parseInt(serviceProject.value, 10);
    const userId = parseInt(user.value, 10);
    const roleId = parseInt(role.value, 10);
    const serviceTypeId = parseInt(serviceType.value, 10);
    const isExtraWork = timeOptions.includes('extraWork');
    const isFlatRateService = timeOptions.includes('flatRate');
    const isOvernightStay = timeOptions.includes('overnightStay');
    const isMaterialSmallUsed = timeOptions.includes('materialSmall');
    const isMaterialLargeUsed = timeOptions.includes('materialLarge');

    // if break, set the service id to break
    let serviceId = null;
    if (serviceTypeId === SERVICE_TYPE_BREAK) {
      serviceId = SERVICE_BREAK;
    } else {
      serviceId = service ? parseInt(service.value, 10) : null;
    }
    const specificationAId = specificationA ? parseInt(specificationA.value, 10) : null;
    const specificationBId = specificationB ? parseInt(specificationB.value, 10) : null;

    const billingTypeId = billingType ? parseInt(billingType.value, 10) : null;

    const rule = rules({
      include: {
        roleId,
        groupId: GROUP_SERVICE,
        serviceTypeId,
        serviceId,
        specificationAId,
        specificationBId,
      },
    });
    if (rule === null) {
      setSnackbar({ open: true, message: 'Es wurde keine Regel ausgewählt.' });
      return;
    }

    // get the timeValues from autocomplete form data
    const timeStartValue = timeStart.value;
    const timeEndValue = timeEnd.value;
    const groupId = GROUP_SERVICE;

    const serviceEntry = {
      id,
      serviceProjectId,
      userId,
      groupId,
      roleId,
      ruleId: rule.id,
      commentPublic,
      commentPrivate,
      commentBilling,
      spareParts,
      licensePlate,
      date,
      timeStart: timeStartValue,
      timeEnd: timeEndValue,
      station,
      distance,
      billingTypeId,
      isExtraWork,
      isFlatRateService,
      isOvernightStay,
      isMaterialSmallUsed,
      isMaterialLargeUsed,
    };

    if (serviceEntry.id) {
      saveServiceEntry(serviceEntry);
    } else {
      addServiceEntry(serviceEntry);
    }
    this.handleClose();
  };
  handleClose = () => {
    const { setServiceEntryDialog, reset, setServiceEntry } = this.props;
    reset();
    setServiceEntryDialog(false);
    setServiceEntry(null);
  };
  handleDelete = () => {
    const { deleteServiceEntry, serviceEntry } = this.props;
    deleteServiceEntry(serviceEntry);
    this.handleClose();
  };
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field, null));
  };
  handleChange = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field.name, field.value));
  };

  render() {
    const {
      serviceEntryDialogOpen,
      // setTimeEntryDialogCreate,
      users,
      dialogServiceEntry,
      // redux-form
      handleSubmit,
      serviceEntry,
      serviceProjects,
    } = this.props;
    if (!dialogServiceEntry) {
      return <></>;
    }

    let userRightId;
    let isDisabled;
    if (dialogServiceEntry) {
      const { user, serviceProject } = dialogServiceEntry.values;
      userRightId = user
        ? users({ include: { userId: user.value }, option: { plain: true } }).rightId
        : null;

      if (serviceProject?.value) {
        const serviceProjectId = parseInt(serviceProject.value, 10);

        const selectedServiceProject = serviceProjects({
          include: { serviceProjectId: serviceProjectId },
          option: { plain: true },
        });
        // service entries can only be updated if the serviceProject is open or
        // if the service entry is "ABREISE" and not exported to ZEUS yet
        isDisabled =
          ![SERVICE_PROJECT_STATE_OPEN].includes(selectedServiceProject.state) &&
          !(
            serviceEntry?.specificationAId === SPECIFICATIONA_DEPARTURE &&
            !serviceEntry?.isZeusExported
          );
      }
    }

    const isEditMode = !!serviceEntry?.id;

    return (
      <Form>
        {serviceEntryDialogOpen && (
          <Fragment>
            <Grid md={12} item container spacing={5}>
              <ServiceEntryProject
                formData={dialogServiceEntry}
                handleFlush={this.handleFlush}
                isDisabled={isDisabled}
              />
              <Button
                handleClick={handleSubmit(this.submitForm)}
                alignRight
                size={3}
              >
                Speichern & schließen
              </Button>
            </Grid>

            <Grid md={12} item container spacing={5}>
              <ServiceEntryUser
                formData={dialogServiceEntry}
                handleFlush={this.handleFlush}
                handleChange={this.handleChange}
                isDisabled={isDisabled}
              />
              <Button handleClick={this.handleClose} alignRight size={3}>
                Abbrechen
              </Button>
            </Grid>
            <Grid md={12} item container spacing={5}>
              <TimeEntryDateTime
                formData={dialogServiceEntry}
                handleFlush={this.handleFlush}
                isDisabled={isDisabled}
              />
              {isEditMode && (
                <Button handleClick={this.handleDelete} alignRight size={3} disabled={isDisabled}>
                  Löschen
                </Button>
              )}
            </Grid>
            <ServiceEntryRules
              formData={dialogServiceEntry}
              handleFlush={this.handleFlush}
              handleChange={this.handleChange}
              isDisabled={isDisabled}
            />
            <TextFieldForm
              size={12}
              name="commentPublic"
              label="Anmerkung / Öffentlich"
              multiline
              disabled={isDisabled}
            />
            {userRightId !== PROJECT_MANAGER && (
              <>
                <TextFieldForm
                  size={12}
                  name="spareParts"
                  label="Ersatzteile"
                  multiline
                  disabled={isDisabled}
                />

                <TextFieldForm
                  size={2}
                  name="licensePlate"
                  label="KFZ-Kennzeichen"
                  disabled={isDisabled}
                />
              </>
            )}
            <TextFieldForm
              size={12}
              name="commentPrivate"
              label="Kommentar / Intern"
              multiline
            />
          </Fragment>
        )}
      </Form>
    );
  }
}

const FormExport = connect<ComponentStateProps, ComponentDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, ComponentProps>({
    // a unique name for the form
    form: 'dialogServiceEntry',
  })(DialogServiceEntry)
);

export default () => {
  const serviceEntryDialogOpen = useSelector(
    (state: any) => state.rootReducer.serviceEntryDialogOpen
  );
  const dispatch = useDispatch();
  const setServiceEntryDialog = (open: any) => dispatch(setServiceEntryDialogAction(open));

  return (
    <DialogTimeEntryWrapper
      open={serviceEntryDialogOpen}
      handleClose={() => setServiceEntryDialog(false)}
    >
      <FormExport />
    </DialogTimeEntryWrapper>
  );
};
