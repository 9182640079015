import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const contractorSelector = (
  state: any,
  {
    include = {
      contractorId: null,
    },
    exclude = {
      contractorId: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.contractors,
    (contractors) => {
      // for single selected contractor
      if (checkSingleValue(include.contractorId)) {
        const contractorId = Array.isArray(include.contractorId)
          ? include.contractorId![0]
          : include.contractorId;

        const contractor = contractors.find((c: any) => c.id === parseInt(contractorId!, 10));
        if (contractor) {
          if (option.plain) {
            return contractor;
          }
          return { label: `${contractor.name}`, value: `${contractor.id}` };
        }
        return null;
      }

      let contractorOptions = contractors;
      if (!option.deactivated) {
        // Filter all deactivated
        contractorOptions = contractorOptions.filter((c: any) => c.deactivated !== true);
      }
      if (exclude.contractorId) {
        contractorOptions = contractorOptions.filter((c: any) => c.id !== exclude.contractorId);
      }
      if (contractorOptions) {
        if (option.plain) {
          return contractorOptions;
        }
        return contractorOptions.map((contractor: any) => ({
          label: contractor.name,
          value: `${contractor.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
