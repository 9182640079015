import React, { Fragment } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';

import Button from './Button';

type DeleteRestoreButtonProps = {
  isDeactivated?: boolean;
  withIcon?: boolean;
  handleDelete: (...args: any[]) => any;
};

const DeleteRestoreButton: React.SFC<DeleteRestoreButtonProps> = ({
  handleDelete,
  isDeactivated = false,
  withIcon = false,
}) => (
  <Fragment>
    {isDeactivated ? (
      <Button
        buttonSize="small"
        handleClick={handleDelete}
        icon={withIcon ? <ReplayIcon /> : undefined}
      >
        Aktivieren
      </Button>
    ) : (
      <Button
        buttonSize="small"
        handleClick={handleDelete}
        icon={withIcon ? <DeleteIcon /> : undefined}
      >
        Deaktivieren
      </Button>
    )}
  </Fragment>
);

export default DeleteRestoreButton;
