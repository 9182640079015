import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Grid } from '@mui/material';
import Form from '../../../../../../Atoms/Form/Form';
import ModalStationDelete from './ModalStationDelete';
import AutocompleteForm from '../../../../../../Molecules/Autocomplete/AutocompleteForm';
import TextFieldForm from '../../../../../../Atoms/TextField/TextFieldForm';
import SwitchesForm from '../../../../../../Molecules/Switches/SwitchesForm';
import { addFormStationAction, deleteFormStationAction } from '../../../../../../../actions';
import Button from '../../../../../../Atoms/Button/Button';
import StationWrapper from './StationWrapper';
import { formCheckQuestionCompletion } from '../../../../../../../helpers/formCompletion';
import {
  FORM_SECTION_STATION,
  FORM_SECTION_RETROFIT_STATION,
  FORM_TYPE_RETROFIT,
  FORM_TYPE_CHECK,
} from '../../../../../../../../../shared/src/constants/general';
import {
  formStationTypeSelector,
  stationTypeSelector,
} from '../../../../../../../helpers/selectors/index';

const mapStateToProps = (state: any) => ({
  forms: state.rootReducer.forms,
  auth: state.rootReducer.auth,
  // contractors: state.rootReducer.contractors,
  formStationTypes: (filter: any) => formStationTypeSelector(state, filter),
  stationTypes: (filter: any) => stationTypeSelector(state, filter),
  answerChecks: state.rootReducer.answerChecks,
});
const mapDispatchToProps = (dispatch: any) => ({
  addFormStation: (data: any) => dispatch(addFormStationAction(data)),
  deleteFormStation: (id: any, data: any) => dispatch(deleteFormStationAction(id)),
});

interface RouteParams {
  id: string;
  section: string;
}

interface ComponentOwnProps {
  formId: number | null;
}

interface ComponentStateProps {
  auth?: any;
  forms: any[];
  // contractors: any[];
  formStationTypes: (...args: any[]) => any;
  stationTypes: (...args: any[]) => any;
  answerChecks: any[];
}

interface ComponentDispatchProps {
  addFormStation: (...args: any[]) => any;
  deleteFormStation: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps &
  RouteComponentProps<RouteParams>;

type FormCheckStationsState = {
  projectId: any;
  dialogDeleteOpen: boolean;
  stationId: null;
};

class FormCheckStations extends PureComponent<ComponentProps, FormCheckStationsState> {
  static defaultProps = {
    auth: null,
  };

  state = { projectId: null, dialogDeleteOpen: false, stationId: null };

  componentDidMount() {
    const { initialize, match, forms } = this.props;
    const formId = parseInt(match.params.id, 10);
    const form = forms.find((f: any) => f.id === formId);
    this.setState({ projectId: form.projectId });
    initialize({
      optional: [],
    });
  }
  handleCreate = (values: any) => {
    const { addFormStation, formId, auth } = this.props;
    const { name, stationType, optional } = values;
    if (name && stationType) {
      if (stationType.value) {
        const optionalSelection = !!optional.length;
        const data = {
          formId,
          name,
          userId: auth.id,
          stationTypeId: stationType.value,
          optional: optionalSelection,
        };
        addFormStation(data);
        // reset();
      }
    }
  };
  handleClick = (formStationTypeId: any) => {
    const { history } = this.props;
    const { match } = this.props;
    const formSectionId = parseInt(match.params.section, 10);
    const formId = parseInt(match.params.id, 10);
    history.push(`/admin/form/${formId}/${formSectionId}/${formStationTypeId}`);
  };
  handleDelete = () => {
    const { deleteFormStation } = this.props;
    const { projectId, stationId } = this.state;
    deleteFormStation(stationId, projectId); // needed projectId to update Answers in CheckHeader
    this.setState({ dialogDeleteOpen: false });
  };
  handleDialog = (id: any) => {
    const { dialogDeleteOpen } = this.state;
    this.setState({ dialogDeleteOpen: !dialogDeleteOpen, stationId: id });
  };

  renderStationWrappers = () => {
    const { answerChecks, formStationTypes, formId, stationTypes, forms } = this.props;
    const form = forms.find((f: any) => f.id === formId);

    let stationSectionId: number;
    if (form.formTypeId === FORM_TYPE_CHECK) {
      stationSectionId = FORM_SECTION_STATION;
    } else if (form.formTypeId === FORM_TYPE_RETROFIT) {
      stationSectionId = FORM_SECTION_RETROFIT_STATION;
    }

    const stationWrappers = formStationTypes({
      include: { formId },
      option: { plain: true },
    }).map((station: any) => (
      <StationWrapper
        key={station.id}
        station={station}
        stationTypes={stationTypes({ option: { plain: true } })}
        onClick={this.handleClick}
        onDelete={this.handleDialog}
        stationAnswerCheck={formCheckQuestionCompletion(answerChecks, stationSectionId, station.id)}
      />
    ));

    return stationWrappers;
  };

  render() {
    const { stationTypes, handleSubmit, formStationTypes, formId } = this.props;
    const { dialogDeleteOpen } = this.state;
    return (
      <Fragment>
        <ModalStationDelete
          open={dialogDeleteOpen}
          handleClose={this.handleDialog}
          handleDelete={this.handleDelete}
        />
        <Form handleSubmit={handleSubmit(this.handleCreate)}>
          {formStationTypes({
            include: { formId },
            option: { plain: true },
          })?.length && <>{this.renderStationWrappers()}</>}
          <Grid
            item
            md={12}
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={3}
            style={{ margin: '0 0 -12px 0' }}
          >
            <TextFieldForm label="Stations-Name" name="name" size={2} formQuestion />
            <AutocompleteForm
              options={stationTypes({})}
              label="Typ"
              size={2}
              name="stationType"
              formQuestion
            />
            <SwitchesForm name="optional" options={[{ value: 1, label: 'SST' }]} />
            <Button handleClick={handleSubmit(this.handleCreate)} alignRight>
              Neu Anlegen
            </Button>
          </Grid>
        </Form>
      </Fragment>
    );
  }
}
export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'formCheckStations',
  })(withRouter(FormCheckStations))
);
