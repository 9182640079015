import React from 'react';
import UserCreate from '../../../Molecules/AdminAreaCreates/UserCreate';
import UserTable from '../../../Molecules/Tables/UserTable';
import AdminOuterWrapper from '../../../Templates/AdminWrappers/AdminOuterWrapper';

const PageUserOverview = () => (
  <AdminOuterWrapper>
    <UserCreate />
    <UserTable />
  </AdminOuterWrapper>
);
export default PageUserOverview;
