import React from 'react';
import { Dialog, DialogContent } from '@mui/material';

const styles = {
  overflow: {
    overflow: 'visible',
  },
  paper: {
    '& .MuiDrawer-paper': {
      margin: '0px',
      height: 'calc(100% - 48px)',
      maxHeight: 'calc(100% - 48px)',
      width: 'calc(100% - 48px)',
    },
  },
};

type DialogTimeEntryWrapperProps = {
  open: boolean;
  handleClose: (...args: any[]) => any;
  overflow?: boolean;
};

const DialogTimeEntryWrapper: React.FC<DialogTimeEntryWrapperProps> = ({
  open,
  handleClose,
  overflow = false,
  children,
}) => (
  <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false} sx={styles.paper}>
    <DialogContent
      sx={{
        ...(overflow && styles.overflow),
      }}
    >
      {children}
    </DialogContent>
  </Dialog>
);

export default DialogTimeEntryWrapper;
