import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { withRouter } from 'react-router-dom';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';
import { getSortedDataTwo } from '../../../helpers/getSortedData';
import { customerSelector } from '../../../helpers/selectors';
import { compareProps } from '../../../helpers/compareProps';

const mapStateToProps = (state: any) => ({
  customers: (filter: any) => customerSelector(state, filter),
  // For memo prop compare and rerender
  compareCustomers: state.rootReducer.customers,
});
type CustomerTableProps = {
  history: any;
  customers: (...args: any[]) => any;
};
const CustomerTable: React.FC<CustomerTableProps> = memo(({ history, customers }) => {
  const getCustomers = () =>
    getSortedDataTwo(
      customers({ option: { deactivated: true, plain: true } }),
      'deactivated',
      'name',
      true
    );
  return (
    <AdminSettingsWrapper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Operation</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Straße und Hausnummer</TableCell>
            <TableCell>Postleitzahl</TableCell>
            <TableCell>Stadt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getCustomers().map((customer: any) => (
            <ActiveTableRow key={customer.id} gray={customer.deactivated}>
              <TableCell>
                <IconButton onClick={() => history.push(`/admin/customer/${customer.id}`)}>
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {customer.name}
              </TableCell>
              <TableCell>{customer.adress}</TableCell>
              <TableCell>{customer.postalCode}</TableCell>
              <TableCell>{customer.city}</TableCell>
            </ActiveTableRow>
          ))}
        </TableBody>
      </Table>
    </AdminSettingsWrapper>
  );
}, compareProps);

export default connect(mapStateToProps, null)(withRouter<any, any>(CustomerTable));
