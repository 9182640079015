// src/js/constants/action-types.js
// from api
export const ADD_ARTICLE: string = 'ADD_ARTICLE';
export const SET_EDITED_FORMS: string = 'SET_EDITED_FORMS';
export const SET_SNACKBAR: string = 'SET_SNACKBAR';
// Auth
export const SET_AUTH: string = 'SET_AUTH';
// admin section
export const SET_ADMIN_SECTION: string = 'SET_ADMIN_SECTION';
// data
export const SET_USERS: string = 'SET_USERS';
export const SET_PROJECTS: string = 'SET_PROJECTS';
export const SET_PROJECT_VARIABLES: string = 'SET_PROJECT_VARIABLES';
export const SET_CUSTOMERS: string = 'SET_CUSTOMERS';
export const SET_CUSTOMER_CONTACTS: string = 'SET_CUSTOMER_CONTACTS';
export const SET_CONTRACTORS: string = 'SET_CONTRACTOR';
export const SET_ROLES: string = 'SET_ROLES';
export const SET_GROUPS: string = 'SET_GROUPS';
export const SET_RIGHTS: string = 'SET_RIGHTS';
export const SET_SITES: string = 'SET_SITES';
export const SET_RULES: string = 'SET_RULES';
export const SET_RULE_VARIABLES: string = 'SET_RULE_VARIABLES';
export const SET_TIME_ENTRIES: string = 'SET_TIME_ENTRIES';
export const SET_PROJECT_TIME_ENTRIES: string = 'SET_PROJECT_TIME_ENTRIES';
export const SET_BILLINGS: string = 'SET_BILLINGS';
export const SET_TIME_SHEETS: string = 'SET_TIME_SHEETS';
export const SET_PRINT_ENTRIES: string = 'SET_PRINT_ENTRIES';
export const SET_PRINT_OPTIONS: string = 'SET_PRINT_OPTIONS';
export const SET_VISITS: string = 'SET_VISITS';
// isLoading
export const SET_IS_LOADING_TIME_SHEET: string = 'SET_IS_LOADING_TIME_SHEET';
// sidebar
export const SET_SIDEBAR: string = 'SET_SIDEBAR';
// dialog
export const SET_SERVICE_ENTRY_DIALOG: string = 'SET_SERVICE_ENTRY_DIALOG';
export const SET_TIME_ENTRY_CREATE_DIALOG: string = 'SET_TIME_ENTRY_CREATE_DIALOG';
export const SET_GREETING_DIALOG: string = 'SET_GREETING_DIALOG';
export const SET_TIME_ENTRY_EDIT_DIALOG: string = 'SET_TIME_ENTRY_EDIT_DIALOG';
export const SET_SIGNATURE_CREATE_DIALOG: string = 'SET_SIGNATURE_CREATE_DIALOG';
export const SET_PROJECT_VISIT_DIALOG: string = 'SET_PROJECT_VISIT_DIALOG';
// locally for edit
export const SET_SINGLE_TIME_ENTRY: string = 'SET_SINGLE_TIME_ENTRY';
export const SET_SINGLE_SERVICE_ENTRY: string = 'SET_SINGLE_SERVICE_ENTRY';
export const SET_SINGLE_VISIT_ENTRY: string = 'SET_SINGLE_VISIT_ENTRY';
// FORMS
export const SET_QUESTION_DETAILS: string = 'SET_QUESTION_DETAILS';
export const SET_FORMS: string = 'SET_FORMS';
export const SET_ANSWER_CHECKS: string = 'SET_ANSWER_CHECKS';
export const SET_ANSWER_SITE_START: string = 'SET_ANSWER_SITE_START';
export const SET_STATION_TYPES: string = 'SET_STATION_TYPES';

// service projects
export const SET_SERVICE_PROJECTS: string = 'SET_SERVICE_PROJECTS';
export const SET_SERVICE_ENTRIES: string = 'SET_SERVICE_ENTRIES';

export const SET_COMBINED_TIME_ENTRIES = 'SET_COMBINED_TIME_ENTRIES';
export const SET_ALL_SERVICE_ENTRIES = 'SET_ALL_SERVICE_ENTRIES';
