import { QUESTION_TYPE_NINE, FORM_TYPE_SITE_CHECK } from '../../../shared/src/constants/general';

export const siteStartCompletion = (answerSiteStarts: any) => {
  const answerSiteStartCheck = answerSiteStarts
    .filter((a: any) => a.question.questionTypeId !== QUESTION_TYPE_NINE) //filter the headlines
    .reduce(
      (acc: any, cv: any) => {
        if (cv.decision === 1) {
          acc.yes += 1;
        } else if (cv.decision === 0) {
          acc.no += 1;
        } else if (cv.decision === null) {
          acc.notAnswered += 1;
        }
        return acc;
      },
      {
        yes: 0,
        no: 0,
        notAnswered: 0,
      }
    );
  return answerSiteStartCheck;
};

/**
 * Checks wether all questions for this form have been answered
 * @param answerChecks All the answers of this form
 * @param sectionId The section we are in
 * @param formStationTypeId The station
 * @param formTypeId The FormtypeId
 */

export const formCheckQuestionCompletion = (
  answerChecks: any,
  sectionId: any,
  formStationTypeId: any = null,
  formTypeId: any = null
) => {
  let sectionAnswerArr;
  if (formTypeId === FORM_TYPE_SITE_CHECK) {
    // If we got this form, we get all questions
    // back except the headlines
    // since we dont really use the "sections"
    sectionAnswerArr = answerChecks.filter(
      (a: any) => a.question.questionTypeId !== QUESTION_TYPE_NINE
    );
  } else {
    if (formStationTypeId) {
      sectionAnswerArr = answerChecks
        .filter((a: any) => a.question.formSectionId === sectionId)
        .filter((a: any) => a.formStationTypeId === formStationTypeId)
        .filter((a: any) => a.question.questionTypeId !== QUESTION_TYPE_NINE);
    } else {
      sectionAnswerArr = answerChecks
        .filter((a: any) => a.question.formSectionId === sectionId)
        .filter((a: any) => a.question.questionTypeId !== QUESTION_TYPE_NINE);
    }
  }

  const sectionAnswerCheck = sectionAnswerArr.reduce(
    (acc: any, cv: any) => {
      if (!cv.required) {
        acc.notRequired += 1;
        return acc;
      }
      if (cv.decision === 1) {
        acc.yes += 1;
      } else if (cv.decision === 0) {
        acc.no += 1;
      } else if (cv.decision === null && cv.required) {
        acc.notAnswered += 1;
      }
      return acc;
    },
    {
      yes: 0,
      no: 0,
      notRequired: 0,
      notAnswered: 0,
    }
  );

  return sectionAnswerCheck;
};
export const formStationCompletion = (formStationTypes: any) => {
  return formStationTypes.reduce(
    (acc: any, cv: any) => {
      acc.counter += 1;
      if (cv.completed === true) {
        acc.completed += 1;
      }
      return acc;
    },
    { counter: 0, completed: 0 }
  );
};

export default {};
