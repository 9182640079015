import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from '../../Atoms/Form/Form';
import AutocompleteForm from '../Autocomplete/AutocompleteForm';
import Button from '../../Atoms/Button/Button';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import CheckboxesForm from '../Checkboxes/CheckboxesForm';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
// import validate from '../../../../../../helpers/validation';
import {
  serviceSelector,
  specificationASelector,
  specificationBSelector,
  roleSelector,
  groupSelector,
} from '../../../helpers/selectors';

import { addRuleAction } from '../../../actions';

import {
  SERVICE_BREAK,
  SERVICE_TRAVEL,
  SPECIFICATIONA_ARRIVAL,
  SPECIFICATIONA_DEPARTURE,
} from '../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
});

const mapDispatchToProps = (dispatch: any) => ({
  addRule: (
    serviceId: any,
    specificationAId: any,
    specificationBId: any,
    roles: any,
    groups: any,
    wa: any,
    station: any
  ) =>
    dispatch(
      addRuleAction({
        serviceId,
        specificationAId,
        specificationBId,
        roles,
        groups,
        wa,
        station,
      })
    ),
});
type RuleCreateProps = {
  auth: any;
  groups: (...args: any[]) => any;
  roles: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
  addRule: (...args: any[]) => any;
  initialize: (...args: any[]) => any;
  reset: (...args: any[]) => any;
  handleSubmit: (...args: any[]) => any;
};
class RuleCreate extends PureComponent<RuleCreateProps, {}> {
  static defaultProps = {
    auth: null,
  };

  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      service: null,
      specificationA: null,
      specificationB: null,
      roles: [],
      groups: [],
      wa: '',
      withStation: [],
    });
  }
  handleCreate = (values: any) => {
    const { addRule, reset } = this.props;
    const { service, specificationA, specificationB, roles, groups, wa, withStation } = values;
    if (service) {
      if (service.value) {
        const serviceId = service.value;
        const specificationAId = specificationA ? specificationA.value : null;
        const specificationBId = specificationB ? specificationB.value : null;
        const station = withStation[0] === 1;
        addRule(
          serviceId,
          specificationAId,
          specificationBId,
          roles,
          groups,
          wa.padStart(3, '0'), // leading zeros
          station
        );
        reset();
      }
    }
  };
  render() {
    const {
      roles,
      groups,
      services,
      specificationAs,
      specificationBs,
      handleSubmit,
      auth,
    } = this.props;
    return (
      <Fragment>
        <AdminSettingsWrapper alignItems="flex-start" editWrapper>
          <Form handleSubmit={handleSubmit(this.handleCreate)}>
            <AutocompleteForm
              size={4}
              name="service"
              options={services({
                // if not user 1, remove the travel etc.
                ...(auth.id !== 1 && { exclude: { serviceId: [SERVICE_BREAK, SERVICE_TRAVEL] } }),
              })}
              label="Tätigkeit"
            />
            <AutocompleteForm
              size={4}
              options={specificationAs({
                exclude: {
                  ...(auth.id !== 1 && {
                    specificationAId: [SPECIFICATIONA_ARRIVAL, SPECIFICATIONA_DEPARTURE],
                  }),
                },
              })}
              label="Spezifikation A"
              name="specificationA"
            />
            <AutocompleteForm
              size={4}
              options={specificationBs({})}
              label="Spezifikation B"
              name="specificationB"
            />
            <CheckboxesForm
              label="Gruppen"
              name="groups"
              options={groups({
                option: { costCentre: true },
              })}
              size={12}
            />
            <CheckboxesForm label="Rollen" name="roles" options={roles({})} size={12} />
            <TextFieldForm size={3} name="wa" label="Kennziffer WA" type="number" />
            <CheckboxesForm
              label=""
              name="withStation"
              options={[{ label: 'Stationsnummer', value: 1 }]}
              size={2}
            />
            <Button handleClick={handleSubmit(this.handleCreate)} alignRight size={3}>
              Neu Anlegen
            </Button>
          </Form>
        </AdminSettingsWrapper>
      </Fragment>
    );
  }
}

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'ruleCreate',
  })(RuleCreate)
);
