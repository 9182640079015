import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SiteStartHeader from './components/SiteStartHeader';
import SiteStartGeneral from './components/SiteStartGeneral';
import {
  getAnswerSiteStartAction,
  saveFormAction,
  saveAnswerSiteStartAction,
} from '../../../../../../actions';
import AdminOuterWrapper from '../../../../../Templates/AdminWrappers/AdminOuterWrapper';
import SidebarScrollSpy from '../../../../../Organisms/Sidebar/SidebarScrollSpy';
import { InjectedFormProps } from 'redux-form';

const mapStateToProps = (state: any) => ({
  forms: state.rootReducer.forms,
  answerSiteStarts: state.rootReducer.answerSiteStarts,
  siteStartHeader: state.form.siteStartHeader,
  siteStartGeneral: state.form.siteStartGeneral,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAnswerSiteStart: (projectId: any) => dispatch(getAnswerSiteStartAction(projectId)),
  saveForm: (data: any) => dispatch(saveFormAction(data)),
  saveAnswerSiteStart: (answerSiteStart: any, projectId: any) =>
    dispatch(saveAnswerSiteStartAction(answerSiteStart, projectId)),
});

interface RouteParams {
  id: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  forms: any[];
  siteStartHeader?: any;
  siteStartGeneral?: any;
  answerSiteStarts?: any[];
}

interface ComponentDispatchProps {
  saveForm: (...args: any[]) => any;
  getAnswerSiteStart: (...args: any[]) => any;
  saveAnswerSiteStart: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams> &
  InjectedFormProps;

type ComponentOwnState = {
  formId: number | null;
};

class FormSiteStart extends PureComponent<ComponentProps, ComponentOwnState> {
  static defaultProps = {
    answerSiteStarts: null,
    siteStartHeader: null,
    siteStartGeneral: null,
  };

  state = {
    formId: null,
  };
  componentDidMount() {
    // Kunde LagerTyp Ort/Land FAB AUftragsNR WA-NR PL-STOPA PL-TRUMP PL-Kunde Monteur mech. Monteru elek
    const { getAnswerSiteStart, match, forms } = this.props;
    const formId = parseInt(match.params.id, 10);
    const form = forms.find(f => f.id === formId);
    this.setState({ formId });
    getAnswerSiteStart(form.projectId);
  }

  handleSave = () => {
    const { saveForm, forms, siteStartHeader, siteStartGeneral, saveAnswerSiteStart } = this.props;
    const { formId } = this.state;
    // todo: refactore to use formSelector
    if (forms) {
      const form = forms.find((f: any) => f.id === formId);

      if (siteStartHeader) {
        const formValues = { ...siteStartHeader.values };
        const {
          responsibleMechanic,
          responsibleElectrician,
          formDate,
          responsibleContractor,
        } = formValues;

        const saveFormData = {
          formId: form.id,
          responsibleMechanicId: responsibleMechanic ? responsibleMechanic.value : null,
          responsibleElectricianId: responsibleElectrician ? responsibleElectrician.value : null,
          responsibleContractor,
          formDate: formDate ? formDate.value : null,
        };

        saveForm({
          ...saveFormData,
        });
      }
      if (siteStartGeneral) {
        const formValues = { ...siteStartGeneral.values };
        if (formValues) {
          saveAnswerSiteStart(formValues, form.projectId);
        }
      }
    }
  };
  render() {
    const { answerSiteStarts } = this.props;
    const { formId } = this.state;
    return (
      <AdminOuterWrapper withScrollSpy>
        {answerSiteStarts && formId && (
          <>
            <SiteStartHeader formId={formId} />
            <SiteStartGeneral formId={formId} />
            <SidebarScrollSpy
              handleSave={this.handleSave}
              withPrint
              printLink={`/print/form/${formId}`}
              backPath={`form`}
            />
          </>
        )}
      </AdminOuterWrapper>
    );
  }
}
export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(FormSiteStart));
