import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import {
  Grid,
  GridSize,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  FormHelperText,
} from '@mui/material';

type renderSwitchesProps = {
  input: any;
  meta: any;
  options: {
    label: string;
    value: number;
  }[];
  label?: string;
  disabled?: boolean;
};

const renderSwitches: React.FC<renderSwitchesProps> = ({
  input,
  meta,
  options,
  disabled,
  label,
}) => {
  const { name, onChange, onBlur, onFocus } = input;
  const { touched, error } = meta;
  const inputValue = input.value;
  const switches = options.map((option, index) => {
    const switchValue = option.value;
    const switchLabel = option.label;
    const handleChange = (event: any) => {
      const arr = [...inputValue];
      if (event.target.checked) {
        arr.push(switchValue);
      } else {
        arr.splice(arr.indexOf(switchValue), 1);
      }
      onBlur(arr);
      return onChange(arr);
    };
    const checked = inputValue.includes(switchValue);
    return (
      <FormControlLabel
        key={switchValue}
        control={
          <Switch
            name={`${name}[${index}]`}
            value={`${switchValue}`}
            checked={checked}
            onChange={handleChange}
            onFocus={onFocus}
            color="primary"
          />
        }
        label={switchLabel}
        disabled={disabled}
      />
    );
  });
  return (
    <FormControl error={error && touched} component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      {touched && error && <FormHelperText className="error">{error}</FormHelperText>}
      <FormGroup row>{switches}</FormGroup>
    </FormControl>
  );
};
renderSwitches.defaultProps = {
  disabled: false,
  label: '',
};

type SwitchesProps = {
  options: {
    label: string;
    value: number;
  }[];
  size?: GridSize | any;
  label?: string;
  disabled?: boolean;
  name?: string;
};

class Switches extends PureComponent<SwitchesProps, {}> {
  render() {
    const { size = 2 } = this.props;
    return (
      <Grid item md={size}>
        <Field {...this.props} type="checkbox" component={renderSwitches} />
      </Grid>
    );
  }
}

export default Switches;
