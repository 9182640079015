import React from 'react';
import moment, { locale } from 'moment';
locale('de');
import { Grid, Dialog, DialogContent } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, change as changeAction, InjectedFormProps } from 'redux-form';
import Form from '../../../../Atoms/Form/Form';
import AutocompleteForm from '../../../../Molecules/Autocomplete/AutocompleteForm';
import TextFieldForm from '../../../../Atoms/TextField/TextFieldForm';
import TextField from '../../../../Atoms/TextField/TextField';
import RadiosForm from '../../../../Molecules/Radios/RadiosForm';
import Button from '../../../../Atoms/Button/Button';
// import validate from '../../../../../../helpers/validation';
import { saveServiceProjectAction } from '../../../../../actions';
import { SERVICE_PROJECT_STATES } from '../../../../../constants/all';
import {
  siteSelector,
  customerSelector,
  customerContactSelector,
  userSelector,
} from '../../../../../helpers/selectors';
import {
  SERVICE_PROJECT_STATE_CANCLED,
  SERVICE_PROJECT_STATE_OPEN,
  DEPARTMENT_MANAGER,
} from '../../../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  sites: (filter: any) => siteSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  customerContacts: (filter: any) => customerContactSelector(state, filter),
  auth: state.rootReducer.auth,

  serviceProjectEdit: state.form.serviceProjectEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveServiceProject: (data: any) => dispatch(saveServiceProjectAction(data)),
  change: (field: string, value: any, form?: any) => dispatch(changeAction(form, field, value)),
});

interface ComponentOwnProps {
  toggleEditDialog: (...args: any[]) => any;
  visibility: boolean;
  serviceProject: any;
}

interface ComponentStateProps {
  sites: (...args: any[]) => any;
  users: (...args: any[]) => any;
  customerContacts: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  serviceProjectEdit?: any;
  auth?: any;
}

interface ComponentDispatchProps {
  saveServiceProject: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class ServiceProjectEdit extends React.Component<ComponentProps, {}> {
  componentWillMount() {
    const { initialize, sites, serviceProject, customers, users } = this.props;
    const fab = sites({
      option: { plain: true },
      include: { siteId: serviceProject.siteId },
    }).fab;

    initialize({
      serviceProjectNumber: {
        value: serviceProject.serviceProjectNumber,
        label: serviceProject.serviceProjectNumber,
      },
      wa: serviceProject.wa,
      ab: serviceProject.ab,
      createdByUserId: users({ include: { userId: serviceProject.createdByUserId } }),
      createdAt: moment(serviceProject.createdAt, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      site: sites({ include: { siteId: serviceProject.siteId } }),
      fab: { value: fab, label: fab },
      comment: serviceProject.comment,
      state: serviceProject.state,
      customer: customers({ include: { customerId: serviceProject.customerId } }),
    });
  }
  //   serviceProjectNumber: {value: "99999990-20200210", label: "99999990-20200210"}
  // wa: null
  // ab: null
  // createdBy: {label: "Frank Runge - STOPA", value: "1"}
  // createdAt: "2020-02-10"
  // site: {label: "Frankreich - MEYZIEU - BOULEVARD MONGE 13", value: "79"}
  // fab: {value: 112233, label: 112233}
  // comment: "123test"
  // state: 1
  // customer: {label: "Atlantic", value: "75"}

  handleSave = (values: any) => {
    const { saveServiceProject, serviceProject, toggleEditDialog, reset } = this.props;
    const { wa, ab, comment } = values;
    const { id } = serviceProject;
    // const siteData = sites({ include: { siteId: site.value }, option: { plain: true } });

    saveServiceProject({ wa, ab, comment, id });
    toggleEditDialog(null);
    reset();
  };
  handleCancel = (values: any) => {
    const { saveServiceProject, serviceProject, toggleEditDialog, reset } = this.props;
    const { id } = serviceProject;
    // const siteData = sites({ include: { siteId: site.value }, option: { plain: true } });

    saveServiceProject({ id, state: SERVICE_PROJECT_STATE_CANCLED });
    toggleEditDialog(null);
    reset();
  };
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: string) => change(field, null));
  };
  render() {
    const { handleSubmit, visibility, toggleEditDialog, serviceProject, customerContacts } =
      this.props;

    let customerContactsSP;
    customerContactsSP = customerContacts({
      include: { customerId: serviceProject.customerId },
    });

    return (
      <Dialog open={visibility} onClose={() => toggleEditDialog(null)} fullWidth maxWidth={false}>
        <DialogContent>
          <Form handleSubmit={handleSubmit(this.handleSave)}>
            <AutocompleteForm size={6} name="serviceProjectNumber" label="SE-Nummer" isDisabled />
            <Button
              handleClick={handleSubmit(this.handleSave)}
              alignRight
              disabled={serviceProject.state === SERVICE_PROJECT_STATE_CANCLED}
            >
              Speichern
            </Button>
            <Button handleClick={() => toggleEditDialog(false)} alignRight>
              Abbrechen
            </Button>
            <Button
              handleClick={handleSubmit(this.handleCancel)}
              alignRight
              disabled={
                !(
                  serviceProject.state === SERVICE_PROJECT_STATE_OPEN ||
                  (this.props.auth.rightId <= DEPARTMENT_MANAGER &&
                    serviceProject.state !== SERVICE_PROJECT_STATE_CANCLED)
                )
              }
            >
              Verwerfen
            </Button>
            <AutocompleteForm
              size={6}
              label="FAB"
              name="fab"
              isDisabled
              // onChange={() => this.handleFlush(['site'])}
            />
            <TextFieldForm
              size={6}
              name="createdAt"
              label="Anlage-Datum des Service-Einsatzes"
              type="date"
              disabled
            />
            <RadiosForm
              label="Status"
              name="state"
              disabled
              options={SERVICE_PROJECT_STATES}
              size={6}
            />
            <AutocompleteForm size={6} name="createdByUserId" label="angelegt von" isDisabled />
            <TextFieldForm
              size={6}
              type="number"
              name="wa"
              label="WA"
              disabled={serviceProject.state === SERVICE_PROJECT_STATE_CANCLED}
            />
            <AutocompleteForm size={6} name="customer" label="Kunde" isDisabled />
            <TextFieldForm
              size={6}
              type="number"
              name="ab"
              label="AB"
              disabled={serviceProject.state === SERVICE_PROJECT_STATE_CANCLED}
            />
            <AutocompleteForm size={6} name="site" label="Baustelle" isDisabled />
            <TextFieldForm
              size={6}
              name="comment"
              label="Kommentar (Pflichtpfeld)"
              multiline
              rowsMax={6}
              disabled={serviceProject.state === SERVICE_PROJECT_STATE_CANCLED}
            />
            <Grid item container md={6} spacing={3}>
              {customerContactsSP.length > 0 &&
                customerContactsSP.map((el: any) => (
                  <TextField
                    key={el.value}
                    id={el.value}
                    size={12}
                    value={el.label}
                    label="Ansprechpartner beim Kunden"
                    disabled
                  />
                ))}
            </Grid>
          </Form>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'serviceProjectEdit',
  })(withRouter<any, any>(ServiceProjectEdit))
);
