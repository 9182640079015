import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import NavBar from '../../Organisms/NavBar/NavBar';
import AdminOuterWrapper from '../AdminWrappers/AdminOuterWrapper';
import Sidebar from '../../Organisms/Sidebar/Sidebar';
import {
  getProjectsAction,
  getUsersAction,
  getProjectVariablesAction,
  getRulesAction,
  getRuleVariablesAction,
  getGroupsAction,
  getRolesAction,
  getCustomersAction,
  getCustomerContactsAction,
  getSitesAction,
  getBillingsAction,
  getContractorsAction,
  getTimeSheetsAction,
  getTimeEntriesAction,
  setSidebarAction,
} from '../../../actions';

export default function PageWeekWrapper(WrappedComponent: React.ComponentType) {
  const mapStateToProps = (state: any) => ({
    timeEntries: state.rootReducer.timeEntries,
    projects: state.rootReducer.projects,
    users: state.rootReducer.users,
    roles: state.rootReducer.roles,
    services: state.rootReducer.services,
    billingTypes: state.rootReducer.billingTypes,
    customers: state.rootReducer.customers,
    timeSheets: state.rootReducer.timeSheets,
  });
  const mapDispatchToProps = (dispatch: any) => ({
    getProjects: () => dispatch(getProjectsAction()),
    getProjectVariables: () => dispatch(getProjectVariablesAction()),
    getCustomers: () => dispatch(getCustomersAction()),
    getCustomerContacts: () => dispatch(getCustomerContactsAction()),
    getSites: () => dispatch(getSitesAction()),
    getRules: () => dispatch(getRulesAction()),
    getRuleVariables: () => dispatch(getRuleVariablesAction()),
    getGroups: () => dispatch(getGroupsAction()),
    getRoles: () => dispatch(getRolesAction()),
    getBillings: () => dispatch(getBillingsAction()),
    getContractors: () => dispatch(getContractorsAction()),
    getUsers: () => dispatch(getUsersAction()),
    getTimeSheets: () => dispatch(getTimeSheetsAction()),
    getTimeEntries: (week: any, year: any) => dispatch(getTimeEntriesAction(week, year)),
    setSidebar: (open: any) => dispatch(setSidebarAction(open)),
  });

  interface ComponentOwnProps {}

  interface ComponentStateProps {
    users?: any;
    customers?: any;
    projects?: any;
    timeEntries?: any;
    services?: any;
    roles?: any;
    billingTypes?: any;
    timeSheets?: any;
  }

  interface ComponentDispatchProps {
    getProjects: (...args: any[]) => any;
    getUsers: (...args: any[]) => any;
    getProjectVariables: (...args: any[]) => any;
    getCustomers: (...args: any[]) => any;
    getCustomerContacts: (...args: any[]) => any;
    getContractors: (...args: any[]) => any;
    getSites: (...args: any[]) => any;
    getRules: (...args: any[]) => any;
    getRuleVariables: (...args: any[]) => any;
    getGroups: (...args: any[]) => any;
    getRoles: (...args: any[]) => any;
    getBillings: (...args: any[]) => any;
    getTimeSheets: (...args: any[]) => any;
    getTimeEntries: (...args: any[]) => any;
    setSidebar: (...args: any[]) => any;
  }

  type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

  class InnerWrapper extends PureComponent<ComponentProps, {}> {
    static defaultProps = {
      users: null,
      customers: null,
      projects: null,
      timeEntries: null,
      services: null,
      roles: null,
      billingTypes: null,
      timeSheets: null,
    };

    componentDidMount() {
      const {
        getProjects,
        getUsers,
        getProjectVariables,
        getCustomers,
        getCustomerContacts,
        getContractors,
        getSites,
        getRules,
        getRuleVariables,
        getGroups,
        getRoles,
        getBillings,
        getTimeSheets,
        getTimeEntries,
        setSidebar,
      } = this.props;
      getProjects();
      getUsers();
      getProjectVariables();
      getCustomers();
      getCustomerContacts();
      getContractors();
      getSites();
      getRules();
      getRuleVariables();
      getGroups();
      getRoles();
      getBillings();
      getTimeSheets();
      // set the current week as predefined
      const currentDate = new Date();
      const currentWeek = moment(currentDate).week();
      const currentYear = moment(currentDate).year();
      getTimeEntries(currentWeek, currentYear);
      setSidebar(false);
    }
    render() {
      const {
        users,
        customers,
        timeEntries,
        services,
        roles,
        billingTypes,
        timeSheets,
        projects,
      } = this.props;
      return (
        <Fragment>
          <NavBar />
          <Sidebar />
          {users &&
            customers &&
            timeEntries &&
            services &&
            roles &&
            billingTypes &&
            timeSheets &&
            projects && (
              <AdminOuterWrapper>
                <WrappedComponent />
              </AdminOuterWrapper>
            )}
        </Fragment>
      );
    }
  }

  // return InnerWrapper;
  return connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(InnerWrapper);
}
