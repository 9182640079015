import React, { memo } from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { withRouter } from 'react-router-dom';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import { getSortedDataTwo } from '../../../helpers/getSortedData';
import { contractorSelector } from '../../../helpers/selectors';
import { CONTRACTOR_STOPA } from '../../../../../shared/src/constants/general';
import { compareProps } from '../../../helpers/compareProps';

const mapStateToProps = (state: any) => ({
  contractors: (filter: any) => contractorSelector(state, filter),
  // For memo prop compare and rerender
  compareContractors: state.rootReducer.contractors,
});

type ContractorTableProps = {
  history: any;
  contractors: (...args: any[]) => any;
};
const ContractorTable: React.SFC<ContractorTableProps> = memo(({ history, contractors }) => {
  const getContractors = () =>
    getSortedDataTwo(
      contractors({
        exclude: { contractorId: CONTRACTOR_STOPA },
        option: { deactivated: true, plain: true },
      }),
      'deactivated',
      'name',
      true
    );
  return (
    <AdminSettingsWrapper>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Operation</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Kurzform</TableCell>
            <TableCell>Straße und Hausnummer</TableCell>
            <TableCell>Stadt</TableCell>
            <TableCell>Postleitzahl</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {getContractors().map((contractor: any) => (
            <ActiveTableRow key={contractor.id} gray={contractor.deactivated}>
              <TableCell>
                <IconButton onClick={() => history.push(`/admin/contractor/${contractor.id}`)}>
                  <Edit />
                </IconButton>
              </TableCell>
              <TableCell component="th" scope="row">
                {contractor.name}
              </TableCell>
              <TableCell>{contractor.shortName}</TableCell>
              <TableCell>{contractor.adress}</TableCell>
              <TableCell>{contractor.city}</TableCell>
              <TableCell>{contractor.postalCode}</TableCell>
            </ActiveTableRow>
          ))}
        </TableBody>
      </Table>
    </AdminSettingsWrapper>
  );
}, compareProps);

export default connect(mapStateToProps, null)(withRouter<any, any>(ContractorTable));
