import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const groupSelector = (
  state: any,
  {
    include = {
      groupId: null,
      userId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      costCentre: false,
      array: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.groups,
    () => state.rootReducer.users,
    (groups, users) => {
      // for single selected contractor
      if (checkSingleValue(include.groupId) && !include.userId) {
        const groupId = Array.isArray(include.groupId) ? include.groupId![0] : include.groupId;
        const group = groups.find((g: any) => g.id === parseInt(groupId!, 10));
        if (group) {
          if (option.plain) {
            return option.array ? [group] : group;
          }
          return option.array
            ? [{ label: `${group.name}`, value: `${group.id}` }]
            : { label: `${group.name}`, value: `${group.id}` };
        }
        return null;
      }

      let groupOptions = groups;
      if (include.userId) {
        const includeUserId = parseInt(include.userId!, 10);
        const user = users.find((u: any) => u.id === includeUserId);
        groupOptions = groupOptions.filter((group: any) => user.groups.includes(group.id));
      }
      if (include.groupId) {
        // @ts-ignore
        groupOptions = groupOptions.filter((group: any) => include.groupId.includes(group.id));
      }
      if (groupOptions) {
        if (option.plain) {
          return groupOptions;
        }
        if (option.costCentre) {
          return groupOptions.map((group: any) => ({
            label: `${group.name} (${group.costCentre})`,
            value: group.id,
          }));
        }

        return groupOptions.map((group: any) => ({ label: `${group.name}`, value: `${group.id}` }));
      }
      return null;
    }
  )(null as any);

export default {};
