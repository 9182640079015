import React, { PureComponent } from 'react';
import { Field } from 'redux-form';
import {
  FormHelperText,
  Grid,
  GridSize,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox as MUICheckbox,
} from '@mui/material';

type renderCheckboxesProps = {
  input: any;
  meta: any;
  options: any[];
  label: string;
  disabled?: boolean;
};

const renderCheckboxes: React.SFC<renderCheckboxesProps> = ({
  input,
  meta,
  options,
  disabled = false,
  label,
}) => {
  const { name, onChange, onBlur, onFocus } = input;
  const { touched, error } = meta;
  // make them to string since our selectors do the same with the id

  const inputValue = input.value.map((v: any) => `${v}`);
  const checkboxes = options.map((option, index) => {
    const checkBoxValue = `${option.value}`;
    const checkBoxLabel = option.label;
    const handleChange = (event: any) => {
      const arr = [...inputValue];
      if (event.target.checked) {
        arr.push(checkBoxValue);
      } else {
        arr.splice(arr.indexOf(checkBoxValue), 1);
      }
      onBlur(arr);
      return onChange(arr);
    };
    const checked = inputValue.includes(checkBoxValue);
    return (
      <FormControlLabel
        key={checkBoxValue}
        control={
          <MUICheckbox
            name={`${name}[${index}]`}
            value={`${checkBoxValue}`}
            checked={checked}
            onChange={handleChange}
            onFocus={onFocus}
            color="primary"
          />
        }
        label={checkBoxLabel}
        disabled={disabled}
      />
    );
  });
  return (
    <FormControl error={error && touched} component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      {touched && error && <FormHelperText className="error">{error}</FormHelperText>}
      <FormGroup row>{checkboxes}</FormGroup>
    </FormControl>
  );
};

type CheckboxesProps = {
  options: object[];
  size?: GridSize;
  label: string;
  name: string;
  disabled?: boolean;
};

class Checkboxes extends PureComponent<CheckboxesProps, {}> {
  render() {
    const { size = 2 } = this.props;
    return (
      <Grid item md={size}>
        <Field {...this.props} type="checkbox" component={renderCheckboxes} />
      </Grid>
    );
  }
}

export default Checkboxes;
