import React, { PureComponent, Fragment } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DialogTimeEntryCreate from './components/DialogTimeEntry/DialogTimeEntryCreate/DialogTimeEntryCreate';
import DialogTimeEntryEdit from './components/DialogTimeEntry/DialogTimeEntryEdit/DialogTimeEntryEdit';
import DayOverview from './components/DayOverview/DayOverview';
import ProjectOverview from './components/ProjectOverview/ProjectOverview';
import WeekOverview from './components/WeekOverview/WeekOverview';
import CalendarWeek from './components/CalendarWeek/CalendarWeek';
import { getSortedData } from '../../../helpers/getSortedData';
import GreetingDialog from '../../Organisms/GreetingDialog/GreetingDialog';
import {
  MECHANIC,
  ADMIN,
  CONTRACTOR_STOPA,
  DEPARTMENT_MANAGER,
  GROUP_SERVICE,
  PROJECT_MANAGER,
} from '../../../../../shared/src/constants/general';
import { setPrintEntriesAction, getTimeEntriesAction } from '../../../actions';
import { projectSelector, userSelector } from '../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  timeEntries: state.rootReducer.timeEntries,
  timeEntry: state.rootReducer.timeEntry,
  auth: state.rootReducer.auth,
  projects: (filter: any) => projectSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  // redux-form
  calendarWeek: state.form.calendarWeek,
});

const mapDispatchToProps = (dispatch: any) => ({
  getTimeEntries: (week: any, year: any) => dispatch(getTimeEntriesAction(week, year)),
  setPrintEntries: (entries: any, week: any, userId: any, projectId: any) =>
    dispatch(
      setPrintEntriesAction({
        entries,
        week,
        userId,
        projectId,
      })
    ),
});

interface ComponentOwnProps {
  // history: any;
}

interface ComponentStateProps {
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  timeEntries: any[];
  auth: any;
  timeEntry?: any;
  calendarWeek?: any;
}

interface ComponentDispatchProps {
  getTimeEntries: (...args: any[]) => any;
  setPrintEntries: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps;

class PageWeekOverview extends PureComponent<ComponentProps, {}> {
  // static defaultProps = {
  //   auth: null,
  //   timeEntry: null,
  //   projects: null,
  //   timeEntries: null,
  //   // redux-forms
  //   calendarWeek: null,
  // };

  getWeekEntries = () => {
    const { projects, auth, users, timeEntries, calendarWeek } = this.props;
    if (!timeEntries || !calendarWeek || !calendarWeek.values.week) return null;
    const weekValue = calendarWeek.values.week.value;
    const selectedYear = parseInt(weekValue.split('-')[0], 10); // moment(value.value, 'YYYY-WW').week();
    const selectedWeek = parseInt(weekValue.split('-')[1], 10); // /moment(value.value, 'YYYY-WW').year();
    if (auth.rightId === ADMIN) {
      const result = timeEntries.find(
        (entry) => entry.week === selectedWeek && entry.year === selectedYear
      );
      if (result) {
        return result.entries;
      }
      return [];
    }
    const weekEntries = timeEntries.find(
      (entry) => entry.week === selectedWeek && entry.year === selectedYear
    );
    if (weekEntries) {
      if (weekEntries.entries) {
        const result = weekEntries.entries.map((day: any) => {
          const allEntries = day.entries;
          let allUserEntries;
          // check for the projects the user is in
          const userProjects = projects({
            include: { userId: auth.id },
            option: { plain: true },
          });
          // if there are any projects
          if (userProjects) {
            // collect all the user ids
            let userId: any[] = [];
            let projectId: any[] = [];
            userProjects.forEach((project: any) => {
              userId = userId.concat(project.userIds);
            });
            userProjects.forEach((project: any) => {
              projectId = projectId.concat(project.id);
            });
            if (auth.rightId === DEPARTMENT_MANAGER) {
              // show only users (internal and external) with right mechanic
              userId = users({
                include: { rightId: MECHANIC, userId },
                option: { plain: true },
              }).map((user: any) => user.id);
            }
            if (auth.rightId === PROJECT_MANAGER) {
              // show only users (internal and external) with right mechanic
              // add add self
              userId = users({
                include: { rightId: MECHANIC, userId },
                option: { plain: true },
              }).map((user: any) => user.id);
              userId = userId.concat(auth.id);
            }
            if (auth.rightId === MECHANIC) {
              userId = users({
                include: { rightId: MECHANIC, userId },
                exclude: { contractorId: CONTRACTOR_STOPA },
                option: { plain: true },
              }).map((user: any) => user.id);
              userId = userId.concat(auth.id);
            }
            // get all entries with the userId
            allUserEntries = allEntries.filter(
              (entry: any) => userId.includes(entry.userId) && projectId.includes(entry.projectId)
            );
          }
          return { ...day, entries: allUserEntries };
        });
        return result;
      }
      return [];
    }
    return [];
  };

  getFilteredEntries = (weekEntries: any) => {
    const { calendarWeek, projects } = this.props;
    if (!weekEntries || !calendarWeek) return null;
    // user is only allowed to see the user or projects assigned to,
    // so filter already takes care to not display anything else
    let projectId: any = null;
    let userId: any = null;
    // add projectNumber to entries
    const entriesWithNumber = weekEntries.map((day: any) => {
      const entryArray = day.entries.map((e: any) => {
        const project = projects({
          include: { projectId: e.projectId },
          option: { plain: true },
        });
        return { projectNumber: project.number, ...e };
      });
      return { ...day, entries: entryArray };
    });
    if (calendarWeek.values.project) {
      projectId = parseInt(calendarWeek.values.project.value, 10);
    }
    if (calendarWeek.values.user) {
      userId = parseInt(calendarWeek.values.user.value, 10);
    }
    if (userId) {
      const filteredEntries = entriesWithNumber.map((day: any) => {
        let allEntries = day.entries;
        if (userId) {
          allEntries = allEntries.filter((entry: any) => entry.userId === userId);
        }

        return { ...day, entries: allEntries };
      });
      return filteredEntries;
    }
    return entriesWithNumber;
  };
  //check if there are other overlapping entries or if there are gaps between entries
  checkFalseEntries = (weekEntries: any) => {
    const { calendarWeek } = this.props;
    if (!weekEntries || !calendarWeek) return null;
    let falseEntryIds: any[] = [];
    if (weekEntries && calendarWeek.values.user) {
      const dataEntries = weekEntries.map((day: any) => {
        let isFalseDay = false;
        //sort the entries based on timestart ascending to find gaps
        const sortedEntries = getSortedData(day.entries, 'timeStart', true);
        const newDayEntries = sortedEntries.map((entry: any, i: any) => {
          let isFalseEntry = false;
          const alreadyFoundEntry =
            falseEntryIds.findIndex((id: any) => id === entry.id) === -1 ? false : true;
          //loop again over array to check
          sortedEntries.forEach((newEntry: any, j: any) => {
            //first: if its the next timeEntry check if there is a gap between
            if (i === j - 1) {
              if (entry.timeEnd < newEntry.timeStart) {
                isFalseEntry = true;
                falseEntryIds.push(entry.id, newEntry.id);
              }
            }
            //second: if entry was already marked as false return entry
            if (alreadyFoundEntry) {
              isFalseEntry = true;
            }
            if (i !== j && !isFalseEntry) {
              // check if entries are overlapping and add both entries to falseEntryIds
              if (
                (newEntry.timeEnd > entry.timeEnd && entry.timeEnd > newEntry.timeStart) ||
                (newEntry.timeStart < entry.timeStart && entry.timeStart < newEntry.timeEnd) ||
                (newEntry.timeStart > entry.timeStart && entry.timeEnd > newEntry.timeEnd) || // check if new entry is in between old entry
                (newEntry.timeStart < entry.timeStart && entry.timeEnd < newEntry.timeEnd) || // check if old entry is in between new entry
                newEntry.timeStart === entry.timeStart
              ) {
                isFalseEntry = true;
                falseEntryIds.push(entry.id, newEntry.id);
              }
            }
          });

          //if found false entry return entry it with isFalse: true and set the day to false
          if (isFalseEntry) {
            isFalseDay = true;
            return { ...entry, isFalseEntry };
          }
          return { ...entry, isFalseEntry };
        });
        return { ...day, entries: newDayEntries, isFalseDay };
      });

      if (calendarWeek.values.project) {
        // project: {label: "7406 - Brunner", value: "69"}
        // user: {label: "Robin  Götz - STOPA", value: "25"}
        // week: {label: "KW 1 04.01.-10.01.21", value: "2021-1"}

        const projectId = parseInt(calendarWeek.values.project.value, 10);
        const filteredEntries = dataEntries.map((day: any) => ({
          ...day,
          entries: day.entries?.filter((entry: any) => entry.projectId === projectId),
        }));
        return filteredEntries;
      }

      return dataEntries;
    }

    // if no user is selected just return the weekentries
    return weekEntries;
  };

  handleChange = () => {
    const { getTimeEntries, calendarWeek } = this.props;
    const selectedYear = calendarWeek.values.week.value.split('-')[0]; // moment(value.value, 'YYYY-WW').week();
    const selectedWeek = calendarWeek.values.week.value.split('-')[1]; // /moment(value.value, 'YYYY-WW').year();
    getTimeEntries(selectedWeek, selectedYear);
  };

  handlePrint = (printEntries: any) => {
    const { calendarWeek, setPrintEntries, history } = this.props;
    const {
      values: { week },
    } = calendarWeek;
    const projectId = parseInt(calendarWeek.values.project.value, 10);
    const userId = parseInt(calendarWeek.values.user.value, 10);
    setPrintEntries(printEntries, week, userId, projectId);
    history.push('/print/timeentry');
  };

  render() {
    const { timeEntry, calendarWeek } = this.props;
    const weekEntries = this.getWeekEntries();
    const filteredEntries = this.getFilteredEntries(weekEntries);
    const checkedFilteredEntries = this.checkFalseEntries(filteredEntries);

    return (
      <Fragment>
        <Grid item md={12}>
          <CalendarWeek
            // TODO: What the hell is even that? Is not used by child but still works
            onChange={this.handleChange}
          />
        </Grid>
        <Grid item md={12}>
          {checkedFilteredEntries && <ProjectOverview />}
        </Grid>
        <Grid item md={12}>
          {checkedFilteredEntries && calendarWeek && (
            <WeekOverview timeEntries={checkedFilteredEntries} handlePrint={this.handlePrint} />
          )}
        </Grid>
        <Grid item md={12}>
          {checkedFilteredEntries && (
            <Fragment>
              {checkedFilteredEntries.map((entry: any) => (
                <Fragment key={entry.date}>
                  {entry.entries.length > 0 && <DayOverview timeEntry={entry} key={entry.date} />}
                </Fragment>
              ))}
            </Fragment>
          )}
        </Grid>
        {calendarWeek && (
          <Fragment>
            <DialogTimeEntryCreate />
            {timeEntry && <DialogTimeEntryEdit />}
          </Fragment>
        )}
        <GreetingDialog />
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PageWeekOverview));
