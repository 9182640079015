import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Form from '../../../../Atoms/Form/Form';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';
import TextFieldForm from '../../../../Atoms/TextField/TextFieldForm';
import SpecificationAToolbar from '../../../../Organisms/Toolbars/SpecificationAToolbar';
import { specificationASelector } from '../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  specificationAs: (filter: any) => specificationASelector(state, filter),
});

type PageSpecificationAEditProps = {
  specificationAs: (...args: any[]) => any;
  match: any;
  initialize: (...args: any[]) => any;
};
class PageSpecificationAEdit extends PureComponent<PageSpecificationAEditProps, {}> {
  componentWillMount() {
    const { specificationAs, match, initialize } = this.props;
    const specificationAId = parseInt(match.params.specificationAId, 10);
    const specificationA = specificationAs({
      include: { specificationAId },
      option: { plain: true },
    });
    initialize({
      ...specificationA,
    });
  }
  render() {
    return (
      <Fragment>
        <SpecificationAToolbar withSave withDelete />
        <AdminSettingsWrapper alignItems="flex-start">
          <Form>
            <TextFieldForm size={4} label="Spezifikation A" name="name" />
          </Form>
        </AdminSettingsWrapper>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  null
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageSpecificationAEdit',
  })(withRouter<any, any>(PageSpecificationAEdit))
);
