import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';
import {
  BILLING_TYPE_FLAT_WITH,
  BILLING_TYPE_FLAT_WITHOUT,
} from '../../../../shared/src/constants/general';
const deactivatedBillingTypes = [BILLING_TYPE_FLAT_WITH, BILLING_TYPE_FLAT_WITHOUT];
export const billingTypeSelector = (
  state: any,
  {
    include = {
      billingTypeId: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.billingTypes,
    (billingTypes) => {
      if (checkSingleValue(include.billingTypeId)) {
        const billingTypeId = Array.isArray(include.billingTypeId)
          ? include.billingTypeId![0]
          : include.billingTypeId;

        const billingType = billingTypes.find((bT: any) => bT.id === parseInt(billingTypeId!, 10));
        if (billingType) {
          if (option.plain) {
            return billingType;
          }
          return { label: billingType.name, value: `${billingType.id}` };
        }
        return null;
      }

      if (billingTypes) {
        if (option.plain) {
          return billingTypes.filter(
            (el: any) => deactivatedBillingTypes.includes(el.id) === false
          );
        }
        return billingTypes
          .filter((el: any) => deactivatedBillingTypes.includes(el.id) === false)
          .map((billingType: any) => ({
            label: billingType.name,
            value: `${billingType.id}`,
          }));
      }
      return null;
    }
  )(null as any);

export default {};
