import PrintFormWrapper from '../../components/Pages/Print/Forms/PrintFormWrapper';
import PageServiceProjectWrapper from '../../components/Templates/PageWrappers/PageServiceProjectWrapper';
import PrintForm from '../../components/Pages/Print/Forms/PrintForm';
import PrintTimeEntry from '../../components/Pages/Print/TimeEntry/PrintTimeEntry';
import PrintServiceEntry from '../../components/Pages/Print/ServiceEntry/PrintServiceEntry';
import PrintVisit from '../../components/Pages/Print/Visit/PrintVisit';
/**
 * @constant
 * @description A constant to define routes for the customer module.
 */
export const printRoute = [
  {
    path: '/print/timeentry',
    component: PrintTimeEntry,
    exact: true,
  },
  {
    path: '/print/serviceproject/:id',
    component: PageServiceProjectWrapper(PrintServiceEntry),
    exact: true,
  },
  {
    path: '/print/form/:id',
    component: PrintFormWrapper(PrintForm),
    exact: true,
  },
  {
    path: '/print/visit',
    component: PrintVisit,
    exact: true,
  },
];

export default {};
