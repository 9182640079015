export const getSortedData: (data: any, prop: any, isAsc: any) => any = (data, prop, isAsc) =>
  data.sort((a: any, b: any) => (a[prop] < b[prop] ? -1 : 1) * (isAsc ? 1 : -1));
export const getSortedDataTwo: (data: any, primaryProp: any, secProp: any, isAsc: any) => any = (
  data,
  primaryProp,
  secProp,
  isAsc
) =>
  data.sort((a: any, b: any) => {
    if (a[primaryProp] === b[primaryProp]) {
      return a[secProp] < b[secProp] ? -1 : 1 * (isAsc ? 1 : -1);
    }
    return a[primaryProp] < b[primaryProp] ? -1 : 1 * (isAsc ? 1 : -1);
  });
export const getSortedDataThree: (
  data: any,
  primaryProp: any,
  secProp: any,
  thirdProp: any,
  isAsc: any
) => any = (data, primaryProp, secProp, thirdProp, isAsc) =>
  data.sort((a: any, b: any) => {
    if (a[primaryProp] === b[primaryProp]) {
      if (a[secProp] === b[secProp]) {
        return a[thirdProp] < b[thirdProp] ? -1 : 1 * (isAsc ? 1 : -1);
      }
      return a[secProp] < b[secProp] ? -1 : 1 * (isAsc ? 1 : -1);
    }
    return a[primaryProp] < b[primaryProp] ? -1 : 1 * (isAsc ? 1 : -1);
  });

export const getSortedDataFour: (
  data: any,
  primaryProp: any,
  secProp: any,
  thirdProp: any,
  fourthProp: any,
  isAsc: any
) => any = (data, primaryProp, secProp, thirdProp, fourthProp, isAsc) =>
  data.sort((a: any, b: any) => {
    if (a[primaryProp] === b[primaryProp]) {
      if (a[secProp] === b[secProp]) {
        if (a[thirdProp] === b[thirdProp]) {
          return a[fourthProp] < b[fourthProp] ? -1 : 1 * (isAsc ? 1 : -1);
        }
        return a[thirdProp] < b[thirdProp] ? -1 : 1 * (isAsc ? 1 : -1);
      }
      return a[secProp] < b[secProp] ? -1 : 1 * (isAsc ? 1 : -1);
    }
    return a[primaryProp] < b[primaryProp] ? -1 : 1 * (isAsc ? 1 : -1);
  });

// module.exports = {
//   getSortedData, getSortedDataTwo, getSortedDataThree, getSortedDataFour,
// };

// export default = {

// }
