import { createSelector } from 'reselect';

export const stationTypeSelector = (
  state: any,
  {
    include = {
      stationTypeId: null,
    },
    option = {
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.stationTypes,
    (stationTypes) => {
      // for multiple users
      let stationTypesOptions = stationTypes;
      if (include.stationTypeId) {
        // @ts-ignore
        stationTypesOptions = stationTypesOptions.filter((e) =>
          // @ts-ignore
          include.stationTypeId.includes(e.id)
        );
      }

      if (stationTypesOptions) {
        // only get the raw / plain data
        if (option.plain) {
          return stationTypesOptions;
        }
        // return autocomplete data
        return stationTypesOptions.map((el: any) => {
          const label = `${el.name}`;
          return { label, value: `${el.id}` };
        });
      }
      return null;
    }
  )(null as any);

export default {};
