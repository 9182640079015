import React, { useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change as changeAction, InjectedFormProps } from 'redux-form';
import Form from '../../../../../../Atoms/Form/Form';
import Button from '../../../../../../Atoms/Button/Button';
import { createInitialFormValues } from '../../../../../../Molecules/Forms/FormHelpers';
import { formCheckQuestionCompletion } from '../../../../../../../helpers/formCompletion';
import { saveFormAction, setSignatureCreateDialogAction } from '../../../../../../../actions';
import {
  PROJECT_MANAGER,
  FORM_TYPE_SITE_CHECK,
} from '../../../../../../../../../shared/src/constants/general';
import { userSelector, stationTypeSelector } from '../../../../../../../helpers/selectors';
import DialogSignature from '../../../../../../Organisms/DialogSignature/DialogSignature';
import SiteCheckQuestions from './SiteCheckQuestions';

// The item renderer is declared outside of the list-rendering component.
// So it has no way to directly access the items array.

const styles = {
  completeButton: {
    marginBottom: '50px',
  },
};

const mapStateToProps = (state: any) => ({
  formSiteCheckGeneral: state.form.formSiteCheckGeneral,
  roles: state.rootReducer.roles,
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
  answerChecks: state.rootReducer.answerChecks,
  formSections: state.rootReducer.formSections,
  formStationTypes: state.rootReducer.formStationTypes,
  stationTypes: (filter: any) => stationTypeSelector(state, filter),
  forms: state.rootReducer.forms,
});
const mapDispatchToProps = (dispatch: any) => ({
  change: (field: any, value: any, form?: any) => dispatch(changeAction(form, field, value)),
  saveForm: (data: any) => dispatch(saveFormAction(data)),
  setSignatureCreateDialog: (open: any) => dispatch(setSignatureCreateDialogAction(open)),
});

interface ComponentOwnProps {
  formId: number | null;
  refs: any;
}

interface ComponentStateProps {
  formSiteCheckGeneral?: any;
  formSections: any[];
  formStationTypes: any[];
  auth: any;
  roles: any[];
  stationTypes: (...args: any[]) => any;
  users: (...args: any[]) => any;
  answerChecks: any[];
  forms: any[];
}

interface ComponentDispatchProps {
  saveForm: (...args: any[]) => any;
  setSignatureCreateDialog: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

const SiteCheckGeneral: React.FunctionComponent<ComponentProps> = ({
  formSiteCheckGeneral = null,
  // formStationTypeId = null,
  initialize,
  users,
  saveForm,
  auth,
  answerChecks,
  roles,
  change,
  formId,
  forms,
  refs,
  setSignatureCreateDialog,
}) => {
  const [user, setUser] = useState<any>(null);
  const [listHeight, setListHeight] = useState<any>(0);
  const SiteCheckQuestionRef = createRef<any>();
  useEffect(() => {
    if (SiteCheckQuestionRef.current) {
      const listHeight = SiteCheckQuestionRef.current.offsetHeight;
      setListHeight(listHeight);
    }
  }, [SiteCheckQuestionRef, SiteCheckQuestionRef.current]);
  useEffect(() => {
    const user = users({
      include: { userId: auth.id },
      option: { plain: true },
    });
    setUser(user);
    // create the value objects
    const initValues = createInitialFormValues(answerChecks);
    // intialize the form data
    initialize({
      ...initValues,
    });
  }, []);
  useEffect(() => {}, [formSiteCheckGeneral?.active]);
  const handleSaveDialog = (signature: string, signedBy: string) => {
    const form: any = forms.find((f: any) => f.id === formId);
    const data = {
      ...form,
      formId: form.id,
      signature,
      signedBy,
      completed: true,
    };
    saveForm(data);
    setSignatureCreateDialog(false);
  };
  const completeEnabled =
    formCheckQuestionCompletion(answerChecks, null, null, FORM_TYPE_SITE_CHECK).notAnswered === 0;
  const formCompleted = forms.find((f: any) => f.id === formId).completed;
  return (
    <>
      {formSiteCheckGeneral && roles && user && (
        <Form>
          {formSiteCheckGeneral.values && (
            <>
              <SiteCheckQuestions
                refs={refs}
                listHeight={listHeight}
                change={change}
                formSiteCheckGeneral={formSiteCheckGeneral}
                user={user}
                SiteCheckQuestionRef={SiteCheckQuestionRef}
                formCompleted={formCompleted}
                answerChecks={answerChecks}
              ></SiteCheckQuestions>
            </>
          )}
        </Form>
      )}
      <Button
        sx={styles.completeButton}
        handleClick={() => setSignatureCreateDialog(true)}
        disabled={!completeEnabled || formCompleted || auth.rightId > PROJECT_MANAGER}
      >
        Abschließen und Unterschreiben PL
      </Button>
      <DialogSignature handleSave={handleSaveDialog} label="Datum, Namen PL-Stopa + Kunde" />
    </>
  );
};

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'formSiteCheckGeneral',
  })(SiteCheckGeneral)
);
