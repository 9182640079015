import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const projectKindSelector = (
  state: any,
  {
    include = {
      projectKindId: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.projectKinds,
    (projectKinds) => {
      // for single selected contractor
      if (checkSingleValue(include.projectKindId)) {
        const projectKindId = Array.isArray(include.projectKindId)
          ? include.projectKindId![0]
          : include.projectKindId;

        const projectKind = projectKinds.find((pT: any) => pT.id === parseInt(projectKindId!, 10));
        if (projectKind) {
          if (option.plain) {
            return projectKind;
          }
          return { label: `${projectKind.name}`, value: `${projectKind.id}` };
        }
        return null;
      }

      if (projectKinds) {
        if (option.plain) {
          return projectKinds;
        }
        return projectKinds.map((projectKind: any) => ({
          label: projectKind.name,
          value: `${projectKind.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
