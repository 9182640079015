import { orderBy } from 'lodash';

import { IProject } from '../../../shared/src/interfaces/Project';
import { IGroup } from '../../../shared/src/interfaces/Group';
import { IServiceEntry, IServiceEntryEnriched } from '../../../shared/src/interfaces/ServiceEntry';

export const getUserWa = (project: IProject, userGroups: IGroup[]) => {
  const waNumbers = userGroups.map((uG) => ({
    name: uG.shortName,
    // @ts-ignore
    number: project[uG.shortName] == null ? '' : project[uG.shortName],
  }));
  if (waNumbers.length >= 1) {
    return waNumbers[0];
  }
  return { number: null };
};

/**
 * sort service entries by date, user, time
 *
 * */
export const getSortedServiceEntries = (
  serviceEntries: IServiceEntry[],
  users: any
): IServiceEntryEnriched[] => {
  if (!serviceEntries) return [];
  const newServiceEntryArray = serviceEntries.map((e: any) => {
    const userLastName = users({
      include: { userId: e.userId },
      option: { plain: true },
    }).lastName;
    return {
      ...e,
      userLastName,
    };
  });
  return orderBy(
    newServiceEntryArray,
    ['date', 'userLastName', 'timeStart'],
    ['asc', 'asc', 'asc']
  );
};
