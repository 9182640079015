import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Grid, AppBar as MuiAppBar, Toolbar, IconButton } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import AddCircle from '@mui/icons-material/AddCircle';
import Build from '@mui/icons-material/Build';
import LibraryBooks from '@mui/icons-material/LibraryBooks';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Menu from '@mui/icons-material/Menu';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import SettingsIcon from '@mui/icons-material/Settings';
import Eye from '@mui/icons-material/RemoveRedEye';
import ThumbUp from '@mui/icons-material/ThumbUp';
import Logout from './components/Logout';
import { ADMIN, PROJECT_MANAGER } from '../../../../../shared/src/constants/general';
import Typography from '../../Atoms/Typography/Typography';
import {
  setTimeEntryDialogCreateAction,
  setServiceEntryDialogAction,
  setSidebarAction,
} from '../../../actions';
import { rightSelector } from '../../../helpers/selectors';
import { DRAWER_WIDTH } from '../../../constants/all';

const styles = {
  scaleUp: {
    transform: 'scale(1.5)',
  },
};

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar)<AppBarProps>(({ open }) => ({
  height: 100,
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  }),
}));

interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = React.memo(() => {
  const history = useHistory();
  const { pathname } = useLocation();

  const auth = useSelector((state: any) => state.rootReducer.auth);
  const rights = useSelector((state: any) => (filter: any) => rightSelector(state, filter));
  const sidebarOpen = useSelector((state: any) => state.rootReducer.sidebarOpen);

  const dispatch = useDispatch();
  const setTimeEntryDialogCreate = (open: any) => dispatch(setTimeEntryDialogCreateAction(open));
  const setServiceEntryDialog = (open: any) => dispatch(setServiceEntryDialogAction(open));
  const setSidebar = (open: any) => dispatch(setSidebarAction(open));

  const handleClick = () => {
    setTimeEntryDialogCreate(true);
  };
  const handleSPEntry = () => {
    setServiceEntryDialog(true);
  };

  return (
    <AppBar color="primary" position="fixed" open={sidebarOpen}>
      <Toolbar>
        <Grid container alignItems={'center'} direction="row" sx={{ height: 100 }}>
          {auth && rights({}) && (
            <Fragment>
              {pathname !== '/overview' && pathname !== '/serviceproject' && (
                <Grid item sm={1}>
                  <IconButton
                    className=""
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => setSidebar(!sidebarOpen)}
                  >
                    {sidebarOpen ? <ArrowBackIos /> : <Menu />}
                  </IconButton>
                </Grid>
              )}
              <Grid item container sm flexDirection={'column'}>
                <Typography
                  size={12}
                  color="light"
                  value={`${auth.firstName} ${auth.lastName}`}
                  caption={
                    rights({
                      include: { rightId: auth.rightId },
                      option: { plain: true },
                    }).name
                  }
                  variant="subtitle1"
                />

                <Typography
                  size={12}
                  value={
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = 'mailto:support@bytepartner.de';
                        e.preventDefault();
                      }}
                      style={{ color: '#D3D3D3', textDecoration: 'underline #D3D3D3' }}
                    >
                      Support-Anfrage stellen oder Fehler melden
                    </Link>
                  }
                  variant="caption"
                />
              </Grid>
              <Grid item container sm={5} justifyContent={'flex-end'}>
                {auth.rightId <= ADMIN && (
                  <IconButton
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => history.push('/controlling')}
                  >
                    <ThumbUp
                      sx={{
                        ...(pathname.includes('controlling') && styles.scaleUp),
                      }}
                    />
                  </IconButton>
                )}

                <>
                  {location.pathname === '/serviceproject' ? (
                    <IconButton color="inherit" onClick={handleSPEntry}>
                      <AddCircle
                        sx={{
                          ...(pathname.includes('serviceproject') && styles.scaleUp),
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      className=""
                      color="inherit"
                      aria-label="Menu"
                      onClick={() => history.push('/serviceproject')}
                    >
                      <Build
                        sx={{
                          ...(pathname.includes('serviceproject') && styles.scaleUp),
                        }}
                      />
                    </IconButton>
                  )}
                </>

                {location.pathname === '/overview' ? (
                  <IconButton color="inherit" onClick={handleClick}>
                    <AddCircle
                      sx={{
                        ...(pathname.includes('overview') && styles.scaleUp),
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton
                    className=""
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => history.push('/overview')}
                  >
                    <AccessTimeIcon
                      sx={{
                        ...(pathname.includes('overview') && styles.scaleUp),
                      }}
                    />
                  </IconButton>
                )}
                {auth.rightId <= PROJECT_MANAGER && (
                  <IconButton
                    color="inherit"
                    aria-label="Menu"
                    onClick={() => history.push('/diagnostic')}
                  >
                    <Eye
                      sx={{
                        ...(pathname.includes('diagnostic') && styles.scaleUp),
                      }}
                    />
                  </IconButton>
                )}
                <IconButton
                  className=""
                  color="inherit"
                  aria-label="Menu"
                  onClick={() => {
                    history.push('/admin/form');
                  }}
                >
                  <LibraryBooks
                    sx={{
                      ...(pathname.includes('/admin/form') && styles.scaleUp),
                    }}
                  />
                </IconButton>
                <IconButton
                  className=""
                  color="inherit"
                  aria-label="Menu"
                  onClick={() => {
                    if (auth.rightId !== ADMIN) {
                      history.push('/admin/user');
                    } else {
                      history.push('/admin/project');
                    }
                  }}
                >
                  <SettingsIcon
                    sx={{
                      ...((pathname.includes('/admin/user') ||
                        pathname.includes('/admin/project')) &&
                        styles.scaleUp),
                    }}
                  />
                </IconButton>
                <Logout />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
});

export default NavBar;
