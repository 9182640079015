/* eslint-disable no-underscore-dangle, no-undef */
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { reducer as form } from 'redux-form';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers';
// Use ES6 object literal shorthand syntax to define the object shape
const reducersCombined = combineReducers({
  rootReducer,
  form,
});

const store = createStore(
  reducersCombined,
  composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<any, any>))
);

export type RootState = ReturnType<typeof store.getState>;
export default store;
