import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import Form from '../../Atoms/Form/Form';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import CheckboxesForm from '../Checkboxes/CheckboxesForm';
import AutocompleteForm from '../Autocomplete/AutocompleteForm';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import {
  SERVICE_BREAK,
  SERVICE_TRAVEL,
  SPECIFICATIONA_ARRIVAL,
  SPECIFICATIONA_DEPARTURE,
} from '../../../../../shared/src/constants/general';

import {
  groupSelector,
  roleSelector,
  serviceSelector,
  serviceTypeSelector,
  specificationASelector,
  specificationBSelector,
  ruleSelector,
} from '../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  rules: (filter: any) => ruleSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  serviceTypes: (filter: any) => serviceTypeSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
});
type RuleDetailsProps = {
  roles?: (...args: any[]) => any;
  groups?: (...args: any[]) => any;
  services?: (...args: any[]) => any;
  specificationAs?: (...args: any[]) => any;
  specificationBs?: (...args: any[]) => any;
};

class RuleDetails extends PureComponent<RuleDetailsProps, {}> {
  render() {
    const { roles, groups, services, specificationAs, specificationBs } = this.props;
    return (
      <Fragment>
        {roles && groups && services && specificationAs && specificationBs && (
          <AdminSettingsWrapper alignItems="flex-start">
            <Form>
              <AutocompleteForm
                size={4}
                name="service"
                options={services({
                  exclude: { serviceId: [SERVICE_BREAK, SERVICE_TRAVEL] },
                })}
                label="Tätigkeit"
              />
              <AutocompleteForm
                size={4}
                options={specificationAs({
                  exclude: {
                    specificationAId: [SPECIFICATIONA_ARRIVAL, SPECIFICATIONA_DEPARTURE],
                  },
                })}
                label="Spezifikation A"
                name="specificationA"
              />
              <AutocompleteForm
                size={4}
                options={specificationBs({})}
                label="Spezifikation B"
                name="specificationB"
              />
              <CheckboxesForm
                label="Gruppen"
                name="groups"
                options={groups({
                  option: { costCentre: true },
                })}
                size={12}
              />
              <CheckboxesForm label="Rollen" name="roles" options={roles({})} size={12} />
              <TextFieldForm size={3} name="wa" label="Kennziffer WA" type="number" />
              <CheckboxesForm
                label=""
                name="withStation"
                options={[{ label: 'Stationsnummer', value: 1 }]}
                size={2}
              />
            </Form>
          </AdminSettingsWrapper>
        )}
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  null
)(RuleDetails);
