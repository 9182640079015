import PageContractorOverview from '../../components/Pages/PageAdminArea/Contractor/PageContractorOverview/PageContractorOverview';
import PageContractorEdit from '../../components/Pages/PageAdminArea/Contractor/PageContractorEdit/PageContractorEdit';
import PageContractorUserEdit from '../../components/Pages/PageAdminArea/Contractor/PageContractorUserEdit/PageContractorUserEdit';
import PageContractorWrapper from '../../components/Templates/PageWrappers/PageContractorWrapper';

/**
 * @constant
 * @description A constant to define routes for the contractor module.
 */
export const contractorRoute = [
  // CONTRACTOR
  {
    path: '/admin/contractor',
    component: PageContractorWrapper(PageContractorOverview),
    exact: true,
  },
  {
    path: '/admin/contractor/:id',
    component: PageContractorWrapper(PageContractorEdit),
    exact: true,
  },
  {
    path: '/admin/contractor/:id/user/:userId',
    component: PageContractorWrapper(PageContractorUserEdit),
    exact: true,
  },
];

export default {};
