import { createSelector } from 'reselect';

export const formStationTypeSelector = (
  state: any,
  {
    include = {
      formId: null,
    },
    option = {
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.formStationTypes,
    (formStationTypes) => {
      // for multiple users
      let formStationTypeOptions = formStationTypes;
      if (include.formId) {
        formStationTypeOptions = formStationTypeOptions.filter(
          (el: any) => el.formId === include.formId
        );
      }

      if (formStationTypeOptions) {
        // only get the raw / plain data
        if (option.plain) {
          return formStationTypeOptions;
        }
        // return autocomplete data
        return formStationTypeOptions.map((el: any) => {
          const label = `${el.name}`;
          return { label, value: `${el.id}` };
        });
      }
      return null;
    }
  )(null as any);

export default {};
