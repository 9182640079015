import React, { useMemo, FC } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

export const ApolloContext: FC = ({ children }) => {
  // const { showErrorMessage } = useToastContext();

  const client = useMemo(() => {
    const errorLink = onError(({ networkError, graphQLErrors }) => {
      // showErrorMessage(f({ id: "alert.error" }));
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        );
      }
      if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_SERVER_URL,
      credentials: 'include',
    });

    const link = ApolloLink.from([errorLink, httpLink]);

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache({
        typePolicies: {
          // SearchItem: {
          //   keyFields: ['id', 'model'],
          // },
        },
      }),
    });

    return client;
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
