import React, { Fragment, PureComponent } from 'react';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';

class ContractorDetails extends PureComponent<{}, {}> {
  render() {
    return (
      <Fragment>
        <TextFieldForm size={3} name="name" label="Name" />
        <TextFieldForm size={3} name="shortName" label="Kurzform" />
        <TextFieldForm size={3} name="adress" label="Adresse" />
        <TextFieldForm size={3} name="city" label="Ort" />
        <TextFieldForm size={3} name="postalCode" label="Postleitzahl" />
        <TextFieldForm size={3} name="country" label="Land" />
        <TextFieldForm size={3} name="telephone" label="Telefon" />
        <TextFieldForm size={3} name="email" label="E-Mail" />
      </Fragment>
    );
  }
}
export default ContractorDetails;
