import {
  FORM_TYPE_CHECK,
  FORM_SECTION_STEELWORK,
  FORM_SECTION_GENERAL,
  FORM_SECTION_RBG,
  FORM_SECTION_STATION,
  FORM_TYPE_RETROFIT,
  FORM_SECTION_RETROFIT_RBG,
  FORM_SECTION_RETROFIT_CONTROL,
  FORM_SECTION_RETROFIT_HANDED,
  FORM_SECTION_RETROFIT_INSTALLATION,
  FORM_SECTION_RETROFIT_STATION,
  MECHANIC,
  PROJECT_MANAGER,
  ADMIN,
  DEPARTMENT_MANAGER,
  FORM_TYPE_SITE_CHECK,
  FORM_SECTION_SITE_CHECK_DELIVERY,
  FORM_SECTION_SITE_CHECK_STORAGE_LAYOUT,
  FORM_SECTION_SITE_CHECK_HALL,
  FORM_SECTION_SITE_CHECK_HOIST,
  FORM_SECTION_SITE_CHECK_ORGANISATION,
} from '../../../shared/src/constants/general';

// export const SERVICE_PROJECT_LABELS: any = { 1: 'Offen', 2: 'Abgechlossen', 3: 'Beendet' };
export const SERVICE_PROJECT_STATES: any[] = [
  { value: 1, label: 'Offen' },
  { value: 2, label: 'Abgeschlossen' },
  { value: 3, label: 'Int.Gepr' },
  { value: 4, label: 'Verworfen' },
];
// TIME SHEETS
export const TIME_SHEET_STATE_WIP = 1;
export const TIME_SHEET_STATE_PRINTED = 2;
export const TIME_SHEET_STATE_DONE = 3;
export const TIME_SHEET_STATE_FINAL = 4;

// TABS
// RULE
export const RULE_EDIT_DETAILS = 0;
export const RULE_EDIT_SERVICES = 1;
export const RULE_EDIT_SPECIFICATIONAS = 2;
export const RULE_EDIT_SPECIFICATIONBS = 3;

// DRAWER WIDTH
export const DRAWER_WIDTH = 240;

// TODO: See backenend formhelpers
export const formSectionsConst: any[] = [
  {
    formTypeId: FORM_TYPE_CHECK,
    section: [FORM_SECTION_GENERAL, FORM_SECTION_STEELWORK, FORM_SECTION_RBG, FORM_SECTION_STATION],
  },
  {
    formTypeId: FORM_TYPE_RETROFIT,
    section: [
      FORM_SECTION_RETROFIT_RBG,
      FORM_SECTION_RETROFIT_CONTROL,
      FORM_SECTION_RETROFIT_HANDED,
      FORM_SECTION_RETROFIT_INSTALLATION,
      FORM_SECTION_RETROFIT_STATION,
    ],
  },
  {
    formTypeId: FORM_TYPE_SITE_CHECK,
    section: [
      FORM_SECTION_SITE_CHECK_DELIVERY,
      FORM_SECTION_SITE_CHECK_STORAGE_LAYOUT,
      FORM_SECTION_SITE_CHECK_HALL,
      FORM_SECTION_SITE_CHECK_HOIST,
      FORM_SECTION_SITE_CHECK_ORGANISATION,
    ],
  },
];

// SCROLLSPY WIDTH
export const SCROLLSPY_WIDTH = 110;
export const PROJECT_SCROLLSPY_ITEMS = [
  { value: 'projectDetails', label: 'Details', rightId: MECHANIC, noContractor: false },
  { value: 'projectUser', label: 'Monteure', rightId: PROJECT_MANAGER, noContractor: false },
  { value: 'projectManager', label: 'PL & AL', rightId: ADMIN, noContractor: false },
];
export const CONTRACTOR_SCROLLSPY_ITEMS = [
  { value: 'contractorDetails', label: 'Details', rightId: MECHANIC, noContractor: false },
  { value: 'contractorUser', label: 'Monteure', rightId: PROJECT_MANAGER, noContractor: false },
];
export const CUSTOMER_SCROLLSPY_ITEMS = [
  { value: 'customerDetails', label: 'Details', rightId: MECHANIC, noContractor: false },
  {
    value: 'customerContacts',
    label: 'Ansprechpartner',
    rightId: PROJECT_MANAGER,
    noContractor: false,
  },
  { value: 'customerSites', label: 'Baustellen', rightId: PROJECT_MANAGER, noContractor: false },
];

export const SIDEBAR_ADMIN_ITEMS = [
  { link: '/admin/user', value: 'user', label: 'STOPA-MA', rightId: MECHANIC, noContractor: false },
  {
    link: '/admin/contractor',
    value: 'contractor',
    label: 'Fremdfirmen',
    rightId: ADMIN,
    noContractor: false,
  },
  {
    link: '/admin/customer',
    value: 'customer',
    label: 'Kunden',
    rightId: PROJECT_MANAGER,
    noContractor: false,
  },
  {
    link: '/admin/project',
    value: 'project',
    label: 'Projekte',
    rightId: DEPARTMENT_MANAGER,
    noContractor: false,
  },
  { link: '/admin/rule', value: 'rule', label: 'Leistungen', rightId: ADMIN, noContractor: false },
];

export const SIDEBAR_DIAGNOSTIC_ITEMS = [
  {
    link: '/diagnostic',
    value: 'diagnostic',
    label: 'Diagnostik',
    rightId: PROJECT_MANAGER,
    noContractor: false,
    subMenuItems: [
      {
        link: '/diagnostic/projectVisit',
        value: 'projectVisit',
        label: 'Projektbesuche',
        rightId: PROJECT_MANAGER,
        noContractor: false,
      },
      {
        link: '/diagnostic/projectHours',
        value: 'projectHours',
        label: 'Projektstunden',
        rightId: PROJECT_MANAGER,
        noContractor: false,
      },
    ],
  },
];
export const SIDEBAR_FORM_ITEMS = [
  {
    link: '/admin/form',
    value: 'form',
    label: 'Protokolle',
    rightId: MECHANIC,
    noContractor: false,
    subMenuItems: [
      {
        link: '/admin/form',
        value: 'formSiteStart',
        label: 'Baustellen-Start',
        rightId: MECHANIC,
        noContractor: false,
      },
      {
        link: '/admin/form',
        value: 'formCheck',
        label: 'Baustellen-Check',
        rightId: MECHANIC,
        noContractor: false,
      },
      {
        link: '/admin/form',
        value: 'formSiteCheck',
        label: 'Projektleiter-Check',
        rightId: PROJECT_MANAGER,
        noContractor: false,
      },
      {
        link: '/admin/form',
        value: 'formVisit',
        label: 'Projektbesuche',
        rightId: PROJECT_MANAGER,
        noContractor: false,
      },
    ],
  },
];
export default {};
