import React, { Fragment, FC } from 'react';
import { useSelector } from 'react-redux';

import { Grid, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import Typography from '../../../../Atoms/Typography/Typography';
import {
  TIME_SHEET_EXPORTED,
  TIME_SHEET_STATES,
} from '../../../../../../../shared/src/constants/general';
import Button from '../../../../Atoms/Button/Button';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ControllingWeekOverview from '../ControllingWeekOverview/ControllingWeekOverview';
import { DayOverview } from '../ControllingDayOverview/ControllingDayOverview';
import { IEntryProject } from '../../../../../types/frontedTypes';
import { TIME_ENTRY_TYPE } from '../../../../../../../shared/src/enums/enums';
import { userSelector } from '../../../../../helpers/selectors';
import { useProjectEntriesData } from '../../useProjectEntriesData';

const styles = {
  paddingZero: {
    padding: '0px!important',
    '& .MuiAccordionSummary-root': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  paper: {
    width: '100%',
  },
};

interface IControllingEntriesOverviewProps {
  handleComplete: any;
  handlePrint: any;
  projectUserId: number;
  selectedUserId: number | null;
  project: IEntryProject;
  type: TIME_ENTRY_TYPE;
}

export const ControllingEntriesOverview: FC<IControllingEntriesOverviewProps> = ({
  handleComplete,
  handlePrint,
  projectUserId,
  selectedUserId,
  project,
  type,
}) => {
  const users = useSelector((state: any) => (filter: any) => userSelector(state, filter));

  const { projectNumber, customer, site } = useProjectEntriesData({ type, id: project.projectId });

  return (
    <>
      <Grid item container justifyContent="flex-start" md={12} alignItems="flex-start">
        <Accordion elevation={0} sx={styles.paper}>
          <AccordionSummary sx={styles.paddingZero} expandIcon={<ExpandMore />}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Typography
                value={users({ include: { userId: projectUserId } }).label}
                size={3}
                caption={'Mitarbeiter'}
                captionType="top"
                variant="body1"
              />
              <Typography
                value={`${projectNumber}, ${customer.name}, ${site.city}/${site.country}`}
                size={4}
                caption={'P-Nr, Kunde, Baustelle Ort/Land'}
                captionType="top"
                variant="body1"
              />
              {project?.state && (
                <Typography
                  value={TIME_SHEET_STATES.find((el) => el.value === project.state)!.label}
                  size={2}
                  caption={'Status'}
                  captionType="top"
                  variant="body1"
                />
              )}
              {type === TIME_ENTRY_TYPE.PROJECT &&
                project.state === TIME_SHEET_EXPORTED &&
                selectedUserId != null && (
                  <Button size={2} handleClick={() => handleComplete(project.projectId)} alignRight>
                    Freigeben
                  </Button>
                )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item md={12}>
                <ControllingWeekOverview
                  timeEntries={project.dayEntries}
                  handlePrint={handlePrint}
                />
              </Grid>
              <Grid item md={12}>
                <Fragment>
                  {project.dayEntries.map((entry) => (
                    <Fragment key={entry.date}>
                      {entry.entries.length > 0 && (
                        <DayOverview
                          type={type}
                          projectId={project.projectId}
                          timeEntry={entry}
                          key={entry.date}
                        />
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};
