import React, { memo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';
import { siteSelector } from '../../../helpers/selectors';
import { compareProps } from '../../../helpers/compareProps';

const mapStateToProps = (state: any) => ({
  sites: (filter: any) => siteSelector(state, filter),
  // For memo prop compare and rerender
  compareSites: state.rootReducer.sites,
});
type SiteTableProps = {
  match: any;
  history: any;
  sites: (...args: any[]) => any;
};
const SiteTable: React.SFC<SiteTableProps> = memo(({ sites, match, history }) => {
  const getSites = () => {
    const customerId = parseInt(match.params.id, 10);
    return sites({
      include: { customerId },
      option: { plain: true, deactivated: true },
    });
  };
  return (
    <AdminSettingsWrapper>
      {getSites()?.length && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Operation</TableCell>
              <TableCell>Adresse</TableCell>
              <TableCell>Zusatzfeld</TableCell>
              <TableCell>Postleitzahl</TableCell>
              <TableCell>Stadt</TableCell>
              <TableCell>Land</TableCell>
              <TableCell>FAB</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getSites().map((site: any) => (
              <ActiveTableRow key={site.id} gray={site.deactivated}>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      const customerId = parseInt(match.params.id, 10);
                      history.push(`/admin/customer/${customerId}/site/${site.id}`);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {site.adress}
                </TableCell>
                <TableCell>{site.adressAdditional}</TableCell>
                <TableCell>{site.postalCode}</TableCell>
                <TableCell>{site.city}</TableCell>
                <TableCell>{site.country}</TableCell>
                <TableCell>{site.fab}</TableCell>
              </ActiveTableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </AdminSettingsWrapper>
  );
}, compareProps);

export default connect(mapStateToProps, null)(withRouter<any, any>(SiteTable));
