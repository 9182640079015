// import { authenticationRoute } from './authentication';
import { generalRoute } from './general';
// import { adminRoute } from './admin';
import { userRoute } from './user';
import { contractorRoute } from './contractor';
import { customerRoute } from './customer';
import { projectRoute } from './project';
import { formRoute } from './form';
import { printRoute } from './print';
import { ruleRoute } from './rule';
import { diagnosticRoute } from './diagnostic';

/**
 * @constant
 * @description A constant to define all routes.
 */

const routes = [
  // ...authenticationRoute,
  ...formRoute,
  ...userRoute,
  ...contractorRoute,
  ...customerRoute,
  ...projectRoute,
  ...ruleRoute,
  ...generalRoute,
  ...printRoute,
  ...diagnosticRoute,
];

export default routes;
