import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from '../../Atoms/Form/Form';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import Button from '../../Atoms/Button/Button';
import { addCustomerAction } from '../../../actions';

const mapDispatchToProps = (dispatch: any) => ({
  addCustomer: (name: any, adress: any, city: any, postalCode: any) =>
    dispatch(
      addCustomerAction({
        name,
        adress,
        city,
        postalCode,
      })
    ),
});

type CustomerCreateProps = {
  addCustomer: (...args: any[]) => any;
  initialize: (...args: any[]) => any;
  reset: (...args: any[]) => any;
  handleSubmit: (...args: any[]) => any;
};

class CustomerCreate extends PureComponent<CustomerCreateProps, {}> {
  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      name: '',
      adress: '',
      city: '',
      postalCode: '',
    });
  }
  handleCreate = (values: any) => {
    const { addCustomer, reset } = this.props;
    const { name, adress, city, postalCode } = values;
    addCustomer(name, adress, city, postalCode);
    reset();
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <AdminSettingsWrapper editWrapper>
        <Form handleSubmit={handleSubmit(this.handleCreate)}>
          <TextFieldForm size={3} name="name" label="Name" />
          <TextFieldForm size={3} name="adress" label="Straße und Hausnummer" />
          <TextFieldForm name="postalCode" label="Postleitzahl" />
          <TextFieldForm name="city" label="Stadt" />
          <Button handleClick={handleSubmit(this.handleCreate)} alignRight>
            Neu Anlegen
          </Button>
        </Form>
      </AdminSettingsWrapper>
    );
  }
}

export default connect<any, any, any>(
  null,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'customerCreate',
  })(CustomerCreate)
);
