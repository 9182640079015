import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import Typography from '../../Atoms/Typography/Typography';
import {
  PROJECT_MANAGER,
  PROJECT_STATE_ACTIVE,
  SERVICE_TRAVEL,
  SPECIFICATIONA_ARRIVAL,
} from '../../../../../shared/src/constants/general';
import { getSortedDataTwo } from '../../../helpers/getSortedData';
import { setProjectVisitDialogAction, setVisitEntryAction } from '../../../actions';
import formatTime from '../../../helpers/FormatTime';
import {
  userSelector,
  ruleSelector,
  projectSelector,
  customerSelector,
  siteSelector,
  contractorSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
} from '../../../helpers/selectors';

const styles = {
  paddingZero: {
    padding: '0px!important',
  },
  paper: {
    width: '100%',
  },
};

const mapDispatchToProps = (dispatch: any) => ({
  setProjectVisitDialog: (open: any) => dispatch(setProjectVisitDialogAction(open)),
  setVisitEntry: (visitEntry: any) => dispatch(setVisitEntryAction(visitEntry)),
});
const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  contractors: (filter: any) => contractorSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  visits: state.rootReducer.visits,
  projectVisit: state.form.projectVisit,
  formFilter: state.form.formFilter,
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  auth: any;
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  rules: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
  visits: any[];
  projectVisit?: any;
  formFilter?: any;
}

interface ComponentDispatchProps {
  setProjectVisitDialog: (...args: any[]) => any;
  setVisitEntry: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

type VisitTableState = {
  isExpanded: boolean;
};
class VisitTable extends PureComponent<ComponentProps, VisitTableState> {
  // static defaultProps = {
  //   projectVisit: null,
  //   visits: null,
  //   users: null,
  //   projects: null,
  //   customers: null,
  //   sites: null,
  //   rules: null,
  //   services: null,
  //   specificationAs: null,
  //   specificationBs: null,
  // };

  state = {
    isExpanded: false,
  };

  getDuration = (start: any, end: any) =>
    end === '23:59' ? formatTime(start, '23:00') + 1 : formatTime(start, end);

  getProjectManager = (project: any) => {
    const { users } = this.props;
    const projectUser = project.userIds.map((u: any) => u);
    return users({ option: { plain: true, deactivated: true } }).find(
      (user: any) => projectUser.includes(user.id) && user.rightId === PROJECT_MANAGER
    );
  };

  getProjectsWithVisit = () => {
    const { auth, projects, visits, projectVisit, formFilter } = this.props;

    let projectIds = visits.map((v) => v.projectId);
    if (formFilter?.values?.project) {
      projectIds = projectIds.filter(
        (projectId) => projectId === parseInt(formFilter.values?.project?.value, 10)
      );
    }

    let include: any = { projectId: projectIds };

    if (projectVisit) {
      if (projectVisit.values) {
        if (auth.rightId === PROJECT_MANAGER) {
          include = { ...include, userId: parseInt(auth.id, 10) };
        } else if (projectVisit.values.projectManager) {
          include = { ...include, userId: parseInt(projectVisit.values.projectManager.value, 10) };
        }
        if (projectVisit.values.project) {
          include = { ...include, projectId: parseInt(projectVisit.values.project.value, 10) };
        }
      }
    }
    const projectWithVisits = projects({
      include: { ...include },
      option: { deactivated: true, plain: true, array: true },
    });
    return projectWithVisits;
  };

  getVisits = () => {
    const { projects, visits, rules } = this.props;
    const projectIds = visits.map((v) => v.projectId);
    const projectWithVisits = projects({
      option: { plain: true, deactivated: true },
    }).filter((p: any) => projectIds.includes(p.id));

    const projectVisitsArray = projectWithVisits.map((project: any) => {
      const sortedProjectVists = getSortedDataTwo(
        visits.filter((v) => v.projectId === project.id),
        'date',
        'timeStart',
        true
      );
      let counter = 0;
      const projectVisitsNumber = sortedProjectVists.map((visit: any) => {
        const rule = rules({ include: { ruleId: visit.ruleId } });
        const newVisit = { ...visit, rule, visitNumber: 0 };
        if (rule.serviceId === SERVICE_TRAVEL && rule.specificationAId === SPECIFICATIONA_ARRIVAL) {
          counter += 1;
          newVisit.visitNumber = counter;
        }
        return newVisit;
      });
      return projectVisitsNumber;
    });
    const flattenProjectArray = projectVisitsArray.reduce(
      (acc: any, val: any) => acc.concat(val),
      []
    ); // dont use .flat() cause of ie support
    return flattenProjectArray;
  };
  // TODO GET NUMBERS FOR LEGEND
  getVisitNumber = (projectId: any) => {
    const { visits, rules } = this.props;
    let counter = 0;
    const projectVisits = visits.filter((el) => el.projectId === projectId);
    projectVisits.forEach((visit) => {
      const rule = rules({ include: { ruleId: visit.ruleId } });
      if (rule.serviceId === SERVICE_TRAVEL && rule.specificationAId === SPECIFICATIONA_ARRIVAL) {
        counter += 1;
      }
    });
    return counter;
  };

  handleProjectVisitDialog = (id: any) => {
    const {
      setProjectVisitDialog,
      setVisitEntry,
      visits,
      projects,
      sites,
      customers,
      rules,
      services,
      specificationAs,
      specificationBs,
      users,
    } = this.props;
    const foundEntry = visits.find((v) => v.id === id);
    if (foundEntry) {
      const duration = this.getDuration(foundEntry.timeStart, foundEntry.timeEnd);
      const user = users({
        include: { userId: foundEntry.userId },
        option: { plain: true },
      });
      const project = projects({
        include: {
          projectId: foundEntry.projectId,
        },
        option: { plain: true },
      });
      const site = sites({ include: { siteId: project.siteId, plain: true } });
      const customer = customers({
        include: { customerId: project.customerId },
        option: { plain: true },
      });
      const rule = rules({ include: { ruleId: foundEntry.ruleId } });
      const service = rule.serviceId
        ? services({
            include: { serviceId: rule.serviceId },
            option: { plain: true },
          }).name
        : 'Keine Angabe';
      const specificationA = rule.specificationAId
        ? specificationAs({
            include: { specificationAId: rule.specificationAId },
            option: { plain: true },
          }).name
        : 'Keine Angabe';
      const specificationB = rule.specificationBId
        ? specificationBs({
            include: { specificationBId: rule.specificationBId },
            option: { plain: true },
          }).name
        : 'Keine Angabe';
      const newEntry = {
        ...foundEntry,
        duration,
        user,
        projectNumber: project.number,
        site: site.label,
        customerName: customer.name,
        service,
        specificationA,
        specificationB,
      };
      setVisitEntry(newEntry);
    }
    setProjectVisitDialog(true);
  };
  handleChange = () => {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  };
  render() {
    const { isExpanded } = this.state;
    const { customers, sites } = this.props;
    return (
      <Fragment>
        <Grid item container justifyContent="flex-start" md={12} alignItems="flex-start">
          {getSortedDataTwo(this.getProjectsWithVisit(), 'projectStateId,', 'number', true).map(
            (project: any) => (
              <Accordion
                onChange={this.handleChange}
                key={project.id}
                elevation={0}
                sx={styles.paper}
              >
                <AccordionSummary sx={styles.paddingZero}>
                  <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
                    <Typography value={project.number.toString()} size={2} variant="body2" />
                    <Typography
                      value={
                        customers({
                          include: { customerId: project.customerId },
                          option: { plain: true },
                        }).name
                      }
                      size={2}
                      variant="body2"
                    />
                    <Typography
                      value={
                        sites({
                          include: { siteId: project.siteId },
                          option: { plain: true },
                        }).adress
                      }
                      size={2}
                      variant="body2"
                    />
                    <Typography
                      value={project.projectStateId === PROJECT_STATE_ACTIVE ? 'aktiv' : 'inaktiv'}
                      size={2}
                      variant="body2"
                    />
                    <Typography
                      value={this.getProjectManager(project).lastName}
                      size={2}
                      variant="body2"
                    />
                    <Typography
                      value={this.getVisitNumber(project.id).toString()}
                      size={1}
                      variant="body2"
                    />
                    <IconButton color="inherit" aria-label="Menu">
                      {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={styles.paddingZero}>
                  <Grid container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item md={12}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Besuch</TableCell>
                            <TableCell>Reise</TableCell>
                            <TableCell>Besprechungs Termin</TableCell>
                            <TableCell>von</TableCell>
                            <TableCell>bis</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        {this.getVisits()
                          .filter((visit: any) => visit.projectId === project.id)
                          .map((visit: any) => (
                            <TableBody key={visit.id}>
                              <TableRow>
                                <TableCell>
                                  {visit.visitNumber !== 0 ? visit.visitNumber : ''}
                                </TableCell>
                                <TableCell>
                                  {visit.rule.serviceId === SERVICE_TRAVEL &&
                                    ((visit.rule.specificationAId === SPECIFICATIONA_ARRIVAL &&
                                      `An: ${moment(visit.date, 'YYYY-MM-DD').format(
                                        'YYYY-MM-DD'
                                      )}`) ||
                                      (visit.rule.specificationAId !== SPECIFICATIONA_ARRIVAL &&
                                        `Ab: ${moment(visit.date, 'YYYY-MM-DD').format(
                                          'YYYY-MM-DD'
                                        )}`))}
                                </TableCell>
                                <TableCell>
                                  {visit.rule.serviceId !== SERVICE_TRAVEL
                                    ? moment(visit.date, 'YYYY-MM-DD').format('YYYY-MM-DD')
                                    : ''}
                                </TableCell>
                                <TableCell>{visit.timeStart}</TableCell>
                                <TableCell>{visit.timeEnd}</TableCell>
                                <TableCell>
                                  {visit.rule.serviceId !== SERVICE_TRAVEL && (
                                    <IconButton
                                      onClick={() => this.handleProjectVisitDialog(visit.id)}
                                    >
                                      <InsertDriveFile />
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                      </Table>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(VisitTable);
