import React, { useState, FC } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/de';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Print from '@mui/icons-material/Print';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import Typography from '../../../../Atoms/Typography/Typography';
import TextField from '../../../../Atoms/TextField/TextField';
import Button from '../../../../Atoms/Button/Button';
import ActiveTableRow from '../../../../Atoms/ActiveTableRow/ActiveTableRow';
import formatTime from '../../../../../helpers/FormatTime';
import { SERVICE_PROJECT_STATE_CANCLED } from '../../../../../../../shared/src/constants/general';
import {
  SERVICE_PROJECT_STATE_FINISHED,
  SERVICE_PROJECT_STATE_CLOSED,
  BILLING_TYPE_FLAT_WITHOUT,
  SERVICE_TYPE_WORK,
  SERVICE_TYPE_TRAVEL,
  SERVICE_TYPE_BREAK,
} from '../../../../../../../shared/src/constants/general';

import {
  setServiceEntryDialogAction,
  getServiceProjectEntriesAction,
  setServiceEntryAction,
} from '../../../../../actions';
import { getSortedServiceEntries } from '../../../../../helpers/utils';
import { SERVICE_PROJECT_STATES } from '../../../../../constants/all';
import {
  customerSelector,
  siteSelector,
  //   projectSelector,
  userSelector,
  //   roleSelector,
  serviceTypeSelector,
  serviceSelector,
  billingTypeSelector,
  ruleSelector,
} from '../../../../../helpers/selectors';
import { saveServiceProjectAction, setPrintOptionsAction } from '../../../../../actions';

const mapStateToProps = (state: any) => ({
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  serviceTypes: (filter: any) => serviceTypeSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  billingTypes: (filter: any) => billingTypeSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
  serviceEntries: state.rootReducer.serviceEntries,
  serviceEntry: state.rootReducer.serviceEntry,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveServiceProject: (data: any) => dispatch(saveServiceProjectAction(data)),
  setServiceEntryDialog: (open: any) => dispatch(setServiceEntryDialogAction(open)),
  setServiceEntry: (serviceEntry: any) => dispatch(setServiceEntryAction(serviceEntry)),
  getServiceEntries: (data: any) => dispatch(getServiceProjectEntriesAction(data)),
  setPrintOptions: (data: any) => dispatch(setPrintOptionsAction(data)),
});

const styles = {
  gray: {
    backgroundColor: '#c2c2c2',
  },
  paddingZero: {
    padding: '0px!important',
  },

  paper: {
    width: '100%',
  },
};

interface ComponentOwnProps {
  serviceProject: any;
  toggleServiceProjectEdit: any;
  history: any;
}

interface ComponentStateProps {
  //   timeSheets: any[];
  sites: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  users: (...args: any[]) => any;
  serviceTypes: (...args: any[]) => any;
  services: (...args: any[]) => any;
  billingTypes: (...args: any[]) => any;
  rules: (...args: any[]) => any;
  serviceEntries: any;
  serviceEntry: any;
}

interface ComponentDispatchProps {
  setServiceEntryDialog: (...args: any[]) => any;
  setServiceEntry: (...args: any[]) => any;
  saveServiceProject: (...args: any[]) => any;
  getServiceEntries: (...args: any[]) => any;
  setPrintOptions: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

const SingleServiceProject: React.FC<ComponentProps> = ({
  toggleServiceProjectEdit,
  serviceProject,
  history,
  serviceEntries,
  customers,
  sites,
  users,
  serviceTypes,
  services,
  rules,
  getServiceEntries,
  saveServiceProject,
  setServiceEntry,
  setServiceEntryDialog,
  setPrintOptions,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const selectedServiceEntries = (serviceEntries && serviceEntries[serviceProject?.id]) || [];

  const expandServiceEntries = () => {
    if (!isExpanded) {
      // fetch data
      getServiceEntries(serviceProject.id);
    }
    setIsExpanded((isExpanded) => !isExpanded);
  };

  const handleEditServiceEntry = async (serviceEntryId: any) => {
    const foundServiceEntry = selectedServiceEntries.find(
      (entry: any) => entry.id === serviceEntryId
    );
    if (foundServiceEntry) {
      // add the serviceTypeId, serviceId, specificationA, speicifactionBId from the rule
      const rule = rules({ include: { ruleId: foundServiceEntry.ruleId } });
      const addedRuleData = {
        // eslint-disable-next-line
        ...foundServiceEntry,
        serviceTypeId: rule.serviceTypeId,
        serviceId: rule.serviceId,
        specificationAId: rule.specificationAId,
        specificationBId: rule.specificationBId,
      };
      setServiceEntry(addedRuleData);
      setServiceEntryDialog(true);
    }
  };
  const handleDuplicateServiceEntry = async (serviceEntryId: any) => {
    const foundServiceEntry = selectedServiceEntries.find(
      (entry: any) => entry.id === serviceEntryId
    );
    if (foundServiceEntry) {
      // add the serviceTypeId, serviceId, specificationA, speicifactionBId from the rule
      const rule = rules({ include: { ruleId: foundServiceEntry.ruleId } });
      const addedRuleData = {
        // eslint-disable-next-line
        ...foundServiceEntry,
        // serviceTypeId: rule.serviceTypeId,
        // serviceId: rule.serviceId,
        // specificationAId: rule.specificationAId,
        // specificationBId: rule.specificationBId,
      };
      const {
        id,
        user,
        role,
        serviceType,
        service,
        specificationA,
        specificationB,
        ...restServiceProject
      } = foundServiceEntry;
      setServiceEntry(restServiceProject);
      setServiceEntryDialog(true);
    }
  };
  const calculateTime = (serviceTypeId: any) => {
    if (selectedServiceEntries.length < 1) {
      return 0;
    }
    const result = selectedServiceEntries.filter((entry: any) => {
      // get the selected rule for the entry
      const rule = rules({ include: { ruleId: entry.ruleId } });
      // check if its the serviceType we are looking fo
      if (
        rule.serviceTypeId === serviceTypeId &&
        entry.billingTypeId !== BILLING_TYPE_FLAT_WITHOUT
      ) {
        return entry;
      }
      return null;
    });
    // .reduce((acc: any, val: any) => acc.concat(val), []); //flatten array;
    if (result) {
      const calculated = result.map((entry: any) => {
        if (entry.timeEnd === '23:59') {
          const calculatedTime = formatTime(entry.timeStart, '23:00') + 1;
          return calculatedTime;
        }
        const dis = formatTime(entry.timeStart, entry.timeEnd);
        return dis;
      });
      const sum = calculated.reduce((pv: any, cv: any) => pv + cv, 0);
      return sum;
    }
    return 0;
  };
  const handleCloseProject = () => {
    saveServiceProject({ id: serviceProject.id, state: SERVICE_PROJECT_STATE_CLOSED });
  };
  const handlePrint = ({ isInternalDoc }: { isInternalDoc: boolean }) => {
    setPrintOptions({ isInternalDoc });
    history.push(`/print/serviceproject/${serviceProject.id}`);
  };

  const workTime = calculateTime(SERVICE_TYPE_WORK).toFixed(2);
  const travelTime = calculateTime(SERVICE_TYPE_TRAVEL).toFixed(2);
  const breakTime = calculateTime(SERVICE_TYPE_BREAK).toFixed(2);
  const totalWork = workTime + travelTime;
  const customerLabelMaxLen = 12;
  return (
    <>
      <Grid item container md={12}>
        <Accordion
          onChange={expandServiceEntries}
          elevation={0}
          sx={{
            ...styles.paper,
            ...(serviceProject.state === SERVICE_PROJECT_STATE_CANCLED && styles.gray),
          }}
        >
          <AccordionSummary sx={styles.paddingZero}>
            <Grid container alignItems="flex-end" spacing={2}>
              <Grid container justifyContent="center" alignSelf={'center'} md={'auto'}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleServiceProjectEdit(serviceProject);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Grid>
              <Typography
                value={customers({
                  include: { customerId: serviceProject.customerId },
                }).label.substring(0, customerLabelMaxLen)}
                caption="Kunde"
                size={2}
                captionType="bot"
                variant="body2"
              />
              <Typography
                size={1}
                value={
                  sites({
                    include: { siteId: serviceProject.siteId },
                    option: { plain: true },
                  }).fab
                }
                caption="FAB"
                captionType="bot"
                variant="body2"
              />

              <Typography
                size={2}
                value={serviceProject.serviceProjectNumber}
                caption="Service-Einsatz"
                captionType="bot"
                variant="body2"
              />
              <Typography
                size={2}
                value={moment(serviceProject.createdAt, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                caption="Erstellt am"
                captionType="bot"
                variant="body2"
              />
              <Typography
                size={1}
                value={
                  SERVICE_PROJECT_STATES.find(
                    (el: any) => el.value === parseInt(serviceProject.state, 10)
                  ).label
                }
                variant="body2"
                caption="Status"
                captionType="bot"
              />
              <Grid container justifyContent={'center'} alignSelf={'center'} md>
                {serviceProject.state === SERVICE_PROJECT_STATE_FINISHED ? (
                  <>
                    <Button
                      size={'auto'}
                      buttonSize="small"
                      sx={{ padding: 0.5 }}
                      handleClick={() => handleCloseProject()}
                    >
                      Freigeben
                    </Button>
                    <PrintButtons handlePrint={handlePrint} />
                  </>
                ) : serviceProject.state === SERVICE_PROJECT_STATE_CLOSED ? (
                  <>
                    <Button
                      buttonSize="small"
                      size={'auto'}
                      handleClick={() => handlePrint({ isInternalDoc: false })}
                      disabled={
                        totalWork < 0 || serviceProject.state === SERVICE_PROJECT_STATE_CANCLED
                      }
                      sx={{ padding: 0.5 }}
                    >
                      Export
                    </Button>
                    <PrintButtons handlePrint={handlePrint} />
                  </>
                ) : (
                  <Button
                    buttonSize="small"
                    size={'auto'}
                    handleClick={() => handlePrint({ isInternalDoc: false })}
                    alignRight
                    disabled={
                      totalWork < 0 || serviceProject.state === SERVICE_PROJECT_STATE_CANCLED
                    }
                    sx={{ margin: 'auto' }}
                  >
                    Export
                  </Button>
                )}
              </Grid>

              <Grid container justifyContent="center" alignSelf={'center'} md={'auto'}>
                <IconButton color="inherit" aria-label="Menu">
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={styles.paddingZero}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item md={11}>
                <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={3}>
                  <TextField
                    id={`${serviceProject.id}comment`}
                    size={3}
                    value={serviceProject.comment}
                    disabled
                    label="Kommentar"
                    multiline={isExpanded}
                  />
                  <Typography
                    size={1}
                    value={serviceProject.wa}
                    variant="body2"
                    caption="WA"
                    captionType="bot"
                  />
                  <Typography
                    size={1}
                    value={serviceProject.ab}
                    variant="body2"
                    caption="AB"
                    captionType="bot"
                  />
                  <Typography
                    size={1}
                    value={workTime}
                    variant="body2"
                    caption="Zeit Arbeit (Summe)"
                    captionType="bot"
                  />
                  <Typography
                    size={1}
                    value={travelTime}
                    variant="body2"
                    caption="Zeit Reise (Summe)"
                    captionType="bot"
                  />
                </Grid>
              </Grid>
              <Grid item md={11}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Mitarbeiter</TableCell>
                      <TableCell>Tätigkeit</TableCell>
                      <TableCell>Datum</TableCell>
                      <TableCell>von</TableCell>
                      <TableCell>bis</TableCell>
                      <TableCell>Std</TableCell>
                      <TableCell>Anmerkung</TableCell>
                      <TableCell>Kommentar</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getSortedServiceEntries(selectedServiceEntries, users).map((entry: any) => (
                      <ActiveTableRow
                        hover
                        key={entry.id}
                        handleClick={() => handleEditServiceEntry(entry.id)}
                        // gray={this.checkTimeSheetState(entry) === 'completed'}
                        // lightGray={this.checkTimeSheetState(entry) === 'exported'}
                        bottomRow={
                          <>
                            {entry.isMaterialSmallUsed && (
                              <TableRow>
                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                  Montagematerial klein verwendet
                                </TableCell>
                              </TableRow>
                            )}
                            {entry.isMaterialLargeUsed && (
                              <TableRow>
                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                  Montagematerial groß verwendet
                                </TableCell>
                              </TableRow>
                            )}
                            {entry.isOvernightStay && (
                              <TableRow>
                                <TableCell colSpan={9} style={{ textAlign: 'center' }}>
                                  Übernachtung
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        }
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell>{entry.userLastName}</TableCell>
                        <TableCell>
                          {entry.serviceTypeId === SERVICE_TYPE_WORK ? (
                            <Typography
                              variant="body2"
                              value={
                                services({
                                  include: {
                                    serviceId: rules({
                                      include: { ruleId: entry.ruleId },
                                    }).serviceId,
                                  },
                                  option: { plain: true },
                                }).name
                              }
                            />
                          ) : (
                            <Typography
                              variant="body2"
                              value={
                                serviceTypes({
                                  include: {
                                    serviceTypeId: rules({
                                      include: { ruleId: entry.ruleId },
                                    }).serviceTypeId,
                                  },
                                  option: { plain: true },
                                }).name
                              }
                              caption={
                                rules({
                                  include: { ruleId: entry.ruleId },
                                }).serviceTypeId === SERVICE_TYPE_TRAVEL
                                  ? `${entry.distance}km`
                                  : ''
                              }
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {moment(entry.date, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            value={moment(entry.timeStart, 'HH:mm:ss').format('HH:mm')}
                          />
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="body2"
                            value={
                              entry.timeEnd === '23:59'
                                ? '24:00'
                                : moment(entry.timeEnd, 'HH:mm:ss').format('HH:mm')
                            }
                          />
                        </TableCell>
                        <TableCell>
                          {entry.timeEnd === '23:59'
                            ? formatTime(entry.timeStart, '23:00') + 1
                            : formatTime(entry.timeStart, entry.timeEnd)}
                        </TableCell>
                        <TableCell>{entry.commentPublic}</TableCell>
                        <TableCell>
                          {entry.commentPrivate !== '' ? (
                            <TextsmsIcon style={{ color: '#0072B6' }} />
                          ) : (
                            <TextsmsIcon style={{ color: '#3333' }} />
                          )}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Eintrag duplizieren">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDuplicateServiceEntry(entry.id);
                              }}
                            >
                              <FileCopyOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </ActiveTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
  // }
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(SingleServiceProject));

interface Props {
  handlePrint: any;
}

const PrintButtons: FC<Props> = ({ handlePrint }) => {
  return (
    <>
      <Button
        size={'auto'}
        buttonSize="small"
        handleClick={() => handlePrint({ isInternalDoc: false })}
        icon={<Print />}
        variant="outlined"
        sx={{ padding: 0.5 }}
      >
        ext.
      </Button>
      <Button
        size={'auto'}
        buttonSize="small"
        handleClick={() => handlePrint({ isInternalDoc: true })}
        icon={<Print />}
        variant="outlined"
        sx={{ padding: 0.5 }}
      >
        int.
      </Button>
    </>
  );
};
