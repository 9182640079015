import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const specificationBSelector = (
  state: any,
  {
    include = {
      specificationBId: null,
    },
    exclude = {
      specificationBId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      array: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.specificationBs,
    (specificationBs) => {
      // for single selected contractor
      if (checkSingleValue(include.specificationBId)) {
        const specificationBId = Array.isArray(include.specificationBId)
          ? include!.specificationBId![0]
          : include.specificationBId;
        // @ts-ignore
        const specificationB = specificationBs.find(
          // @ts-ignore
          (s: any) => s.id === parseInt(specificationBId, 10)
        );
        if (specificationB) {
          if (option.plain) {
            return option.array ? [specificationB] : specificationB;
          }
          return option.array
            ? [{ label: specificationB.name, value: `${specificationB.id}` }]
            : { label: specificationB.name, value: `${specificationB.id}` };
        }
        return null;
      }

      let specificationBOptions = specificationBs;
      if (!option.deactivated) {
        // Filter all deactivated specificationBs
        specificationBOptions = specificationBOptions.filter(
          (specificationB: any) => specificationB.deactivated !== true
        );
      }
      if (include.specificationBId) {
        specificationBOptions = specificationBOptions.filter((specificationB: any) =>
          // @ts-ignore
          include.specificationBId.includes(specificationB.id)
        );
      }
      if (exclude.specificationBId) {
        specificationBOptions = specificationBOptions.filter(
          // @ts-ignore
          (specificationB: any) => !exclude.specificationBId.includes(specificationB.id)
        );
      }
      if (specificationBOptions) {
        if (option.plain) {
          return specificationBOptions;
        }
        return specificationBOptions.map((specificationB: any) => ({
          label: specificationB.name,
          value: `${specificationB.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
