import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Form from '../../../../Atoms/Form/Form';
import Button from '../../../../Atoms/Button/Button';
import RadiosForm from '../../../../Molecules/Radios/RadiosForm';
import AutocompleteForm from '../../../../Molecules/Autocomplete/AutocompleteForm';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { serviceProjectSelector } from '../../../../../helpers/selectors';
import { SERVICE_PROJECT_STATES } from '../../../../../constants/all';
import { SERVICE_PROJECT_STATE_OPEN } from '../../../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  // auth: state.rootReducer.auth,
  serviceProjects: (filter: any) => serviceProjectSelector(state, filter),
  serviceProjectFilter: state.form.serviceProjectFilter,
  // redux-form
});

const mapDispatchToProps = () => ({});

interface ComponentOwnProps {
  toggleCreateDialog: (...args: any[]) => any;
}

interface ComponentDispatchProps {}

interface ComponentStateProps {
  serviceProjects: (...args: any[]) => any;
  serviceProjectFilter?: any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class ServiceProjectFilter extends PureComponent<ComponentProps, {}> {
  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      serviceProject: null,
      state: SERVICE_PROJECT_STATE_OPEN,
    });
  }
  render() {
    const { toggleCreateDialog, serviceProjects, serviceProjectFilter } = this.props;
    return (
      <Form>
        <AutocompleteForm
          size={5}
          options={serviceProjects({})}
          label="Service-Einsatz Suche"
          name="serviceProject"
        />
        {!serviceProjectFilter?.values?.serviceProject && (
          <RadiosForm
            label="Statusfilter"
            name="state"
            options={[...SERVICE_PROJECT_STATES]}
            size={5}
          />
        )}
        <Button handleClick={() => toggleCreateDialog(true)} alignRight>
          Neuer Service-Einsatz
        </Button>
      </Form>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'serviceProjectFilter',
  })(ServiceProjectFilter)
);
