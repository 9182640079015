import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SiteCheckHeader from './components/SiteCheckHeader';
import SiteCheckGeneral from './components/SiteCheckGeneral';
import SiteCheckInformation from './components/SiteCheckInformation';
import {
  getAnswerChecksAction,
  saveAnswerChecksAction,
  saveFormAction,
} from '../../../../../../actions';
import AdminOuterWrapper from '../../../../../Templates/AdminWrappers/AdminOuterWrapper';
import SidebarScrollSpy from '../../../../../Organisms/Sidebar/SidebarScrollSpy';
import { QUESTION_TYPE_NINE } from '../../../../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  forms: state.rootReducer.forms,
  answerChecks: state.rootReducer.answerChecks,
  formSiteCheckGeneral: state.form.formSiteCheckGeneral,
  formSiteCheckHeader: state.form.formSiteCheckHeader,
  editedForms: state.rootReducer.editedForms,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAnswerChecks: (formId: any) => dispatch(getAnswerChecksAction(formId)),
  saveAnswerChecks: (answerChecks: any, formId: any, formStationTypeId: any) =>
    dispatch(saveAnswerChecksAction(answerChecks, formId, formStationTypeId)),
  saveForm: (data: any) => dispatch(saveFormAction(data)),
});

interface RouteParams {
  id: string;
  formStationTypeId: string;
  section: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  auth?: any;
  answerChecks?: any;
  editedForms: any;
  forms: any;
  formSiteCheckGeneral?: any;
  formSiteCheckHeader?: any;
}

interface ComponentDispatchProps {
  getAnswerChecks: (...args: any[]) => any;
  saveAnswerChecks: (...args: any[]) => any;
  saveForm: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams>;

type FormSiteCheckState = {
  // formSectionId: number | null;
  formId: any;
  showQuestions: boolean;
};

class FormSiteCheck extends PureComponent<ComponentProps, FormSiteCheckState> {
  state = {
    // formStationTypeId: null,
    showQuestions: false,
    formId: null,
  };

  static defaultProps = {
    auth: null,
    answerChecks: null,
    formSiteCheckGeneral: null,
  };

  componentDidMount() {
    const { match, getAnswerChecks } = this.props;
    const showQuestions = match.params.section ? true : false;
    const formId = parseInt(match.params.id, 10);
    this.setState({ formId, showQuestions });
    getAnswerChecks(formId);
  }

  showFormQuestions = () => {
    const { history } = this.props;
    const { formId } = this.state;
    history.push(`/admin/form/${formId}/questions`);
    this.setState({ showQuestions: true });
  };

  renderSection = (scrollToRefsForm: any) => {
    const { formId, showQuestions } = this.state;
    // if we are in a formsection, render it
    if (showQuestions) {
      return <SiteCheckGeneral formId={formId} refs={scrollToRefsForm} />;
    }
    return <SiteCheckInformation formId={formId} />;
  };

  handleSave = () => {
    const { saveForm, saveAnswerChecks, match, forms, formSiteCheckHeader, formSiteCheckGeneral } =
      this.props;
    const { formId } = this.state;
    // todo: refactore to use formSelector
    if (forms) {
      const form = forms.find((f: any) => f.id === formId);

      // save header information
      if (formSiteCheckHeader) {
        const formValues = { ...formSiteCheckHeader.values };
        const { responsibleMechanic, responsibleElectrician, responsibleContractor } = formValues;

        const saveFormData = {
          formId: form.id,
          responsibleMechanicId: responsibleMechanic ? responsibleMechanic.value : null,
          responsibleElectricianId: responsibleElectrician ? responsibleElectrician.value : null,
          responsibleContractor,
        };

        saveForm({
          ...saveFormData,
        });
      }
      if (formSiteCheckGeneral) {
        const { formStationTypeId } = match.params;
        const formValues = { ...formSiteCheckGeneral.values };
        if (formValues) {
          saveAnswerChecks(formValues, form.id, parseInt(formStationTypeId, 10));
        }
      }
    }
  };
  // todo:
  // add ref to questionType nine like in projectEdit and adminSettingsWrapper
  getSiteCheckScrollSpyItems = () => {
    const { answerChecks } = this.props;
    if (answerChecks) {
      const items = answerChecks.filter(
        (element: any) => element.question.questionTypeId === QUESTION_TYPE_NINE
      );
      const newItems = items.map((val: any) => {
        return {
          id: val.question.id,
          value: val.question.id.toString(),
          label: val.question.text,
        };
      });
      return newItems;
    }
  };

  getScrollToRefs = () =>
    this.getSiteCheckScrollSpyItems().map((val: any) => {
      return { id: val.id, setRef: React.createRef() };
    });

  render() {
    const { answerChecks, match } = this.props;
    const { showQuestions, formId } = this.state;
    let scrollToRefs;
    if (answerChecks) {
      scrollToRefs = this.getScrollToRefs();
    }
    return (
      <AdminOuterWrapper withScrollSpy>
        {answerChecks && formId && (
          <>
            <SiteCheckHeader formId={formId} showFormQuestions={this.showFormQuestions} />
            {this.renderSection(scrollToRefs)}
            <SidebarScrollSpy
              scrollSpyItems={showQuestions ? this.getSiteCheckScrollSpyItems() : null}
              scrollToRefs={showQuestions ? scrollToRefs : null}
              handleSave={this.handleSave}
              withPrint
              printLink={`/print/form/${formId}`}
              backPath={showQuestions ? `form/${match.params.id}` : 'form'}
            />
          </>
        )}
      </AdminOuterWrapper>
    );
  }
}
export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FormSiteCheck));
