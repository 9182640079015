import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Grid } from '@mui/material';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';

import { getSortedDataTwo } from '../../../helpers/getSortedData';
type DataTableProps = {
  data: any[];
  label: string;
  getVariable: (...args: any[]) => any;
};

const DataTable: React.FC<DataTableProps> = ({ label, getVariable, data }) => (
  <Grid item md={12}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Operation</TableCell>
          <TableCell>ID</TableCell>
          <TableCell>{label}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {getSortedDataTwo(data, 'deactivated', 'name', true).map((d: any) => (
          <ActiveTableRow key={d.id} gray={d.deactivated}>
            <TableCell>
              <IconButton onClick={() => getVariable(d.id)}>
                <EditIcon />
              </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
              {d.id}
            </TableCell>
            <TableCell>{d.name}</TableCell>
          </ActiveTableRow>
        ))}
      </TableBody>
    </Table>
  </Grid>
);
export default DataTable;
