import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';
import { RootState } from '../../store/index';

export const customerSelector = <T = any>(
  state: RootState,
  {
    include = {
      customerId: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
): T =>
  createSelector(
    () => state.rootReducer.customers,
    (customers) => {
      // for single selected customer
      if (checkSingleValue(include.customerId)) {
        const customerId = Array.isArray(include.customerId)
          ? include.customerId![0]
          : include.customerId;

        const customer = customers.find((c: any) => c.id === parseInt(customerId!, 10));
        if (customer) {
          if (option.plain) {
            return customer;
          }
          return { label: customer.name, value: `${customer.id}` };
        }
        return null;
      }

      let customerOptions = customers;
      if (!option.deactivated) {
        customerOptions = customerOptions.filter((customer: any) => customer.deactivated !== true);
      }
      if (customerOptions) {
        if (option.plain) {
          return customerOptions;
        }
        return customerOptions.map((customer: any) => ({
          label: customer.name,
          value: `${customer.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
