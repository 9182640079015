import PageFormWrapper from '../../components/Templates/PageWrappers/PageFormWrapper';
import PageFormOverview from '../../components/Pages/PageAdminArea/Form/PageFormOverview/PageFormOverview';
import PageFormEdit from '../../components/Pages/PageAdminArea/Form/PageFormEdit/PageFormEdit';
/**
 * @constant
 * @description A constant to define routes for the customer module.
 */
export const formRoute = [
  // CUSTOMER
  {
    path: '/admin/form',
    component: PageFormWrapper(PageFormOverview),
    exact: true,
  },
  {
    path: '/admin/form/:id',
    component: PageFormWrapper(PageFormEdit),
    exact: true,
  },
  {
    path: '/admin/form/:id/:section',
    component: PageFormWrapper(PageFormEdit),
    exact: true,
  },
  {
    path: '/admin/form/:id/:section/:formStationTypeId',
    component: PageFormWrapper(PageFormEdit),
    exact: true,
  },
];

export default {};
