export const API_LOCAL_BASE: string = 'localhost';
export const API_PROD_BASE: string = 'stopa-pac-tam.de';
export const API_STAGING_BASE: string = 'staging.stopa-pac-tam.de';
export const API_PROD_TEST_BASE: string = '195.62.123.80';

let apiBase: string;
let apiUrl: string;
let inDevelopmentMode: boolean = false;
const hostname = window && window.location && window.location.hostname;

// localhost
if (hostname === API_LOCAL_BASE) {
  apiBase = API_LOCAL_BASE;
  apiUrl = `http://${API_LOCAL_BASE}:8080`;
  inDevelopmentMode = true;
  // production
} else if (hostname === API_PROD_BASE) {
  apiBase = API_PROD_BASE;
  apiUrl = `https://${API_PROD_BASE}/backend`;
} else if (hostname === API_STAGING_BASE) {
  apiBase = API_STAGING_BASE;
  apiUrl = `https://${API_STAGING_BASE}/backend`;
} else if (hostname === API_PROD_TEST_BASE) {
  apiBase = API_PROD_TEST_BASE;
  apiUrl = `http://${API_PROD_TEST_BASE}:8080`;
}

// if (hostname === API_LOCAL_BASE) {
//   apiBase = API_LOCAL_BASE;
//   apiUrl = `http://${API_LOCAL_BASE}:8080`;
//   inDevelopmentMode = true;
//   // production
// } else if (hostname === API_PROD_BASE) {
//   apiBase = API_PROD_BASE;
//   apiUrl = `https://${API_PROD_BASE}/backend`;
// } else if (hostname === API_STAGING_BASE) {
//   apiBase = API_STAGING_BASE;
//   apiUrl = `https://${API_STAGING_BASE}/backend`;
// } else if (hostname === API_PROD_TEST_BASE) {
//   apiBase = API_PROD_TEST_BASE;
//   apiUrl = `http://${API_PROD_TEST_BASE}:8080`;
// }

export { inDevelopmentMode, hostname, apiUrl, apiBase };
