import PageCustomerOverview from '../../components/Pages/PageAdminArea/Customer/PageCustomerOverview/PageCustomerOverview';
import PageCustomerWrapper from '../../components/Templates/PageWrappers/PageCustomerWrapper';
import PageCustomerEdit from '../../components/Pages/PageAdminArea/Customer/PageCustomerEdit/PageCustomerEdit';
import PageCustomerContactEdit from '../../components/Pages/PageAdminArea/Customer/PageCustomerContactEdit/PageCustomerContactEdit';
import PagecustomerSiteEdit from '../../components/Pages/PageAdminArea/Customer/PageCustomerSiteEdit/PageCustomerSiteEdit';

// import PageCustomer from '../../components/Pages/PageAdminArea/Customer/PageCustomer';
/**
 * @constant
 * @description A constant to define routes for the customer module.
 */
export const customerRoute = [
  // CUSTOMER
  {
    path: '/admin/customer',
    component: PageCustomerWrapper(PageCustomerOverview),
    exact: true,
  },
  {
    path: '/admin/customer/:id/contact/:customerContactId',
    component: PageCustomerWrapper(PageCustomerContactEdit),
    exact: true,
  },
  {
    path: '/admin/customer/:id/site/:siteId',
    component: PageCustomerWrapper(PagecustomerSiteEdit),
    exact: true,
  },
  {
    path: '/admin/customer/:id',
    component: PageCustomerWrapper(PageCustomerEdit),
    exact: true,
  },
];

export default {};
