import PageDiagnosticWrapper from '../../components/Templates/PageWrappers/PageDiagnosticWrapper';
import PageDiagnostic from '../../components/Pages/PageDiagnostic/PageDiagnostic';

/**
 * @constant
 * @description A constant to define routes for the user module.
 */
export const diagnosticRoute = [
  {
    path: '/diagnostic',
    // @ts-ignore
    component: PageDiagnosticWrapper(PageDiagnostic),
    exact: false,
  },
];

export default {};
