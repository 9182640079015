import React from 'react';
import { Grid, GridSize, TextField as MUITextField } from '@mui/material';

const styles = {
  buttonRight: {
    marginLeft: 'auto',
  },
};

type TextFieldProps = {
  id?: string;
  label: string;
  value?: string | number;
  handleChange?: (...args: any[]) => any;
  size?: GridSize;
  type?: string;
  disabled?: boolean;
  defaultValue?: string;
  multiline?: boolean;
  alignRight?: boolean;
};

const TextField: React.SFC<TextFieldProps> = ({
  id = '',
  label,
  handleChange = () => {},
  size = 2,
  type = 'text',
  value = '',
  disabled = false,
  defaultValue = null,
  multiline = false,
  alignRight = false,
}) => (
  <Grid item md={size} sx={{ ...(alignRight && styles.buttonRight) }}>
    <MUITextField
      id={id}
      label={label}
      variant={'standard'}
      onChange={(event) => handleChange(id, event, type)}
      fullWidth
      type={type}
      disabled={disabled}
      multiline={multiline}
      {...(defaultValue ? { defaultValue } : { ...(value !== null ? { value } : '') })}
      {...(type === 'date'
        ? {
            InputLabelProps: {
              shrink: true,
            },
          }
        : {})}
    />
  </Grid>
);

export default TextField;
