import React, { Fragment, memo } from 'react';
import { Grid } from '@mui/material';
import RadiosForm from '../Radios/RadiosForm';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import Typography from '../../Atoms/Typography/Typography';

type QuestionTypeThreeProps = {
  question: any;
  formCompleted: boolean;
  formData?: any;
};

const QuestionTypeThree: React.SFC<QuestionTypeThreeProps> = memo(
  ({ question, formData = {}, formCompleted }) => {
    const { id } = question;
    return (
      <Grid container item md={12} spacing={3} alignItems="flex-start" justifyContent="flex-start">
        <Typography size={4} variant="body1" value={question.text} formQuestion />
        <RadiosForm
          name={`${id}`}
          options={[
            { label: 'Ja', value: 1 },
            { label: 'Nein', value: 0 },
          ]}
          size={2}
          disabled={formCompleted}
        />
        <Grid container item md={6} spacing={3} alignItems="flex-start" justifyContent="flex-start">
          {parseInt(formData.values[id], 10) === 1 && (
            <Fragment>
              <TextFieldForm
                name={`${id}dateOne`}
                label="Datum"
                size={6}
                type="date"
                formQuestion
                disabled={formCompleted}
              />
            </Fragment>
          )}
          {formData.values[id] !== null && (
            <TextFieldForm
              size={12}
              name={`${id}textOne`}
              label="Anmerkung"
              formQuestion
              multiline
              disabled={formCompleted}
              rowsMax={3}
            />
          )}
          {parseInt(formData.values[id], 10) === 1 && (
            <Fragment>
              <TextFieldForm
                name={`${id}dateTwo`}
                label="Datum"
                size={6}
                type="date"
                formQuestion
                disabled={formCompleted}
              />

              <TextFieldForm
                size={12}
                name={`${id}textTwo`}
                label="Anmerkung"
                formQuestion
                multiline
                disabled={formCompleted}
                rowsMax={3}
              />
            </Fragment>
          )}
          {parseInt(formData.values[id], 10) === 1 && (
            <Fragment>
              <TextFieldForm
                name={`${id}dateThree`}
                label="Datum"
                size={6}
                type="date"
                formQuestion
                disabled={formCompleted}
              />
              <TextFieldForm
                size={12}
                name={`${id}textThree`}
                label="Anmerkung"
                formQuestion
                multiline
                disabled={formCompleted}
                rowsMax={3}
              />
            </Fragment>
          )}
          {parseInt(formData.values[id], 10) === 1 && (
            <Fragment>
              <TextFieldForm
                name={`${id}dateFour`}
                label="Datum"
                size={6}
                type="date"
                formQuestion
                disabled={formCompleted}
              />
              <TextFieldForm
                size={12}
                name={`${id}textFour`}
                label="Anmerkung"
                formQuestion
                multiline
                disabled={formCompleted}
                rowsMax={3}
              />
            </Fragment>
          )}
        </Grid>
      </Grid>
    );
  }
);

export default QuestionTypeThree;
