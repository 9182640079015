import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogContent, Box, Typography } from '@mui/material';
import Image from '../../../assets/img/STOPA-Logo.svg';
import { Commandments } from './Commandments';
import { setGreetingDialogAction } from '../../../actions';
import { inDevelopmentMode } from '../../../constants/api';

const styles = {
  cssImage: {
    height: '60px',
    marginRight: '14px',
    marginBottom: '40px',
  },
};

const mapStateToProps = (state: any) => ({
  greetingDialogOpen: state.rootReducer.greetingDialogOpen,
});
const mapDispatchToProps = (dispatch: any) => ({
  setGreetingDialog: (open: any) => dispatch(setGreetingDialogAction(open)),
});

type GreetingDialogProps = {
  setGreetingDialog: (...args: any[]) => any;
  greetingDialogOpen: boolean;
};
class GreetingDialog extends PureComponent<GreetingDialogProps, {}> {
  handleClose = () => {
    const { setGreetingDialog } = this.props;
    setGreetingDialog(false);
  };

  getCommandment = () => Commandments[Math.floor(Math.random() * (Commandments.length + 1))];

  render() {
    const { greetingDialogOpen } = this.props;
    return (
      <Dialog
        open={greetingDialogOpen && !inDevelopmentMode}
        onClose={this.handleClose}
        maxWidth="sm"
      >
        <DialogContent style={{ display: 'flex', flexDirection: 'column', padding: 50 }}>
          <Box component="img" sx={styles.cssImage} src={Image} alt="Logo" />
          <Typography variant="h6" style={{ textAlign: 'center' }}>
            <Box style={{ fontWeight: 700 }} display="inline">
              {this.getCommandment()?.[0]}
            </Box>
            {this.getCommandment()?.[1]}
          </Typography>
        </DialogContent>
      </Dialog>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GreetingDialog);
