import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import Form from '../../Atoms/Form/Form';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import CheckboxesForm from '../Checkboxes/CheckboxesForm';
import RadiosForm from '../Radios/RadiosForm';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import { ADMIN, ENTITY_USER } from '../../../../../shared/src/constants/general';
import { groupSelector, roleSelector, rightSelector } from '../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  rights: (filter: any) => rightSelector(state, filter),
});

interface ComponentOwnProps {
  disabled: boolean;
  checkManager: (...args: any[]) => any;
}

interface ComponentStateProps {
  auth: any;
  groups: (...args: any[]) => any;
  roles: (...args: any[]) => any;
  rights: (...args: any[]) => any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class UserDetails extends PureComponent<ComponentProps, {}> {
  render() {
    const { auth, groups, roles, rights, disabled, checkManager } = this.props;

    return (
      <Fragment>
        {groups && roles && rights && checkManager && (
          <AdminSettingsWrapper alignItems="flex-start">
            <Form>
              <TextFieldForm name="firstName" label="Vorname" />
              <TextFieldForm name="lastName" label="Nachname" />
              <TextFieldForm size={3} name="telephone" label="Telefon" />
              <TextFieldForm size={3} name="email" label="E-Mail" />
              <TextFieldForm size={3} name="number" label="Personal-Nr." type="number" />
              <TextFieldForm size={3} name="password" label="Passwort" type="password" />
              {auth.rightId === ADMIN && (
                <Fragment>
                  <CheckboxesForm
                    label="Gruppen"
                    name="groups"
                    options={groups({
                      option: { costCentre: true },
                    })}
                    size={12}
                    disabled={disabled}
                  />
                  <CheckboxesForm
                    label="Rollen"
                    name="roles"
                    options={roles({})}
                    size={12}
                    disabled={disabled}
                  />
                  <RadiosForm
                    label="Rechte"
                    name="rightId"
                    options={rights({ include: { entityId: ENTITY_USER } })}
                    size={12}
                    handleChange={(value: any) => checkManager(value[0])}
                  />
                </Fragment>
              )}
            </Form>
          </AdminSettingsWrapper>
        )}
      </Fragment>
    );
  }
}
export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(UserDetails);
