import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Form from '../../Atoms/Form/Form';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import Button from '../../Atoms/Button/Button';
import { addCustomerContactAction } from '../../../actions';
import RadiosForm from '../Radios/RadiosForm';

import { rightSelector } from '../../../helpers/selectors';
import { ENTITY_CUSTOMER_CONTACT } from '../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  rights: (filter: any) => rightSelector(state, filter),
});

const mapDispatchToProps = (dispatch: any) => ({
  addCustomerContact: (
    customerId: any,
    firstName: any,
    lastName: any,
    title: any,
    telephone: any,
    position: any,
    email: any,
    rightId: any
  ) =>
    dispatch(
      addCustomerContactAction({
        customerId,
        firstName,
        lastName,
        title,
        telephone,
        position,
        email,
        rightId,
      })
    ),
});

interface RouteParams {
  id: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  rights: (...args: any[]) => any;
}

interface ComponentDispatchProps {
  addCustomerContact: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams> &
  InjectedFormProps;

class CustomerContactCreate extends PureComponent<ComponentProps, {}> {
  componentWillMount() {
    const { initialize, match } = this.props;
    const customerId = parseInt(match.params.id, 10);
    initialize({
      firstName: '',
      lastName: '',
      title: '',
      telephone: '',
      position: '',
      email: '',
      customerId,
    });
  }
  handleCreate = (values: any) => {
    const { addCustomerContact, reset } = this.props;
    const { customerId, firstName, lastName, title, telephone, position, email, rightId } = values;
    addCustomerContact(customerId, firstName, lastName, title, telephone, position, email, rightId);
    reset();
  };
  render() {
    const { handleSubmit, rights } = this.props;
    return (
      <Fragment>
        <Form handleSubmit={handleSubmit(this.handleCreate)}>
          <TextFieldForm name="firstName" label="Vorname" />
          <TextFieldForm name="lastName" label="Nachname" />
          <TextFieldForm name="title" label="Titel" />
          <TextFieldForm name="telephone" label="Telefon" />
          <TextFieldForm name="position" label="Position" />
          <TextFieldForm name="email" label="E-Mail" />
          <RadiosForm
            label="Rechte"
            name="rightId"
            options={rights({ include: { entityId: ENTITY_CUSTOMER_CONTACT } })}
            size={12}
          />
          <Button handleClick={handleSubmit(this.handleCreate)} alignRight>
            Neu Anlegen
          </Button>
        </Form>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'contactCreate',
  })(withRouter<any, any>(CustomerContactCreate))
);
