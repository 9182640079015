import React, { FC } from 'react';
import { Drawer, Divider, MenuList, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Scrollspy from 'react-scrollspy';
import LogoImage from '../../../assets/img/Stopa-Pactam-standard.svg';
import Button from '../../Atoms/Button/Button';
import DeleteRestoreButton from '../../Atoms/Button/DeleteRestoreButton';
import BackButton from '../../Atoms/Button/BackButton';
import { SCROLLSPY_WIDTH } from '../../../constants/all';

const styles = {
  root: {
    display: 'flex',
  },
  drawer: {
    width: SCROLLSPY_WIDTH,
    flexShrink: 0,
    position: 'relative',
    zIndex: 1,
  },

  cssImage: {
    height: '60px',
    margin: '20px 0',
  },
  // @ts-ignore "fuck CSS typings"
  labelInfo: {
    width: 'auto',
    overflow: 'hidden',
    paddingTop: '4px',
    paddingLeft: '16px',
    paddingBottom: '4px',
    whiteSpace: 'nowrap',
    fontSize: '.75rem',
    boxSizing: 'border-box',
    fontFamily: 'Roboto , Helvetica, Arial, sans-serif',
    fontWeight: '600',
    lineHeight: '1.75',
    letterSpacing: '0.00938em',
    marginBottom: '0px',
    '&:last-of-type': {
      marginBottom: '16px',
      marginTop: '8px',
    },
  },
  spyItem: {
    width: 'auto',
    overflow: 'hidden',
    paddingTop: '4px',
    paddingBottom: '4px',
    whiteSpace: 'nowrap',
    letterSpacing: '0.00938em',
    color: 'rgba(0, 0, 0, 0.38)',
    borderLeft: '4px solid transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'rgba(0, 0, 0, 0.8)',
    },
  },
  menu: {
    '&:focus': {
      boxShadow: 'none',
      outline: 'none',
      border: 'none',
    },
  },
};

const useStyles = makeStyles({
  drawerPaper: {
    width: SCROLLSPY_WIDTH,
  },
  currentItem: {
    color: 'rgba(0, 0, 0, 0.8)',
    borderLeft: '4px solid #e0e0e0',
  },
  spyLink: {
    textDecoration: 'none',
    color: 'inherit',
    border: 'none',
    fontSize: '.75rem',
    boxSizing: 'border-box',
    fontFamily: 'Roboto , Helvetica, Arial, sans-serif',
    fontWeight: '400',
    lineHeight: '1.75',
    '&:focus': {
      boxShadow: 'none',
      outline: 'none',
      border: 'none',
    },
  },
});

interface ComponentOwnProps {
  auth?: any;
  handleSave: (...args: any[]) => any;
  handleDelete?: (...args: any[]) => any;
  withDelete?: boolean;
  withPrint?: boolean;
  deactivatedEntry?: boolean | any;
  scrollSpyLabel?: string[];
  printLink?: string;
  scrollSpyItems?: any;
  scrollToRefs?: any;
  backPath: string;
}

const SidebarScrollSpy: FC<ComponentOwnProps> = ({
  scrollSpyItems,
  handleSave,
  handleDelete,
  deactivatedEntry,
  scrollSpyLabel,
  withDelete,
  printLink,
  withPrint,
  backPath,
  scrollToRefs,
}) => {
  const classes = useStyles();
  const adminSection = useSelector((state: any) => state.rootReducer.adminSection);
  const history = useHistory();
  const { pathname } = useLocation();

  const shortenLabel = (string: any) => {
    if (string.length > 10) {
      return `${string.slice(0, 10)}...`;
    }
    return string;
  };

  const scrollTo = (e: any, index: any) => {
    // e.preventDefault();
    if (scrollToRefs) {
      if (scrollToRefs[index]) {
        // eslint-disable-next-line
        if (scrollToRefs[index].setRef) {
          const element = document.getElementsByClassName('testlist123');
          element[0].scrollTo({
            behavior: 'smooth',
            top: scrollToRefs[index].setRef.current.offsetTop - 100,
          });
        }
      }
    }
  };

  return (
    <Drawer
      sx={styles.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >
      <Box component="img" sx={styles.cssImage} src={LogoImage} alt="Logo" />

      <Divider />
      {scrollSpyLabel &&
        scrollSpyLabel.map((el: any) => (
          <Box component="p" sx={styles.labelInfo} key={el}>
            {shortenLabel(el)}
          </Box>
        ))}
      <Divider />
      <Button buttonSize="small" handleClick={() => handleSave()}>
        Speichern
      </Button>
      {withDelete && handleDelete && (
        <DeleteRestoreButton handleDelete={handleDelete} isDeactivated={deactivatedEntry} />
      )}
      <BackButton type={backPath} />
      {withPrint && (
        <Button
          buttonSize="small"
          handleClick={() => {
            if (printLink) {
              history.push(printLink);
            }
          }}
        >
          Drucken
        </Button>
      )}
      <Divider />
      <div>
        {(pathname === `/admin/${adminSection}/:id` ||
          pathname === `/admin/${adminSection}/:id/:section`) &&
          scrollSpyItems && (
            <Scrollspy
              items={scrollSpyItems.map((el: any) => el.value)}
              currentClassName={classes.currentItem}
              componentTag={(props) => <MenuList {...props} sx={styles.menu} />}
            >
              {scrollSpyItems.map((el: any, i: any) => (
                <Box component="li" key={el.value} sx={styles.spyItem}>
                  <button type="button" onClick={(e) => scrollTo(e, i)} className={classes.spyLink}>
                    {el.label}
                  </button>
                </Box>
              ))}
            </Scrollspy>
          )}
      </div>
    </Drawer>
  );
};

export default SidebarScrollSpy;
