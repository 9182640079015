import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import {
  getProjectsAction,
  getProjectVariablesAction,
  getCustomersAction,
  getCustomerContactsAction,
  getUsersAction,
  getContractorsAction,
  getSitesAction,
  getGroupsAction,
  setAdminAreaSectionAction,
  getRolesAction,
  getFormsAction,
  getRightsAction,
  getQuestionDetailsAction,
  getStationTypesAction,
} from '../../../../actions';

export default function PrintFormWrapper(WrappedComponent: React.ComponentType) {
  const mapStateToProps = (state: any) => ({
    users: state.rootReducer.users,
    groups: state.rootReducer.groups,
    projects: state.rootReducer.projects,
    projectTypes: state.rootReducer.projectTypes,
    projectStates: state.rootReducer.projectStates,
    projectKinds: state.rootReducer.projectKinds,
    customers: state.rootReducer.customers,
    customerContacts: state.rootReducer.customerContacts,
    sites: state.rootReducer.sites,
    contractors: state.rootReducer.contractors,
    roles: state.rootReducer.roles,
    rights: state.rootReducer.rights,
    // forms
    forms: state.rootReducer.forms,
    formTypes: state.rootReducer.formTypes,
    questionTypes: state.rootReducer.questionTypes,
    stationTypes: state.rootReducer.stationTypes,
  });

  const mapDispatchToProps = (dispatch: any) => ({
    getProjects: () => dispatch(getProjectsAction()),
    getProjectVariables: () => dispatch(getProjectVariablesAction()),
    getCustomers: () => dispatch(getCustomersAction()),
    getCustomerContacts: () => dispatch(getCustomerContactsAction()),
    getUsers: () => dispatch(getUsersAction()),
    getContractors: () => dispatch(getContractorsAction()),
    getSites: () => dispatch(getSitesAction()),
    setAdminAreaSection: (section: any) => dispatch(setAdminAreaSectionAction(section)),
    getGroups: () => dispatch(getGroupsAction()),
    getRoles: () => dispatch(getRolesAction()),
    getRights: () => dispatch(getRightsAction()),
    // forms
    getForms: () => dispatch(getFormsAction()),
    getQuestionDetails: () => dispatch(getQuestionDetailsAction()),
    getStationTypes: () => dispatch(getStationTypesAction()),
  });

  interface ComponentOwnProps {}

  interface ComponentStateProps {
    users?: any[] | null;
    projects?: any[] | null;
    projectTypes?: any[] | null;
    projectStates?: any[] | null;
    rights?: any[] | null;
    projectKinds?: any[] | null;
    customers?: any[] | null;
    customerContacts?: any[] | null;
    sites?: any[] | null;
    contractors?: any[] | null;
    roles?: any[] | null;
    forms?: any[] | null;
    formTypes?: any[] | null;
    questionTypes?: any[] | null;
    stationTypes?: any[] | null;
  }

  interface ComponentDispatchProps {
    getProjects: (...args: any[]) => any;
    getProjectVariables: (...args: any[]) => any;
    getCustomers: (...args: any[]) => any;
    getCustomerContacts: (...args: any[]) => any;
    getUsers: (...args: any[]) => any;
    getContractors: (...args: any[]) => any;
    getSites: (...args: any[]) => any;
    getGroups: (...args: any[]) => any;
    getRoles: (...args: any[]) => any;
    getForms: (...args: any[]) => any;
    getRights: (...args: any[]) => any;
    getQuestionDetails: (...args: any[]) => any;
    getStationTypes: (...args: any[]) => any;
    setAdminAreaSection: (...args: any[]) => any;
  }

  type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

  class InnerWrapper extends PureComponent<ComponentProps, {}> {
    static defaultProps = {
      users: null,
      roles: null,
      projects: null,
      projectTypes: null,
      rights: null,
      projectStates: null,
      projectKinds: null,
      customers: null,
      customerContacts: null,
      sites: null,
      contractors: null,
      // forms
      forms: null,
      questionTypes: null,
      formTypes: null,
      stationTypes: null,
    };

    componentDidMount() {
      const {
        getUsers,
        getProjects,
        getProjectVariables,
        getCustomers,
        getCustomerContacts,
        getGroups,
        getContractors,
        getSites,
        getRoles,
        setAdminAreaSection,
        getForms,
        getRights,
        getQuestionDetails,
        getStationTypes,
      } = this.props;
      getGroups();
      getProjects();
      getProjectVariables();
      getCustomers();
      getCustomerContacts();
      getUsers();
      getContractors();
      getSites();
      getRoles();
      getForms();
      getRights();
      getQuestionDetails();
      getStationTypes();
      setAdminAreaSection('form');
    }
    render() {
      const {
        users,
        projects,
        projectTypes,
        projectStates,
        projectKinds,
        customers,
        customerContacts,
        sites,
        roles,
        contractors,
        forms,
        formTypes,
        rights,
        questionTypes,
        stationTypes,
      } = this.props;
      return (
        <Fragment>
          {users &&
            projects &&
            projectTypes &&
            projectStates &&
            projectKinds &&
            customers &&
            customerContacts &&
            sites &&
            roles &&
            contractors &&
            rights &&
            forms &&
            formTypes &&
            questionTypes &&
            stationTypes && <WrappedComponent />}
        </Fragment>
      );
    }
  }

  return connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(InnerWrapper);
}
