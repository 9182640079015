import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import Form from '../../Atoms/Form/Form';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import Button from '../../Atoms/Button/Button';
import { addContractorAction } from '../../../actions';

const mapDispatchToProps = (dispatch: any) => ({
  addContractor: (
    name: any,
    shortName: any,
    adress: any,
    city: any,
    postalCode: any,
    country: any,
    telephone: any,
    email: any
  ) =>
    dispatch(
      addContractorAction({
        name,
        shortName,
        adress,
        city,
        postalCode,
        country,
        telephone,
        email,
      })
    ),
});
type ContractorCreateProps = {
  addContractor: (...args: any[]) => any;
  initialize: (...args: any[]) => any;
  reset: (...args: any[]) => any;
  handleSubmit: (...args: any[]) => any;
};
class ContractorCreate extends PureComponent<ContractorCreateProps, {}> {
  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      name: '',
      shortName: '',
      adress: '',
      city: '',
      postalCode: '',
      country: '',
      telephone: '',
      email: '',
    });
  }
  handleCreate = (values: any) => {
    const { addContractor, reset } = this.props;
    const { name, shortName, adress, city, postalCode, country, telephone, email } = values;
    addContractor(name, shortName, adress, city, postalCode, country, telephone, email);
    reset();
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <AdminSettingsWrapper editWrapper>
        <Form handleSubmit={handleSubmit(this.handleCreate)}>
          <TextFieldForm size={4} name="name" label="Name" />
          <TextFieldForm name="shortName" label="Kurzform" />
          <TextFieldForm size={3} name="adress" label="Straße und Hausnummer" />
          <TextFieldForm size={3} name="city" label="Ort" />
          <TextFieldForm name="postalCode" label="Postleitzahl" />
          <TextFieldForm name="country" label="Land" />
          <TextFieldForm size={2} name="telephone" label="Telefon" />
          <TextFieldForm size={3} name="email" label="E-Mail" />
          <Button handleClick={handleSubmit(this.handleCreate)} alignRight size={3}>
            Neu Anlegen
          </Button>
        </Form>
      </AdminSettingsWrapper>
    );
  }
}

export default connect<any, any, any>(
  null,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'contactorCreate',
  })(ContractorCreate)
);
