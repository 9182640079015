import React, { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { getAllServiceEntriesAction } from '../../../../../actions';
import ServiceProjectWeekUserFilter from './../../components/ServiceProjectWeekUserFilter/ServiceProjectWeekUserFilter';
import moment from 'moment';
import { Entries } from '../../../PageControlling/PageControlling';
import { TIME_ENTRY_TYPE } from '../../../../../../../shared/src/enums/enums';
import { ICombinedTimeEntries, IEntries } from '../../../../../types/frontedTypes';

export const ServiceProjectWeeks: FC = () => {
  const dispatch = useDispatch();

  const serviceTimeEntries: ICombinedTimeEntries[] = useSelector(
    (state: any) => state.rootReducer.serviceTimeEntries
  );
  const serviceEntriesCalendarWeek = useSelector(
    (state: any) => state.form.serviceEntriesCalendarWeek
  );

  useEffect(() => {
    // set the current week as predefined
    const currentDate = new Date();
    const currentWeek = serviceEntriesCalendarWeek?.values?.week.value
      ? parseInt(serviceEntriesCalendarWeek?.values?.week.value.split('-')[1], 10)
      : moment(currentDate).week();
    const currentYear = serviceEntriesCalendarWeek?.values?.week.value
      ? parseInt(serviceEntriesCalendarWeek?.values?.week.value.split('-')[0], 10)
      : moment(currentDate).year();
    dispatch(getAllServiceEntriesAction(currentWeek, currentYear));
  }, [dispatch, serviceEntriesCalendarWeek?.values?.week?.value]);

  const selectedUserId = serviceEntriesCalendarWeek?.values?.user
    ? parseInt(serviceEntriesCalendarWeek?.values?.user?.value, 10)
    : null;

  const getFilteredEntries = () => {
    if (!serviceEntriesCalendarWeek) return null;

    const weekValue = serviceEntriesCalendarWeek?.values?.week?.value;
    const selectedYear = parseInt(weekValue.split('-')[0], 10); // moment(value.value, 'YYYY-WW').week();
    const selectedWeek = parseInt(weekValue.split('-')[1], 10); // /moment(value.value, 'YYYY-WW').year();
    const weekEntries =
      serviceTimeEntries.find(
        (entry: any) => entry.week === selectedWeek && entry.year === selectedYear
      )?.service || [];

    if (!weekEntries) return null;

    let newData: IEntries[] = [];
    // need a new data structure of weekEntries for controlling page
    // so we get uniqueUserIds and belonging projects
    weekEntries.forEach((day) => {
      day.entries.forEach((e) => {
        const foundUserIndex = newData.findIndex((data) => data.userId === e.userId);
        if (foundUserIndex !== -1) {
          const foundUserProjectIndex = newData[foundUserIndex].projects.findIndex(
            (project: any) => project.projectId === e.serviceProjectId
          );
          if (foundUserProjectIndex === -1) {
            newData[foundUserIndex].projects.push({
              state: null,
              projectId: e.serviceProjectId,
              dayEntries: [],
            });
          }
        } else {
          newData.push({
            userId: e.userId,
            projects: [
              {
                state: null,
                projectId: e.serviceProjectId,
                dayEntries: [],
              },
            ],
          });
        }
      });
    });

    // //find in newData belonging user projects and add old data structure
    weekEntries.forEach((day) => {
      day.entries.forEach((e) => {
        const foundUserIndex = newData.findIndex((data) => data.userId === e.userId);
        const foundUserProjectIndex = newData[foundUserIndex].projects.findIndex(
          (project: any) => project.projectId === e.serviceProjectId
        );
        const foundProjectDayIndex = newData[foundUserIndex].projects[
          foundUserProjectIndex
        ].dayEntries.findIndex((projectDay: any) => projectDay.date === day.date);

        if (foundProjectDayIndex !== -1) {
          newData[foundUserIndex].projects[foundUserProjectIndex].dayEntries[
            foundProjectDayIndex
          ].entries.push(e);
        } else {
          newData[foundUserIndex].projects[foundUserProjectIndex].dayEntries.push({
            date: day.date,
            entries: [e],
          });
        }
      });
    });
    let filteredEntries;

    if (serviceEntriesCalendarWeek.values.user) {
      const userId = parseInt(serviceEntriesCalendarWeek.values.user.value, 10);
      filteredEntries = newData.filter((userProjects) => userProjects.userId === userId);
    }

    return filteredEntries ? filteredEntries : newData;
  };

  if (!serviceTimeEntries) {
    return null;
  }
  return (
    <Grid container>
      <Grid item md={12}>
        <ServiceProjectWeekUserFilter
          filteredProjectTimeEntries={[]}
          filteredServiceTimeEntries={getFilteredEntries()}
        />
      </Grid>
      <Grid item md={12} sx={{ mt: 4 }}>
        {serviceEntriesCalendarWeek && (
          <Entries
            title="Serviceprojekte"
            type={TIME_ENTRY_TYPE.SERVICE_PROJECT}
            entries={getFilteredEntries()}
            selectedUserId={selectedUserId}
            handleComplete={() => {}}
            handlePrint={() => {}}
          />
        )}
      </Grid>
    </Grid>
  );
};
