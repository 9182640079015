import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const projectStateSelector = (
  state: any,
  {
    include = {
      projectStateId: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.projectStates,
    (projectStates) => {
      let projectStateOption = projectStates;
      // for single selected contractor
      if (checkSingleValue(include.projectStateId)) {
        const projectStateId = Array.isArray(include.projectStateId)
          ? include.projectStateId![0]
          : include.projectStateId;

        const projectState = projectStates.find(
          (pT: any) => pT.id === parseInt(projectStateId!, 10)
        );
        if (projectState) {
          if (option.plain) {
            return projectState;
          }
          return { label: `${projectState.name}`, value: `${projectState.id}` };
        }
        return null;
      }

      if (projectStates) {
        if (include.projectStateId) {
          projectStateOption = projectStateOption.filter((projectState: any) =>
            // @ts-ignore
            include.projectStateId.includes(projectState.id)
          );
        }
        if (option.plain) {
          return projectStateOption;
        }
        return projectStateOption.map((projectState: any) => ({
          label: projectState.name,
          value: `${projectState.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
