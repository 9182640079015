import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/de';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Typography from '../../../../Atoms/Typography/Typography';
import ActiveTableRow from '../../../../Atoms/ActiveTableRow/ActiveTableRow';
import formatTime from '../../../../../helpers/FormatTime';
import { useProjectEntriesData } from '../../useProjectEntriesData';

import {
  SERVICE_TYPE_WORK,
  SERVICE_TYPE_TRAVEL,
  SERVICE_TYPE_BREAK,
} from '../../../../../../../shared/src/constants/general';
import { setTimeEntryDialogEditAction, setTimeEntryAction } from '../../../../../actions';
import { getSortedData } from '../../../../../helpers/getSortedData';
import { IEntriesByDay } from '../../../../../types/frontedTypes';
import { TIME_ENTRY_TYPE } from '../../../../../../../shared/src/enums/enums';
import { ITimeEntry } from '../../../../../../../shared/src/interfaces/TimeEntry';
import { IServiceEntry } from '../../../../../../../shared/src/interfaces/ServiceEntry';

import {
  userSelector,
  serviceTypeSelector,
  serviceSelector,
  billingTypeSelector,
  ruleSelector,
  groupSelector,
  projectSelector,
  serviceProjectSelector,
} from '../../../../../helpers/selectors';

import {getUserWa} from '../../../../../helpers/utils';

const styles = {
  paddingZero: {
    padding: '0px!important',
  },
  paper: {
    width: '100%',
  },
};

interface IDayOverviewProps {
  timeEntry: IEntriesByDay<IServiceEntry | ITimeEntry>;
  type: TIME_ENTRY_TYPE;
  projectId: number;
}

export const DayOverview: FC<IDayOverviewProps> = React.memo(({ timeEntry, type, projectId } : any) => {
  const timeSheets = useSelector((state: any) => state.rootReducer.timeSheets);
  const billingTypes = useSelector(
    (state: any) => (filter: any) => billingTypeSelector(state, filter)
  );
  const users = useSelector((state: any) => (filter: any) => userSelector(state, filter));
  const groups = useSelector((state: any) => (filter: any) => groupSelector(state, filter));
  const projects = useSelector((state: any) => (filter: any) => projectSelector(state, filter));
  const serviceProjects = useSelector((state: any) => (filter: any) => serviceProjectSelector(state, filter));
  const rules = useSelector((state: any) => (filter: any) => ruleSelector(state, filter));
  const serviceTypes = useSelector(
    (state: any) => (filter: any) => serviceTypeSelector(state, filter)
  );
  const services = useSelector((state: any) => (filter: any) => serviceSelector(state, filter));

  const { projectNumber, customer } = useProjectEntriesData({ type, id: projectId });

  const dispatch = useDispatch();

  const setTimeEntryDialogEdit = (open: any) => dispatch(setTimeEntryDialogEditAction(open));
  const setTimeEntry = (timeEntry: any) => dispatch(setTimeEntryAction(timeEntry));

  const checkTimeSheetState = (timeEntry: any) => {
    if (timeSheets && timeEntry) {
      const foundTimeSheet = timeSheets.find(
        (tS: any) =>
          tS.week === timeEntry.week &&
          tS.year === timeEntry.year &&
          tS.projectId === timeEntry.projectId &&
          tS.userId === timeEntry.userId
      );
      if (foundTimeSheet) {
        if (foundTimeSheet.completed !== 1) {
          return 'completed';
        }
        return 'exported';
      }
    }
    return null;
  };

  const handleEditTimeEntry = (timeEntryId: any) => {
    const foundTimeEntry = timeEntry.entries.find((entry: any) => entry.id === timeEntryId);
    setTimeEntryDialogEdit(true);
    // add the serviceTypeId, serviceId, specificationA, speicifactionBId from the rule
    const rule = rules({ include: { ruleId: foundTimeEntry?.ruleId } });
    const addedRuleData = {
      ...foundTimeEntry,
      serviceTypeId: rule.serviceTypeId,
      serviceId: rule.serviceId,
      specificationAId: rule.specificationAId,
      specificationBId: rule.specificationBId,
    };
    setTimeEntry(addedRuleData);
  };

  const calculateTime = (serviceTypeId: any) => {
    const result = timeEntry.entries.filter((entry: any) => {
      // get the selected rule for the entry
      const rule = rules({ include: { ruleId: entry.ruleId } });
      // check if its the serviceType we are looking for
      if (rule.serviceTypeId === serviceTypeId) {
        return entry;
      }
      return null;
    });
    if (result) {
      const calculated = result.map((entry: any) => {
        if (entry.timeEnd === '23:59') {
          return formatTime(entry.timeStart, '23:00') + 1;
        }
        return formatTime(entry.timeStart, entry.timeEnd);
      });
      return calculated.reduce((pv: any, cv: any) => pv + cv, 0);
    }
    return '';
  };

  const getSortedTimeEntries = () => {
    const newTimeEntryArray = timeEntry.entries.map((e: any) => {
      const userLastName = users({
        include: { userId: e.userId },
        option: { plain: true },
      }).lastName;
      return {
        ...e,
        userLastName,
      };
    });
    return getSortedData(newTimeEntryArray, 'timeStart', true);
  };

  const getWa = (timeEntry: ITimeEntry) => {
    const { userId, groupId } = timeEntry;
    const userGroups = groups({
      include: { userId, groupId: [groupId] },
      option: { plain: true },
    });
    const project = projects({
      include: { projectId: timeEntry.projectId },
      option: { plain: true },
    });
    const wa1 = getUserWa(project, userGroups)?.number;
    const wa2 = rules({
      include: { ruleId: timeEntry.ruleId },
    })?.wa;
    return `${wa1 ? wa1 : 'k.A'} ${wa2 ? wa2 : 'k.A.'}`;
  };

  const getWaServiceProject = (timeEntry: IServiceEntry) => {
    const project = serviceProjects({
      include: { serviceProjectId: timeEntry.serviceProjectId },
      option: { plain: true },
    });
    const wa1 = project?.wa;
    const wa2 = rules({
      include: { ruleId: timeEntry.ruleId },
    })?.wa;
    return `${wa1 ? wa1 : 'k.A'} ${wa2 ? wa2 : 'k.A.'}`;
  };

  const workTime = calculateTime(SERVICE_TYPE_WORK);
  const travelTime = calculateTime(SERVICE_TYPE_TRAVEL);
  const breakTime = calculateTime(SERVICE_TYPE_BREAK);
  const totalWork = workTime + travelTime;
  return (
    <Grid item container justifyContent="flex-start" md={12} alignItems="flex-start">
      <Accordion elevation={0} sx={styles.paper}>
        <AccordionSummary sx={styles.paddingZero} expandIcon={<ExpandMore />}>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
            <Typography value={moment(timeEntry.date).format('DD.MM')} />
            <Typography value={moment(timeEntry.date).format('dddd')} size={2} />
            <Typography value={travelTime.toFixed(2)} caption="Reise" />
            <Typography value={workTime.toFixed(2)} caption="Arbeit" />
            <Typography value={breakTime.toFixed(2)} caption="Pause" />
            <Typography value={totalWork.toFixed(2)} caption="gesamt Arbeit" size={2} />
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={styles.paddingZero}>
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item md={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Projekt</TableCell>
                    <TableCell>Kunde</TableCell>
                    <TableCell>Mitarbeiter</TableCell>
                    <TableCell>Tätigkeit</TableCell>
                    <TableCell>von</TableCell>
                    <TableCell>bis</TableCell>
                    <TableCell>Std</TableCell>
                    <TableCell>Abrechnungsart</TableCell>
                    <TableCell>WA-Nr. Vorgang</TableCell>
                    <TableCell>Anmerkung</TableCell>
                    <TableCell>Kommentar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getSortedTimeEntries().map((entry: any) => (
                    <ActiveTableRow
                      hover
                      key={entry.id}
                      handleClick={() => handleEditTimeEntry(entry.id)}
                      gray={checkTimeSheetState(entry) === 'completed'}
                      lightGray={checkTimeSheetState(entry) === 'exported'}
                    >
                      <TableCell>{projectNumber}</TableCell>
                      <TableCell>{customer.name}</TableCell>
                      <TableCell>{entry.userLastName}</TableCell>
                      <TableCell>
                        {entry.serviceTypeId === SERVICE_TYPE_WORK ? (
                          <Typography
                            variant="body2"
                            value={
                              services({
                                include: {
                                  serviceId: rules({
                                    include: { ruleId: entry.ruleId },
                                  }).serviceId,
                                },
                                option: { plain: true },
                              }).name
                            }
                          />
                        ) : (
                          <Typography
                            variant="body2"
                            value={
                              serviceTypes({
                                include: {
                                  serviceTypeId: rules({
                                    include: { ruleId: entry.ruleId },
                                  }).serviceTypeId,
                                },
                                option: { plain: true },
                              }).name
                            }
                            caption={
                              rules({
                                include: { ruleId: entry.ruleId },
                              }).serviceTypeId === SERVICE_TYPE_TRAVEL
                                ? `${entry.distance}km`
                                : ''
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          value={moment(entry.timeStart, 'HH:mm:ss').format('HH:mm')}
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          value={
                            entry.timeEnd === '23:59'
                              ? '24:00'
                              : moment(entry.timeEnd, 'HH:mm:ss').format('HH:mm')
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {entry.timeEnd === '23:59'
                          ? formatTime(entry.timeStart, '23:00') + 1
                          : formatTime(entry.timeStart, entry.timeEnd)}
                      </TableCell>
                      <TableCell>
                        {entry.billingTypeId
                          ? billingTypes({
                              include: { billingTypeId: entry.billingTypeId },
                              option: { plain: true },
                            }).name
                          : 'Keine Angabe'}
                      </TableCell>
                      <TableCell>{('serviceProjectId' in entry) ? getWaServiceProject(entry) : getWa(entry)}</TableCell>
                      <TableCell>{entry.commentPublic}</TableCell>
                      <TableCell>
                        {entry.commentPrivate !== '' ? (
                          <TextsmsIcon style={{ color: '#0072B6' }} />
                        ) : (
                          <TextsmsIcon style={{ color: '#3333' }} />
                        )}
                      </TableCell>
                    </ActiveTableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
});
