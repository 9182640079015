import React, { useEffect } from 'react';
import { VariableSizeList as List } from 'react-window';
import {
  createInitialFormValues,
  renderForm,
  useWindowSize,
} from '../../../../../../Molecules/Forms/FormHelpers';
import AutoSizer from 'react-virtualized-auto-sizer';
export const FormContext = React.createContext({});

interface ItemRenderer {
  data: any;
  index: any;
  style: any;
}
const ItemRenderer: (params: any) => any = ({ data, index, style }) => {
  const item = data[index];
  return <div style={style}>{item}</div>;
};

interface SiteCheckQuestions {
  listHeight: any;
  answerChecks: any;
  formSiteCheckGeneral: any;
  SiteCheckQuestionRef: any;
  user: any;
  change: any;
  formCompleted: any;
  refs: any;
}

const SiteCheckQuestions: React.FC<SiteCheckQuestions> = ({
  listHeight,
  answerChecks,
  formSiteCheckGeneral,
  SiteCheckQuestionRef,
  user,
  change,
  formCompleted,
  refs,
}) => {
  const sizeMap = React.useRef<any>({});
  const listRef = React.useRef<any>({});
  const setSize = (index: any, size: any) => {
    sizeMap.current = { ...sizeMap.current, [index]: size };
    listRef.current.resetAfterIndex(index);
  };
  const getSize = React.useCallback((index) => {
    return sizeMap.current[index] || 200;
  }, []);
  const [windowWidth] = useWindowSize();

  return (
    <FormContext.Provider value={{ setSize, windowWidth }}>
      <div ref={SiteCheckQuestionRef} style={{ height: '100vh', width: '100%' }}>
        <List
          className="testlist123"
          ref={(ref) => {
            listRef.current = ref;
          }}
          height={listHeight}
          width={'100%'}
          // itemSize={110}
          itemSize={getSize}
          itemCount={answerChecks.length}
          itemData={renderForm({
            answers: answerChecks,
            formData: formSiteCheckGeneral,
            user,
            change,
            formCompleted: formCompleted,
            refs: refs ? refs : null,
          })}
        >
          {ItemRenderer}
        </List>
      </div>
    </FormContext.Provider>
  );
};

export default SiteCheckQuestions;
