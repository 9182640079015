import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const rightSelector = (
  state: any,
  {
    include = {
      rightId: null,
      entityId: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.rights,
    (rights) => {
      // for single selected contractor
      if (checkSingleValue(include.rightId)) {
        const rightId = Array.isArray(include.rightId) ? include.rightId![0] : include.rightId;

        const right = rights.find((pT: any) => pT.id === parseInt(rightId!, 10));
        if (right) {
          if (option.plain) {
            return right;
          }
          return { label: `${right.name}`, value: `${right.id}` };
        }
        return null;
      }

      let rightOptions = rights;
      if (include.entityId) {
        const includeEntityId = parseInt(include.entityId!, 10);
        rightOptions = rightOptions.filter((r: any) => r.entityId === includeEntityId);
      }

      if (rightOptions) {
        if (option.plain) {
          return rightOptions;
        }
        return rightOptions.map((right: any) => ({
          label: right.name,
          value: `${right.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
