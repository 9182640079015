import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Sidebar from '../../Organisms/Sidebar/Sidebar';
import { PROJECT_MANAGER } from '../../../../../shared/src/constants/general';
import NavBar from '../../Organisms/NavBar/NavBar';
import {
  getCustomersAction,
  getCustomerContactsAction,
  getSitesAction,
  getUsersAction,
  getProjectsAction,
  setAdminAreaSectionAction,
} from '../../../actions';

export default function PageCustomerWrapper(WrappedComponent: React.ComponentType) {
  const mapStateToProps = (state: any) => ({
    auth: state.rootReducer.auth,
    history: state.rootReducer.history,
    customers: state.rootReducer.customers,
    projects: state.rootReducer.projects,
    customerContacts: state.rootReducer.customerContacts,
    sites: state.rootReducer.sites,
    users: state.rootReducer.users,
  });
  const mapDispatchToProps = (dispatch: any) => ({
    getCustomers: () => dispatch(getCustomersAction()),
    getProjects: () => dispatch(getProjectsAction()),
    getCustomerContacts: () => dispatch(getCustomerContactsAction()),
    getSites: () => dispatch(getSitesAction()),
    getUsers: () => dispatch(getUsersAction()),
    setAdminAreaSection: (section: any) => dispatch(setAdminAreaSectionAction(section)),
  });

  interface ComponentOwnProps {}

  interface ComponentStateProps {
    auth: any;
    customers: any;
    projects: any;
    sites: any;
    users: any;
    customerContacts: any;
  }

  interface ComponentDispatchProps {
    getCustomers: (...args: any[]) => any;
    getProjects: (...args: any[]) => any;
    getCustomerContacts: (...args: any[]) => any;
    getSites: (...args: any[]) => any;
    getUsers: (...args: any[]) => any;
    setAdminAreaSection: (...args: any[]) => any;
  }

  type ComponentProps = ComponentOwnProps &
    ComponentStateProps &
    ComponentDispatchProps &
    RouteComponentProps;

  class InnerWrapper extends PureComponent<ComponentProps, {}> {
    static defaultProps = {
      sites: null,
      customers: null,
      projects: null,
      users: null,
      customerContacts: null,
    };

    componentDidMount() {
      const {
        auth,
        history,
        getCustomers,
        getProjects,
        getCustomerContacts,
        getSites,
        getUsers,
        setAdminAreaSection,
      } = this.props;
      if (auth.rightId > PROJECT_MANAGER) {
        history.push('/overview');
      }
      getCustomers();
      getProjects();
      getCustomerContacts();
      getSites();
      getUsers();
      setAdminAreaSection('customer');
    }

    render() {
      const { customers, projects, sites, customerContacts, users } = this.props;

      return (
        <Fragment>
          <NavBar />
          <Sidebar />
          {customers && projects && customerContacts && sites && users && (
            <Fragment>
              <WrappedComponent />
            </Fragment>
          )}
        </Fragment>
      );
    }
  }

  // return InnerWrapper;
  return connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(InnerWrapper));
}
