import * as React from 'react';
import UndoIcon from '@mui/icons-material/Undo';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Button from './Button';

interface ComponentOwnProps {
  withIcon?: boolean;
  type?: any;
}

type ComponentProps = ComponentOwnProps & RouteComponentProps;

const BackButton: React.SFC<ComponentProps> = ({ history, type = '', withIcon = false }) => (
  <Button
    buttonSize="small"
    handleClick={() => history.push(`/admin/${type}`)}
    icon={withIcon ? <UndoIcon /> : undefined}
  >
    Zurück
  </Button>
);

export default withRouter(BackButton);
