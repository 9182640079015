import Info from '@mui/icons-material/Info';
import React, { memo } from 'react';
import TooltipIcon from '../../../../Atoms/TooltipIcon/TooltipIcon';
import Typography from '../../../../Atoms/Typography/Typography';

interface QuestionTypoProps {
  info: string;
  order: string;
  text: string;
  roleCheck?: boolean;
  required: boolean;
  formCompleted?: boolean;
}

const QuestionTypo: React.SFC<QuestionTypoProps> = memo(
  ({ info, order, text, required, roleCheck = true, formCompleted }) => {
    // info, order,text
    const questionInfo = info ? (
      <>
        <TooltipIcon title={info} icon={<Info />} />
        {`${order} `}
        {text}
      </>
    ) : (
      <>
        {`${order} `}
        {text}
      </>
    );
    return (
      <Typography
        size={5}
        formQuestion={true}
        variant="body1"
        value={questionInfo}
        disabled={!required || !roleCheck || formCompleted}
      />
    );
  }
);
QuestionTypo.defaultProps = {
  formCompleted: false,
};
export default QuestionTypo;
