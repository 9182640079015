import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import Form from '../../Atoms/Form/Form';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import CheckboxesForm from '../Checkboxes/CheckboxesForm';
import RadiosForm from '../Radios/RadiosForm';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import { groupSelector, roleSelector } from '../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
});

type ContractorUserDetailsProps = {
  roles: (...args: any[]) => any;
  groups: (...args: any[]) => any;
};

class ContractorUserDetails extends PureComponent<ContractorUserDetailsProps, {}> {
  static defaultProps = {
    roles: () => {},
    groups: () => {},
  };

  render() {
    const { roles, groups } = this.props;
    return (
      <Fragment>
        <AdminSettingsWrapper alignItems="flex-start">
          <Form>
            <TextFieldForm size={3} name="firstName" label="Vorname" />
            <TextFieldForm name="lastName" label="Nachname" />
            <TextFieldForm size={3} name="telephone" label="Telefon" />
            <TextFieldForm size={3} name="position" label="Position" />
            <TextFieldForm size={3} name="email" label="E-mail" />
            <TextFieldForm size={3} name="password" label="Passwort" type="password" />
            <RadiosForm
              label="Gruppen"
              name="groups"
              options={groups({
                option: { costCentre: true },
              })}
              size={12}
            />
            <CheckboxesForm label="Rollen" name="roles" options={roles({})} size={12} />
          </Form>
        </AdminSettingsWrapper>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  null
)(ContractorUserDetails);
