import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, change as changeAction, InjectedFormProps } from 'redux-form';
import Form from '../../../../../../Atoms/Form/Form';
import Button from '../../../../../../Atoms/Button/Button';
import Typography from '../../../../../../Atoms/Typography/Typography';
import { createInitialFormValues, renderForm } from '../../../../../../Molecules/Forms/FormHelpers';
import { formCheckQuestionCompletion } from '../../../../../../../helpers/formCompletion';

import { saveFormSectionAction, saveFormStationAction } from '../../../../../../../actions';
import {
  FORM_SECTION_STATION,
  PROJECT_MANAGER,
  FORM_SECTION_RETROFIT_STATION,
} from '../../../../../../../../../shared/src/constants/general';
import { userSelector, stationTypeSelector } from '../../../../../../../helpers/selectors';

const styles = {
  completeButton: {
    marginBottom: '50px',
  },
};

const mapStateToProps = (state: any) => ({
  formCheckGeneral: state.form.formCheckGeneral,
  roles: state.rootReducer.roles,
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
  answerChecks: state.rootReducer.answerChecks,
  formSections: state.rootReducer.formSections,
  formStationTypes: state.rootReducer.formStationTypes,
  stationTypes: (filter: any) => stationTypeSelector(state, filter),
  forms: state.rootReducer.forms,
});
const mapDispatchToProps = (dispatch: any) => ({
  change: (field: any, value: any, form?: any) => dispatch(changeAction(form, field, value)),
  saveFormStation: (data: any) => dispatch(saveFormStationAction(data)),
  saveFormSection: (data: any) => dispatch(saveFormSectionAction(data)),
});

const StationTypeHeading = (
  currentFormStationTypeId: any,
  formStationTypes: any,
  stationTypes: any
) => {
  const currentStation = formStationTypes.find((e: any) => e.id === currentFormStationTypeId);
  const currentStationType = stationTypes({
    include: { stationTypeId: [currentStation.stationTypeId] },
  });
  const typoValue = (
    <>
      {currentStation.name}, {currentStationType[0].label} {currentStation.optional && 'mit SST'}
    </>
  );
  return <Typography size={12} variant="h6" value={typoValue} />;
};

interface ComponentOwnProps {
  formId: number | null;
  formSectionId: number | null;
  formStationTypeId?: number | null;
}

interface ComponentStateProps {
  formCheckGeneral?: any;
  formSections: any[];
  formStationTypes: any[];
  auth: any;
  roles: any[];
  stationTypes: (...args: any[]) => any;
  users: (...args: any[]) => any;
  answerChecks: any[];
  forms: any[];
}

interface ComponentDispatchProps {
  saveFormStation: (...args: any[]) => any;
  saveFormSection: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

type CheckGeneralState = {
  user: any;
};

class CheckGeneral extends React.Component<ComponentProps, CheckGeneralState> {
  static defaultProps = {
    formCheckGeneral: null,
    formStationTypeId: null,
  };

  state = { user: null };
  componentDidMount() {
    const { initialize, auth, users } = this.props;
    const sectionAnswers = this.getSectionAnswers();
    const user = users({
      include: { userId: auth.id },
      option: { plain: true },
    });
    this.setState({ user });
    // create the value objects
    const initValues = createInitialFormValues(sectionAnswers);
    // intialize the form data
    initialize({
      ...initValues,
    });
  }
  handleComplete = () => {
    const { formSectionId, formId, saveFormSection, saveFormStation, formStationTypeId } =
      this.props;
    if (formSectionId !== FORM_SECTION_STATION && formSectionId !== FORM_SECTION_RETROFIT_STATION) {
      saveFormSection({ sectionId: formSectionId, formId });
    } else {
      saveFormStation({ stationId: formStationTypeId, formId });
    }
  };

  getFormSectionCompleted = () => {
    const { formSectionId, formSections, formStationTypes, formStationTypeId, formId } = this.props;

    if (formSectionId !== FORM_SECTION_STATION && formSectionId !== FORM_SECTION_RETROFIT_STATION) {
      return formSections.find((f) => f.sectionId === formSectionId && f.formId === formId)
        .completed;
    }

    return formStationTypes.find((f) => f.id === formStationTypeId && f.formId === formId)
      .completed;
  };

  getSectionAnswers = () => {
    const { formSectionId, formStationTypeId, answerChecks } = this.props;
    return answerChecks
      .filter((a) => a.question.formSectionId === formSectionId)
      .filter((a) => a.formStationTypeId === formStationTypeId);
  };

  render() {
    const {
      auth,
      formSectionId,
      formCheckGeneral,
      answerChecks,
      roles,
      change,
      formStationTypeId,
      formStationTypes,
      stationTypes,
    } = this.props;
    const { user } = this.state;
    const completeEnabled =
      formCheckQuestionCompletion(answerChecks, formSectionId, formStationTypeId).notAnswered === 0;
    const formSectionCompleted = this.getFormSectionCompleted();
    const answers = this.getSectionAnswers();

    return (
      <>
        {formStationTypeId && StationTypeHeading(formStationTypeId, formStationTypes, stationTypes)}
        {formCheckGeneral && roles && user && (
          <Form>
            {formCheckGeneral.values && (
              <>
                {renderForm({
                  answers,
                  formData: formCheckGeneral,
                  user,
                  change,
                  formCompleted: formSectionCompleted,
                })}
              </>
            )}
            <Button
              sx={styles.completeButton}
              handleClick={this.handleComplete}
              disabled={!completeEnabled || formSectionCompleted || auth.rightId > PROJECT_MANAGER}
            >
              Abschließen PL
            </Button>
          </Form>
        )}
      </>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'formCheckGeneral',
  })(CheckGeneral)
);
