import React, { PureComponent, FC } from 'react';
import { head } from 'lodash';
import { Grid, Divider } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ControllingCalendarWeek from './components/ControllingCalendarWeek/ControllingCalendarWeek';
import Typography from '../../Atoms/Typography/Typography';
import { ITimeEntry } from '../../../../../shared/src/interfaces/TimeEntry';
import { IEntriesByDay, IEntries, ICombinedTimeEntries } from '../../../types/frontedTypes';
import { TIME_ENTRY_TYPE } from '../../../../../shared/src/enums/enums';

import {
  TIME_SHEET_REGISTERED,
  TIME_SHEET_EXPORTED,
  TIME_SHEET_ZEUS,
  TIME_SHEET_APPROVED,
  SERVICE_PROJECT_STATE_FINISHED,
  SERVICE_PROJECT_STATE_CLOSED,
} from '../../../../../shared/src/constants/general';
import {
  setPrintEntriesAction,
  getAllTimeEntriesAction,
  saveTimeSheetAction,
} from '../../../actions';
import { projectSelector, serviceProjectSelector, userSelector } from '../../../helpers/selectors';
import moment from 'moment';
import { IServiceEntry } from '../../../../../shared/src/interfaces/ServiceEntry';
import { ITimeSheet } from '../../../../../shared/src/interfaces/TimeSheet';
import { ControllingEntriesOverview } from './components/ControllingEntriesOverview/ControllingEntriesOverview';

const mapStateToProps = (state: any) => ({
  timeSheets: state.rootReducer.timeSheets,
  combinedTimeEntries: state.rootReducer.combinedTimeEntries,
  timeEntry: state.rootReducer.timeEntry,
  auth: state.rootReducer.auth,
  projects: (filter: any) => projectSelector(state, filter),
  serviceProjects: (filter: any) => serviceProjectSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  // redux-form
  controllingCalendarWeek: state.form.controllingCalendarWeek,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveTimeSheet: (timeSheet: any) => dispatch(saveTimeSheetAction(timeSheet)),
  getAllTimeEntries: (week: any, year: any) => dispatch(getAllTimeEntriesAction(week, year)),
  setPrintEntries: (entries: any, week: any, userId: any, projectId: any) =>
    dispatch(
      setPrintEntriesAction({
        entries,
        week,
        userId,
        projectId,
      })
    ),
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  serviceProjects: (...args: any[]) => any;
  combinedTimeEntries: ICombinedTimeEntries[];
  auth: any;
  timeSheets: ITimeSheet[];
  timeEntry?: any;
  controllingCalendarWeek?: any;
}

interface ComponentDispatchProps {
  getAllTimeEntries: (...args: any[]) => any;
  setPrintEntries: (...args: any[]) => any;
  saveTimeSheet: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps;

class PageControlling extends PureComponent<ComponentProps, {}> {
  getWeekEntries = () => {
    const { combinedTimeEntries, controllingCalendarWeek } = this.props;
    if (!combinedTimeEntries || !controllingCalendarWeek || !controllingCalendarWeek.values.week)
      return { time: [], service: [] };
    const weekValue = controllingCalendarWeek.values.week.value;
    const selectedYear = parseInt(weekValue.split('-')[0], 10); // moment(value.value, 'YYYY-WW').week();
    const selectedWeek = parseInt(weekValue.split('-')[1], 10); // /moment(value.value, 'YYYY-WW').year();
    const result = combinedTimeEntries.find(
      (entry) => entry.week === selectedWeek && entry.year === selectedYear
    );
    return { time: result?.time || [], service: result?.service || [] };
  };

  getFilteredEntries<T extends IServiceEntry | ITimeEntry>(
    type: TIME_ENTRY_TYPE,
    weekEntries: IEntriesByDay<T>[]
  ) {
    const { controllingCalendarWeek, timeSheets, serviceProjects } = this.props;
    if (!weekEntries || !controllingCalendarWeek) return null;
    const weekValue = controllingCalendarWeek.values.week.value;
    const selectedYear = moment(weekValue, 'YYYY-WW').year();
    const selectedWeek = moment(weekValue, 'YYYY-WW').week();

    let newData: IEntries[] = [];

    const getProjectId = (type: TIME_ENTRY_TYPE, entry: ITimeEntry | IServiceEntry) => {
      if (type === TIME_ENTRY_TYPE.PROJECT) {
        return (entry as ITimeEntry).projectId;
      }
      return (entry as IServiceEntry).serviceProjectId;
    };

    const checkTimeSheet = (projectId: number, userId: number) => {
      if (!timeSheets) return null;

      const foundTimeSheet = timeSheets.find(
        (timeSheet) =>
          timeSheet.userId === userId &&
          timeSheet.projectId === projectId &&
          timeSheet.week === selectedWeek &&
          timeSheet.year === selectedYear
      );
      if (foundTimeSheet) {
        if (foundTimeSheet.completed === 1) {
          return TIME_SHEET_ZEUS;
        }
        if (foundTimeSheet.completed === 0) {
          return TIME_SHEET_EXPORTED;
        }
        if (foundTimeSheet.completed === 2) {
          return TIME_SHEET_APPROVED;
        }
      }
      return TIME_SHEET_REGISTERED;
    };

    const checkServiceEntriesState = (projectId: number, isZeusExported: boolean) => {
      const serviceProjectState = serviceProjects({
        include: { serviceProjectId: projectId },
        option: { plain: true },
      })?.state;

      if (isZeusExported) {
        return TIME_SHEET_ZEUS;
      }
      if (serviceProjectState === SERVICE_PROJECT_STATE_FINISHED && !isZeusExported) {
        return TIME_SHEET_EXPORTED;
      }
      if (serviceProjectState === SERVICE_PROJECT_STATE_CLOSED && !isZeusExported) {
        return TIME_SHEET_APPROVED;
      }

      return TIME_SHEET_REGISTERED;
    };

    const checkState = (
      type: TIME_ENTRY_TYPE,
      projectId: number,
      userId: number,
      isZeusExported: boolean
    ) =>
      type === TIME_ENTRY_TYPE.PROJECT
        ? checkTimeSheet(projectId, userId)
        : checkServiceEntriesState(projectId, isZeusExported);

    // need a new data structure of weekEntries for controlling page
    // so we get uniqueUserIds and belonging projects
    weekEntries.forEach((day) => {
      day.entries.forEach((e) => {
        const foundUserIndex = newData.findIndex((data) => data.userId === e.userId);
        if (foundUserIndex !== -1) {
          const foundUserProjectIndex = newData[foundUserIndex].projects.findIndex(
            (project) => project.projectId === getProjectId(type, e)
          );
          if (foundUserProjectIndex === -1) {
            newData[foundUserIndex].projects.push({
              state: checkState(
                type,
                getProjectId(type, e),
                e.userId,
                (e as IServiceEntry).isZeusExported
              ),
              projectId: getProjectId(type, e),
              dayEntries: [],
            });
          }
        } else {
          newData.push({
            userId: e.userId,
            projects: [
              {
                state: checkState(
                  type,
                  getProjectId(type, e),
                  e.userId,
                  (e as IServiceEntry).isZeusExported
                ),
                projectId: getProjectId(type, e),
                dayEntries: [],
              },
            ],
          });
        }
      });
    });

    //find in newData belonging user projects and add old data structure
    weekEntries.forEach((day) => {
      day.entries.forEach((e) => {
        const foundUserIndex = newData.findIndex((data) => data.userId === e.userId);
        const foundUserProjectIndex = newData[foundUserIndex].projects.findIndex(
          (project) => project.projectId === getProjectId(type, e)
        );
        const foundProjectDayIndex = newData[foundUserIndex].projects[
          foundUserProjectIndex
        ].dayEntries.findIndex((projectDay) => projectDay.date === day.date);

        if (foundProjectDayIndex !== -1) {
          newData[foundUserIndex].projects[foundUserProjectIndex].dayEntries[
            foundProjectDayIndex
          ].entries.push(e);
        } else {
          newData[foundUserIndex].projects[foundUserProjectIndex].dayEntries.push({
            date: day.date,
            entries: [e],
          });
        }
      });
    });
    let filteredEntries;

    if (controllingCalendarWeek.values.user) {
      const userId = parseInt(controllingCalendarWeek.values.user.value, 10);
      filteredEntries = newData.filter((userProjects) => userProjects.userId === userId);
    }
    if (controllingCalendarWeek?.values?.timeSheetState) {
      const state = parseInt(controllingCalendarWeek.values.timeSheetState, 10);
      filteredEntries = newData.map((userProjects) => {
        const filteredProjects = userProjects.projects.filter((project) => project.state === state);
        return { ...userProjects, projects: filteredProjects };
      });
    }
    return filteredEntries ? filteredEntries : newData;
  }

  handleChange = () => {
    const { getAllTimeEntries, controllingCalendarWeek } = this.props;
    const selectedYear = controllingCalendarWeek.values.week.value.split('-')[0]; // moment(value.value, 'YYYY-WW').week();
    const selectedWeek = controllingCalendarWeek.values.week.value.split('-')[1]; // moment(value.value, 'YYYY-WW').year();
    getAllTimeEntries(selectedWeek, selectedYear);
  };

  handlePrint = (printEntries: any) => {
    const { controllingCalendarWeek, setPrintEntries, history } = this.props;
    const {
      values: { week },
    } = controllingCalendarWeek;
    const projectId = parseInt(controllingCalendarWeek.values.project.value, 10);
    const userId = parseInt(controllingCalendarWeek.values.user.value, 10);
    setPrintEntries(printEntries, week, userId, projectId);
    history.push('/print/timeentry');
  };

  handleComplete = (projectId: any) => {
    const { timeSheets, saveTimeSheet, controllingCalendarWeek } = this.props;
    if (!timeSheets) return null;

    const weekValue = controllingCalendarWeek.values.week.value;
    // const projectId = parseInt(controllingCalendarWeek.values.project.value, 10);
    const userId = parseInt(controllingCalendarWeek.values.user.value, 10);
    const selectedYear = moment(weekValue, 'YYYY-WW').year();
    const selectedWeek = moment(weekValue, 'YYYY-WW').week();
    const foundTimeSheet = timeSheets.find(
      (timeSheet: any) =>
        timeSheet.userId === userId &&
        timeSheet.projectId === projectId &&
        timeSheet.week === selectedWeek &&
        timeSheet.year === selectedYear
    );
    saveTimeSheet(foundTimeSheet);
  };

  render() {
    const { controllingCalendarWeek } = this.props;
    const { time: weekEntries, service } = this.getWeekEntries();
    const filteredProjectTimeEntries = this.getFilteredEntries(
      TIME_ENTRY_TYPE.PROJECT,
      weekEntries
    );
    const filteredServiceTimeEntries = this.getFilteredEntries(
      TIME_ENTRY_TYPE.SERVICE_PROJECT,
      service
    );
    const selectedUserId = controllingCalendarWeek?.values?.user
      ? parseInt(controllingCalendarWeek?.values?.user?.value, 10)
      : null;

    return (
      <>
        <Grid item container justifyContent="flex-start" md={12} alignItems="flex-start">
          <ControllingCalendarWeek
            onChange={this.handleChange}
            filteredProjectTimeEntries={filteredProjectTimeEntries}
            filteredServiceTimeEntries={filteredServiceTimeEntries}
          />
        </Grid>
        <Divider style={{ width: '100%', marginBottom: 20, marginTop: 20 }} />
        <Entries
          title="Baustellenprojekte"
          type={TIME_ENTRY_TYPE.PROJECT}
          entries={filteredProjectTimeEntries}
          selectedUserId={selectedUserId}
          handleComplete={this.handleComplete}
          handlePrint={this.handlePrint}
        />
        <Entries
          title="Serviceprojekte"
          type={TIME_ENTRY_TYPE.SERVICE_PROJECT}
          entries={filteredServiceTimeEntries}
          selectedUserId={selectedUserId}
          handleComplete={() => {}}
          handlePrint={() => {}}
        />
      </>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(PageControlling));

interface IEntriesProps {
  entries: IEntries[] | null;
  handleComplete: any;
  handlePrint: any;
  selectedUserId: number | null;
  title: string;
  type: TIME_ENTRY_TYPE;
}

export const Entries: FC<IEntriesProps> = ({
  title,
  entries,
  selectedUserId,
  handlePrint,
  handleComplete,
  type,
}) => {
  if (!entries?.length || !head(entries)?.projects?.length) {
    return null;
  }

  return (
    <>
      <Typography variant="h4" value={title} size={12} />
      {entries.map((userProjects) =>
        userProjects.projects?.map((project) => {
          return (
            <ControllingEntriesOverview
              key={`${userProjects.userId}-${project.projectId}`}
              handlePrint={handlePrint}
              handleComplete={handleComplete}
              projectUserId={userProjects.userId}
              project={project}
              selectedUserId={selectedUserId}
              type={type}
            />
          );
        })
      )}
    </>
  );
};
