import React, { memo } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';

import Edit from '@mui/icons-material/Edit';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';
import { customerContactSelector, rightSelector } from '../../../helpers/selectors';
import { compareProps } from '../../../helpers/compareProps';

const mapStateToProps = (state: any) => ({
  customerContacts: (filter: any) => customerContactSelector(state, filter),
  rights: (filter: any) => rightSelector(state, filter),
  compareCustomerContacts: state.rootReducer.customerContacts,
});

interface RouteParams {
  id: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  customerContacts: (...args: any[]) => any;
  rights: (...args: any[]) => any;
  compareCustomerContacts: any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams>;

const CustomerContactTable: React.SFC<ComponentProps> = memo(
  ({ customerContacts, rights, match, history }) => {
    const getCustomerContacts = () => {
      const customerId = parseInt(match.params.id, 10);
      return customerContacts({
        include: { customerId },
        option: { plain: true },
      });
    };
    return (
      <AdminSettingsWrapper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Operation</TableCell>
              <TableCell>Vorname</TableCell>
              <TableCell>Nachname</TableCell>
              <TableCell>Titel</TableCell>
              <TableCell>Telefon</TableCell>
              <TableCell>Position</TableCell>
              <TableCell>E-Mail</TableCell>
              <TableCell>Position</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getCustomerContacts().map((contact: any) => (
              <ActiveTableRow key={contact.id} gray={contact.deactivated}>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      const customerId = parseInt(match.params.id, 10);
                      history.push(`/admin/customer/${customerId}/contact/${contact.id}`);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {contact.firstName}
                </TableCell>
                <TableCell>{contact.lastName}</TableCell>
                <TableCell>{contact.title}</TableCell>
                <TableCell>{contact.telephone}</TableCell>
                <TableCell>{contact.position}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>
                  {
                    rights({
                      include: { rightId: contact.rightId },
                      option: { plain: true },
                    }).name
                  }
                </TableCell>
              </ActiveTableRow>
            ))}
          </TableBody>
        </Table>
      </AdminSettingsWrapper>
    );
  },
  compareProps
);

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(withRouter<any, any>(CustomerContactTable));
