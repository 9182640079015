import React, { PureComponent, Fragment } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import TextFieldForm from '../../../../../Atoms/TextField/TextFieldForm';
import Typography from '../../../../../Atoms/Typography/Typography';
import AutocompleteForm from '../../../../../Molecules/Autocomplete/AutocompleteForm';
import {
  SERVICE_TYPE_BREAK,
  SPECIFICATIONA_ARRIVAL,
  SPECIFICATIONA_DEPARTURE,
  GROUP_SERVICE,
  PROJECT_MANAGER,
  BILLING_TYPE_CALCULATED,
  SERVICE_TYPE_WORK,
  SERVICE_SERVICE,
  SERVICE_TRAVEL,
  SERVICE_TYPE_TRAVEL,
} from '../../../../../../../../shared/src/constants/general';
import {
  projectSelector,
  serviceProjectSelector,
  userSelector,
  groupSelector,
  roleSelector,
  serviceTypeSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
  billingTypeSelector,
  billingSelector,
  ruleSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  projects: (filter: any) => projectSelector(state, filter),
  serviceProjects: (filter: any) => serviceProjectSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  serviceTypes: (filter: any) => serviceTypeSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  billingTypes: (filter: any) => billingTypeSelector(state, filter),
  billings: (filter: any) => billingSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
});

interface ComponentStateProps {
  projects: (...args: any[]) => any;
  serviceProjects: (...args: any[]) => any;
  users: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  roles: (...args: any[]) => any;
  serviceTypes: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
  billingTypes: (...args: any[]) => any;
  billings: (...args: any[]) => any;
  rules: (...args: any[]) => any;
}

interface ComponentOwnProps {
  handleFlush: (...args: any[]) => any;
  handleChange: (...args: any[]) => any;
  formData: any;
  isDisabled?: boolean;
}

type ComponentProps = ComponentStateProps & ComponentOwnProps;

class ServiceEntryRules extends PureComponent<ComponentProps, {}> {
  getServices = (serviceTypeParam?: any, roleParam?: any) => {
    const { services, rules, formData } = this.props;
    const { serviceType, role } = formData.values;
    const serviceTypeValue = serviceTypeParam || serviceType;
    const roleValue = roleParam || role;
    if (serviceTypeValue && roleValue) {
      // Get the rules for the group and role
      const ruleOptions = rules({
        include: {
          serviceTypeId: serviceTypeValue.value,
          roleId: roleValue.value,
          groupId: GROUP_SERVICE,
        },
      });
      // if there are any rules get only the services
      if (ruleOptions) {
        // if found only return the ids
        const serviceId = ruleOptions.map((rule: any) => rule.serviceId);
        const service = services({
          include: { serviceId },
          option: { array: true },
        });
        return service;
      }
    }
    return [];
  };
  getSpecificationAs = (serviceTypeParam?: any, serviceParam?: any) => {
    const { rules, specificationAs, formData } = this.props;
    const { serviceType, role, service } = formData.values;
    const serviceTypeValue = serviceTypeParam || serviceType;
    const serviceValue = serviceParam || service;
    // Get the rules for the group and role
    if (serviceTypeValue && role && serviceValue) {
      const ruleOptions = rules({
        include: {
          serviceTypeId: serviceTypeValue.value,
          roleId: role.value,
          groupId: GROUP_SERVICE,
          serviceId: serviceValue.value,
        },
        option: {
          array: true,
        },
      });
      if (ruleOptions) {
        // if found only return the ids
        const specificationAId = ruleOptions.map((rule: any) => rule.specificationAId);
        const specificationA = specificationAs({
          include: { specificationAId },
          option: {
            array: true,
          },
        });
        return specificationA || [];
      }
    }
    return [];
  };
  getSpecificationBs = (serviceTypeParam?: any, serviceParam?: any, specificationAParam?: any) => {
    const { rules, specificationBs, formData } = this.props;
    const { serviceType, role, service, specificationA } = formData.values;
    const serviceTypeValue = serviceTypeParam || serviceType;
    const serviceValue = serviceParam || service;
    const specificationAValue = specificationAParam || specificationA;

    if (serviceTypeValue && role && serviceValue && specificationAValue) {
      // Get the rules for the group and role
      const ruleOptions = rules({
        include: {
          serviceTypeId: serviceTypeValue.value,
          roleId: role.value,
          groupId: GROUP_SERVICE,
          serviceId: serviceValue.value,
          specificationAId: specificationAValue.value,
        },
        option: {
          array: true,
        },
      });
      if (ruleOptions) {
        const specificationBId = ruleOptions.map((rule: any) => rule.specificationBId);
        const specificationB = specificationBs({
          include: { specificationBId },
          option: {
            array: true,
          },
        });
        return specificationB || [];
      }
    }
    return [];
  };
  getSelectedRule = () => {
    const { rules, formData } = this.props;
    const { role, serviceType, service, specificationA, specificationB } = formData.values;
    if (role && serviceType && service) {
      const rule = rules({
        include: {
          serviceTypeId: serviceType.value,
          roleId: role.value,
          groupId: GROUP_SERVICE,
          serviceId: service.value,
          specificationAId: specificationA ? specificationA.value : null,
          specificationBId: specificationB ? specificationB.value : null,
        },
      });
      return rule;
    }
    return null;
  };
  // getGroupOptions = () => {
  //   const { groups, formData } = this.props;
  //   const { user } = formData.values;
  //   if (user) {
  //     return groups({ include: { userId: user.value } });
  //   }
  //   return [];
  // };
  getRoleOptions = () => {
    const { roles, formData } = this.props;
    const { user } = formData.values;
    if (user) {
      return roles({ include: { userId: user.value } });
    }
    return [];
  };
  getSelectedProject = () => {
    const { projects, formData } = this.props;
    const { project } = formData.values;
    if (project) {
      return projects({
        include: { projectId: project.value },
        option: { plain: true },
      });
    }
    return [];
  };
  handleChange = (field: any) => {
    const { handleFlush } = this.props;
    let flushFields;
    if (field === 'role' || field === 'group') {
      flushFields = [
        'billingType',
        'billing',
        'commentBilling',
        'service',
        'specificationA',
        'specificationB',
      ];
    }
    if (field === 'serviceType') {
      // flushFields = ['service', 'specificationA', 'specificationB', 'billingType', 'billing'];
      flushFields = [
        'service',
        'specificationA',
        'specificationB',
        'billingType',
        'billing',
        'visitTopic',
        'visitParticipants',
        'visitDistributionCircle',
      ];
    }
    if (field === 'service') {
      flushFields = ['specificationA', 'specificationB'];
    }
    if (field === 'specificationA') {
      flushFields = ['specificationB'];
    }
    if (field === 'billingType') {
      flushFields = ['billing'];
    }
    handleFlush(flushFields);
  };
  getUserWa = () => {
    const { serviceProjects, formData } = this.props;
    const { serviceProject } = formData.values;
    if (serviceProject) {
      const selectedServiceProject = serviceProjects({
        include: {
          serviceProjectId: parseInt(serviceProject.value, 10),
        },
        option: { plain: true },
      });

      if (selectedServiceProject.wa) {
        return { number: selectedServiceProject.wa };
      }
    }
    return { number: null };
  };
  render() {
    const { users, groups, serviceTypes, billingTypes, billings, formData, isDisabled } =
      this.props;
    const { serviceType, specificationA, billingType, user, group } = formData.values;
    const rule = this.getSelectedRule();
    const project = this.getSelectedProject();
    const serviceTypeId = serviceType ? parseInt(serviceType.value, 10) : null;
    const billingTypeId = billingType ? parseInt(billingType.value, 10) : null;
    const specificationAId = specificationA ? parseInt(specificationA.value, 10) : null;
    const userRightId = user
      ? users({ include: { userId: user.value }, option: { plain: true } }).rightId
      : null;
    const userId = user
      ? users({ include: { userId: user.value }, option: { plain: true } }).id
      : null;
    const groupId = group ? parseInt(group.value, 10) : 0;
    const waNumber = this.getUserWa();
    return (
      <Fragment>
        <Grid md={12} item container spacing={5}>
          {userRightId !== PROJECT_MANAGER && (
            <Fragment>
              <TextFieldForm name="group" label="Gruppe" disabled />
              <AutocompleteForm
                options={this.getRoleOptions()}
                label="Rolle"
                name="role"
                onChange={() => this.handleChange('role')}
                isDisabled={isDisabled}
              />
            </Fragment>
          )}
        </Grid>
        <Grid md={12} item container spacing={5}>
          <AutocompleteForm
            options={serviceTypes({})}
            label="Art"
            name="serviceType"
            onChange={(value) => {
              this.handleChange('serviceType');
              if (value.value === SERVICE_TYPE_WORK.toString()) {
                const work = billingTypes({ include: { billingTypeId: BILLING_TYPE_CALCULATED } });
                const serviceValue = this.getServices(value).find(
                  (service: any) => service.value === SERVICE_SERVICE.toString()
                );
                const specificationAValue = this.getSpecificationAs(value, serviceValue).find(
                  (specificationA: any) => specificationA.label === 'Allgemein' // TODO: should not be hard coded
                );
                const specificationBValue = this.getSpecificationBs(
                  value,
                  serviceValue,
                  specificationAValue
                ).find((specificationB: any) => specificationB.label === 'Standard'); // TODO: should not be hard coded
                this.props.handleChange([
                  { name: 'billingType', value: work },
                  { name: 'service', value: serviceValue },
                  { name: 'specificationA', value: specificationAValue },
                  { name: 'specificationB', value: specificationBValue },
                ]);
              } else if (value.value === SERVICE_TYPE_TRAVEL.toString()) {
                const travel = billingTypes({
                  include: { billingTypeId: BILLING_TYPE_CALCULATED },
                });
                const serviceValue = this.getServices(value).find(
                  (service: any) => service.value === SERVICE_TRAVEL.toString()
                );
                this.props.handleChange([
                  { name: 'billingType', value: travel },
                  { name: 'service', value: serviceValue },
                ]);
              }
            }}
            isDisabled={isDisabled}
          />
          {serviceTypeId && (
            <Fragment>
              {serviceTypeId !== SERVICE_TYPE_BREAK && (
                <Fragment>
                  <AutocompleteForm
                    options={billingTypes({})}
                    label="Abrechnungsart"
                    size={3}
                    name="billingType"
                    onChange={() => this.handleChange('billingType')}
                    isDisabled={isDisabled}
                  />
                  {billingTypeId && (
                    <Fragment>
                      {/* {billingTypeId !== BILLING_TYPE_WITHOUT && (
                        <AutocompleteForm
                          options={billings({
                            include: { billingTypeId: billingType.value },
                          })}
                          label="Abrechnung"
                          name="billing"
                        />
                      )} */}
                      {billingTypeId !== BILLING_TYPE_CALCULATED && (
                        <TextFieldForm
                          name="commentBilling"
                          label="Kommentar zur Abrechnung"
                          // TODO: Do we need this here?
                          // onChange={() => this.handleChange('commentBilling')}
                          disabled={isDisabled}
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Grid>

        {serviceTypeId && (
          <Fragment>
            {serviceTypeId !== SERVICE_TYPE_BREAK && (
              <Fragment>
                <Grid md={12} item container spacing={5}>
                  <AutocompleteForm
                    size={4}
                    options={this.getServices()}
                    label="Tätigkeit allgemein"
                    name="service"
                    onChange={() => this.handleChange('service')}
                    isDisabled={isDisabled}
                  />
                </Grid>
                <Grid md={12} item container spacing={5}>
                  <AutocompleteForm
                    size={3}
                    options={this.getSpecificationAs()}
                    label="Spezifikation A"
                    name="specificationA"
                    onChange={() => this.handleChange('specificationA')}
                    isDisabled={isDisabled}
                  />
                  {specificationAId && (
                    <Fragment>
                      {specificationAId !== SPECIFICATIONA_DEPARTURE &&
                        specificationAId !== SPECIFICATIONA_ARRIVAL && (
                          <AutocompleteForm
                            size={3}
                            options={this.getSpecificationBs()}
                            label="Spezifikation B"
                            name="specificationB"
                            isDisabled={isDisabled}
                          />
                        )}

                      {(specificationAId === SPECIFICATIONA_DEPARTURE ||
                        specificationAId === SPECIFICATIONA_ARRIVAL) && (
                        <TextFieldForm
                          name="distance"
                          label="Distanz"
                          type="number"
                          disabled={isDisabled}
                        />
                      )}
                    </Fragment>
                  )}
                  {rule && project && (
                    <Fragment>
                      {rule.station === '1' && (
                        <TextFieldForm
                          name="station"
                          label="Stations-Nr."
                          type="number"
                          disabled={isDisabled}
                        />
                      )}
                      {waNumber && (
                        <Typography
                          size={3}
                          value={`${waNumber.number ? waNumber.number : 'k.A'}
                             ${rule.wa ? rule.wa : 'k.A.'}`}
                          variant="subtitle1"
                          caption="Wa-Nr. Vorgang"
                          captionType="top"
                        />
                      )}
                    </Fragment>
                  )}
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export default connect<ComponentStateProps, {}, ComponentOwnProps>(
  mapStateToProps,
  {}
)(ServiceEntryRules);
