import PageRuleWrapper from '../../components/Templates/PageWrappers/PageRuleWrapper';
import PageRuleOverview from '../../components/Pages/PageAdminArea/Rule/PageRuleOverview/PageRuleOverview';
import PageRuleEdit from '../../components/Pages/PageAdminArea/Rule/PageRuleEdit/PageRuleEdit';
import PageServiceOverview from '../../components/Pages/PageAdminArea/Rule/PageServiceOverview/PageServiceOverview';
import PageServiceEdit from '../../components/Pages/PageAdminArea/Rule/PageServiceEdit/PageServiceEdit';
import PageSpecificationAOverview from '../../components/Pages/PageAdminArea/Rule/PageSpecificationAOverview/PageSpecificationAOverview';
import PageSpecificationAEdit from '../../components/Pages/PageAdminArea/Rule/PageSpecificationAEdit/PageSpecificationAEdit';
import PageSpecificationBOverview from '../../components/Pages/PageAdminArea/Rule/PageSpecificationBOverview/PageSpecificationBOverview';
import PageSpecificationBEdit from '../../components/Pages/PageAdminArea/Rule/PageSpecificationBEdit/PageSpecificationBEdit';

/**
 * @constant
 * @description A constant to define routes for the parameter module.
 */
export const ruleRoute = [
  // PARAMETER
  {
    path: '/admin/rule',
    component: PageRuleWrapper(PageRuleOverview),
    exact: true,
  },
  {
    path: '/admin/rule/service',
    component: PageRuleWrapper(PageServiceOverview),
    exact: true,
  },

  {
    path: '/admin/rule/service/:serviceId',
    component: PageRuleWrapper(PageServiceEdit),
    exact: true,
  },
  {
    path: '/admin/rule/specificationa',
    component: PageRuleWrapper(PageSpecificationAOverview),
    exact: true,
  },
  {
    path: '/admin/rule/specificationa/:specificationAId',
    component: PageRuleWrapper(PageSpecificationAEdit),
    exact: true,
  },
  {
    path: '/admin/rule/specificationb/',
    component: PageRuleWrapper(PageSpecificationBOverview),
    exact: true,
  },
  {
    path: '/admin/rule/specificationb/:specificationBId',
    component: PageRuleWrapper(PageSpecificationBEdit),
    exact: true,
  },
  {
    path: '/admin/rule/:id',
    component: PageRuleWrapper(PageRuleEdit),
    exact: true,
  },
];

export default {};
