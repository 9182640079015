import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Grid } from '@mui/material';
import TextField from '../../../../../../Atoms/TextField/TextField';
import Typography from '../../../../../../Atoms/Typography/Typography';
import HeaderSectionButton from './HeaderSectionButtons';

import { getSortedData } from '../../../../../../../helpers/getSortedData';

import {
  projectSelector,
  projectKindSelector,
  formStationTypeSelector,
  formSelector,
  customerSelector,
  siteSelector,
} from '../../../../../../../helpers/selectors';
import { formSectionsConst } from '../../../../../../../constants/all';

const mapStateToProps = (state: any) => ({
  answerChecks: state.rootReducer.answerChecks,
  formTypes: state.rootReducer.formTypes,
  forms: (filter: any) => formSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  formStationTypes: (filter: any) => formStationTypeSelector(state, filter),
  projectKinds: (filter: any) => projectKindSelector(state, filter),
  formSections: state.rootReducer.formSections,
});

interface ComponentOwnProps {
  formSectionId?: number | null;
  formId: any;
  changeFormSection: (...args: any[]) => any;
}

interface ComponentStateProps {
  formSections: any[];
  formTypes: any[];
  answerChecks: any[];
  sites: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  forms: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  projectKinds: (...args: any[]) => any;
  formStationTypes: (...args: any[]) => any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

type CheckHeaderState = {
  project: any;
  formTypeId: number | null;
};

class CheckHeader extends React.Component<ComponentProps, CheckHeaderState> {
  static defaultProps = {
    // CheckHeader: null,
    formSectionId: null,
    formId: null,
  };

  state = {
    project: {
      number: null,
      customerId: null,
      siteId: null,
      projectKindId: null,
    },
    formTypeId: null,
  };

  componentDidMount() {
    const { projects, forms, formId } = this.props;
    const form = forms({ include: { formId }, option: { plain: true } });
    const project = projects({
      include: { projectId: form.projectId },
      option: { plain: true },
    });
    this.setState({ project, formTypeId: form.formTypeId });
  }
  getTimeStamp = () => {
    const { answerChecks, forms, formId } = this.props;
    const form = forms({ include: { formId }, option: { plain: true } });

    // created at, since new answers get created as the old ones get deleted
    const lastEdited = answerChecks.map((a) => ({ createdAt: a.createdAt }));
    // add the form date, because we may have updated the basic form data, not the questions
    lastEdited.push({ createdAt: form.updatedAt });
    const timeStamp = moment(getSortedData(lastEdited, 'createdAt', false)[0].createdAt).format(
      'YYYY-MM-DD HH:mm'
    );
    return timeStamp;
  };

  render() {
    const {
      formSectionId,
      changeFormSection,
      formTypes,
      answerChecks,
      formStationTypes,
      projectKinds,
      formId,
      customers,
      sites,
      formSections,
      forms,
    } = this.props;
    // need to map because of mutation...
    const { project, formTypeId } = this.state;
    const formType = formTypeId ? formTypes.find((fT) => fT.id === formTypeId) : '';

    return (
      <Fragment>
        <Typography size={12} value={`Protokoll ${formType.name}`} variant="h5" />
        <Grid item md={10}>
          <Typography
            size={6}
            value="Prüfprotokoll"
            captionType="after"
            caption="in Anlehnung an DIN EN 528 2009 02"
            // type="text"
            variant="body1"
          />
          <Typography
            size={6}
            value={`${forms({ include: { formId }, option: { plain: true } }).name}`}
            // type="text"
            variant="body1"
          />
        </Grid>

        <TextField
          size={2}
          // name="completedAt"
          label="Letzte Speicherung"
          value={this.getTimeStamp()}
          disabled
        />
        <Typography
          size={1}
          caption="Projekt-Nr"
          // type="text"
          value={project ? `${project.number}` : ''}
          variant="h5"
          captionType="top"
        />
        <Typography
          size={3}
          caption="Kunde"
          // type="text"
          value={project ? customers({ include: { customerId: project.customerId } }).label : ''}
          variant="h5"
          captionType="top"
        />
        <Typography
          size={5}
          caption="Baustelle"
          // type="text"
          value={project ? sites({ include: { siteId: project.siteId } }).label : ''}
          variant="h5"
          captionType="top"
        />
        <Typography
          size={2}
          caption="Lagertyp"
          // type="text"
          value={
            project
              ? projectKinds({
                  include: { projectKindId: project.projectKindId },
                }).label
              : ''
          }
          variant="h5"
          captionType="top"
        />
        <Typography
          size={1}
          caption="Stationen"
          // type="text"
          value={`${formStationTypes({ include: { formId } }).length}`}
          variant="h5"
          captionType="top"
        />

        {answerChecks && formTypeId && (
          <Fragment>
            {formSectionsConst
              .find((e: any) => e.formTypeId === formTypeId)
              .section.map((e: number) => (
                <HeaderSectionButton
                  key={e}
                  formId={formId}
                  formSections={formSections}
                  formStationTypes={formStationTypes({
                    include: { formId },
                    option: { plain: true },
                  })}
                  sectionId={e}
                  answerChecks={answerChecks}
                  formSectionId={formSectionId}
                  handleClick={() => changeFormSection(e)}
                />
              ))}
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(withRouter<any, any>(CheckHeader));
