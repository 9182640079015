import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import UserSelectorTable from '../UserSelectorTable/UserSelectorTable';
import {
  DEPARTMENT_MANAGER,
  PROJECT_MANAGER,
  ADMIN,
  CONTRACTOR_STOPA,
} from '../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  // redux-form
  pageProjectEdit: state.form.pageProjectEdit,
});

type ProjectManagerProps = {
  auth?: any;
  pageProjectEdit?: any;
  handleSave?: any;
};

class ProjectManager extends React.Component<ProjectManagerProps, {}> {
  static defaultProps = {
    pageProjectEdit: null,
  };

  render() {
    const { auth, pageProjectEdit, handleSave } = this.props;
    return (
      <Fragment>
        <UserSelectorTable
          label="Abteilungsleiter"
          name="departmentManagerAdd"
          rightId={DEPARTMENT_MANAGER}
          contractorId={CONTRACTOR_STOPA}
          formData={pageProjectEdit}
          formName="pageProjectEdit"
          handleSave={handleSave}
        />
        <UserSelectorTable
          label="Projektleiter"
          rightId={PROJECT_MANAGER}
          name="projectManagerAdd"
          contractorId={CONTRACTOR_STOPA}
          disabled={auth.rightId !== ADMIN}
          formData={pageProjectEdit}
          formName="pageProjectEdit"
          handleSave={handleSave}
        />
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, null)(ProjectManager);
