import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import { getSortedData } from '../../../../../../helpers/getSortedData';
import {
  CONTACT_BY_STOPA,
  CONTACT_BY_GU,
  CONTACT_BY_CUSTOMER,
  CONTACT_TYPE_MAIL,
  CONTACT_TYPE_POST,
  CONTACT_TYPE_PHONE,
  FORM_SECTION_GENERAL,
  FORM_SECTION_STEELWORK,
  FORM_SECTION_RBG,
  FORM_SECTION_STATION,
  FORM_SECTION_RETROFIT_RBG,
  FORM_SECTION_RETROFIT_CONTROL,
  FORM_SECTION_RETROFIT_INSTALLATION,
  FORM_SECTION_RETROFIT_HANDED,
  FORM_SECTION_RETROFIT_STATION,
} from '../../../../../../../../shared/src/constants/general';
import { userSelector } from '../../../../../../helpers/selectors';
import { formSectionsConst } from '../../../../../../constants/all';

const mapStateToProps = (state: any) => ({
  answerChecks: state.rootReducer.answerChecks,
  users: (filter: any) => userSelector(state, filter),
});

type ComponentStateProps = {
  answerChecks: any[];
  users: (...args: any[]) => any;
};

interface ComponentOwnProps {
  form: any;
}

interface ComponentDispatchProps {}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class PrintCheckBody extends React.Component<ComponentProps, {}> {
  getTimeStamp = () => {
    const { answerChecks } = this.props;
    const lastEdited = answerChecks.map((a: any) => a);
    const timeStamp = moment(getSortedData(lastEdited, 'createdAt', false)[0].createdAt).format(
      'YYYY-MM-DD HH:mm'
    );
    return timeStamp;
  };
  render() {
    const { answerChecks, form, users } = this.props;
    return (
      <Fragment>
        <table className="question-table">
          <thead>
            <tr>
              <th>Frage</th>
              <th>n.b.</th>
              <th>J/N</th>
              <th>Fa</th>
              <th>Anmerkung</th>
              <th>Dok, Ind</th>
              <th>an/per/am</th>
              <th>MA</th>
            </tr>
          </thead>
          <tbody>
            {formSectionsConst
              .find((e: any) => e.formTypeId === form.formTypeId)
              .section.map((section: any) => {
                const filteredAnswers = answerChecks.filter(
                  el => el.question.formSectionId === section
                );
                // IF STATIONEN TAB QUESTIONS ORDER THEM BY STATIONID AND GIVE THEM TITLES
                if (section === FORM_SECTION_STATION || section === FORM_SECTION_RETROFIT_STATION) {
                  const stationQuestionArrays = form.stations.map((station: any) => {
                    const newFilter = filteredAnswers.filter(
                      ans => ans.formStationTypeId === station.id
                    );
                    const stationTitle = `${station.name}, ${station.stationType} ${
                      station.optional ? 'mit SST' : ''
                    }`;
                    return { stationTitle, newFilter };
                  });
                  return (
                    <Fragment key={section}>
                      <tr className="tr-heading">
                        <td className="td-heading">
                          <strong>
                            {section === FORM_SECTION_STATION && 'STATIONEN'}
                            {section === FORM_SECTION_RETROFIT_STATION && 'STATIONEN'}
                          </strong>
                        </td>
                      </tr>
                      {stationQuestionArrays.map((stat: any) => (
                        <Fragment key={stat.stationTitle}>
                          <tr className="tr-heading">
                            <td className="td-heading">
                              <strong>{stat.stationTitle}</strong>
                            </td>
                          </tr>
                          {stat.newFilter.map((ans: any) => {
                            return ans.question.questionTypeId !== 9 ? (
                              <tr
                                key={ans.id}
                                className={classNames({
                                  'tr-no': ans.decision === 0,
                                  'tr-not-required': !ans.required,
                                })}
                              >
                                <td>{ans.question.text}</td>
                                <td>{ans.required ? ' ' : 'x'}</td>
                                <td>
                                  {ans.decision === 1 && 'J'}
                                  {ans.decision === 0 && 'N'}
                                </td>
                                <td>
                                  {ans.givenFrom === CONTACT_BY_STOPA && 'STOPA'}
                                  {ans.givenFrom === CONTACT_BY_GU && 'GU'}
                                  {ans.givenFrom === CONTACT_BY_CUSTOMER && 'Kunde'}
                                </td>
                                <td>{ans.textOne}</td>
                                <td>
                                  {ans.textTwo && ans.textThree
                                    ? `${ans.textTwo}, ${ans.textThree}`
                                    : '-'}
                                </td>
                                <td>
                                  {`${ans.contactName ? `${ans.contactName}, ` : ''}
                                ${ans.contactType === CONTACT_TYPE_MAIL ? 'Mail, ' : ''}
                                ${ans.contactType === CONTACT_TYPE_POST ? 'Post, ' : ''}
                                ${ans.contactType === CONTACT_TYPE_PHONE ? 'Tel., ' : ''}
                                ${
                                  ans.date
                                    ? moment(ans.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                                    : ''
                                }`}
                                </td>
                                <td>
                                  {ans.userId ? (
                                    <Fragment>
                                      {`${
                                        users({
                                          include: { userId: ans.userId },
                                          option: { plain: true },
                                        }).lastName
                                      } ${moment(ans.createdAt, 'YYYY-MM-DD').format(
                                        'DD.MM.YYYY'
                                      )}`}
                                    </Fragment>
                                  ) : (
                                    ''
                                  )}
                                </td>
                              </tr>
                            ) : (
                              <tr key={ans.id}>
                                <td className="td-sub-heading">
                                  <strong>{ans.question.text}</strong>
                                </td>
                              </tr>
                            );
                          })}
                        </Fragment>
                      ))}
                    </Fragment>
                  );
                }
                return (
                  <Fragment key={section}>
                    <tr className="tr-heading">
                      <td className="td-heading">
                        <strong>
                          {section === FORM_SECTION_GENERAL && 'ALLGEMEIN'}
                          {section === FORM_SECTION_STEELWORK && 'STAHLBAU'}
                          {section === FORM_SECTION_RBG && 'RBG'}
                          {section === FORM_SECTION_STATION && 'STATIONEN'}

                          {section === FORM_SECTION_RETROFIT_RBG && 'RBG + STAHLBAU'}
                          {section === FORM_SECTION_RETROFIT_CONTROL && 'STEUERUNG'}
                          {section === FORM_SECTION_RETROFIT_INSTALLATION && 'INBETRIEBNAHME'}
                          {section === FORM_SECTION_RETROFIT_HANDED && 'ÜBERGABE'}
                        </strong>
                      </td>
                    </tr>
                    {filteredAnswers.map(ans => {
                      return ans.question.questionTypeId !== 9 ? (
                        <tr
                          key={ans.id}
                          className={classNames({
                            'tr-no': ans.decision === 0,
                            'tr-not-required': !ans.required,
                          })}
                        >
                          <td>{ans.question.text}</td>
                          <td>{ans.required ? ' ' : 'x'}</td>
                          <td>
                            {ans.decision === 1 && 'J'}
                            {ans.decision === 0 && 'N'}
                          </td>
                          <td>
                            {ans.givenFrom === CONTACT_BY_STOPA && 'STOPA'}
                            {ans.givenFrom === CONTACT_BY_GU && 'GU'}
                            {ans.givenFrom === CONTACT_BY_CUSTOMER && 'Kunde'}
                          </td>
                          <td>{ans.textOne}</td>
                          <td>
                            {ans.textTwo && ans.textThree
                              ? `${ans.textTwo}, ${ans.textThree}`
                              : '-'}
                          </td>
                          <td>
                            {`${ans.contactName ? `${ans.contactName}, ` : ''}
                                ${ans.contactType === CONTACT_TYPE_MAIL ? 'Mail, ' : ''}
                                ${ans.contactType === CONTACT_TYPE_POST ? 'Post, ' : ''}
                                ${ans.contactType === CONTACT_TYPE_PHONE ? 'Tel., ' : ''}
                                ${
                                  ans.date
                                    ? moment(ans.date, 'YYYY-MM-DD').format('DD.MM.YYYY')
                                    : ''
                                }`}
                          </td>
                          <td>
                            {ans.userId ? (
                              <Fragment>
                                {`${
                                  users({
                                    include: { userId: ans.userId },
                                    option: { plain: true },
                                  }).lastName
                                } ${moment(ans.createdAt, 'YYYY-MM-DD').format('DD.MM.YYYY')}`}
                              </Fragment>
                            ) : (
                              ''
                            )}
                          </td>
                        </tr>
                      ) : (
                        <tr key={ans.id} className="tr-heading">
                          <td className="td-sub-heading">
                            <strong>{ans.question.text}</strong>
                          </td>
                        </tr>
                      );
                    })}
                  </Fragment>
                );
              })}
          </tbody>
        </table>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  {}
)(PrintCheckBody);
