import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';
import { Grid } from '@mui/material';
import TextField from '../../../../../../Atoms/TextField/TextField';
import Typography from '../../../../../../Atoms/Typography/Typography';
import { getSortedData } from '../../../../../../../helpers/getSortedData';
import {
  projectSelector,
  projectKindSelector,
  formSelector,
  customerSelector,
  siteSelector,
} from '../../../../../../../helpers/selectors';
import Button from '../../../../../../Atoms/Button/Button';

import { duplicateFormAction } from '../../../../../../../actions';

const mapStateToProps = (state: any) => ({
  answerChecks: state.rootReducer.answerChecks,
  formTypes: state.rootReducer.formTypes,
  forms: (filter: any) => formSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  projectKinds: (filter: any) => projectKindSelector(state, filter),
  formSections: state.rootReducer.formSections,
});

const mapDispatchToProps = (dispatch: any) => ({
  duplicateForm: (formId: any) => dispatch(duplicateFormAction(formId)),
});

interface ComponentOwnProps {
  formId: any;
  showFormQuestions: (...args: any[]) => any;
}

interface ComponentStateProps {
  formSections: any[];
  formTypes: any[];
  answerChecks: any[];
  sites: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  forms: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  projectKinds: (...args: any[]) => any;
}

interface ComponentDispatchProps {
  duplicateForm: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps;

type SiteCheckHeaderState = {
  project: any;
  formTypeId: number | null;
};

class SiteCheckHeader extends React.Component<ComponentProps, SiteCheckHeaderState> {
  static defaultProps = {
    formId: null,
  };

  state = {
    project: {
      number: null,
      customerId: null,
      siteId: null,
      projectKindId: null,
    },
    formTypeId: null,
  };

  componentDidMount() {
    const { projects, forms, formId } = this.props;
    const form = forms({ include: { formId }, option: { plain: true } });
    const project = projects({
      include: { projectId: form.projectId },
      option: { plain: true },
    });
    this.setState({ project, formTypeId: form.formTypeId });
  }
  getTimeStamp = () => {
    const { answerChecks, forms, formId } = this.props;
    const form = forms({ include: { formId }, option: { plain: true } });

    // created at, since new answers get created as the old ones get deleted
    const lastEdited = answerChecks.map((a) => ({ createdAt: a.createdAt }));
    // add the form date, because we may have updated the basic form data, not the questions
    lastEdited.push({ createdAt: form.updatedAt });
    const timeStamp = moment(getSortedData(lastEdited, 'createdAt', false)[0].createdAt).format(
      'YYYY-MM-DD HH:mm'
    );
    return timeStamp;
  };

  duplicateForm = () => {
    const { duplicateForm, formId } = this.props;
    duplicateForm(formId);
    // history.push(`/admin/form`);
  };

  /**
   * Checks if this is the latest of
   * this formType for this project
   */

  allowDuplications = () => {
    const { forms, formId } = this.props;
    const form = forms({ include: { formId }, option: { plain: true } });
    let projectSiteCheckForms = forms({
      include: { projectId: [form.projectId], formTypeId: form.formTypeId },
      option: { plain: true },
    }).sort((a: any, b: any) => {
      const dateOne = moment(a.createdAt).valueOf();
      const dateTwo = moment(b.createdAt).valueOf();
      return (dateTwo as any) - (dateOne as any);
    });

    if (formId === projectSiteCheckForms[0].id && form.completed) {
      return false;
    }
    return true;
  };

  getVersion = () => {
    const { forms, formId } = this.props;
    const form = forms({ include: { formId }, option: { plain: true } });
    // sort by date and get only the id, so we can check
    // how many versions we have, and which is the newest
    let projectSiteCheckForms = forms({
      include: { projectId: [form.projectId], formTypeId: form.formTypeId },
      option: { plain: true },
    })
      .sort((a: any, b: any) => {
        const dateOne = moment(a.createdAt).valueOf();
        const dateTwo = moment(b.createdAt).valueOf();
        return (dateOne as any) - (dateTwo as any);
      })
      .map((pS: any) => pS.id);
    const versionCount = projectSiteCheckForms.length;
    const currentVersion = projectSiteCheckForms.indexOf(formId) + 1;
    return `${currentVersion}/${versionCount}`;
  };

  render() {
    const {
      showFormQuestions,
      formTypes,
      answerChecks,
      projectKinds,
      formId,
      customers,
      sites,
      forms,
    } = this.props;
    // need to map because of mutation...
    const { project, formTypeId } = this.state;
    const formType = formTypeId ? formTypes.find((fT) => fT.id === formTypeId) : '';

    return (
      <Fragment>
        <Typography size={12} value={`Protokoll ${formType.name}`} variant="h5" />
        <Grid item md={10}>
          <Typography
            size={6}
            value="Prüfprotokoll"
            captionType="after"
            caption="in Anlehnung an DIN EN 528 2009 02"
            // type="text"
            variant="body1"
          />
          <Typography
            size={6}
            value={`${forms({ include: { formId }, option: { plain: true } }).name}`}
            // type="text"
            variant="body1"
          />
        </Grid>

        <TextField
          size={2}
          // name="completedAt"
          label="Letzte Speicherung"
          value={this.getTimeStamp()}
          disabled
        />
        <Typography
          size={1}
          caption="Projekt-Nr"
          // type="text"
          value={project ? `${project.number}` : ''}
          variant="h5"
          captionType="top"
        />
        <Typography
          size={3}
          caption="Kunde"
          // type="text"
          value={project ? customers({ include: { customerId: project.customerId } }).label : ''}
          variant="h5"
          captionType="top"
        />
        <Typography
          size={4}
          caption="Baustelle"
          value={project ? sites({ include: { siteId: project.siteId } }).label : ''}
          variant="h5"
          captionType="top"
        />
        <Typography
          size={3}
          caption="Lagertyp"
          value={
            project
              ? projectKinds({
                  include: { projectKindId: project.projectKindId },
                }).label
              : ''
          }
          variant="h5"
          captionType="top"
        />

        <Typography
          size={1}
          caption="Version"
          value={this.getVersion()}
          variant="h5"
          captionType="top"
        />

        {answerChecks && formTypeId && (
          <Fragment>
            <Button
              size={6}
              variant={'outlined'}
              // We only send this section back, but show all the others later for this form
              handleClick={() => showFormQuestions()}
            >
              Fragenkatalog
            </Button>
            <Button
              size={2}
              variant={'outlined'}
              alignRight
              // We only send this section back, but show all the others later for this form
              handleClick={this.duplicateForm}
              disabled={this.allowDuplications()}
            >
              Duplizieren
            </Button>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(SiteCheckHeader));
