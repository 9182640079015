import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Image from '../../../../assets/img/STOPA-Logo.svg';
import formatTime from '../../../../helpers/FormatTime';
import Button from '../../../Atoms/Button/Button';
import DialogSignature from '../../../Organisms/DialogSignature/DialogSignature';
import { CircularProgress } from '@mui/material';
import {
  SERVICE_TYPE_WORK,
  SERVICE_TYPE_TRAVEL,
  SERVICE_TYPE_BREAK,
} from '../../../../../../shared/src/constants/general';
import './print.scss';
import { setSignatureCreateDialogAction, addTimeSheetAction } from '../../../../actions';
import {
  userSelector,
  projectSelector,
  projectTypeSelector,
  projectKindSelector,
  customerSelector,
  siteSelector,
  contractorSelector,
  serviceSelector,
  specificationASelector,
  billingSelector,
  ruleSelector,
  groupSelector,
} from '../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  isLoadingTimeSheet: state.rootReducer.isLoadingTimeSheet,
  printEntries: state.rootReducer.printEntries,
  timeSheets: state.rootReducer.timeSheets,
  users: (filter: any) => userSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  projectTypes: (filter: any) => projectTypeSelector(state, filter),
  projectKinds: (filter: any) => projectKindSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  contractors: (filter: any) => contractorSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  billings: (filter: any) => billingSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
});
const mapDispatchToProps = (dispatch: any) => ({
  setSignatureCreateDialog: (open: any) => dispatch(setSignatureCreateDialogAction(open)),
  addTimeSheet: (
    year: any,
    week: any,
    signature: any,
    userId: any,
    projectId: any,
    signedBy: any
  ) => dispatch(addTimeSheetAction({ year, week, signature, userId, projectId, signedBy })),
});

interface ComponentOwnProps {}

interface ComponentStateProps {
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  projectTypes: (...args: any[]) => any;
  projectKinds: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  contractors: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  billings: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  rules: (...args: any[]) => any;
  printEntries: any;
  isLoadingTimeSheet: boolean;
  timeSheets: any[];
}

interface ComponentDispatchProps {
  setSignatureCreateDialog: (...args: any[]) => any;
  addTimeSheet: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class PrintTimeEntry extends PureComponent<ComponentProps, {}> {
  getTimeSheet = () => {
    const { users, projects, timeSheets, printEntries } = this.props;
    const year = moment(printEntries.week.value, 'YYYY-WW').year();
    const week = moment(printEntries.week.value, 'YYYY-WW').week();
    const user = users({
      include: { userId: printEntries.userId },
      option: { plain: true },
    });
    const project = projects({
      include: { projectId: printEntries.projectId },
      option: { plain: true },
    });
    const foundTimeSheet = timeSheets.find(
      (timeSheet) =>
        timeSheet.userId === user.id &&
        timeSheet.projectId === project.id &&
        timeSheet.week === week &&
        timeSheet.year === year
    );
    if (foundTimeSheet) {
      return foundTimeSheet;
    }
    return null;
  };
  calculateTime = (serviceTypeId: any) => {
    const { printEntries, rules } = this.props;
    const allWeekEntries: any[] = [];
    printEntries.entries.forEach((timeEntry: any) => {
      timeEntry.entries.forEach((entry: any) => {
        allWeekEntries.push(entry);
      });
    });
    const result = allWeekEntries.filter((entry) => {
      // get the selected rule for the entry
      const rule = rules({ include: { ruleId: entry.ruleId } });
      // check if its the serviceType we are looking for
      if (rule.serviceTypeId === serviceTypeId) {
        return entry;
      }
      return null;
    });

    if (result) {
      const calculated = result.map((entry) => {
        if (entry.timeEnd === '23:59') {
          const calculatedTime = formatTime(entry.timeStart, '23:00') + 1;
          return calculatedTime;
        }
        return formatTime(entry.timeStart, entry.timeEnd);
      });
      const sum = calculated.reduce((pv, cv) => pv + cv, 0);
      return sum;
    }
    return 0;
  };
  calculateDayTime = (dayEntry: any, serviceTypeId: any) => {
    const { rules } = this.props;
    const allDayEntries: any[] = [];
    dayEntry.entries.forEach((entry: any) => {
      allDayEntries.push(entry);
    });
    const result = allDayEntries.filter((entry) => {
      // get the selected rule for the entry
      const rule = rules({ include: { ruleId: entry.ruleId } });
      // check if its the serviceType we are looking for
      if (rule.serviceTypeId === serviceTypeId) {
        return entry;
      }
      return null;
    });
    if (result) {
      const calculated = result.map((entry) => {
        if (entry.timeEnd === '23:59') {
          const calculatedTime = formatTime(entry.timeStart, '23:00') + 1;
          return calculatedTime;
        }
        return formatTime(entry.timeStart, entry.timeEnd);
      });
      const sum = calculated.reduce((pv, cv) => pv + cv, 0);
      return sum;
    }
    return 0;
  };
  getUserWa = (project: any, printEntries: any) => {
    const { groups } = this.props;
    // get all unique ruleIds
    const uniqueGroupIds = printEntries.entries
      .map((pE: any) => pE.entries.map((entry: any) => entry.groupId))
      .reduce((acc: any, val: any) => acc.concat(val), []) // dont use .flat() cause of ie support
      .filter((value: any, i: any, self: any) => self.indexOf(value) === i);

    const userGroups = groups({
      include: { userId: printEntries.userId, groupId: uniqueGroupIds },
      option: { plain: true },
    });
    const waNumbers = userGroups.map((uG: any) => ({
      name: uG.shortName,
      number: project[uG.shortName] == null ? 'k.A.' : project[uG.shortName],
    }));
    return waNumbers;
  };

  handleSaveDialog = (signature: string, signedBy: string) => {
    const {
      users,
      projects,
      printEntries,
      addTimeSheet,
      setSignatureCreateDialog,
      // handleSave,
    } = this.props;
    // const { signedBy } = this.state;
    // get all the data
    const year = moment(printEntries.week.value, 'YYYY-WW').year();
    const week = moment(printEntries.week.value, 'YYYY-WW').week();
    const userId = users({
      include: { userId: printEntries.userId },
      option: { plain: true },
    }).id;
    const projectId = projects({
      include: { projectId: printEntries.projectId },
      option: { plain: true },
    }).id;
    // const signature = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    // this.sigPad.clear();
    addTimeSheet(year, week, signature, userId, projectId, signedBy);
    // handleSave();
    setSignatureCreateDialog(false);
  };

  render() {
    const {
      isLoadingTimeSheet,
      users,
      projects,
      projectTypes,
      projectKinds,
      customers,
      sites,
      contractors,
      services,
      specificationAs,
      billings,
      printEntries,
      rules,
      setSignatureCreateDialog,
    } = this.props;

    const workTime = this.calculateTime(SERVICE_TYPE_WORK);
    const travelTime = this.calculateTime(SERVICE_TYPE_TRAVEL);
    const totalWork = workTime && travelTime ? workTime + travelTime : 0;
    const week = moment(printEntries.week.value, 'YYYY-WW').week();
    const project = projects({
      include: { projectId: printEntries.projectId },
      option: { plain: true },
    });
    const userWaNumbers = this.getUserWa(project, printEntries);
    const customer = customers({
      include: { customerId: project.customerId },
      option: { plain: true },
    });
    const site = sites({
      include: { siteId: project.siteId },
      option: { plain: true },
    });
    const projectKind = projectKinds({
      include: { projectKindId: project.projectKindId },
      option: { plain: true },
    });
    const projectType = projectTypes({
      include: { projectTypeId: project.projectTypeId },
      option: { plain: true },
    });
    const user = users({
      include: { userId: printEntries.userId },
      option: { plain: true },
    });
    const foundTimeSheet = this.getTimeSheet();
    return (
      <div className="container-wrapper">
        <img src={Image} width="100px" className="logo" alt="logo" />

        <div className="header-wrapper">
          <div className="headerInformation">
            <p className="headerInformationPara">{`${week}`}</p>
            <p className="headerInformationPara">{workTime.toFixed(2)}</p>
            <p className="headerInformationPara">{travelTime.toFixed(2)}</p>
            <p className="headerInformationPara">{totalWork.toFixed(2)}</p>
          </div>
          <div className="stampArea">
            {foundTimeSheet ? (
              <Fragment>
                <img className="signatureImage" src={foundTimeSheet.signature} alt="Signatur" />
                <p className="stampInformation">
                  {moment(foundTimeSheet.createdAt).format('DD.MM.YYYY')}
                  {', '}
                  {foundTimeSheet.signedBy}
                </p>
              </Fragment>
            ) : isLoadingTimeSheet ? (
              <div className="signatureButton">
                <CircularProgress />
              </div>
            ) : (
              <Fragment>
                <div className="signatureButton">
                  <Button handleClick={() => setSignatureCreateDialog(true)} size={12}>
                    Unterschreiben
                  </Button>
                </div>
                <p className="stampInformation">Datum, Name, Unterschrift</p>
              </Fragment>
            )}
          </div>

          <div className="information-wrapper">
            <p className="customer">{customer.name}</p>
            <p className="user">{`${user.lastName}, ${user.firstName}`}</p>
          </div>
        </div>
        <div className="info-row">
          <div className="container-left">
            <p className="location">{`${site.city} / ${site.adress}`}</p>
            <p className="company">
              {
                contractors({
                  include: { contractorId: user.contractorId },
                  option: { plain: true },
                }).shortName
              }
            </p>
          </div>
          <div className="project-data">
            <p style={{ marginRight: '40px' }}>{`${project.number}`}</p>
            <p>{`${project.ab}`}</p>
            {userWaNumbers.map((waNumber: any) => (
              <p className="wa-number" key={waNumber.number}>
                {`${waNumber.number}`}
                <span className="wa-name">{`${waNumber.name} - WA-Nr`}</span>
              </p>
            ))}
            <p>{projectType && projectKind ? `${projectType.name} ${projectKind.name}` : 'k.A.'}</p>
          </div>
        </div>
        {printEntries.entries.map((entry: any) => (
          <Fragment key={entry.date}>
            {entry.entries.length > 0 && (
              <div className="entry-wrapper">
                <div className="entry-general-information">
                  <p>
                    {moment(entry.date).format('DD.MM')}
                    <span>{moment(entry.date).format('dd')}</span>
                  </p>
                  <p>{this.calculateDayTime(entry, SERVICE_TYPE_WORK).toFixed(2)}</p>
                  <p>{this.calculateDayTime(entry, SERVICE_TYPE_TRAVEL).toFixed(2)}</p>
                  <p>
                    {(
                      this.calculateDayTime(entry, SERVICE_TYPE_WORK) +
                      this.calculateDayTime(entry, SERVICE_TYPE_TRAVEL)
                    ).toFixed(2)}
                  </p>
                  <p>{this.calculateDayTime(entry, SERVICE_TYPE_BREAK).toFixed(2)}</p>
                </div>
                <table className="entry-table">
                  <thead>
                    <tr>
                      <th>Std</th>
                      <th>von</th>
                      <th>bis</th>
                      {/* <th>Abrechnung</th> */}
                      <th>Tätigkeit</th>
                      <th>Spez. 1</th>
                      <th>Ersatzteile</th>
                      <th>Kommentar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entry.entries.map((singleEntry: any) => (
                      <tr key={singleEntry.id}>
                        <td>
                          {singleEntry.timeEnd === '23:59'
                            ? formatTime(singleEntry.timeStart, '23:00') + 1
                            : formatTime(singleEntry.timeStart, singleEntry.timeEnd)}
                        </td>
                        <td>{moment(singleEntry.timeStart, 'HH:mm:ss').format('HH:mm')}</td>
                        <td>
                          {singleEntry.timeEnd === '23:59'
                            ? '24:00'
                            : moment(singleEntry.timeEnd, 'HH:mm:ss').format('HH:mm')}
                        </td>
                        {/* <td>
                          {singleEntry.billingId
                            ? billings({
                                include: { billingId: singleEntry.billingId },
                                option: { plain: true },
                              }).name
                            : 'Keine Angabe'}
                        </td> */}
                        <td>
                          {
                            services({
                              include: {
                                serviceId: rules({
                                  include: { ruleId: singleEntry.ruleId },
                                }).serviceId,
                              },
                              option: { plain: true },
                            }).name
                          }
                        </td>
                        <td>
                          {specificationAs({
                            include: {
                              specificationAId: rules({
                                include: { ruleId: singleEntry.ruleId },
                              }).specificationAId,
                            },
                            option: { plain: true },
                          }) &&
                            specificationAs({
                              include: {
                                specificationAId: rules({
                                  include: { ruleId: singleEntry.ruleId },
                                }).specificationAId,
                              },
                              option: { plain: true },
                            }).name}
                        </td>
                        <td>{singleEntry.spareParts}</td>
                        <td>{singleEntry.commentPublic}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </Fragment>
        ))}
        <DialogSignature handleSave={this.handleSaveDialog} />
      </div>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(PrintTimeEntry);
