import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import Form from '../../Atoms/Form/Form';
import Button from '../../Atoms/Button/Button';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';

import { addUserAction } from '../../../actions';
import { CONTRACTOR_STOPA } from '../../../../../shared/src/constants/general';

const mapDispatchToProps = (dispatch: any) => ({
  addUser: (
    contractorId: any,
    firstName: any,
    lastName: any,
    password: any,
    telephone: any,
    email: any,
    number: any,
    position: any
  ) =>
    dispatch(
      addUserAction({
        contractorId,
        firstName,
        lastName,
        password,
        telephone,
        email,
        number,
        position,
      })
    ),
});

interface ComponentOwnProps {}

interface ComponentStateProps {}

interface ComponentDispatchProps {
  addUser: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class UserCreate extends PureComponent<ComponentProps, {}> {
  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      firstName: '',
      lastName: '',
      password: '',
      telephone: '',
      email: '',
      number: '',
    });
  }
  handleCreate = (values: any) => {
    const { addUser, reset } = this.props;
    const { firstName, lastName, password, telephone, email, number } = values;
    addUser(CONTRACTOR_STOPA, firstName, lastName, password, telephone, email, number);
    reset();
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <AdminSettingsWrapper editWrapper>
        <Form handleSubmit={handleSubmit(this.handleCreate)}>
          <TextFieldForm size={3} name="firstName" label="Vorname" />
          <TextFieldForm size={3} name="lastName" label="Nachname" />
          <TextFieldForm size={3} name="email" label="E-Mail" />
          <TextFieldForm size={3} name="telephone" label="Telefon" />
          <TextFieldForm size={3} name="number" label="Personal-Nr." type="number" />
          <TextFieldForm size={3} name="password" label="Passwort" type="password" />
          <Button handleClick={handleSubmit(this.handleCreate)} alignRight>
            Neu Anlegen
          </Button>
        </Form>
      </AdminSettingsWrapper>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  null,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'userCreate',
  })(UserCreate)
);
