import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';
import { Grid } from '@mui/material';
import Form from '../../../../Atoms/Form/Form';
import AutocompleteForm from '../../../../Molecules/Autocomplete/AutocompleteForm';
import { userSelector, projectSelector } from '../../../../../helpers/selectors';
import { saveTimeSheetAllAction } from '../../../../../actions';
import { ICombinedTimeEntries, IEntries } from '../../../../../types/frontedTypes';
import { uniqBy, orderBy, uniq, head } from 'lodash';
import {
  MECHANIC,
} from '../../../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
  serviceTimeEntries: state.rootReducer.serviceTimeEntries,
  projects: (filter: any) => projectSelector(state, filter),
  // redux-form
  serviceEntriesCalendarWeek: state.form.serviceEntriesCalendarWeek,
});
const mapDispatchToProps = (dispatch: any) => ({
  saveTimeSheetAll: ({ userId, week, year }: any) =>
    dispatch(saveTimeSheetAllAction({ userId, week, year })),
});

interface ComponentOwnProps {
  filteredProjectTimeEntries: IEntries[] | null;
  filteredServiceTimeEntries: IEntries[] | null;
}

interface ComponentStateProps {
  auth: any;
  serviceTimeEntries: ICombinedTimeEntries[];
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  serviceEntriesCalendarWeek?: any;
}

interface ComponentDispatchProps {
  saveTimeSheetAll: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class serviceEntriesCalendarWeek extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    serviceEntriesCalendarWeek: null,
  };

  componentWillMount() {
    const { auth, users, initialize } = this.props;
    // set the current week as predefined
    const currentDate = new Date();
    const currentWeek = moment(currentDate).week();
    const currentYear = moment(currentDate).year();
    const mondayDate = moment(currentDate, 'YYYY-WW').startOf('week').format('DD.MM.');
    const sundayDate = moment(currentDate, 'YYYY-WW').endOf('week').format('DD.MM.YY');
    const week = {
      label: `KW ${currentWeek} ${mondayDate}-${sundayDate}`,
      value: `${currentYear}-${currentWeek}`,
    };
    let user;
    if (auth.rightId === MECHANIC) {
      user = users({
        include: { userId: auth.id },
      });
    } else {
      user = null;
    }

    initialize({
      week,
      user,
      timeSheetState: null,
      project: null,
    });
  }

  getWeekOptions = () => {
    const { serviceTimeEntries } = this.props;
    // set the current week as predefined
    const currentDate = new Date();
    const currentWeek = moment(currentDate).week();
    const currentYear = moment(currentDate).year();
    // get only the week and year
    const onlyWeekYear = uniqBy(
      serviceTimeEntries
        .map((entry) => ({ week: entry.week, year: entry.year }))
        .concat({ week: currentWeek, year: currentYear }),
      ({ year, week }) => [year, week].join()
    );
    // sort with year and week, then create new array
    const sorted = orderBy(onlyWeekYear, ['year', 'week'], ['desc', 'desc']).map((entry) => {
      const mondayDate = moment(`${entry.year}-${entry.week}`, 'YYYY-WW')
        .startOf('week')
        .format('DD.MM.');
      const sundayDate = moment(`${entry.year}-${entry.week}`, 'YYYY-WW')
        .endOf('week')
        .format('DD.MM.YY');
      return {
        label: `KW ${entry.week} ${mondayDate}-${sundayDate}`,
        value: `${entry.year}-${entry.week}`,
      };
    });
    return sorted;
  };

  getUserOptions = () => {
    const { auth, users, filteredServiceTimeEntries } = this.props;

    if (!!filteredServiceTimeEntries?.length) {
      const serviceProjectUserIds = filteredServiceTimeEntries?.map(({ userId }) => userId) || [];
      return users({
        include: { userId: serviceProjectUserIds },
        option: { all: true },
      });
    }
    return [];
  };
  handleFlush = (fields: any) => {
    const { change, auth } = this.props;
    if (auth.rightId !== MECHANIC) {
      fields.map((field: any) => change(field, null));
    }
  };

  render() {
    const { serviceEntriesCalendarWeek, auth } = this.props;

    return (
      <Fragment>
        <Grid
          item
          container
          spacing={3}
          justifyContent="flex-start"
          md={12}
          alignItems="flex-start"
        >
          {serviceEntriesCalendarWeek && (
            <Form>
              <AutocompleteForm
                size={2}
                options={this.getWeekOptions()}
                label="Übersicht"
                name="week"
                isClearable={false}
                onChange={() => this.handleFlush(['user'])}
              />
              <AutocompleteForm size={4}
                                options={this.getUserOptions()}
                                label="für"
                                name="user"
                                isDisabled={auth.rightId === MECHANIC} />
            </Form>
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'serviceEntriesCalendarWeek',
  })(serviceEntriesCalendarWeek)
);
