import React, { PureComponent, Fragment } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import TextField from '../../../../Atoms/TextField/TextField';
import {
  projectSelector,
  projectTypeSelector,
  projectKindSelector,
  customerSelector,
  customerContactSelector,
  siteSelector,
  groupSelector,
  userSelector,
} from '../../../../../helpers/selectors';
import {
  RIGHT_CUSTOMER_GENERAL,
  RIGHT_CUSTOMER_ADMIN,
  RIGHT_CUSTOMER_PROJECT_MANAGER,
} from '../../../../../../../shared/src/constants/general';

const mapStateToProps = (state: any) => ({
  projects: (filter: any) => projectSelector(state, filter),
  projectTypes: (filter: any) => projectTypeSelector(state, filter),
  projectKinds: (filter: any) => projectKindSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  customerContacts: (filter: any) => customerContactSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  // redux-form
  calendarWeek: state.form.calendarWeek,
});

interface ComponentStateProps {
  projects: (...args: any[]) => any;
  projectTypes: (...args: any[]) => any;
  projectKinds: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  customerContacts: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  calendarWeek: any;
}
class ProjectOverview extends PureComponent<ComponentStateProps, {}> {
  getProjectTypesKinds = (project: any) => {
    const { projectTypes, projectKinds } = this.props;
    const type = projectTypes({
      include: { projectTypeId: project.projectTypeId },
      option: { plain: true },
    });
    const kind = projectKinds({
      include: { projectKindId: project.projectKindId },
      option: { plain: true },
    });
    if (type && kind) {
      return `${type.name} ${kind.name}`;
    }
    return 'Keine Angaben';
  };
  getCustomerContactName = (project: any, rightId: number) => {
    const { customerContacts } = this.props;
    const customerContact = customerContacts({
      include: { customerContactId: project.customerContactIds, rightId },
      option: { plain: true },
    });
    if (customerContact[0]) {
      return `${customerContact[0].firstName} ${customerContact[0].lastName}`;
    }
    return 'Keine Angaben';
  };
  getCustomerContactTelephone = (project: any) => {
    const { customerContacts } = this.props;
    const customerContact = customerContacts({
      include: { customerContactId: project.customerContactId },
      option: { plain: true },
    });
    if (customerContact) {
      return customerContact.telephone;
    }
    return 'Keine Angaben';
  };
  getCustomerContactMail = (project: any) => {
    const { customerContacts } = this.props;
    const customerContact = customerContacts({
      include: { customerContactId: project.customerContactId },
      option: { plain: true },
    });
    if (customerContact) {
      return customerContact.mail;
    }
    return 'Keine Angaben';
  };
  getProject = () => {
    const { projects, calendarWeek } = this.props;
    if (calendarWeek.values.project) {
      if (calendarWeek.values.project.value) {
        const projectId = parseInt(calendarWeek.values.project.value, 10);
        return projects({
          include: { projectId },
          option: { plain: true },
        });
      }
    }
    return null;
  };
  render() {
    const {
      // projects,
      customers,
      sites,
      groups,
    } = this.props;
    const project = this.getProject();
    return (
      <Fragment>
        {project && (
          <Grid
            item
            container
            spacing={3}
            justifyContent="flex-start"
            md={12}
            alignItems="flex-start"
          >
            <TextField
              label="Kunde"
              value={
                customers({
                  include: { customerId: project.customerId },
                  option: { plain: true },
                }).name
              }
              size={4}
              disabled
            />
            <TextField
              label="Ort / Baustelle"
              value={
                sites({
                  include: { siteId: project.siteId },
                }).label
              }
              size={4}
              disabled
            />
            <TextField
              label="Type-Art"
              value={this.getProjectTypesKinds(project)}
              disabled
              size={4}
            />
            <TextField
              label="Kunde Ansprechpartner"
              value={this.getCustomerContactName(project, RIGHT_CUSTOMER_GENERAL)}
              disabled
              size={4}
            />
            <TextField
              label="Kunde IT-Admin"
              value={this.getCustomerContactName(project, RIGHT_CUSTOMER_ADMIN)}
              disabled
              size={4}
            />
            <TextField
              label="Kunde Projektleiter"
              value={this.getCustomerContactName(project, RIGHT_CUSTOMER_PROJECT_MANAGER)}
              disabled
              size={4}
            />
            <TextField label="Telefon" value={this.getCustomerContactTelephone(project)} disabled />
            <TextField label="Mail" value={this.getCustomerContactMail(project)} disabled />
            <TextField label="AB-Nr" value={project.ab} disabled />
            {groups({ option: { plain: true } }).map((group: any) => (
              <TextField
                key={group.id}
                value={project[group.shortName]}
                label={`WA - ${group.shortName}`}
                type="number"
                disabled
              />
            ))}
            <TextField label="FAB-Nr" value={project.fab} disabled />
          </Grid>
        )}
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, {}, {}>(mapStateToProps, {})(ProjectOverview);
