import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Form from '../../../../Atoms/Form/Form';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';
import TextFieldForm from '../../../../Atoms/TextField/TextFieldForm';
import ServiceToolbar from '../../../../Organisms/Toolbars/ServiceToolbar';
import { serviceSelector } from '../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  services: (filter: any) => serviceSelector(state, filter),
});

type PageServiceEditProps = {
  services: (...args: any[]) => any;
  match: any;
  initialize: (...args: any[]) => any;
};

class PageServiceEdit extends PureComponent<PageServiceEditProps, {}> {
  componentWillMount() {
    const { initialize, services, match } = this.props;
    const serviceId = parseInt(match.params.serviceId, 10);
    const service = services({
      include: { serviceId },
      option: { plain: true },
    });
    initialize({
      ...service,
    });
  }
  render() {
    return (
      <Fragment>
        <ServiceToolbar withSave withDelete />
        <AdminSettingsWrapper alignItems="flex-start">
          <Form>
            <TextFieldForm size={4} name="name" label="Tätigkeit" />
          </Form>
        </AdminSettingsWrapper>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  null
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageServiceEdit',
  })(withRouter<any, any>(PageServiceEdit))
);
