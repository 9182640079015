import React, { PureComponent, Fragment } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import moment from 'moment';
import { Chip } from '@mui/material';
import Typography from '../../../../Atoms/Typography/Typography';
import Button from '../../../../Atoms/Button/Button';
import {
  SERVICE_TYPE_WORK,
  SERVICE_TYPE_TRAVEL,
  SERVICE_TYPE_BREAK,
  MECHANIC,
} from '../../../../../../../shared/src/constants/general';
import formatTime from '../../../../../helpers/FormatTime';
import { ruleSelector } from '../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  timeSheets: state.rootReducer.timeSheets,
  auth: state.rootReducer.auth,
  rules: (filter: any) => ruleSelector(state, filter),
  // redux-form
  calendarWeek: state.form.calendarWeek,
});

interface ComponentOwnProps {
  handlePrint: (...args: any[]) => any;
  timeEntries: any[];
}

interface ComponentStateProps {
  auth: any;
  rules: (...args: any[]) => any;
  timeSheets?: any[] | null;
  calendarWeek: any;
}

interface ComponentDispatchProps {
  saveTimeSheet?: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

class WeekOverview extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    timeSheets: null,
  };

  calculateTime = (serviceTypeId: any) => {
    const { rules, timeEntries } = this.props;
    const allWeekEntries: any[] = [];
    timeEntries.forEach((timeEntry) => {
      timeEntry.entries.forEach((entry: any) => {
        allWeekEntries.push(entry);
      });
    });
    const result = allWeekEntries.filter((entry) => {
      // get the selected rule for the entry
      const rule = rules({ include: { ruleId: entry.ruleId } });
      // check if its the serviceType we are looking for
      if (rule.serviceTypeId === serviceTypeId) {
        return entry;
      }
      return null;
    });
    if (result) {
      const calculated = result.map((entry) => {
        if (entry.timeEnd === '23:59') {
          const calculatedTime = formatTime(entry.timeStart, '23:00') + 1;
          // const result = Number(calculatedTime) ? calculatedTime : '';
          // console.log(formatTime(entry.timeStart, '23:00') + 1);
          return calculatedTime;
        }
        return formatTime(entry.timeStart, entry.timeEnd);
      });
      const sum = calculated.reduce((pv, cv) => pv + cv, 0);
      return sum;
    }
    return 0;
  };
  //if true its not finished
  checkTimeSheet = () => {
    const { auth, timeSheets, calendarWeek } = this.props;
    if (!timeSheets) return null;
    if (auth.rightId < MECHANIC) {
      if (!calendarWeek.values.project || !calendarWeek.values.user) {
        return null;
      }
      const weekValue = calendarWeek.values.week.value;
      const projectId = parseInt(calendarWeek.values.project.value, 10);
      const userId = parseInt(calendarWeek.values.user.value, 10);
      const selectedYear = moment(weekValue, 'YYYY-WW').year();
      const selectedWeek = moment(weekValue, 'YYYY-WW').week();
      const foundTimeSheet = timeSheets.find(
        (timeSheet) =>
          timeSheet.userId === userId &&
          timeSheet.projectId === projectId &&
          timeSheet.week === selectedWeek &&
          timeSheet.year === selectedYear
      );
      if (foundTimeSheet) {
        if (foundTimeSheet.completed !== 1) {
          return true;
        }
        return false;
      }
      return null;
    }
    return null;
  };
  checkForFalseTimeEntries = () => {
    const { timeEntries } = this.props;
    const hasFalseEntries = timeEntries.reduce((acc: any, curr: any) => {
      if (acc) return acc;
      if (curr.isFalseDay) return true;
    }, false);
    return hasFalseEntries;
  };

  render() {
    const { handlePrint, timeEntries, calendarWeek } = this.props;
    const workTime = this.calculateTime(SERVICE_TYPE_WORK);
    const travelTime = this.calculateTime(SERVICE_TYPE_TRAVEL);
    const breakTime = this.calculateTime(SERVICE_TYPE_BREAK);
    const totalWork = workTime + travelTime;
    const checkTimeSheet = this.checkTimeSheet();
    const hasFalseEntries = this.checkForFalseTimeEntries();

    return (
      <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
        <Typography variant="h6" size={3} value="Wochenübersicht" />
        <Typography value={travelTime!.toFixed(2)} caption="Reise" />
        <Typography value={workTime!.toFixed(2)} caption="Arbeit" />
        <Typography value={breakTime!.toFixed(2)} caption="Pause" />
        <Typography value={totalWork.toFixed(2)} caption="gesamt Arbeit" size={2} />
        {calendarWeek.values.project && calendarWeek.values.user && (
          <Fragment>
            {/* {checkTimeSheet === true && (
              <Button size={2} handleClick={() => this.handleComplete()} alignRight>
                Abschließen
              </Button>
            )} */}
            {checkTimeSheet === false && (
              <Fragment>
                <Chip label="Wochenbericht wurde abgeschlossen" color="primary" />
              </Fragment>
            )}
            {totalWork > 0 && (
              <Button
                size={2}
                handleClick={() => handlePrint(timeEntries)}
                alignRight
                disabled={hasFalseEntries}
              >
                Export
              </Button>
            )}
          </Fragment>
        )}
      </Grid>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps
)(WeekOverview);
