import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';
// import { FORM_TYPE_CHECK, FORM_TYPE_SITE_START } from '../../../../shared/src/constants/general';

export const formSelector = (
  state: any,
  {
    include = {
      formId: null,
      formTypeId: null,
      projectId: null,
    },
    option = {
      plain: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.forms,
    (forms) => {
      if (checkSingleValue(include.formId)) {
        const formId = Array.isArray(include.formId) ? include.formId![0] : include.formId;
        const form = forms.find((u: any) => u.id === parseInt(formId!, 10));
        if (option.plain) {
          return form;
        }
        return {
          label: `${form.name}`,
          value: `${form.id}`,
        };
      }
      // for multiple users
      let formOptions = forms;
      if (include.projectId) {
        // @ts-ignore
        formOptions = formOptions.filter((e: any) => include.projectId.includes(e.projectId));
      }
      if (include.formId) {
        // @ts-ignore
        formOptions = formOptions.filter((e: any) => include.formId.includes(e.id));
      }
      if (include.formTypeId) {
        formOptions = formOptions.filter((e: any) => e.formTypeId === include.formTypeId);
      }
      if (formOptions) {
        // only get the raw / plain data
        if (option.plain) {
          return formOptions;
        }
        // return autocomplete data
        return formOptions.map((el: any) => {
          const label = `${el.name}`;
          return { label, value: `${el.id}` };
        });
      }
      return null;
    }
  )(null as any);

export default {};
