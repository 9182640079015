import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import DeleteRestoreButton from './components/DeleteRestoreButton';
import Button from '../../Atoms/Button/Button';

import { saveSpecificationAAction, deleteSpecificationAAction } from '../../../actions';

const mapStateToProps = (state: any) => ({
  pageSpecificationAEdit: state.form.pageSpecificationAEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveSpecificationA: (specificationA: any) => dispatch(saveSpecificationAAction(specificationA)),
  deleteSpecificationA: (specificationAId: any) =>
    dispatch(deleteSpecificationAAction(specificationAId)),
});

type SpecificationAToolbarProps = {
  saveSpecificationA: (...args: any[]) => any;
  deleteSpecificationA: (...args: any[]) => any;
  withDelete?: boolean;
  history: any;
  pageSpecificationAEdit: any;
};

class SpecificationAToolbar extends React.Component<SpecificationAToolbarProps, {}> {
  static defaultProps = {
    withDelete: false,
  };

  handleSave = () => {
    const { saveSpecificationA, pageSpecificationAEdit } = this.props;
    saveSpecificationA(pageSpecificationAEdit.values);
  };
  handleBack = () => {
    const { history } = this.props;
    history.push('/admin/rule/specificationA');
  };
  handleDelete = async () => {
    const { history, deleteSpecificationA, pageSpecificationAEdit } = this.props;
    history.push('/admin/rule/specificationA');
    deleteSpecificationA(pageSpecificationAEdit.values.id);
  };
  render() {
    const { pageSpecificationAEdit, withDelete } = this.props;
    return (
      <AdminSettingsWrapper justify="flex-end">
        {withDelete && (
          <DeleteRestoreButton
            handleDelete={this.handleDelete}
            isDeactivated={pageSpecificationAEdit.values.deactivated}
          />
        )}
        <Button buttonSize="small" handleClick={() => this.handleSave()} icon={<SaveIcon />}>
          Speichern
        </Button>

        <Button buttonSize="small" handleClick={() => this.handleBack()} icon={<UndoIcon />}>
          Zurück
        </Button>
      </AdminSettingsWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(SpecificationAToolbar));
