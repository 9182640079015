// Entities
export const ENTITY_USER: number = 1;
export const ENTITY_CUSTOMER_CONTACT: number = 2;

// ProjectStates
export const PROJECT_STATE_PLANNED: number = 1;
export const PROJECT_STATE_ACTIVE: number = 2;
export const PROJECT_STATE_INACTIVE: number = 3;

// ServiceProjectStates
// export const SERVICE_PROJECT_STATES: any = { 1: 'In Planung', 2: 'Aktiv', 3: 'Inaktiv' };

// ServiceProject States
export const SERVICE_PROJECT_STATE_OPEN: number = 1;
export const SERVICE_PROJECT_STATE_FINISHED: number = 2;
export const SERVICE_PROJECT_STATE_CLOSED: number = 3;
export const SERVICE_PROJECT_STATE_CANCLED: number = 4;

// ProjectKinds
export const PROJECT_KIND_NEULAGER: number = 1;
export const PROJECT_KIND_UMBAU: number = 2;
export const PROJECT_KIND_RETROFIT: number = 3;
export const PROJECT_KIND_SERVICE: number = 4;
export const PROJECT_KIND_MESSE: number = 5;

// TODO: Add Types and Categories
// rights
// user
export const ADMIN: number = 1;
export const PROJECT_MANAGER: number = 2;
export const DEPARTMENT_MANAGER: number = 3;
export const MECHANIC: number = 4;
// customer_contact
export const RIGHT_CUSTOMER_ADMIN: number = 5;
export const RIGHT_CUSTOMER_PROJECT_MANAGER: number = 6;
export const RIGHT_CUSTOMER_GENERAL: number = 7;

// CONTRACTOR
export const CONTRACTOR_STOPA: number = 1;

// SERVICE TYPES
export const SERVICE_TYPE_WORK: number = 1;
export const SERVICE_TYPE_BREAK: number = 2;
export const SERVICE_TYPE_TRAVEL: number = 3;

//  SERVICE
export const SERVICE_BREAK: number = 1;
export const SERVICE_TRAVEL: number = 2;
export const SERVICE_ASSEMBLY: number = 3;
export const SERVICE_SERVICE: number = 4;
export const SERVICE_COMMISSIONING: number = 5;
export const SERVICE_TRAINING: number = 6;
export const SERVICE_WAREHOUSEMANAGEMENT: number = 7;

// GROUPS
export const GROUP_ASSEMBLER: number = 1;
export const GROUP_SERVICE: number = 2;
export const GROUP_AUTOMATOR: number = 3;
export const GROUP_WAREHOUSEMANAGEMENT: number = 4;
export const GROUP_PROJECTMANAGER: number = 5;
export const GROUP_AUTOMATOR_PREP: number = 6;
export const GROUP_WAREHOUSEMANAGEMENT_PREP: number = 7;

// ROLES
export const ROLE_MECHANIC: number = 1;
export const ROLE_ELECTRICIAN: number = 2;
export const ROLE_AUTOMATOR: number = 3;
export const ROLE_PROJECTMANAGER: number = 4;

// SPECIFICATION A
export const SPECIFICATIONA_DEPARTURE: number = 1;
export const SPECIFICATIONA_ARRIVAL: number = 2;

// BILLING
export const BILLING_TYPE_CALCULATED: number = 1;
export const BILLING_TYPE_FLAT_WITH: number = 2;
export const BILLING_TYPE_WITHOUT: number = 3;
// todo: change this?
export const BILLING_TYPE_FLAT_WITHOUT: number = 4;

// QUESTION TYPES
export const QUESTION_TYPE_ONE: number = 1;
export const QUESTION_TYPE_TWO: number = 2;
export const QUESTION_TYPE_THREE: number = 3;
export const QUESTION_TYPE_FOUR: number = 4;
export const QUESTION_TYPE_FIVE: number = 5;
export const QUESTION_TYPE_SIX: number = 6;
export const QUESTION_TYPE_SEVEN: number = 7;
export const QUESTION_TYPE_EIGHT: number = 8;
export const QUESTION_TYPE_NINE: number = 9;
// site-check
export const QUESTION_TYPE_TEN: number = 10;
export const QUESTION_TYPE_ELEVEN: number = 11;
export const QUESTION_TYPE_TWELVE: number = 12;
// FORM TYPES
export const FORM_TYPE_SITE_START: number = 1;
export const FORM_TYPE_CHECK: number = 2;
export const FORM_TYPE_RETROFIT: number = 3;
export const FORM_TYPE_SITE_CHECK: number = 4;

// Kunde / GU / STOPA
// // GIVEN FROM
export const GIVEN_FROM_CUSTOMER: number = 1;
export const GIVEN_FROM_GU: number = 2;
export const GIVEN_FROM_STOPA: number = 3;

// Mail / Post / Telefon
// ANSWER TYPE
export const ANSWER_CONTACT_TYPE_MAIL: number = 1;
export const ANSWER_CONTACT_TYPE_POST: number = 2;
export const ANSWER_CONTACT_TYPE_TELEPHONE: number = 3;

// FORM SECTION FOR NEULAGER
export const FORM_SECTION_GENERAL: number = 1; // Allgemein
export const FORM_SECTION_STEELWORK: number = 2; // Stahlbau
export const FORM_SECTION_RBG: number = 3; // RBG
export const FORM_SECTION_STATION: number = 4; // Station

// FORM SECTION FOR Retrofit
export const FORM_SECTION_RETROFIT_RBG: number = 5; // RBG + Stahlbau
export const FORM_SECTION_RETROFIT_CONTROL: number = 6; // Steuerung
export const FORM_SECTION_RETROFIT_INSTALLATION: number = 7; // Inbetriebnahme
export const FORM_SECTION_RETROFIT_STATION: number = 8; // Station
export const FORM_SECTION_RETROFIT_HANDED: number = 9; // Uebergabe

// FORM SECTION FOR SITE-CHECK
export const FORM_SECTION_SITE_CHECK_DELIVERY: number = 10; // Anlieferung
export const FORM_SECTION_SITE_CHECK_STORAGE_LAYOUT: number = 11; // Lagerlayout
export const FORM_SECTION_SITE_CHECK_HALL: number = 12; // Halle / Aufstellungsort
export const FORM_SECTION_SITE_CHECK_HOIST: number = 13; // Hebezeug
export const FORM_SECTION_SITE_CHECK_ORGANISATION: number = 14; // Organisation

export enum FormSections {
  FORM_SECTION_GENERAL,
  FORM_SECTION_STEELWORK,
  FORM_SECTION_RBG,
  FORM_SECTION_STATION,
  FORM_SECTION_RETROFIT_RBG,
  FORM_SECTION_RETROFIT_CONTROL,
  FORM_SECTION_RETROFIT_INSTALLATION,
  FORM_SECTION_RETROFIT_STATION,
  FORM_SECTION_RETROFIT_HANDED,
  FORM_SECTION_SITE_CHECK_DELIVERY,
  FORM_SECTION_SITE_CHECK_STORAGE_LAYOUT,
  FORM_SECTION_SITE_CHECK_HALL,
  FORM_SECTION_SITE_CHECK_HOIST,
  FORM_SECTION_SITE_CHECK_ORGANISATION,
}

// FORM CONTACT BY
export const CONTACT_BY_STOPA: number = 1;
export const CONTACT_BY_GU: number = 2;
export const CONTACT_BY_CUSTOMER: number = 3;

// FORM CONTACT TYPE
export const CONTACT_TYPE_MAIL: number = 1;
export const CONTACT_TYPE_POST: number = 2;
export const CONTACT_TYPE_PHONE: number = 3;

// TIMESHEET STATES
export const TIME_SHEET_REGISTERED: number = 1; //no timesheet is found
export const TIME_SHEET_EXPORTED: number = 2; // timesheet.completed is false, timesheet.completed == 0 in the db
export const TIME_SHEET_ZEUS: number = 3; // timesheet.completed is true, timesheet.completed == 1 in the db
export const TIME_SHEET_APPROVED: number = 4; //no timesheet.completed is true, completed == 2

export const TIME_SHEET_STATES = [
  { value: 1, label: 'Eingetragen' },
  { value: 2, label: 'Exportiert' },
  { value: 4, label: 'Freigegeben' }, // Wrong order because we added/changed functionality
  { value: 3, label: 'Zeus' },
];
