import React, { memo } from 'react';
import { Grid } from '@mui/material';
import RadiosForm from '../Radios/RadiosForm';
import Typography from '../../Atoms/Typography/Typography';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';

type QuestionTypeOneProps = {
  question: any;
  formCompleted: boolean;
  formData?: any;
};

const QuestionTypeOne: React.SFC<QuestionTypeOneProps> = memo(
  ({ question, formData = {}, formCompleted }) => {
    const { id } = question;
    return (
      <Grid container item md={12} spacing={3} alignItems="flex-start" justifyContent="flex-start">
        <Typography size={4} variant="body1" value={question.text} formQuestion />
        <RadiosForm
          key={id}
          name={`${id}`}
          options={[
            { label: 'Ja', value: 1 },
            { label: 'Nein', value: 0 },
          ]}
          size={2}
          disabled={formCompleted}
        />
        {formData.values[id] !== null && (
          <TextFieldForm
            size={6}
            name={`${id}textOne`}
            label="Anmerkung"
            formQuestion
            disabled={formCompleted}
            multiline
            rowsMax={3}
          />
        )}
      </Grid>
    );
  }
);

export default QuestionTypeOne;
