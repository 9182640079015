import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RuleTabs from '../../../../Organisms/Tabs/RuleTabs';
import RuleTable from '../../../../Molecules/Tables/RuleTable';
import RuleCreate from '../../../../Molecules/AdminAreaCreates/RuleCreate';
import { ADMIN } from '../../../../../../../shared/src/constants/general';
import { RULE_EDIT_DETAILS } from '../../../../../constants/all';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
});
type PageRuleOverviewProps = {
  history: any;
  auth: any;
};
class PageRuleOverview extends PureComponent<PageRuleOverviewProps, {}> {
  componentDidMount() {
    const { history, auth } = this.props;
    if (auth.rightId > ADMIN) {
      history.push('/overview');
    }
  }
  render() {
    return (
      <Fragment>
        <RuleTabs selectedTab={RULE_EDIT_DETAILS} />
        <RuleCreate />
        <RuleTable />
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  null
)(withRouter<any, any>(PageRuleOverview));
