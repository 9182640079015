import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';

export const userSelector = (
  state: any,
  {
    include = {
      userId: null,
      contractorId: null,
      roleId: null,
      groupId: null,
      rightId: null,
    },
    exclude = {
      userId: null,
      contractorId: null,
      rightId: null,
    },
    option = {
      deactivated: false,
      plain: false,
      array: false,
      //TODO: do we need all? search for all: true
      all: false,
    },
  }
) =>
  createSelector(
    () => state.rootReducer.users,
    () => state.rootReducer.contractors,
    (users, contractors) => {
      if (checkSingleValue(include.userId) && !option.all) {
        const userId = Array.isArray(include.userId) ? include.userId![0] : include.userId;
        const user = users.find((u: { id: number }) => u.id === parseInt(userId!, 10));

        if (option.plain) {
          return option.array ? [user] : user;
        }
        const contractor = contractors.find((c: { id: any }) => c.id === user.contractorId);
        return option.array
          ? [
              {
                label: `${user.firstName} ${user.lastName} - ${contractor.shortName}`,
                value: `${user.id}`,
              },
            ]
          : {
              label: `${user.firstName} ${user.lastName} - ${contractor.shortName}`,
              value: `${user.id}`,
            };
      }
      // for multiple users
      let userOptions = users;
      if (!option.deactivated) {
        // Filter all deactivated users or users for deactivated contractors
        userOptions = userOptions.filter(
          (user: { deactivated: boolean }) => user.deactivated !== true
        );
        userOptions = userOptions.filter((user: { contractorId: any }) => {
          const contractor = contractors.find((c: { id: any }) => c.id === user.contractorId);
          if (contractor.deactivated) {
            return null;
          }
          return user;
        });
      }

      if (include.rightId) {
        userOptions = userOptions.filter(
          (user: { rightId: null }) => user.rightId === include.rightId
        );
      }
      if (include.roleId) {
        userOptions = userOptions.filter((user: { roles: { includes: (arg0: any) => void } }) =>
          user.roles.includes(include.roleId)
        );
      }
      if (include.groupId) {
        userOptions = userOptions.filter((user: any) => user.groups.includes(include.groupId));
      }
      if (include.contractorId) {
        userOptions = userOptions.filter(
          (user: { contractorId: null }) => user.contractorId === include.contractorId
        );
      }
      // include the specified users
      if (include.userId) {
        // @ts-ignore
        userOptions = userOptions.filter((user: { id: any }) => include.userId.includes(user.id));
      }
      if (exclude.userId) {
        // @ts-ignore
        userOptions = userOptions.filter((user: { id: any }) => !exclude.userId.includes(user.id));
      }
      if (exclude.contractorId) {
        userOptions = userOptions.filter(
          (user: { contractorId: null }) => user.contractorId !== exclude.contractorId
        );
      }
      if (exclude.rightId) {
        userOptions = userOptions.filter(
          (user: { rightId: null }) => user.rightId !== exclude.rightId
        );
      }

      if (userOptions) {
        // only get the raw / plain data
        if (option.plain) {
          return userOptions;
        }
        // return autocomplete data
        return userOptions.map(
          (user: { firstName: any; lastName: any; contractorId: any; id: any }) => {
            let label = `${user.firstName} ${user.lastName}`;
            // if the contractor id has not been specified, we need to add the name of the contractor
            if (!include.contractorId) {
              const contractor = contractors.find((c: { id: any }) => c.id === user.contractorId);
              label = `${label} - ${contractor.shortName}`;
            }
            return { label, value: `${user.id}` };
          }
        );
      }
      return null;
    }
  )(null as any);

export default {};
