import React, { PureComponent, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import NavBar from '../../Organisms/NavBar/NavBar';
import AdminOuterWrapper from '../AdminWrappers/AdminOuterWrapper';
import Sidebar from '../../Organisms/Sidebar/Sidebar';
import {
  // getProjectsAction,
  getUsersAction,
  // getProjectVariablesAction,
  getRightsAction,
  getRulesAction,
  getRuleVariablesAction,
  getGroupsAction,
  getRolesAction,
  getBillingsAction,
  getCustomersAction,
  getCustomerContactsAction,
  getSitesAction,
  getContractorsAction,
  // getTimeSheetsAction,
  // getTimeEntriesAction,
  setSidebarAction,
  getServiceProjectsAction,
} from '../../../actions';

export default function PageServiceProjectWrapper(WrappedComponent: React.ComponentType) {
  const mapStateToProps = (state: any) => ({
    // timeEntries: state.rootReducer.timeEntries,
    // projects: state.rootReducer.projects,
    auth: state.rootReducer.auth,
    users: state.rootReducer.users,
    rules: state.rootReducer.rules,
    sites: state.rootReducer.sites,
    roles: state.rootReducer.roles,
    services: state.rootReducer.services,
    billingTypes: state.rootReducer.billingTypes,
    billings: state.rootReducer.billings,
    customers: state.rootReducer.customers,
    customerContacts: state.rootReducer.customerContacts,
    contractors: state.rootReducer.contractors,

    serviceTypes: state.rootReducer.serviceTypes,
    groups: state.rootReducer.groups,
    specificationAs: state.rootReducer.specificationAs,
    specificationBs: state.rootReducer.specificationBs,
    // timeSheets: state.rootReducer.timeSheets,
    serviceProjects: state.rootReducer.serviceProjects,
  });
  const mapDispatchToProps = (dispatch: any) => ({
    // getProjects: () => dispatch(getProjectsAction()),
    // getProjectVariables: () => dispatch(getProjectVariablesAction()),
    getCustomers: () => dispatch(getCustomersAction()),
    getCustomerContacts: () => dispatch(getCustomerContactsAction()),
    getRights: () => dispatch(getRightsAction()),
    getSites: () => dispatch(getSitesAction()),
    getRules: () => dispatch(getRulesAction()),
    getRuleVariables: () => dispatch(getRuleVariablesAction()),
    getGroups: () => dispatch(getGroupsAction()),
    getBillings: () => dispatch(getBillingsAction()),
    getRoles: () => dispatch(getRolesAction()),
    getContractors: () => dispatch(getContractorsAction()),
    getUsers: () => dispatch(getUsersAction()),
    // getTimeSheets: () => dispatch(getTimeSheetsAction()),
    // getTimeEntries: (week: any, year: any) => dispatch(getTimeEntriesAction(week, year)),
    setSidebar: (open: any) => dispatch(setSidebarAction(open)),
    // service project
    getServiceProjects: () => dispatch(getServiceProjectsAction()),
  });

  interface ComponentOwnProps {}

  interface ComponentStateProps {
    users?: any;
    contractors?: any;
    customerContacts?: any;
    customers?: any;
    sites?: any;
    rules?: any;
    auth?: any;
    serviceTypes?: any;
    services?: any;
    specificationAs?: any;
    specificationBs?: any;
    location?: any;
    billings?: any;
    billingTypes?: any;
    // projects?: any;
    serviceProjects?: any;
    groups?: any;
    roles?: any;
    // timeSheets?: any;
  }

  interface ComponentDispatchProps {
    // getProjects: (...args: any[]) => any;
    getUsers: (...args: any[]) => any;
    // getProjectVariables: (...args: any[]) => any;
    getCustomers: (...args: any[]) => any;
    getCustomerContacts: (...args: any[]) => any;
    getContractors: (...args: any[]) => any;
    getSites: (...args: any[]) => any;
    getRules: (...args: any[]) => any;
    getBillings: (...args: any[]) => any;
    getRuleVariables: (...args: any[]) => any;
    getGroups: (...args: any[]) => any;
    getRoles: (...args: any[]) => any;
    getRights: (...args: any[]) => any;
    // getTimeSheets: (...args: any[]) => any;
    // getTimeEntries: (...args: any[]) => any;
    setSidebar: (...args: any[]) => any;
    getServiceProjects: (...args: any[]) => any;
  }

  type ComponentProps = ComponentOwnProps & ComponentStateProps & ComponentDispatchProps;

  class InnerWrapper extends PureComponent<ComponentProps, {}> {
    static defaultProps = {
      auth: null,
      users: null,
      customers: null,
      customerContacts: null,
      contractors: null,
      sites: null,
      rules: null,
      serviceTypes: null,
      services: null,
      specificationAs: null,
      specificationBs: null,
      billings: null,
      billingTypes: null,
      location: {},
      // projects: null,
      // timeEntries: null,
      // services: null,
      roles: null,
      groups: null,
      // timeSheets: null,
      serviceProjects: null,
    };

    componentDidMount() {
      const {
        //   getProjects,
        getUsers,
        // getProjectVariables,
        getCustomers,
        getCustomerContacts,
        getContractors,
        getSites,
        getRules,
        getRuleVariables,
        getGroups,
        getRoles,
        getBillings,
        getRights,
        //   getTimeSheets,
        //   getTimeEntries,
        setSidebar,
        getServiceProjects,
      } = this.props;
      // getProjects();
      getUsers();
      getRights();
      getBillings();
      // getProjectVariables();
      getCustomers();
      getCustomerContacts();
      getContractors();
      getSites();
      getRules();
      getRuleVariables();
      getGroups();
      getRoles();
      getServiceProjects();
      // getTimeSheets();
      // // set the current week as predefined
      // const currentDate = new Date();
      // const currentWeek = moment(currentDate).week();
      // const currentYear = moment(currentDate).year();
      // getTimeEntries(currentWeek, currentYear);
      setSidebar(false);
    }
    render() {
      const {
        users,
        customers,
        customerContacts,
        sites,
        contractors,
        rules,
        serviceTypes,
        services,
        specificationAs,
        specificationBs,
        billings,
        billingTypes,
        location,
        groups,
        auth,
        roles,
        serviceProjects,
      } = this.props;
      return (
        <Fragment>
          {location.pathname === '/serviceproject' && <NavBar />}
          <Sidebar />
          {users &&
            auth &&
            customers &&
            customerContacts &&
            sites &&
            contractors &&
            rules &&
            serviceTypes &&
            services &&
            specificationAs &&
            specificationBs &&
            billings &&
            billingTypes &&
            //   timeEntries &&
            groups &&
            roles &&
            serviceProjects && (
              //   timeSheets &&
              //   projects &&
              <AdminOuterWrapper>
                <WrappedComponent />
              </AdminOuterWrapper>
            )}
        </Fragment>
      );
    }
  }

  // return InnerWrapper;
  return connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
    mapStateToProps,
    mapDispatchToProps
  )(InnerWrapper);
}
