import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, change as changeAction, InjectedFormProps } from 'redux-form';
import { Grid } from '@mui/material';
import moment from 'moment';
import Form from '../../../../../Atoms/Form/Form';
import DialogTimeEntryWrapper from '../components/TimeEntryWrapper';
import TextFieldForm from '../../../../../Atoms/TextField/TextFieldForm';
import Button from '../../../../../Atoms/Button/Button';
import TimeEntryDateTime from '../components/TimeEntryDateTime';
import TimeEntryRules from '../components/TimeEntryRules';
import TimeEntryVisit from '../components/TimeEntryVisit';
import TimeEntryProject from '../components/TimeEntryProject';
import TimeEntryUser from '../components/TimeEntryUser';
import {
  SERVICE_TYPE_BREAK,
  SERVICE_BREAK,
  PROJECT_MANAGER,
} from '../../../../../../../../shared/src/constants/general';
import {
  setTimeEntryAction,
  saveTimeEntryAction,
  setTimeEntryDialogEditAction,
  deleteTimeEntryAction,
  setSnackbarAction,
} from '../../../../../../actions';
import {
  ruleSelector,
  projectSelector,
  userSelector,
  groupSelector,
  roleSelector,
  serviceTypeSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
  billingTypeSelector,
  billingSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  timeEntryDialogEditOpen: state.rootReducer.timeEntryDialogEditOpen,
  timeEntry: state.rootReducer.timeEntry,
  rules: (filter: any) => ruleSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  serviceTypes: (filter: any) => serviceTypeSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  billingTypes: (filter: any) => billingTypeSelector(state, filter),
  // billings: (filter: any) => billingSelector(state, filter),
  // redux-form
  // self
  dialogTimeEntryEdit: state.form.dialogTimeEntryEdit,
  // other
  calendarWeek: state.form.calendarWeek,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveTimeEntry: (timeEntry: any, week: any, year: any) =>
    dispatch(saveTimeEntryAction(timeEntry, week, year)),
  setSnackbar: (snackbar: any) => dispatch(setSnackbarAction(snackbar)),
  setTimeEntry: (timeEntry: any) => dispatch(setTimeEntryAction(timeEntry)),
  setTimeEntryDialogEdit: (open: any) => dispatch(setTimeEntryDialogEditAction(open)),
  deleteTimeEntry: (timeEntryId: any, week: any, year: any) =>
    dispatch(deleteTimeEntryAction(timeEntryId, week, year)),
  // redux-form
  change: (field: any, value: any, form?: any) => dispatch(changeAction(form, field, value)),
});

interface ComponentStateProps {
  // state
  timeEntry?: any;
  timeEntryDialogEditOpen: boolean;
  dialogTimeEntryEdit?: any;
  calendarWeek?: any;
  rules: (...args: any[]) => any;
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  roles: (...args: any[]) => any;
  serviceTypes: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
  billingTypes: (...args: any[]) => any;
  // billings: (...args: any[]) => any;
}

interface ComponentDispatchProps {
  //dispatch
  saveTimeEntry: (...args: any[]) => any;
  setTimeEntry: (...args: any[]) => any;
  deleteTimeEntry: (...args: any[]) => any;
  setSnackbar: (...args: any[]) => any;
  setTimeEntryDialogEdit: (...args: any[]) => any;
}

type ComponentProps = ComponentStateProps & ComponentDispatchProps;
// type DialogTimeEntryEditProps = {
//   // form
//   initialize: (...args: any[]) => any;
//   reset: (...args: any[]) => any;
//   handleSubmit: (...args: any[]) => any;
//   change: (...args: any[]) => any;
// };

type DialogTimeEntryEditState = {
  timeEntryVisitData: any[];
};

class DialogTimeEntryEdit extends PureComponent<
  ComponentProps & InjectedFormProps<{}, ComponentProps>,
  DialogTimeEntryEditState
> {
  static defaultProps = {
    timeEntry: null,
    // redux-form
    dialogTimeEntryEdit: null,
    // passed
    calendarWeek: null,
  };

  state = {
    timeEntryVisitData: [],
  };
  componentWillMount() {
    const {
      initialize,
      timeEntry,
      rules,
      projects,
      users,
      groups,
      roles,
      serviceTypes,
      services,
      specificationAs,
      specificationBs,
      billingTypes,
      // billings,
    } = this.props;
    const rule = rules({
      include: {
        serviceTypeId: timeEntry.serviceTypeId,
        roleId: timeEntry.roleId,
        groupId: timeEntry.groupId,
        serviceId: timeEntry.serviceId,
        specificationAId: timeEntry.specificationAId,
        specificationBId: timeEntry.specificationBId,
      },
    });
    // get the right format for the time autocomplete input
    const timeStartValue = moment(timeEntry.timeStart, 'HH:mm:ss').format('HH:mm');
    const timeEndValue = moment(timeEntry.timeEnd, 'HH:mm:ss').format('HH:mm');
    const timeStart = {
      label: `${timeStartValue}`,
      value: `${timeStartValue}`,
    };
    let timeEntryVisits = {};
    if (timeEntry.timeEntryVisit.length > 0) {
      const sizeArr: any[] = [];
      timeEntry.timeEntryVisit.forEach((e: any, i: any) => {
        sizeArr.push(i);
        const visitDeadLine = e.visitDeadline
          ? moment(e.visitDeadline, 'YYYY-MM-DD').format('YYYY-MM-DD')
          : null;
        timeEntryVisits = {
          ...timeEntryVisits,
          [`visitDiscussedTopic${i}`]: e.visitDiscussedTopic,
          [`visitComment${i}`]: e.visitComment,
          [`visitNameResponsible${i}`]: e.visitNameResponsible,
          [`visitDeadline${i}`]: visitDeadLine,
        };
      });
      this.setState({ timeEntryVisitData: sizeArr });
    }
    const timeEnd =
      timeEndValue === '23:59'
        ? { label: '24:00', value: `${timeEndValue}` }
        : { label: `${timeEndValue}`, value: `${timeEndValue}` };
    const extraWork = timeEntry.isExtraWork === '1' ? ['1'] : [];
    initialize({
      id: timeEntry.id,
      project: projects({ include: { projectId: timeEntry.projectId } }),
      user: users({ include: { userId: timeEntry.userId } }),
      group: groups({ include: { groupId: timeEntry.groupId } }),
      role: roles({ include: { roleId: timeEntry.roleId } }),
      // get from rule
      serviceType: serviceTypes({
        include: { serviceTypeId: rule.serviceTypeId },
      }),
      service: services({ include: { serviceId: rule.serviceId } }),
      specificationA: rule.specificationAId
        ? specificationAs({
            include: { specificationAId: rule.specificationAId },
          })
        : null,
      specificationB: rule.specificationBId
        ? specificationBs({
            include: { specificationBId: rule.specificationBId },
          })
        : null,
      //
      billingType: billingTypes({
        include: { billingTypeId: timeEntry.billingTypeId },
      }),
      // billing: billings({ include: { billingId: timeEntry.billingId } }),
      commentPublic: timeEntry.commentPublic,
      commentPrivate: timeEntry.commentPrivate,
      commentBilling: timeEntry.commentBilling,
      spareParts: timeEntry.spareParts,
      date: timeEntry.date,
      timeStart,
      timeEnd,
      station: timeEntry.station,
      distance: timeEntry.distance,
      visitTopic: timeEntry.visitTopic,
      visitParticipants: timeEntry.visitParticipants,
      visitDistributionCircle: timeEntry.visitDistributionCircle,
      extraWork,
      ...timeEntryVisits,
    });
  }
  handleSave = (values: any) => {
    const {
      rules,
      saveTimeEntry,
      setTimeEntryDialogEdit,
      timeEntryDialogEditOpen,
      setTimeEntry,
      calendarWeek,
      reset,
      setSnackbar,
    } = this.props;
    const {
      project,
      user,
      group,
      role,
      serviceType,
      service,
      specificationA,
      specificationB,
      commentPublic,
      commentPrivate,
      commentBilling,
      // billing,
      billingType,
      spareParts,
      date,
      timeStart,
      timeEnd,
      station,
      distance,
      extraWork,
      ...visitData
    } = values;
    const { timeEntryVisitData } = this.state;
    const isExtraWork = extraWork[0] === '1';
    const projectId = parseInt(project.value, 10);
    const userId = parseInt(user.value, 10);
    const roleId = parseInt(role.value, 10);
    const groupId = parseInt(group.value, 10);
    const serviceTypeId = parseInt(serviceType.value, 10);
    // if break, set the service id to break
    let serviceId = null;
    if (serviceTypeId === SERVICE_TYPE_BREAK) {
      serviceId = SERVICE_BREAK;
    } else {
      serviceId = service ? parseInt(service.value, 10) : null;
    }
    const specificationAId = specificationA ? parseInt(specificationA.value, 10) : null;
    const specificationBId = specificationB ? parseInt(specificationB.value, 10) : null;
    // const billingId = billing ? parseInt(billing.value, 10) : null;
    const billingTypeId = billingType ? parseInt(billingType.value, 10) : null;
    const rule = rules({
      include: {
        roleId,
        groupId,
        serviceTypeId,
        serviceId,
        specificationAId,
        specificationBId,
      },
    });
    // TODO: Same function as in create, move together!
    if (rule === null) {
      setSnackbar({ open: true, message: 'Es wurde keine Regel ausgewählt.' });
      return;
    }
    // get the already selected week for post-fetch after creation
    // since we wann to keep the user in the same screen
    const weekValue = moment(calendarWeek.values.week.value, 'YYYY-WW').week();
    const yearValue = moment(calendarWeek.values.week.value, 'YYYY-WW').year();
    // get the timeValues from autocomplete form data
    const timeStartValue = timeStart.value;
    const timeEndValue = timeEnd.value;
    let timeEntryVisits: any[] = [];
    // get the size of the visit data
    if (timeEntryVisitData.length > 0) {
      timeEntryVisits = timeEntryVisitData.map((e) => {
        return {
          visitDiscussedTopic: visitData[`visitDiscussedTopic${e}`],
          visitComment: visitData[`visitComment${e}`],
          visitNameResponsible: visitData[`visitNameResponsible${e}`],
          visitDeadline: visitData[`visitDeadline${e}`],
        };
      });
    }
    const timeEntry = {
      ...values,
      projectId,
      userId,
      groupId,
      roleId,
      ruleId: rule.id,
      commentPublic,
      commentPrivate,
      commentBilling,
      // billingId,
      billingTypeId,
      spareParts,
      date,
      timeStart: timeStartValue,
      timeEnd: timeEndValue,
      station,
      distance,
      timeEntryVisits,
      isExtraWork,
    };
    saveTimeEntry(timeEntry, weekValue, yearValue);
    setTimeEntry(null);
    reset();
    setTimeEntryDialogEdit(!timeEntryDialogEditOpen);
  };
  handleClose = () => {
    const { setTimeEntryDialogEdit, setTimeEntry, reset } = this.props;
    reset();
    setTimeEntryDialogEdit(false);
    setTimeEntry(null);
  };
  handleDelete = () => {
    const { deleteTimeEntry, setTimeEntryDialogEdit, calendarWeek, timeEntry } = this.props;
    const weekValue = moment(calendarWeek.values.week.value, 'YYYY-WW').week();
    const yearValue = moment(calendarWeek.values.week.value, 'YYYY-WW').year();
    deleteTimeEntry(timeEntry.id, weekValue, yearValue);
    setTimeEntryDialogEdit(false);
  };
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field, null));
  };
  handleChange = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field.name, field.value));
  };
  handleTimeEntryVisitAdd = () => {
    const { timeEntryVisitData } = this.state;
    const newArr: any[] = [...timeEntryVisitData];
    if (timeEntryVisitData.length === 0) {
      newArr.push(1);
    } else {
      newArr.push(timeEntryVisitData[timeEntryVisitData.length - 1] + 1);
    }
    this.setState({ timeEntryVisitData: newArr });
  };
  handleTimeEntryVisitDelete = (id: any) => {
    const { timeEntryVisitData } = this.state; //  ids of redux form fields
    const newArr: any[] = [...timeEntryVisitData];
    newArr.splice(newArr.indexOf(id), 1);
    this.setState({ timeEntryVisitData: newArr });
  };

  render() {
    const { users, timeEntryDialogEditOpen, dialogTimeEntryEdit, handleSubmit } = this.props;
    const { timeEntryVisitData } = this.state;
    let userRightId;
    if (dialogTimeEntryEdit) {
      const { user } = dialogTimeEntryEdit.values;
      userRightId = user
        ? users({ include: { userId: user.value }, option: { plain: true } }).rightId
        : null;
    }
    return (
      <DialogTimeEntryWrapper open={timeEntryDialogEditOpen} handleClose={this.handleClose}>
        <Form>
          {dialogTimeEntryEdit && (
            <Fragment>
              <Grid md={12} item container spacing={5}>
                <TimeEntryProject formData={dialogTimeEntryEdit} handleFlush={this.handleFlush} />
                <Button handleClick={handleSubmit(this.handleSave)} alignRight size={3}>
                  Speichern & schließen
                </Button>
              </Grid>
              <Grid md={12} item container spacing={5}>
                <TimeEntryUser
                  formData={dialogTimeEntryEdit}
                  handleFlush={this.handleFlush}
                  handleChange={this.handleChange}
                />
                <Button handleClick={this.handleClose} alignRight size={3}>
                  Abbrechen
                </Button>
              </Grid>
              <Grid md={12} item container spacing={5}>
                <TimeEntryDateTime formData={dialogTimeEntryEdit} handleFlush={this.handleFlush} />
                <Button handleClick={this.handleDelete} alignRight size={3}>
                  Löschen
                </Button>
              </Grid>
              <TimeEntryRules
                formData={dialogTimeEntryEdit}
                handleFlush={this.handleFlush}
                timeEntryVisitData={timeEntryVisitData}
              />
              <TextFieldForm size={4} name="commentPublic" label="Anmerkung / Öffentlich" />
              {userRightId !== PROJECT_MANAGER && (
                <TextFieldForm size={12} name="spareParts" label="Ersatzteile" multiline />
              )}
              <TextFieldForm size={12} name="commentPrivate" label="Kommentar / Intern" multiline />
              <TimeEntryVisit
                handleTimeEntryVisitAdd={this.handleTimeEntryVisitAdd}
                handleTimeEntryVisitDelete={this.handleTimeEntryVisitDelete}
                timeEntryVisitData={timeEntryVisitData}
                formData={dialogTimeEntryEdit}
              />
            </Fragment>
          )}
        </Form>
      </DialogTimeEntryWrapper>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<{}, ComponentProps>({
    // a unique name for the form
    form: 'dialogTimeEntryEdit',
  })(DialogTimeEntryEdit)
);
