import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';
import { Grid } from '@mui/material';
import Form from '../../../../Atoms/Form/Form';
import AutocompleteForm from '../../../../Molecules/Autocomplete/AutocompleteForm';
import RadiosForm from '../../../../Molecules/Radios/RadiosForm';
import {
  ADMIN,
  PROJECT_MANAGER,
  DEPARTMENT_MANAGER,
  MECHANIC,
  CONTRACTOR_STOPA,
  TIME_SHEET_APPROVED,
  TIME_SHEET_STATES,
} from '../../../../../../../shared/src/constants/general';
import { userSelector, projectSelector } from '../../../../../helpers/selectors';
import Button from '../../../../Atoms/Button/Button';
import { saveTimeSheetAllAction } from '../../../../../actions';
import { ICombinedTimeEntries, IEntries } from '../../../../../types/frontedTypes';
import { uniqBy, orderBy, uniq, head } from 'lodash';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  users: (filter: any) => userSelector(state, filter),
  combinedTimeEntries: state.rootReducer.combinedTimeEntries,
  projects: (filter: any) => projectSelector(state, filter),
  // redux-form
  controllingCalendarWeek: state.form.controllingCalendarWeek,
});
const mapDispatchToProps = (dispatch: any) => ({
  saveTimeSheetAll: ({ userId, week, year }: any) =>
    dispatch(saveTimeSheetAllAction({ userId, week, year })),
});

interface ComponentOwnProps {
  filteredProjectTimeEntries: IEntries[] | null;
  filteredServiceTimeEntries: IEntries[] | null;
}

interface ComponentStateProps {
  auth: any;
  combinedTimeEntries: ICombinedTimeEntries[];
  users: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  controllingCalendarWeek?: any;
}

interface ComponentDispatchProps {
  saveTimeSheetAll: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  InjectedFormProps;

class controllingCalendarWeek extends PureComponent<ComponentProps, {}> {
  static defaultProps = {
    controllingCalendarWeek: null,
  };

  componentWillMount() {
    const { auth, users, initialize } = this.props;
    // set the current week as predefined
    const currentDate = new Date();
    const currentWeek = moment(currentDate).week();
    const currentYear = moment(currentDate).year();
    const mondayDate = moment(currentDate, 'YYYY-WW').startOf('week').format('DD.MM.');
    const sundayDate = moment(currentDate, 'YYYY-WW').endOf('week').format('DD.MM.YY');
    const week = {
      label: `KW ${currentWeek} ${mondayDate}-${sundayDate}`,
      value: `${currentYear}-${currentWeek}`,
    };
    let user;
    if (auth.rightId === MECHANIC) {
      user = users({
        include: { userId: auth.id },
      });
    } else {
      user = null;
    }
    initialize({
      week,
      user,
      timeSheetState: null,
      project: null,
    });
  }

  getWeekOptions = () => {
    const { combinedTimeEntries } = this.props;
    // set the current week as predefined
    const currentDate = new Date();
    const currentWeek = moment(currentDate).week();
    const currentYear = moment(currentDate).year();
    // get only the week and year
    const onlyWeekYear = uniqBy(
      combinedTimeEntries
        .map((entry) => ({ week: entry.week, year: entry.year }))
        .concat({ week: currentWeek, year: currentYear }),
      ({ year, week }) => [year, week].join()
    );
    // sort with year and week, then create new array
    const sorted = orderBy(onlyWeekYear, ['year', 'week'], ['desc', 'desc']).map((entry) => {
      const mondayDate = moment(`${entry.year}-${entry.week}`, 'YYYY-WW')
        .startOf('week')
        .format('DD.MM.');
      const sundayDate = moment(`${entry.year}-${entry.week}`, 'YYYY-WW')
        .endOf('week')
        .format('DD.MM.YY');
      return {
        label: `KW ${entry.week} ${mondayDate}-${sundayDate}`,
        value: `${entry.year}-${entry.week}`,
      };
    });
    return sorted;
  };

  getUserOptions = () => {
    const { auth, users, projects, filteredServiceTimeEntries } = this.props;
    let userProjects;
    // if admin, get all projects, if not, get only the ones the user is in
    if (auth.rightId === ADMIN) {
      userProjects = projects({ option: { plain: true } });
    } else {
      userProjects = projects({
        include: { userId: auth.id },
        option: { plain: true },
      });
    }
    // const usersData = [];
    if (!!userProjects?.length || !!filteredServiceTimeEntries?.length) {
      // if higher level than mechanic, get all users in all projects
      let userIds: number[] = [];
      userProjects.forEach((project: any) => {
        userIds = userIds.concat(project.userIds);
      });
      const serviceProjectUsers = filteredServiceTimeEntries?.map(({ userId }) => userId) || [];
      const alluserIds = uniq([...userIds, ...serviceProjectUsers]);
      // check if the user is an admin
      // if so, return all users, except department managers
      if (auth.rightId === ADMIN) {
        return users({
          exclude: { rightId: DEPARTMENT_MANAGER },
          include: { userId: alluserIds },
        });
      }
      // check if the user is an department manager
      // if so, return all users with the right mechanic
      if (auth.rightId === DEPARTMENT_MANAGER) {
        return users({
          include: { userId: alluserIds, rightId: MECHANIC },
        });
      }
      // check if the user is an project manager
      // if so, return all users and include the user himself
      if (auth.rightId === PROJECT_MANAGER) {
        const allUsers = users({
          include: { userId: alluserIds, rightId: MECHANIC },
        });
        const usersData = [
          users({
            include: { userId: auth.id },
          }),
        ];
        return usersData.concat(allUsers);
      }
      // if none of the above
      // its a mechanic, so send the user and the externals back
      // and contractor is stopa
      if (auth.contractorId === CONTRACTOR_STOPA) {
        const externalUsers = users({
          include: { userId: alluserIds, rightId: MECHANIC },
          exclude: { contractorId: CONTRACTOR_STOPA },
        });
        const usersData = [
          users({
            include: { userId: auth.id },
          }),
        ];
        return usersData
          .concat(externalUsers)
          .filter((value, i, self) => self.indexOf(value) === i);
      }
      // its an external contractor
      return [
        users({
          include: { userId: auth.id },
        }),
      ];
    }
    return [];
  };
  handleFlush = (fields: any) => {
    const { change } = this.props;
    fields.map((field: any) => change(field, null));
  };

  // returns true if all timesheets are approved
  checkTimeSheetAll = () => {
    const {
      auth,
      filteredProjectTimeEntries = [],
      filteredServiceTimeEntries = [],
      controllingCalendarWeek,
    } = this.props;

    if (auth.rightId < MECHANIC) {
      if (!controllingCalendarWeek.values.user) {
        return false;
      }
      //if user is selected in controllingCalendarWeek there is only one object in filteredEntries
      const hasNotApprovedTimeSheet = !!head(filteredProjectTimeEntries)?.projects?.find(
        (userProject) => userProject.state !== TIME_SHEET_APPROVED
      );
      //if user is selected in controllingCalendarWeek there is only one object in filteredEntries
      const hasNotClosedServiceProject = !!head(filteredServiceTimeEntries)?.projects?.find(
        (userProject) => userProject?.state !== TIME_SHEET_APPROVED
      );
      return !(hasNotApprovedTimeSheet || hasNotClosedServiceProject);
    }
    return null;
  };
  handleComplete = () => {
    const {
      filteredProjectTimeEntries,
      filteredServiceTimeEntries,
      saveTimeSheetAll,
      controllingCalendarWeek,
    } = this.props;
    if (!filteredProjectTimeEntries?.length && !filteredServiceTimeEntries?.length) return null;
    const weekValue = controllingCalendarWeek.values.week.value;
    const userId = parseInt(controllingCalendarWeek.values.user.value, 10);
    const selectedYear = moment(weekValue, 'YYYY-WW').year();
    const selectedWeek = moment(weekValue, 'YYYY-WW').week();
    saveTimeSheetAll({ userId, year: selectedYear, week: selectedWeek });
  };
  render() {
    const { controllingCalendarWeek } = this.props;

    return (
      <Fragment>
        <Grid
          item
          container
          spacing={3}
          justifyContent="flex-start"
          md={12}
          alignItems="flex-start"
        >
          {controllingCalendarWeek && (
            <Form>
              <AutocompleteForm
                size={2}
                options={this.getWeekOptions()}
                label="Übersicht"
                name="week"
                isClearable={false}
              />
              <AutocompleteForm
                size={4}
                options={this.getUserOptions()}
                label="für"
                name="user"
                onChange={() => this.handleFlush(['timeSheetState'])}
              />
              <RadiosForm
                label="Filter nach Status"
                name="timeSheetState"
                options={TIME_SHEET_STATES}
                size={4}
                disabled={controllingCalendarWeek.values?.user?.value?.length > 0}
              />
              <Button
                alignRight
                size={2}
                handleClick={() => this.handleFlush(['user', 'timeSheetState'])}
              >
                Zurücksetzen
              </Button>
              {this.checkTimeSheetAll() && (
                <Button alignRight size={2} handleClick={() => this.handleComplete()}>
                  An Zeus Übertragen
                </Button>
              )}
            </Form>
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'controllingCalendarWeek',
  })(controllingCalendarWeek)
);
