import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ProjectDetails from '../../../../Molecules/AdminAreaDetails/ProjectDetails';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';
import {
  projectSelector,
  customerSelector,
  siteSelector,
  customerContactSelector,
  projectTypeSelector,
  projectKindSelector,
  formStationTypeSelector,
  formSelector,
  userSelector,
  groupSelector,
} from '../../../../../helpers/selectors';
import ProjectUser from '../../../../Organisms/ProjectUser/ProjectUser';
import ProjectManager from '../../../../Organisms/ProjectManager/ProjectManager';
import {
  FORM_TYPE_CHECK,
  RIGHT_CUSTOMER_GENERAL,
  RIGHT_CUSTOMER_PROJECT_MANAGER,
  RIGHT_CUSTOMER_ADMIN,
} from '../../../../../../../shared/src/constants/general';
import { saveProjectAction } from '../../../../../actions';
import SidebarScrollSpy from '../../../../Organisms/Sidebar/SidebarScrollSpy';
import { PROJECT_SCROLLSPY_ITEMS } from '../../../../../constants/all';
import AdminOuterWrapper from '../../../../Templates/AdminWrappers/AdminOuterWrapper';

const mapStateToProps = (state: any) => ({
  groups: (filter: any) => groupSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  customerContacts: (filter: any) => customerContactSelector(state, filter),
  formStationTypes: (filter: any) => formStationTypeSelector(state, filter),
  forms: (filter: any) => formSelector(state, filter),
  sites: (filter: any) => siteSelector(state, filter),
  customers: (filter: any) => customerSelector(state, filter),
  projectTypes: (filter: any) => projectTypeSelector(state, filter),
  projectKinds: (filter: any) => projectKindSelector(state, filter),
  projects: (filter: any) => projectSelector(state, filter),
  // redux-form
  pageProjectEdit: state.form.pageProjectEdit,
});

const mapDispatchToProps = (dispatch: any) => ({
  saveProject: (project: any) => dispatch(saveProjectAction(project)),
});

interface RouteParams {
  id: string;
}

interface ComponentOwnProps {}

interface ComponentStateProps {
  groups: (...args: any[]) => any;
  projects: (...args: any[]) => any;
  customerContacts: (...args: any[]) => any;
  customers: (...args: any[]) => any;
  sites: (...args: any[]) => any;
  projectTypes: (...args: any[]) => any;
  projectKinds: (...args: any[]) => any;
  formStationTypes: (...args: any[]) => any;
  forms: (...args: any[]) => any;
  pageProjectEdit?: any;
}

interface ComponentDispatchProps {
  saveProject: (...args: any[]) => any;
}

type ComponentProps = ComponentOwnProps &
  ComponentStateProps &
  ComponentDispatchProps &
  RouteComponentProps<RouteParams> &
  InjectedFormProps;

type ComponentOwnState = {
  scrollSpyLabel: string[];
  projectId: any;
};

class PageProjectEdit extends React.Component<ComponentProps, ComponentOwnState> {
  static defaultProps = {
    pageProjectEdit: null,
  };

  state = {
    scrollSpyLabel: ['', ''],
    projectId: null,
  };

  componentWillMount() {
    const {
      initialize,
      customers,
      sites,
      customerContacts,
      projectTypes,
      projectKinds,
      forms,
      formStationTypes,
      projects,
      match,
    } = this.props;
    let formStations;
    const projectId = parseInt(match.params.id, 10);
    this.setState({ projectId: projectId });
    const formCheck = forms({
      include: { projectId: [projectId], formTypeId: FORM_TYPE_CHECK },
      option: { plain: true },
    });
    const project = projects({
      include: { projectId },
      option: { plain: true },
    });
    const customer = customers({ include: { customerId: project.customerId } });

    const site = project.siteId ? sites({ include: { siteId: project.siteId } }) : null;
    const sitePlain = project.siteId
      ? sites({ include: { siteId: project.siteId }, option: { plain: true } })
      : null;

    const projectType = project.projectTypeId
      ? projectTypes({
          include: { projectTypeId: project.projectTypeId },
        })
      : null;

    const projectKind = project.projectKindId
      ? projectKinds({
          include: { projectKindId: project.projectKindId },
        })
      : null;

    if (formCheck.length > 0) {
      formStations = formStationTypes({ include: { formId: formCheck[0].id } });
    }

    // get customer contacts
    const customerContact0 = customerContacts({
      include: { customerContactId: project.customerContactIds, rightId: RIGHT_CUSTOMER_GENERAL },
      option: { all: true },
    });

    const customerContact1 = customerContacts({
      include: {
        customerContactId: project.customerContactIds,
        rightId: RIGHT_CUSTOMER_ADMIN,
      },
      option: { all: true },
    });
    const customerContact2 = customerContacts({
      include: {
        customerContactId: project.customerContactIds,
        rightId: RIGHT_CUSTOMER_PROJECT_MANAGER,
      },
      option: { all: true },
    });

    // set label for scrollspy
    this.setState({ scrollSpyLabel: [project.number, customer.label] });

    initialize({
      ...project,
      fab: sitePlain?.fab,
      customer,
      site,
      customerContact0,
      customerContact1,
      customerContact2,
      projectType,
      projectKind,
      projectStationTypeCount: formCheck.length > 0 ? formStations.length : null,
    });
  }

  componentDidUpdate(newProps: any) {
    if (parseInt(newProps.match.params.id, 10) !== this.state.projectId) {
      const {
        initialize,
        customers,
        sites,
        customerContacts,
        projectTypes,
        projectKinds,
        forms,
        formStationTypes,
        projects,
        match,
      } = this.props;
      let formStations;
      const projectId = parseInt(match.params.id, 10);
      this.setState({ projectId: projectId });
      const formCheck = forms({
        include: { projectId: [projectId], formTypeId: FORM_TYPE_CHECK },
        option: { plain: true },
      });
      const project = projects({
        include: { projectId },
        option: { plain: true },
      });

      const customer = customers({ include: { customerId: project.customerId } });

      const site = project.siteId ? sites({ include: { siteId: project.siteId } }) : null;
      const sitePlain = project.siteId
        ? sites({ include: { siteId: project.siteId }, option: { plain: true } })
        : null;

      const projectType = project.projectTypeId
        ? projectTypes({
            include: { projectTypeId: project.projectTypeId },
          })
        : null;

      const projectKind = project.projectKindId
        ? projectKinds({
            include: { projectKindId: project.projectKindId },
          })
        : null;

      if (formCheck.length > 0) {
        formStations = formStationTypes({ include: { formId: formCheck[0].id } });
      }

      // get customer contacts
      const customerContact0 = customerContacts({
        include: { customerContactId: project.customerContactIds, rightId: RIGHT_CUSTOMER_GENERAL },
        option: { all: true },
      });

      const customerContact1 = customerContacts({
        include: {
          customerContactId: project.customerContactIds,
          rightId: RIGHT_CUSTOMER_ADMIN,
        },
        option: { all: true },
      });
      const customerContact2 = customerContacts({
        include: {
          customerContactId: project.customerContactIds,
          rightId: RIGHT_CUSTOMER_PROJECT_MANAGER,
        },
        option: { all: true },
      });

      // set label for scrollspy
      this.setState({ scrollSpyLabel: [project.number, customer.label] });

      initialize({
        ...project,
        fab: sitePlain.fab,
        customer,
        site,
        customerContact0,
        customerContact1,
        customerContact2,
        projectType,
        projectKind,
        projectStationTypeCount: formCheck.length > 0 ? formStations.length : null,
      });
    }
  }
  handleSave = () => {
    const { saveProject, pageProjectEdit, groups } = this.props;
    const formValues = { ...pageProjectEdit.values };
    if (formValues) {
      // see if autocomplete has been changed/set, then take the values from it
      const customerId = formValues.customer ? formValues.customer.value : formValues.customerId;
      const siteId = formValues.site ? formValues.site.value : formValues.siteId;
      const projectKindId = formValues.projectKind
        ? formValues.projectKind.value
        : formValues.projectKindId;
      const projectTypeId = formValues.projectType
        ? formValues.projectType.value
        : formValues.projectTypeId;
      const uniqueUserIds = formValues.userIds.filter(
        (value: any, i: any, self: any) => self.indexOf(value) === i
      );
      groups({}).forEach((group: any) => {
        if (formValues[group.shortName]) {
          formValues[group.shortName] = formValues[group.shortName].padStart(3, '0');
        }
      });
      // get the customerContactIds
      const customerContactId0 = formValues.customerContact0
        ? formValues.customerContact0.value
        : formValues.customerContact0;
      const customerContactId1 = formValues.customerContact1
        ? formValues.customerContact1.value
        : formValues.customerContact1;
      const customerContactId2 = formValues.customerContact2
        ? formValues.customerContact2.value
        : formValues.customerContact2;

      const customerContactIds: string[] = [
        customerContactId0,
        customerContactId1,
        customerContactId2,
      ]
        .filter((e: string) => e !== undefined)
        .filter((e: string) => e !== '');

      // get the data from the form
      const project = {
        ...formValues,
        userIds: uniqueUserIds,
        customerId,
        customerContactIds,
        siteId,
        projectKindId,
        projectTypeId,
      };
      saveProject(project);
    }
  };
  scrollToRefs = () => PROJECT_SCROLLSPY_ITEMS.map(() => React.createRef());

  render() {
    const { scrollSpyLabel } = this.state;
    const { pageProjectEdit } = this.props;
    const scrollToRefs = this.scrollToRefs();
    return (
      <AdminOuterWrapper withScrollSpy>
        {pageProjectEdit && (
          <Fragment>
            <AdminSettingsWrapper
              alignItems="flex-start"
              heading="Projektdetails"
              id="projectDetails"
              setRef={scrollToRefs[0]}
              editWrapper
            >
              <ProjectDetails handleSave={this.handleSave} />
            </AdminSettingsWrapper>
            <AdminSettingsWrapper
              alignItems="flex-start"
              id="projectUser"
              heading="Monteure"
              setRef={scrollToRefs[1]}
              editWrapper
            >
              <ProjectUser handleSave={this.handleSave} />
            </AdminSettingsWrapper>
            <AdminSettingsWrapper
              alignItems="flex-start"
              heading="PL & AL"
              id="projectManager"
              setRef={scrollToRefs[2]}
              editWrapper
            >
              <ProjectManager handleSave={this.handleSave} />
            </AdminSettingsWrapper>
            <SidebarScrollSpy
              scrollSpyItems={PROJECT_SCROLLSPY_ITEMS}
              scrollToRefs={scrollToRefs}
              handleSave={this.handleSave}
              scrollSpyLabel={scrollSpyLabel}
              backPath="project"
            />
          </Fragment>
        )}
      </AdminOuterWrapper>
    );
  }
}

export default connect<ComponentStateProps, ComponentDispatchProps, ComponentOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'pageProjectEdit',
  })(withRouter<any, any>(PageProjectEdit))
);
