import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Form from '../../Atoms/Form/Form';
import TextFieldForm from '../../Atoms/TextField/TextFieldForm';
import Button from '../../Atoms/Button/Button';
import { addSiteAction } from '../../../actions';

const mapDispatchToProps = (dispatch: any) => ({
  addSite: (
    customerId: any,
    adress: any,
    adressAdditional: any,
    city: any,
    postalCode: any,
    country: any,
    fab: any
  ) =>
    dispatch(
      addSiteAction({
        customerId,
        adress,
        adressAdditional,
        city,
        postalCode,
        country,
        fab,
      })
    ),
});

type SiteCreateProps = {
  match: { params: { id: string } };
  addSite: (...args: any[]) => any;
  initialize: (...args: any[]) => any;
  reset: (...args: any[]) => any;
  handleSubmit: (...args: any[]) => any;
};

class SiteCreate extends PureComponent<SiteCreateProps, {}> {
  componentWillMount() {
    const { initialize, match } = this.props;
    const customerId = parseInt(match.params.id, 10);
    initialize({
      customerId,
      adress: '',
      adressAdditional: '',
      city: '',
      postalCode: '',
      country: '',
      fab: '',
    });
  }
  handleCreate = (values: any) => {
    const { addSite, reset } = this.props;
    const { customerId, adress, adressAdditional, city, postalCode, country, fab } = values;
    addSite(customerId, adress, adressAdditional, city, postalCode, country, fab);
    reset();
  };
  render() {
    const { handleSubmit } = this.props;
    return (
      <Fragment>
        <Form handleSubmit={handleSubmit(this.handleCreate)}>
          <TextFieldForm size={2} name="adress" label="Adresse" />
          <TextFieldForm size={2} name="adressAdditional" label="Zusatzfeld" />
          <TextFieldForm size={1} name="postalCode" label="PLZ" />
          <TextFieldForm size={2} name="city" label="Stadt" />
          <TextFieldForm size={2} name="country" label="Land" />
          <TextFieldForm size={1} name="fab" label="FAB" />
          <Button handleClick={handleSubmit(this.handleCreate)} alignRight>
            Neu Anlegen
          </Button>
        </Form>
      </Fragment>
    );
  }
}

export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'siteCreate',
  })(withRouter<any, any>(SiteCreate))
);
