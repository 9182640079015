import React from 'react';
import { Grid, Dialog, DialogContent } from '@mui/material';

type ComponentOwnProps = {
  open: boolean;
  handleClose: (...args: any[]) => any;
};

const DialogeSignatureWrapper: React.FC<ComponentOwnProps> = ({ open, handleClose, children }) => (
  <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
    <DialogContent>
      <Grid container justifyContent="center">
        <Grid container item md={12} spacing={5}>
          {children}
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>
);
export default DialogeSignatureWrapper;
