export const Commandments: string[][] = [
  [
    'Respekt',
    ' … Wir zeigen und erwarten Aufmerksamkeit und Wertschätzung gegenüber Menschen und Sachen.',
  ],
  [
    'Partnerschaft',
    ' … Wir leben unsere Kunden- und Lieferanten-Beziehungen intern wie extern. Unser Tun ist auf unseren gemeinsamen Erfolg ausgerichtet.',
  ],
  ['Verantwortung', ' … Wir denken und handeln im Sinne des Unternehmens.'],
  ['Verbindlichkeit', ' … Wir treffen klare Vereinbarungen und halten sie ein.'],
  [
    'Weiterentwicklung',
    ' … Wir gestalten unsere Zukunft mit Kreativität, Mut und Freude an der Veränderung.',
  ],
];
