import React from 'react';
import { connect } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import moment from 'moment';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import OpenInNew from '@mui/icons-material/OpenInNew';
import Typography from '../../../../../../Atoms/Typography/Typography';
import TextField from '../../../../../../Atoms/TextField/TextField';
import { userSelector } from '../../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  users: (filter: any) => userSelector(state, filter),
});

type StationWrapperProps = {
  station: any;
  stationAnswerCheck: any;
  users?: (...args: any[]) => any;
  stationTypes: any[];
  onClick: (...args: any[]) => any;
  onDelete: (...args: any[]) => any;
};

const StationWrapper: React.SFC<StationWrapperProps> = ({
  station,
  stationTypes,
  stationAnswerCheck,
  onClick,
  users = () => {},
  onDelete,
}) => {
  return (
    <Grid
      item
      md={12}
      container
      justifyContent="flex-start"
      alignItems="center"
      spacing={3}
      style={{ margin: '0 0 -12px 0' }}
    >
      <TextField
        label="Stations-Name"
        value={station.name}
        size={2}
        // formQuestion
        disabled
      />
      <TextField
        label="StationsTyp"
        size={2}
        value={stationTypes.find((s) => s.id === station.stationTypeId).name}
        disabled
        // formQuestion
      />
      {station.optional ? (
        <Typography variant="body1" value="SST" size={1} />
      ) : (
        <Grid item md={1} />
      )}
      <Typography
        // add for lastEditedBy
        caption={`${moment(station.updatedAt, 'YYYY-MM-DD').format('YYYY-MM-DD')}: ${
          users({
            include: { userId: station.userId },
          }).label
        }`}
        captionType="bot"
        variant="body1"
        size={2}
      />
      <Grid item md={1} style={{ marginLeft: 'auto' }}>
        <IconButton onClick={() => onClick(station.id)}>
          {station.completed ? <Visibility /> : <OpenInNew />}
        </IconButton>
      </Grid>
      <Typography
        size={3}
        value={station.completed ? <Lock /> : <LockOpen />}
        captionType="after"
        caption={`${stationAnswerCheck.no}x nein, ${stationAnswerCheck.notAnswered}x unbeantwortet`}
        variant="h6"
      />
      <Grid item md={1} style={{ marginLeft: 'auto' }}>
        <IconButton onClick={() => onDelete(station.id)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default connect(mapStateToProps, null)(StationWrapper);
