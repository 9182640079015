import React, { Fragment, memo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AdminSettingsWrapper from '../../Templates/AdminWrappers/AdminSettingsWrapper';
import ActiveTableRow from '../../Atoms/ActiveTableRow/ActiveTableRow';
import { getSortedDataFour } from '../../../helpers/getSortedData';
import {
  groupSelector,
  roleSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
  ruleSelector,
} from '../../../helpers/selectors';
import {
  SERVICE_TYPE_TRAVEL,
  SERVICE_TYPE_BREAK,
} from '../../../../../shared/src/constants/general';
import { compareProps } from '../../../helpers/compareProps';

const mapStateToProps = (state: any) => ({
  rules: (filter: any) => ruleSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  // For memo prop compare and rerender
  compareRules: state.rootReducer.rules,
});

type RuleTableProps = {
  history: any;
  rules: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  roles: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
};
const RuleTable: React.SFC<RuleTableProps> = memo(
  ({ history, groups, roles, rules, services, specificationAs, specificationBs }) => {
    const getRules = () => {
      const rulesComplete = rules({
        exclude: { serviceTypeId: [SERVICE_TYPE_TRAVEL, SERVICE_TYPE_BREAK] },
      }).map((rule: any) => {
        const serviceName = rule.serviceId
          ? services({
              include: { serviceId: rule.serviceId },
              option: { plain: true },
            }).name
          : null;
        const specificationAName = rule.specificationAId
          ? specificationAs({
              include: { specificationAId: rule.specificationAId },
              option: { plain: true },
            }).name
          : null;
        const specificationBName = rule.specificationBId
          ? specificationBs({
              include: { specificationBId: rule.specificationBId },
              option: { plain: true },
            }).name
          : null;
        const newRule = {
          ...rule,
          serviceName,
          specificationAName,
          specificationBName,
        };
        return newRule;
      });
      return getSortedDataFour(
        rulesComplete,
        'deactivated',
        'serviceName',
        'specificationAName',
        'specificationBName',
        true
      );
    };
    return (
      <AdminSettingsWrapper>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Operation</TableCell>
              <TableCell>Tätigkeit</TableCell>
              <TableCell>Spezifikation A</TableCell>
              <TableCell>Spezifikation B</TableCell>
              <TableCell>Gruppen</TableCell>
              <TableCell>Rollen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getRules().map((rule: any) => (
              <ActiveTableRow key={rule.id} gray={rule.deactivated}>
                <TableCell>
                  <IconButton onClick={() => history.push(`/admin/rule/${rule.id}`)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {rule.serviceName}
                </TableCell>
                <TableCell>
                  {rule.specificationAId ? (
                    <Fragment>{rule.specificationAName}</Fragment>
                  ) : (
                    <Fragment>Keine Angabe</Fragment>
                  )}
                </TableCell>
                <TableCell>
                  {rule.specificationBId ? (
                    <Fragment>{rule.specificationBName}</Fragment>
                  ) : (
                    <Fragment>Keine Angabe</Fragment>
                  )}
                </TableCell>
                <TableCell>
                  {groups({
                    include: { groupId: rule.groups },
                    option: { plain: true, array: true },
                  }).map((el: any) => `${el.name} `)}
                </TableCell>
                <TableCell>
                  {roles({
                    include: { roleId: rule.roles },
                    option: { plain: true, array: true },
                  }).map((el: any) => `${el.name} `)}
                </TableCell>
              </ActiveTableRow>
            ))}
          </TableBody>
        </Table>
      </AdminSettingsWrapper>
    );
  },
  compareProps
);

export default connect(mapStateToProps, null)(withRouter<any, any>(RuleTable));
