import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import Form from '../../../../Atoms/Form/Form';
import TextFieldForm from '../../../../Atoms/TextField/TextFieldForm';
import Button from '../../../../Atoms/Button/Button';
import DataTable from '../../../../Molecules/Tables/DataTable';
import RuleTabs from '../../../../Organisms/Tabs/RuleTabs';
import {
  SPECIFICATIONA_ARRIVAL,
  SPECIFICATIONA_DEPARTURE,
} from '../../../../../../../shared/src/constants/general';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';
import { addSpecificationAAction } from '../../../../../actions';
import { specificationASelector } from '../../../../../helpers/selectors';
import { RULE_EDIT_SPECIFICATIONAS } from '../../../../../constants/all';

const mapStateToProps = (state: any) => ({
  specificationAs: (filter: any) => specificationASelector(state, filter),
});
const mapDispatchToProps = (dispatch: any) => ({
  addSpecificationA: (name: any) => dispatch(addSpecificationAAction(name)),
});
type PageSpecificationAOverviewProps = {
  specificationAs: (...args: any[]) => any;
  history: any;
  addSpecificationA: (...args: any[]) => any;
  initialize: (...args: any[]) => any;
  reset: (...args: any[]) => any;
  handleSubmit: (...args: any[]) => any;
};
class PageSpecificationAOverview extends PureComponent<PageSpecificationAOverviewProps, {}> {
  componentWillMount() {
    const { initialize } = this.props;
    initialize({
      name: '',
    });
  }
  getSpecificationA = async (specificationAId: any) => {
    const { history } = this.props;
    history.push(`/admin/rule/specificationa/${specificationAId}`);
  };
  handleCreate = (values: any) => {
    const { addSpecificationA, reset } = this.props;
    const { name } = values;
    if (name.value !== '') {
      addSpecificationA(name);
      reset();
    }
  };
  render() {
    const { specificationAs, handleSubmit } = this.props;
    // const { name } = this.state;
    // travel services
    const specificationAsTableData = specificationAs({
      exclude: {
        specificationAId: [SPECIFICATIONA_ARRIVAL, SPECIFICATIONA_DEPARTURE],
      },
      option: { plain: true, deactivated: true },
    });
    return (
      <Fragment>
        <RuleTabs selectedTab={RULE_EDIT_SPECIFICATIONAS} />
        <AdminSettingsWrapper>
          <Form handleSubmit={handleSubmit(this.handleCreate)} justify="center">
            <TextFieldForm size={4} name="name" label="Tätigkeit" />
            <Button handleClick={handleSubmit(this.handleCreate)}>Neu Anlegen</Button>
            <DataTable
              label="Tätigkeiten"
              data={specificationAsTableData}
              getVariable={this.getSpecificationA}
            />
          </Form>
        </AdminSettingsWrapper>
      </Fragment>
    );
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<any, any>({
    // a unique name for the form
    form: 'specificationAsCreate',
  })(withRouter<any, any>(PageSpecificationAOverview))
);
