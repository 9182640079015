import { createSelector } from 'reselect';
import { checkSingleValue } from './utilities';
import { RootState } from '../../store/index';

export const siteSelector = <T = any>(
  state: RootState,
  {
    include = {
      customerId: null,
      siteId: null,
      fab: null,
    },
    option = {
      deactivated: false,
      plain: false,
    },
  }
): T =>
  createSelector(
    () => state.rootReducer.sites,
    (sites = []) => {
      // for single selected site
      if (checkSingleValue(include.siteId)) {
        const siteId = Array.isArray(include.siteId) ? include.siteId![0] : include.siteId;
        // @ts-ignore
        const site = sites.find((s) => s.id === parseInt(siteId, 10));

        if (site) {
          if (option.plain) {
            return site;
          }
          return {
            // label: `${site.country} - ${site.city} - ${site.adress} - ${site.fab}`,
            label: `${site.country} - ${site.city} - ${site.adress}`,
            value: `${site.id}`,
          };
        }
        return null;
      }

      let siteOptions = sites;
      if (!option.deactivated) {
        siteOptions = siteOptions?.filter((site: any) => site.deactivated !== true);
      }
      if (include.customerId) {
        siteOptions = siteOptions?.filter(
          // @ts-ignore
          (site) => site.customerId === parseInt(include.customerId, 10)
        );
      }

      // only the ones with fab
      if (include.fab) {
        siteOptions = siteOptions?.filter(
          // @ts-ignore
          (site) => site.fab !== null
        );
      }

      if (siteOptions) {
        if (option.plain) {
          return siteOptions;
        }
        return siteOptions.map((s: any) => ({
          // label: `${s.country} - ${s.city} - ${s.adress} - ${s.fab}`,
          label: `${s.country} - ${s.city} - ${s.adress}`,
          value: `${s.id}`,
        }));
      }
      return null;
    }
  )(null as any);

export default {};
