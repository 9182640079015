import React from 'react';
import { connect } from 'react-redux';
import { ADMIN } from '../../../../../../../shared/src/constants/general';
import CustomerCreate from '../../../../Molecules/AdminAreaCreates/CustomerCreate';
import CustomerTable from '../../../../Molecules/Tables/CustomerTable';
import Search from '../../../../Organisms/NavBar/components/Search';
import AdminOuterWrapper from '../../../../Templates/AdminWrappers/AdminOuterWrapper';
import AdminSettingsWrapper from '../../../../Templates/AdminWrappers/AdminSettingsWrapper';

const mapStateToProps = (state: any) => ({
  auth: state.rootReducer.auth,
  projects: state.rootReducer.projects,
  customers: state.rootReducer.customers,
  sites: state.rootReducer.sites,
});

type PageCustomerOverviewProps = {
  auth?: any;
  projects?: any[];
  customers?: any[];
  sites?: any[];
};
const PageCustomerOverview: React.SFC<PageCustomerOverviewProps> = ({
  auth = null,
  projects = null,
  customers = null,
  sites = null,
}) => (
  <AdminOuterWrapper>
    <CustomerCreate />
    {auth.rightId === ADMIN && projects && customers && sites && (
      <AdminSettingsWrapper editWrapper>
        <Search searchType="customer" />
      </AdminSettingsWrapper>
    )}
    <CustomerTable />
  </AdminOuterWrapper>
);

export default connect(mapStateToProps, null)(PageCustomerOverview);
