import React, { Fragment } from 'react';
import { Tab } from '@mui/material';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Tabs from '../../Atoms/Tabs/Tabs';
import {
  RULE_EDIT_DETAILS,
  RULE_EDIT_SERVICES,
  RULE_EDIT_SPECIFICATIONAS,
  RULE_EDIT_SPECIFICATIONBS,
} from '../../../constants/all';

interface ComponentOwnProps {
  selectedTab: number;
}

type ComponentProps = ComponentOwnProps & RouteComponentProps;

class RuleTabs extends React.Component<ComponentProps, {}> {
  handleTab = (event: any, value: any) => {
    const { history } = this.props;
    if (value === RULE_EDIT_DETAILS) {
      history.push('/admin/rule/');
    } else if (value === RULE_EDIT_SERVICES) {
      history.push('/admin/rule/service/');
    } else if (value === RULE_EDIT_SPECIFICATIONAS) {
      history.push('/admin/rule/specificationa');
    } else if (value === RULE_EDIT_SPECIFICATIONBS) {
      history.push('/admin/rule/specificationb');
    }
  };
  render() {
    const { selectedTab } = this.props;
    return (
      <Fragment>
        <Tabs selectedTab={selectedTab} handleTab={this.handleTab}>
          <Tab label="Regeln" />
          <Tab label="Tätigkeit" />
          <Tab label="Spezifikation A" />
          <Tab label="Spezifikation B" />
        </Tabs>
      </Fragment>
    );
  }
}
export default withRouter(RuleTabs);
