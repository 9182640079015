import React, { PureComponent, Fragment } from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import TextFieldForm from '../../../../../Atoms/TextField/TextFieldForm';
import Typography from '../../../../../Atoms/Typography/Typography';
import AutocompleteForm from '../../../../../Molecules/Autocomplete/AutocompleteForm';
import {
  SERVICE_TYPE_BREAK,
  SPECIFICATIONA_ARRIVAL,
  SPECIFICATIONA_DEPARTURE,
  BILLING_TYPE_CALCULATED,
  BILLING_TYPE_WITHOUT,
  PROJECT_MANAGER,
} from '../../../../../../../../shared/src/constants/general';
import { getUserWa } from '../../../../../../helpers/utils';
import {
  projectSelector,
  userSelector,
  groupSelector,
  roleSelector,
  serviceTypeSelector,
  serviceSelector,
  specificationASelector,
  specificationBSelector,
  billingTypeSelector,
  billingSelector,
  ruleSelector,
} from '../../../../../../helpers/selectors';

const mapStateToProps = (state: any) => ({
  projects: (filter: any) => projectSelector(state, filter),
  users: (filter: any) => userSelector(state, filter),
  groups: (filter: any) => groupSelector(state, filter),
  roles: (filter: any) => roleSelector(state, filter),
  serviceTypes: (filter: any) => serviceTypeSelector(state, filter),
  services: (filter: any) => serviceSelector(state, filter),
  specificationAs: (filter: any) => specificationASelector(state, filter),
  specificationBs: (filter: any) => specificationBSelector(state, filter),
  billingTypes: (filter: any) => billingTypeSelector(state, filter),
  billings: (filter: any) => billingSelector(state, filter),
  rules: (filter: any) => ruleSelector(state, filter),
});

interface ComponentStateProps {
  projects: (...args: any[]) => any;
  users: (...args: any[]) => any;
  groups: (...args: any[]) => any;
  roles: (...args: any[]) => any;
  serviceTypes: (...args: any[]) => any;
  services: (...args: any[]) => any;
  specificationAs: (...args: any[]) => any;
  specificationBs: (...args: any[]) => any;
  billingTypes: (...args: any[]) => any;
  billings: (...args: any[]) => any;
  rules: (...args: any[]) => any;
}

interface ComponentOwnProps {
  timeEntryVisitData: any[];
  handleFlush: (...args: any[]) => any;
  formData: any;
}

type ComponentProps = ComponentStateProps & ComponentOwnProps;

class TimeEntryRules extends PureComponent<ComponentProps, {}> {
  getServices = () => {
    const { services, rules, formData } = this.props;
    const { serviceType, role, group } = formData.values;
    if (serviceType && role && group) {
      // Get the rules for the group and role
      const ruleOptions = rules({
        include: {
          serviceTypeId: serviceType.value,
          roleId: role.value,
          groupId: group.value,
        },
      });
      // if there are any rules get only the services
      if (ruleOptions) {
        // if found only return the ids
        const serviceId = ruleOptions.map((rule: any) => rule.serviceId);
        const service = services({
          include: { serviceId },
          option: { array: true },
        });
        return service;
      }
    }
    return [];
  };
  getSpecificationAs = () => {
    const { rules, specificationAs, formData } = this.props;
    const { serviceType, role, group, service } = formData.values;
    // Get the rules for the group and role
    if (serviceType && role && group && service) {
      const ruleOptions = rules({
        include: {
          serviceTypeId: serviceType.value,
          roleId: role.value,
          groupId: group.value,
          serviceId: service.value,
        },
        option: {
          array: true,
        },
      });
      if (ruleOptions) {
        // if found only return the ids
        const specificationAId = ruleOptions.map((rule: any) => rule.specificationAId);
        const specificationA = specificationAs({
          include: { specificationAId },
          option: {
            array: true,
          },
        });
        return specificationA || [];
      }
    }
    return [];
  };
  getSpecificationBs = () => {
    const { rules, specificationBs, formData } = this.props;
    const { serviceType, role, group, service, specificationA } = formData.values;
    if (serviceType && role && group && service && specificationA) {
      // Get the rules for the group and role
      const ruleOptions = rules({
        include: {
          serviceTypeId: serviceType.value,
          roleId: role.value,
          groupId: group.value,
          serviceId: service.value,
          specificationAId: specificationA.value,
        },
        option: {
          array: true,
        },
      });
      if (ruleOptions) {
        const specificationBId = ruleOptions.map((rule: any) => rule.specificationBId);
        const specificationB = specificationBs({
          include: { specificationBId },
          option: {
            array: true,
          },
        });
        return specificationB || [];
      }
    }
    return [];
  };
  getSelectedRule = () => {
    const { rules, formData } = this.props;
    const { group, role, serviceType, service, specificationA, specificationB } = formData.values;
    if (group && role && serviceType && service) {
      const rule = rules({
        include: {
          serviceTypeId: serviceType.value,
          roleId: role.value,
          groupId: group.value,
          serviceId: service.value,
          specificationAId: specificationA ? specificationA.value : null,
          specificationBId: specificationB ? specificationB.value : null,
        },
      });
      return rule;
    }
    return null;
  };
  getGroupOptions = () => {
    const { groups, formData } = this.props;
    const { user } = formData.values;
    if (user) {
      return groups({ include: { userId: user.value } });
    }
    return [];
  };
  getRoleOptions = () => {
    const { roles, formData } = this.props;
    const { user } = formData.values;
    if (user) {
      return roles({ include: { userId: user.value } });
    }
    return [];
  };
  getSelectedProject = () => {
    const { projects, formData } = this.props;
    const { project } = formData.values;
    if (project) {
      return projects({
        include: { projectId: project.value },
        option: { plain: true },
      });
    }
    return [];
  };
  handleChange = (field: any) => {
    const { handleFlush, timeEntryVisitData } = this.props;
    let flushFields;
    if (field === 'role' || field === 'group') {
      flushFields = [
        'billingType',
        'billing',
        'commentBilling',
        'service',
        'specificationA',
        'specificationB',
      ];
    }
    if (field === 'serviceType') {
      // flushFields = ['service', 'specificationA', 'specificationB', 'billingType', 'billing'];
      flushFields = [
        'service',
        'specificationA',
        'specificationB',
        'billingType',
        'billing',
        'visitTopic',
        'visitParticipants',
        'visitDistributionCircle',
      ];
      if (timeEntryVisitData) {
        timeEntryVisitData.forEach((el) =>
          flushFields.push(
            `visitDiscussedTopic${el}`,
            `visitComment${el}`,
            `visitNameResponsible${el}`,
            `visitDeadline${el}`
          )
        );
      }
    }
    if (field === 'service') {
      flushFields = ['specificationA', 'specificationB'];
    }
    if (field === 'specificationA') {
      flushFields = ['specificationB'];
    }
    if (field === 'billingType') {
      flushFields = ['billing'];
    }
    handleFlush(flushFields);
  };
  getWa = (project: any, groupId: any, userId: any) => {
    const { groups } = this.props;
    const userGroups = groups({
      include: { userId, groupId: [groupId] },
      option: { plain: true },
    });
    return getUserWa(project, userGroups);
  };

  render() {
    const { users, serviceTypes, billingTypes, billings, formData } = this.props;
    const { serviceType, specificationA, billingType, user, group } = formData.values;
    const rule = this.getSelectedRule();
    const project = this.getSelectedProject();
    const serviceTypeId = serviceType ? parseInt(serviceType.value, 10) : null;
    const billingTypeId = billingType ? parseInt(billingType.value, 10) : null;
    const specificationAId = specificationA ? parseInt(specificationA.value, 10) : null;
    const userRightId = user
      ? users({ include: { userId: user.value }, option: { plain: true } }).rightId
      : null;
    const userId = user
      ? users({ include: { userId: user.value }, option: { plain: true } }).id
      : null;
    const groupId = group ? parseInt(group.value, 10) : 0;
    const waNumber = group && user ? this.getWa(project, groupId, userId) : [];

    return (
      <Fragment>
        <Grid md={12} item container spacing={5}>
          {userRightId !== PROJECT_MANAGER && (
            <Fragment>
              <AutocompleteForm
                options={this.getGroupOptions()}
                label="Gruppe"
                name="group"
                onChange={() => this.handleChange('group')}
              />
              <AutocompleteForm
                options={this.getRoleOptions()}
                label="Rolle"
                name="role"
                onChange={() => this.handleChange('role')}
              />
            </Fragment>
          )}
        </Grid>
        <Grid md={12} item container spacing={5}>
          <AutocompleteForm
            options={serviceTypes({})}
            label="Art"
            name="serviceType"
            onChange={() => this.handleChange('serviceType')}
          />
          {serviceTypeId && (
            <Fragment>
              {serviceTypeId !== SERVICE_TYPE_BREAK && (
                <Fragment>
                  <AutocompleteForm
                    options={billingTypes({})}
                    label="Abrechnungsart"
                    size={3}
                    name="billingType"
                    onChange={() => this.handleChange('billingType')}
                  />
                  {billingTypeId && (
                    <Fragment>
                      {/* {billingTypeId !== BILLING_TYPE_WITHOUT && (
                        <AutocompleteForm
                          options={billings({
                            include: { billingTypeId: billingType.value },
                          })}
                          label="Abrechnung"
                          name="billing"
                        />
                      )} */}
                      {billingTypeId !== BILLING_TYPE_CALCULATED && (
                        <TextFieldForm
                          name="commentBilling"
                          label="Kommentar zur Abrechnung"
                          // TODO: Do we need this here?
                          // onChange={() => this.handleChange('commentBilling')}
                        />
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Grid>
        {serviceTypeId && (
          <Fragment>
            {serviceTypeId !== SERVICE_TYPE_BREAK && (
              <Fragment>
                <Grid md={12} item container spacing={5}>
                  <AutocompleteForm
                    size={4}
                    options={this.getServices()}
                    label="Tätigkeit allgemein"
                    name="service"
                    onChange={() => this.handleChange('service')}
                  />
                </Grid>
                <Grid md={12} item container spacing={5}>
                  <AutocompleteForm
                    size={3}
                    options={this.getSpecificationAs()}
                    label="Spezifikation A"
                    name="specificationA"
                    onChange={() => this.handleChange('specificationA')}
                  />
                  {specificationAId && (
                    <Fragment>
                      {specificationAId !== SPECIFICATIONA_DEPARTURE &&
                        specificationAId !== SPECIFICATIONA_ARRIVAL && (
                          <AutocompleteForm
                            size={3}
                            options={this.getSpecificationBs()}
                            label="Spezifikation B"
                            name="specificationB"
                          />
                        )}

                      {(specificationAId === SPECIFICATIONA_DEPARTURE ||
                        specificationAId === SPECIFICATIONA_ARRIVAL) && (
                        <TextFieldForm name="distance" label="Distanz" type="number" />
                      )}
                    </Fragment>
                  )}
                  {rule && project && (
                    <Fragment>
                      {rule.station === '1' && (
                        <TextFieldForm name="station" label="Stations-Nr." type="number" />
                      )}
                      {waNumber && (
                        <Typography
                          size={3}
                          // @ts-ignore
                          value={`${waNumber.number ? waNumber.number : 'k.A'}
                             ${rule.wa ? rule.wa : 'k.A.'}`}
                          variant="subtitle1"
                          caption="Wa-Nr. Vorgang"
                          captionType="top"
                        />
                      )}
                    </Fragment>
                  )}
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export default connect<ComponentStateProps, {}, ComponentOwnProps>(
  mapStateToProps,
  {}
)(TimeEntryRules);
