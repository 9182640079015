import React from 'react';
import { Grid, GridSize, Typography as MUITypography } from '@mui/material';

const styles = {
  withCaption: {
    paddingRight: '5px',
    display: 'inline',
  },
  textLight: {
    color: '#FFF',
  },
  textError: {
    color: '#f44336',
  },
  textDisabled: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontStyle: 'italic',
  },
  captionLight: {
    color: '#D3D3D3',
  },
  captionBlock: {
    display: 'block',
  },
  captionAfter: {
    display: 'inline',
  },
  alignCenter: {
    textAlign: 'center',
  },
  formQuestionGrid: {
    paddingTop: '24px!important',
  },
  formIconGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formIcon: {
    paddingRight: '0!important',
  },
  formButton: {
    display: 'block',
  },
};

type TypographyProps = {
  sx?: any;
  // TODO: only gridsize leads to error, why? In button it works...
  size?: GridSize | any;
  color?: string;
  variant?: any;
  style?: object;
  value?: string | object;
  caption?: string | object;
  captionType?: string;
  alignment?: string;
  disabled?: boolean;
  formQuestion?: boolean;
  formIcon?: boolean;
  formButton?: boolean;
};

const Typography: React.FC<TypographyProps> = React.memo(
  ({
    size = 1,
    color = 'default',
    variant = 'h6',
    style,
    value = '',
    caption,
    captionType = 'after',
    alignment,
    formQuestion,
    formButton,
    formIcon,
    disabled,
    sx,
  }) => {
    return (
      <Grid
        item
        md={size}
        sx={{
          ...(formQuestion && styles.captionLight),
          ...(formIcon && styles.formIconGrid),
          ...sx,
        }}
      >
        {caption && captionType === 'top' && (
          <MUITypography
            variant="caption"
            sx={{
              ...styles.captionBlock,
              ...(color === 'light' && styles.captionLight),
              ...(disabled && styles.textDisabled),
            }}
          >
            {caption}
          </MUITypography>
        )}
        <MUITypography
          variant={variant}
          style={style}
          sx={{
            ...(formIcon === true && styles.formIcon),
            ...(caption !== null && !formButton && styles.withCaption),
            ...(color === 'light' && styles.textLight),
            ...(color === 'error' && styles.textError),
            ...(alignment === 'center' && styles.alignCenter),
            ...(disabled && styles.textDisabled),
          }}
        >
          {value}
        </MUITypography>
        {caption && (captionType === 'after' || captionType === 'bot') && (
          <MUITypography
            variant="caption"
            sx={{
              ...(captionType === 'after' && styles.captionAfter),
              ...(captionType === 'bot' && styles.captionBlock),
              ...(color === 'light' && styles.captionLight),
              ...(disabled && styles.textDisabled),
            }}
          >
            {caption}
          </MUITypography>
        )}
      </Grid>
    );
  }
);

export default Typography;
